import {Forum, Hub, JoinInner, PieChartOutline, Share} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import * as ProfileEndpoints from '../../../../endpoints/ProfilesEndpoints';
import {Routes} from '../../../../globals/Routes';
import {ALL_ACCOUNT_SHARE_OPTIONS, ALL_OPP_SHARE_OPTIONS} from '../../../../helpers/EcosystemShareHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import Loading from '../../../../ui/Loading';
import SubNav from '../../../../ui/navigation/SubNav';
import IntelPage from '../../../shared/IntelPage';
import PartnerConnectionAccountsPage from './PartnerConnectionAccountsPage';
import PartnerConnectionHeader from './PartnerConnectionHeader';
import PartnerConnectionOpportunitiesPage from './PartnerConnectionOpportunitiesPage';
import PartnerConnectionShare from './PartnerConnectionShare';
import PartnerConnectionSummary from './PartnerConnectionSummary';
import FeatureHelper, {FEATURE_CHANNEL_PARTNER_INVITE_PERMISSION} from '../../../../helpers/FeatureHelper';
import MessageBoxInfo from '../../../../ui/messages/MessageBoxInfo';

class PartnerConnectionRouter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, partnerData: null, shareSettings: null, partnerId: this.partnerId};
		this.fetchPartner = this.fetchPartner.bind(this);
	}

	componentDidMount() {
		this.fetchPartner(this.partnerId);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get partnerId() {
		return this.props.match.params.partnerId;
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	fetchPartner(partnerId) {
		this.setState({loading: true});
		ProfileEndpoints.fetchChannelPartnerProfile(partnerId)
		.then((result) => {
			if (this.unmounted) return;

			// partner not found, so navigate up to partner selection
			if (!result.payload) {
				EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE);
				return;
			}

			result.payload.partnerId = partnerId;
			this.setState({partnerData: result.payload});
			return ChannelMappingEndpoints.getShareSettings(result.payload.personId);
		})
		.then((result) => {
			if (this.unmounted) return;
			this.addDisabledShareSettings(result.payload);
			this.setState({shareSettings: result.payload, loading: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchChannelPartnerProfile', error);
		});
	}

	addDisabledShareSettings(shareSettings) {
		let availableAccountShareOptions = shareSettings.accountShareSettings.availableShareOptions;
		ALL_ACCOUNT_SHARE_OPTIONS.forEach((shareOption, index) => {
			if (!availableAccountShareOptions.find((availableShareOption) => availableShareOption.key === shareOption)) {
				availableAccountShareOptions.splice(index, 0, {key: shareOption, disabled: true});
			}
		});
		let availableOppShareOptions = shareSettings.oppShareSettings.availableShareOptions;
		ALL_OPP_SHARE_OPTIONS.forEach((shareOption, index) => {
			if (!availableOppShareOptions.find((availableShareOption) => availableShareOption.key === shareOption)) {
				availableOppShareOptions.splice(index, 0, {key: shareOption, disabled: true});
			}
		});
		return availableAccountShareOptions;
	}

	get subNavData() {
		let route = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER;
		let {partnerId} = this.state;
		return [
			{
				label: 'Summary',
				MaterialIcon: PieChartOutline,
				route: route.PATH(partnerId, route.SUMMARY.PAGE_NAME)
			},
			{
				label: 'Share',
				MaterialIcon: Share,
				route: route.PATH(partnerId, route.SHARE.PAGE_NAME)
			},
			{
				label: 'Accounts',
				MaterialIcon: JoinInner,
				route: route.PATH(partnerId, route.SHARED_ACCOUNTS.PAGE_NAME),
				activeRoutes: [route.SHARED_ACCOUNTS.ROUTE]
			},
			{
				label: 'Opportunities',
				MaterialIcon: Hub,
				route: route.PATH(partnerId, route.SHARED_OPPORTUNITIES.PAGE_NAME),
				activeRoutes: [route.SHARED_OPPORTUNITIES.ROUTE]
			},
			{
				label: 'Collaborate',
				MaterialIcon: Forum,
				route: route.PATH(partnerId, route.INTEL.PAGE_NAME)
			}
		];
	}

	render() {
		if (this.state.loading) return <Loading>Loading Channel Partner...</Loading>;
		let inReportDialog = this.props.history.location.pathname !== this.props.location.pathname;
		let {partnerData, shareSettings} = this.state;
		const invitePermission = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_PARTNER_INVITE_PERMISSION);
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<PartnerConnectionHeader partnerData={partnerData} shareSettings={shareSettings} reportType={this.reportType}/>
				{!inReportDialog && <SubNav subNavData={this.subNavData}/>}
				{/* Single conditional rendering for permission check */}
				{invitePermission ? (
					<Switch>
						<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SUMMARY.ROUTE}
							   render={() => <PartnerConnectionSummary partner={partnerData}/>}/>
						<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARE.ROUTE}
							   render={() => <PartnerConnectionShare partner={partnerData} shareSettings={shareSettings}/>}/>
						<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_ACCOUNTS.ROUTE}
							   render={() => <PartnerConnectionAccountsPage partner={partnerData}
																			accountShareSettings={shareSettings.accountShareSettings}
																			reportType={this.reportType}/>}/>
						<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_OPPORTUNITIES.ROUTE}
							   render={() => <PartnerConnectionOpportunitiesPage partner={partnerData}
																				 oppShareSettings={shareSettings.oppShareSettings}
																				 reportType={this.reportType}/>}/>
						<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.INTEL.ROUTE}
							   render={(props) => <IntelPage {...props} partner={partnerData} inDetails={true}/>}/>
					</Switch>
				) : (
					<div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
						<MessageBoxInfo title={'You do not have access to Connect Partners Feature. Please reach out to your PartnerTap administrator'}
										hideIcon={true}/>
					</div>
				)}
			</div>
		);
	}
}

PartnerConnectionRouter.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerConnectionRouter));
