import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import Loading from '../../../ui/Loading';
import {partnerTapDropShadow, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import CoSellActionListMetadataLive from './co_sell_components/CoSellActionListMetadataLive';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import EnvHelper from '../../../helpers/EnvHelper';
import CoSellActionListWorkflowSelector from './co_sell_components/CoSellActionListWorkflowSelector';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import HorizontalBarGraph from '../../../ui/graphs/HorizontalBarGraph';
import {DASHBOARD_TYPE_WORKFLOW, WORKFLOW_SALES_PLAY, WORKFLOW_THRU_CHANNEL_PLAY} from './CoSellEngineHelperLive';
import {Diversity3, EditCalendar, EmojiPeople, Groups, ReceiptLong, ReduceCapacity, SwapHoriz, TrackChanges, Verified} from '@mui/icons-material';
import DashboardTopItem from '../shared/DashboardTopItem';
import ChannelHelper from '../../../helpers/ChannelHelper';
import CircleGraph from '../../../ui/graphs/CircleGraph';
import MessageBox from '../../../ui/messages/MessageBox';

const BOUNCES = 'BOUNCES';
const paperStyle = {padding: 10, borderRadius: 5, backgroundColor: partnerTapWhite};
const paperStyleColumn = {padding: 10, borderRadius: 5, backgroundColor: partnerTapWhite, display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1};
const recipientIntroStyle = {
	fontSize: 20,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
	flexBasis: 0,
	padding: 10,
	textAlign: 'center'
};
const recipientCountStyle = {fontSize: 24, fontWeight: 'bold'};

class CoSellActionListDashboard extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, actionListMetadata: null, activeWorkflow: null};
	}

	componentDidMount() {
		this.setState({loading: true});
		this.initData();
	}

	async initData() {
		try {
			const [activeWorkflowResult, actionListMetadataResponse, dashboard] = await Promise.all([
				CoSellEngineEndpoints.getActiveActionListWorkflow(this.actionListId),
				CoSellEngineEndpoints.getCoSellActionListMetadata(this.actionListId),
				CoSellEngineEndpoints.getActionListDashboard(this.actionListId, DASHBOARD_TYPE_WORKFLOW)
			]);
			if (this.unmounted || !actionListMetadataResponse || !actionListMetadataResponse.payload) return;
			const activeWorkflow = activeWorkflowResult.payload instanceof Array ? null : activeWorkflowResult.payload;

			this.setState({
				actionListMetadata: actionListMetadataResponse.payload,
				activeWorkflow: activeWorkflow,
				dashboard: dashboard?.payload || [],
				loading: false,
				averageDaysToIntro: dashboard?.payload?.averageDaysToIntro || 0,
				totalIntros: dashboard?.payload?.totalIntros || 0,
				uniqueRecipients: dashboard?.payload?.uniqueRecipients || [],
				participationData: this.prepareParticipationData(dashboard?.payload?.recipientParticipation || {}, dashboard?.payload?.participationToolTipMap || {}),
				emailAnalytics: dashboard?.payload?.emailAnalytics,
				throughChannelRecipientStats: dashboard?.payload.throughChannelRecipientStats || {}
			});
		}
		catch (error) {
			this.setState({loading: false, error: true});
			EnvHelper.serverError('Error loading workflow data for dashboard ', error);
		}
	}

	prepareParticipationData(recipientParticipation, participationToolTipMap) {
		return Object.entries(recipientParticipation).map(([key, value]) => {
			return {'orgName': key, 'data': Object.keys(value).map(name => ({name, value: value[name], toolTip: participationToolTipMap[name]}))};
		});
	}

	get showParticipationAnalytics() {
		const workFlowName = this.state.activeWorkflow?.name || "";
		return workFlowName !== WORKFLOW_THRU_CHANNEL_PLAY && workFlowName !== WORKFLOW_SALES_PLAY;
	}

	get actionListId() {
		return this.props.match.params.actionListId;
	}

	interpolateColor(color1, color2, factor) {
		const result = color1.slice();
		for (let i = 0; i < 3; i++) {
			result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
		}
		return result;
	}

	getColor(value, inverted) {
		const startColor = [242, 194, 194];
		const endColor = [210, 255, 206];
		const adjustedValue = inverted ? 1 - value : value;
		const interpolated = this.interpolateColor(startColor, endColor, adjustedValue);
		return `rgb(${interpolated[0]}, ${interpolated[1]}, ${interpolated[2]})`;
	}

	renderEmailAnalyticsCells(eventKey, emailAnalytics) {
		let cells = [];
		const widthPercentage = 100 / (Object.keys(emailAnalytics).length + 1);
		Object.keys(emailAnalytics).forEach((stepRecipeName, i) => {
			const stepEventData = emailAnalytics[stepRecipeName];
			const eventData = stepEventData.find((event) => event.eventType === eventKey);
			const eventVal = eventData?.rate || 0;
			const cellStyle = {
				backgroundColor: this.getColor(eventVal, eventData?.invertHeat || eventKey === BOUNCES),
				color: eventVal > 50 ? 'white' : 'black',
				padding: 5,
				textAlign: 'center',
				verticalAlign: 'middle',
				width: widthPercentage + '%'
			};
			cells.push(<td style={cellStyle} key={stepRecipeName + '_' + eventKey + '_' + i}>{Math.round(eventVal * 100)}%</td>);
		});
		return cells;
	}

	renderEmailAnalytics() {
		const {emailAnalytics} = this.state;
		const stepNames = Object.keys(emailAnalytics).map((v) => {
			const analyticsGroup = emailAnalytics[v];
			return analyticsGroup.at(0)?.stepNameDisplayName || v;
		});

		const eventNames = [
			{displayName: 'Bounce rate', key: BOUNCES},
			{displayName: 'Open rate', key: 'OPENS'},
			{displayName: 'Delivery rate', key: 'DELIVERIES'},
			{displayName: 'Click through rate', key: 'CLICKS'}
		];

		const maxWidth = 200 + (250 * stepNames.length);

		return (
			<div style={paperStyleColumn}>
				<h2 style={{padding: 10}}>Workflow Email Analytics</h2>
				<table style={{maxWidth: maxWidth}}>
					<thead>
					<tr>
						<th/>
						{stepNames.map((v, i) => {
							return (
								<th style={{border: 1}} key={v + '_' + i}>
									{v}
								</th>
							);
						})}
					</tr>
					</thead>
					<tbody>
					{eventNames.map((e, i) => {
						return (
							<tr key={'email_analytics_row_' + e.key + '_' + i}>
								<td style={{border: 1}}>{e.displayName}</td>
								{this.renderEmailAnalyticsCells(e.key, emailAnalytics)}
							</tr>
						);
					})}
					</tbody>
				</table>
			</div>
		);
	}

	render() {
		const {
			loading,
			error,
			actionListMetadata,
			activeWorkflow,
			dashboard,
			participationData,
			averageDaysToIntro,
			totalIntros,
			uniqueRecipients,
			throughChannelRecipientStats
		} = this.state;
		if (loading) return <Loading>Loading workflow dashboard...</Loading>;
		if (error) return <MessageBox title={"Workflow not active"}>There was an issue loading your workflow data or a workflow has not been started.</MessageBox>;

		return (
			<ScrollingContainer divId={'ecosystem_partner_dashboard'}>
				<div style={{display: 'flex', flexDirection: 'column', margin: 10, marginLeft: 5, marginRight: 5, overflow: 'scroll'}}>
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
						<CoSellActionListMetadataLive actionListMetadata={actionListMetadata} activeWorkflow={activeWorkflow}/>
						<CoSellActionListWorkflowSelector actionListMetadata={actionListMetadata}/>
					</div>
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
						<div style={paperStyle}>
							<h2 style={{padding: 10}}>Action List Record Results</h2>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: 5, margin: 5}}>
								<DashboardTopItem MaterialIcon={Diversity3}
												  title={'Total Action List Records'}
												  message={'Number of records in the workflow'}
												  value={dashboard.recordCount}
												  maxWidth={200}/>
								<DashboardTopItem MaterialIcon={Verified}
												  title={'Initiated'}
												  message={'Total valid records sent'}
												  value={dashboard.totalRecordsSent}
												  maxWidth={200}/>
							</div>
							{this.showParticipationAnalytics && <HorizontalBarGraph segments={dashboard.segments}
																					maxValue={dashboard.recordCount}
																					onBarClick={(s, d) => console.log('click ', d)}/>}
						</div>
					</div>
					{!this.showParticipationAnalytics &&
					 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
						 <div style={paperStyle}>
							 <h2 style={{padding: 10}}>Recipient Details</h2>
							 <div style={{display: 'flex', flexDirection: 'row', gap: 5, margin: 5}}>
								 <DashboardTopItem MaterialIcon={Groups}
												   title={'Total Recipients in Action List'}
												   message={'Number of people records sent to'}
												   value={throughChannelRecipientStats.totalUniqueRecipients || 0}
												   minWidth={150}/>
								 <DashboardTopItem MaterialIcon={ReduceCapacity}
												   title={'Total Successful Recipients'}
												   message={'Number of people received list'}
												   value={throughChannelRecipientStats.totalSuccessfulRecipients || 0}
												   minWidth={150}/>
								 <DashboardTopItem MaterialIcon={ReceiptLong}
												   title={'Total Successful Record Distributions'}
												   message={'Number of records received'}
												   value={throughChannelRecipientStats.totalSuccessfulRecords || 0}
												   minWidth={150}/>
							 </div>
						 </div>
					 </div>
					}
					{this.showParticipationAnalytics &&
					 <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: 10, marginLeft: 5, marginRight: 5, gap: 10}}>
						 <div style={paperStyleColumn}>
							 <h2 style={{padding: 10}}>Recipient Participation</h2>
							 <p style={{padding: '0 10px 10px'}}>Number of recipients participating in workflow</p>
							 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: 5, margin: 5}}>
								 {participationData.map(v => {
									 return (
										 <CircleGraph key={'participation_' + v.orgName} title={v.orgName}
													  sections={ChannelHelper.getCircleGraphObject(v.data)}
													  onClick={(d) => console.log(d)}/>
									 );
								 })}
							 </div>
						 </div>
						 <div style={paperStyleColumn}>
							 <h2 style={{padding: 10}}>Recipient Introductions</h2>
							 <div style={{display: 'flex', flexDirection: 'row', gap: 5, margin: 5}}>
								 <DashboardTopItem MaterialIcon={EditCalendar}
												   title={'Intros made'}
												   message={'Intro emails sent'}
												   value={totalIntros}
												   minWidth={150}/>
								 <DashboardTopItem MaterialIcon={TrackChanges}
												   title={'Avg days to intro'}
												   message={'Average days to introduction'}
												   value={averageDaysToIntro}
												   minWidth={150}/>
							 </div>
							 <div style={{boxShadow: partnerTapDropShadow, margin: '0px 10px 10px'}}>
								 <div style={{fontSize: 20, padding: 10}}>
									 <div style={{display: 'flex', alignItems: 'center'}}>
										 <EmojiPeople sx={{fontSize: 40}}/>
										 Number of recipients introduced
									 </div>
								 </div>
								 <hr style={{borderTop: '1px dotted', borderColor: partnerTapStroke}}/>
								 <div style={{
									 flex: 1,
									 display: 'flex',
									 flexDirection: 'row',
									 justifyContent: 'center',
									 gap: 15,
									 padding: 10,
									 margin: 10
								 }}>
									 {uniqueRecipients.map((item, index) => {
										 let divider = <div/>;
										 if (index + 1 < uniqueRecipients.length) {
											 divider = <div style={{...recipientIntroStyle, ...recipientCountStyle}}><SwapHoriz sx={{fontSize: 60}}/></div>;
										 }
										 return (
											 <Fragment key={'rec_frag_' + index}>
												 <div key={'rec_' + index} style={recipientIntroStyle}>
													 <div>{item.orgName}</div>
													 <div style={recipientCountStyle}>{item.recipientCount}</div>
												 </div>
												 {divider}
											 </Fragment>
										 );
									 })}
								 </div>
							 </div>
						 </div>
					 </div>}
					<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: 10, marginLeft: 5, marginRight: 5, gap: 10}}>
						{this.renderEmailAnalytics()}
					</div>

				</div>
			</ScrollingContainer>
		);
	}

}

export default withRouter(CoSellActionListDashboard);