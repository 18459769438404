import {HubOutlined, TaskOutlined} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {CHANNEL_DOWNLOAD_ENDPOINT} from '../../../endpoints/ChannelMappingEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES, MATCHING_TYPE_MUTUAL, MATCHING_TYPE_MY_UNLINKED_OPPS} from '../../../helpers/ReportHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import {Routes} from '../../../globals/Routes';
import DownloadButton from '../../../ui/buttons/DownloadButton';
import DownloadHelper from '../../../helpers/DownloadHelper';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import PartnerTapIcon from '../../../ui/PartnerTapIcon';
import SheetInfoHelper from '../shared/sheets/SheetInfoHelper';

const VIEW_MY_OPPS = 'VIEW_MY_OPPS';
const VIEW_MY_UNLINKED_OPPS = 'VIEW_MY_UNLINKED_OPPS';

class MyOpportunitiesPage extends PagingBase {

	dropDownMenuOptions = [
		{key: VIEW_MY_OPPS, label: 'My Opps'},
		{key: VIEW_MY_UNLINKED_OPPS, label: 'My Unlinked Opps'},
	];

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES};
		this.state.filterSelectorMounted = false;
		this.state.selectedOption = this.dropDownMenuOptions[0];
		this.state.showDetails = false;
	}

	componentDidMount() {
		this.fetchCompanyOpportunityColumnData();
	}

	get storageKeyBase() {
		return 'my_opportunities';
	}

	get title() {
		if (this.personQueueId) return 'My Sheet';
		return '';
	}

	get dropDownMenu() {
		if (this.personQueueId) return null; // No dropdown for My Sheet
		return (
			<DropdownMenu
				options={this.dropDownMenuOptions}
				selectedOption={this.state.selectedOption}
				onSelect={(option) =>
					this.setState({selectedOption: option}, () => {
						EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_OPPORTUNITIES.ROUTE);
						this.getRowData();
					})
				}
			/>
		);
	}

	get icon() {
		if (this.personQueueId) return TaskOutlined;
		return HubOutlined;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get personQueueId() {
		return this.props.match.params.personQueueId;
	}

	get filterSelectorArgs() {
		return {
			channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES,
			matchingType: this.matchingType,
			personQueueIds: this.personQueueId ? [this.personQueueId] : [],
		}
	}

	get additionalToolbarButtons() {
		let buttons = [];
		if (this.state.selectedOption.key === VIEW_MY_UNLINKED_OPPS) {
			buttons.push(<DownloadButton key={'download_button'} callingPage={this}/>);
		}

		if (this.personQueueId) {
			buttons.push(<SecondaryButton key={'csv_info_button'} label={'CSV INFO'}
										  icon={<PartnerTapIcon glyph={'I'} style={PartnerTapIcon.buttonIconStyle}/>}
										  onClick={() => this.setState({showDetails: true})}/>);
		}
		return buttons;
	}

	get matchingType() {
		return this.state.selectedOption.key === VIEW_MY_UNLINKED_OPPS ? MATCHING_TYPE_MY_UNLINKED_OPPS : MATCHING_TYPE_MUTUAL
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		return {
			endpoint: CHANNEL_DOWNLOAD_ENDPOINT,
			channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES,
			matchingType: this.matchingType,
			search: params.search,
			filters: params.filters,
			exportBaseFileName: 'MyOpportunities.csv',
			exportFields: DownloadHelper.getFields(this.columnData)
		};
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				personId: this.personId,
				selectedColumns: this.activeColumns,
				channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES,
				personQueueIds: this.personQueueId ? [this.personQueueId] : [],
				matchingType: this.matchingType,
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			0)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	fetchCompanyOpportunityColumnData() {
		let actions = [ChannelMappingEndpoints.getColumns({channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES})];
		if (this.personQueueId) {
			actions.push(ChannelMappingEndpoints.getUploadDataForId(this.personQueueId));
		}
		Promise.all(actions)
		.then((results) => {
			if (this.unmounted) return;
			this.setState({sheetData: results[1]?.payload || {}});
			this.initColumnData(results[0].payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCompanyOpportunityColumns', error);
		});
	}

	renderCsvDetails() {
		let {sheetData} = this.state;
		return SheetInfoHelper.renderDetails(sheetData, () => this.setState({showDetails: false}));
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
				{this.state.showDetails && this.renderCsvDetails()}
				{super.render()}
			</div>
		);
	}
}

export default connect()(MyOpportunitiesPage);
