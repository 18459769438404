import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    partnerTapDefaultText, partnerTapInactive, partnerTapLightestGrey, partnerTapPrimaryLight, partnerTapQuaternary,
    partnerTapStroke,
    partnerTapTernary,
    partnerTapWhite
} from '../../styles/partnertap_theme';
import NumberHelper from '../../helpers/NumberHelper';

class HorizontalBarGraph extends Component {

    constructor(props, context) {
        super(props, context);
        this.svgRef = React.createRef();
        this.svgOriginalWidth = 0;
        this.state = {
            scale: 0,
            fontSize: 2.25,
            scaleLineFontSize: 2.25
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Initial calculation on component mount
    }

    componentDidUpdate() {
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.svgRef.current) {
            if(this.svgOriginalWidth === 0) {
                this.svgOriginalWidth = this.svgRef.current.parentElement.getBoundingClientRect().width;
            }
            const magicFontScaler = 0.0018; // This is our ideal ratio between font-size and bar graph width
            const targetFontSize = 2.28;
            const minWidth = 400;
            const containerWidth = this.svgRef.current.parentElement.getBoundingClientRect().width;
            let newFontSize = targetFontSize / (magicFontScaler * containerWidth);
            let scaleLineFontSize = newFontSize;
            if(containerWidth < minWidth) {
                scaleLineFontSize = targetFontSize;
            }
            if(newFontSize !== this.state.fontSize) {
                setTimeout(() => {this.setState({ fontSize: newFontSize, scaleLineFontSize: scaleLineFontSize });}, 50);
            }
        }
    };

    calculatePercentage(value) {
        return (value / this.props.maxValue) * 100;
    }

    renderTickValue(tick) {
        return NumberHelper.shortNumber((tick / 100) * this.props.maxValue);
    }

    renderValue(value) {
        const {fontSize} = this.state;
        const {maxValue} = this.props;
        const positionValueOutsideBar = value / maxValue > 0.75;
        const position = positionValueOutsideBar? 'end' : 'start';
        const positionOffset = positionValueOutsideBar? -0.5 : 0.5;
        const fill = positionValueOutsideBar ? 'white' : partnerTapDefaultText;
        return (
            <text x={this.calculatePercentage(value) + positionOffset} y="0.8" textAnchor={position} fill={fill}
                  fontWeight={'bold'} fontSize={fontSize}>
                {NumberHelper.formatNumber(value)}
            </text>
        );
    }

    getFill(label) {
        switch(label) {
            case 'Pending': return partnerTapStroke;
            case 'Introduced':
            case 'Approved':return partnerTapTernary;
            default: return partnerTapInactive;
        }
    }

    render() {
        let {segments, onBarClick, maxValue} = this.props;
        let {scaleLineFontSize} = this.state;
        const numberOfTicks = Math.min(11, maxValue);
        const tickMuliplier = 100 / numberOfTicks;
        const ticks = Array.from({length: numberOfTicks + 1}, (_, i) => i * tickMuliplier);
        const strokeWidth = 1.5;
        return (
            <div style={{
                flex: 1,
                backgroundColor: partnerTapWhite,
                minWidth: 300
            }}>
                <div style={{
                    display: 'flex',
                    backgroundColor: partnerTapWhite,
                    flexDirection: 'column',
                    justifyContent: 'left',
                    alignItems: 'stretch'
                }}>
                    {segments.map((segment, index) => {
                        const renderDivider = index < segments.length - 1;
                        return (
                            <div key={index}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: 'space-evenly',
                                     margin: '0 10px',
                                     padding: '0 10px',
                                     borderBottom: renderDivider ? '1px solid ' + partnerTapStroke : 'none',
                                 }}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        padding: 10,
                                        flex: '0 0 150px'
                                    }}>
                                        <div style={{fontWeight: 'bold'}}>{segment.segmentHeadline}</div>
                                        {segment.segmentLabel}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: 10,
                                        flex: '1'
                                    }}>
                                        {segment.segmentData.map((data) => {
                                            const fill = data.fill || partnerTapTernary;
                                            return (
                                                <div key={'bar_' + data.label}
                                                     style={{display: 'flex', alignItems: 'left', minWidth: 200}}
                                                     onClick={() => Boolean(onBarClick) && onBarClick(segment, data)}>
                                                    <div style={{flex: '0 0 100px'}}>
                                                        <div style={{padding: 5}}>{data.label}</div>
                                                    </div>
                                                    <div style={{width: '100%', maxHeight: 75}}>
                                                        <svg viewBox="0 0 100 1" preserveAspectRatio="xMinYMid meet"
                                                             width="100%" height="30">
                                                            <line x1="0"
                                                                  y1="0"
                                                                  x2={this.calculatePercentage(data.value)}
                                                                  y2="0"
                                                                  stroke={this.getFill(data.label)}
                                                                  strokeWidth={35}
                                                                  vectorEffect="non-scaling-stroke"/>
                                                            {this.renderValue(data.value)}
                                                        </svg>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        margin: '0 10px',
                        padding: '0 10px'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: 10,
                                fontSize: 20,
                                fontWeight: 'bold',
                                flex: '0 0 150px'
                            }}/>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '0 10px',
                                flex: '1'
                            }}>
                                <div style={{display: 'flex', alignItems: 'left', minWidth: 200}}>
                                    <div style={{flex: '0 0 100px'}}/>
                                    <div style={{width: '100%', maxHeight: 200}}>
                                        <svg ref={this.svgRef} viewBox="0 0 100 10">
                                            <line x1="0" y1="0" x2="100" y2="0" stroke={partnerTapStroke}
                                                  strokeWidth={strokeWidth} vectorEffect="non-scaling-stroke"/>
                                            {ticks.map((tick, index) => {
                                                let textAnchor = 'middle';
                                                if (index === 0) {
                                                    textAnchor = 'start';
                                                }
                                                else if (index === ticks.length - 1) {
                                                    textAnchor = 'end';
                                                }
                                                return (
                                                    <g key={tick} transform={`translate(${tick}, 0)`}>
                                                        {/* Tick mark */}
                                                        <line x1="0" y1="0" x2="0" y2="2" stroke={partnerTapStroke}
                                                              strokeWidth={strokeWidth}
                                                              vectorEffect="non-scaling-stroke"/>
                                                        {/* Label */}
                                                        <text x="0" y="5" fontSize={scaleLineFontSize} textAnchor={textAnchor} dominantBaseline="middle">
                                                            {this.renderTickValue(tick)}
                                                        </text>
                                                    </g>
                                                );
                                            })}
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

HorizontalBarGraph.propTypes = {
    'segments': PropTypes.arrayOf(PropTypes.shape({
        "segmentLabel": PropTypes.string,
        "segmentData": PropTypes.arrayOf(PropTypes.shape({
            "label": PropTypes.string.isRequired,
            "value": PropTypes.number.isRequired,
            "fill": PropTypes.string
        }))
    })).isRequired,
    'maxValue': PropTypes.number.isRequired,
    'onBarClick': PropTypes.func
};

export default HorizontalBarGraph;