import {Check, Clear, ViewColumn} from '@mui/icons-material';
import {Drawer} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import ColumnHelper from '../../helpers/ColumnHelper';
import TableHelper from '../../helpers/TableHelper';
import {partnerTapStroke} from '../../styles/partnertap_theme';
import CancelButton from '../buttons/CancelButton';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PagingTableColumnHeader from '../lists/PagingTableColumnHeader';
import SearchBox from '../SearchBox';

class ColumnSelector extends Component {

	constructor(props, context) {
		super(props, context);

		this.ordinalValue = null;
		this.state = {isDialogOpen: false};

		this.onKeyPressed = this.onKeyPressed.bind(this);
		this.onColumnOrderChanged = this.onColumnOrderChanged.bind(this);
		this.moveSelectedToOrdinal = this.moveSelectedToOrdinal.bind(this);
		this.isColumnVisible = this.isColumnVisible.bind(this);
		this.renderColumnButton = this.renderColumnButton.bind(this);
		this.setAll = this.setAll.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keypress', this.onKeyPressed);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		TableHelper.updateColumnOrderAttributes(this.props.columnData, true);
	}

	componentWillUnmount() {
		document.removeEventListener('keypress', this.onKeyPressed);
	}

	onKeyPressed(e) {
		if (!this.state.isDialogOpen) return;
		let value = parseInt(e.key);
		if (value) {
			let {columnData} = this.props;
			if (this.ordinalValue) {
				this.ordinalValue = parseInt(this.ordinalValue + '' + value);
			}
			else {
				this.ordinalValue = value;
			}
			if (this.ordinalValue === 0 || this.ordinalValue >= columnData.length) {
				this.ordinalValue = columnData.length - 1;
			}
			if (this.moveTimeout) {
				clearTimeout(this.moveTimeout);
			}
			this.moveTimeout = setTimeout(this.moveSelectedToOrdinal, 500);
		}
	}

	moveSelectedToOrdinal() {
		let {columnData} = this.props;
		let targetIndex = this.ordinalValue - 1;
		PagingTableColumnHeader.selectedForOrdinal.forEach((column) => {
			columnData.splice(column.index, 1);
			columnData.splice(targetIndex, 0, column);
			TableHelper.updateColumnOrderAttributes(columnData, true);
			targetIndex++;
		});
		this.ordinalValue = null;
		this.forceUpdate();
		setTimeout(() => this.onColumnOrderChanged(true), 500);
	}

	onColumnOrderChanged(isDrop) {
		if (isDrop) {
			this.clearSelectedColumnHeaders();
		}

		// onColumnOrderChanged is called repeatedly when dragging, so compacting into a single delayed call
		if (this.callOnce) {
			clearTimeout(this.callOnce);
		}
		this.callOnce = setTimeout(() => {
			if (this.unmounted) return;
			this.forceUpdate();
		}, 100);
	}

	clearSelectedColumnHeaders() {
		PagingTableColumnHeader.selectedForOrdinal.forEach((column) => column.selectedForOrdinal = false);
		PagingTableColumnHeader.selectedForOrdinal.length = 0;
	}

	setAll(isActive) {
		this.props.columnData.forEach((column) => {
			if (!ColumnHelper.isSortable(column)) return;
			column.activeInverted = isActive ? !column.active : column.active;
		});
		this.forceUpdate();
	}

	get countVisibleColumnButtons() {
		let count = 0;
		this.props.columnData.forEach((column) => {
			if (this.isColumnVisible(column)) count++;
		});
		return count;
	}

	isColumnVisible(column) {
		if (!ColumnHelper.isSortable(column)) return false;
		let {search} = this.state;
		return !(search && column.title.toLowerCase().indexOf(search.toLowerCase()) === -1);
	}

	renderToolbar() {
		return (
			<div style={{backgroundColor: 'white', padding: 10, borderBottom: '1px solid ' + partnerTapStroke}}>
				<div style={{paddingBottom: 10}}>
					<SearchBox autoApply={true} defaultValue={this.state.search} onSearch={(value) => this.setState({search: value})}/>
				</div>
				<div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto'}}>
					<SecondaryButton label={'CHECK ALL'} icon={<Check/>} onClick={() => this.setAll(true)}/>
					<div style={{paddingRight: 5}}/>
					<SecondaryButton label={'CLEAR ALL'} icon={<Clear/>} onClick={() => this.setAll(false)}/>
				</div>
			</div>
		);
	}

	renderActionButtons() {
		return (
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				padding: 10,
				justifyContent: 'center',
				backgroundColor: 'white',
				borderTop: '1px solid ' + partnerTapStroke
			}}>
				<CancelButton onClick={this.closeDialog}/>
				<div style={{paddingRight: 5}}/>
				<PrimaryButton label={'APPLY'} onClick={() => {
					this.closeDialog(false);
					this.props.onColumnActiveChanged(true);
					this.props.onColumnOrderChanged(true);
				}}/>
			</div>
		);
	}

	renderColumnButtons() {
		let {search} = this.state;
		if (search && this.countVisibleColumnButtons === 0) {
			return (
				<div style={{display: 'flex', justifyContent: 'center', padding: 20, paddingTop: 30, fontWeight: 'bold'}}>
					No columns found for search '{search}'
				</div>
			);
		}
		let {columnData} = this.props;
		return (
			<div style={{flex: 1, overflowY: 'scroll', overflowX: 'hidden'}}>
				<div key={Math.random()} // new key is required every render to eliminate unexpected duplication
					 style={{
						 display: 'flex',
						 flexWrap: 'wrap',
						 flexDirection: 'column',
						 justifyContent: 'center',
						 alignSelf: 'center',
						 paddingTop: 10
					 }}>
					{columnData.map((column) => this.renderColumnButton(column))}
				</div>
			</div>
		);
	}

	renderColumnButton(column) {
		if (!this.isColumnVisible(column)) return;
		let {columnData} = this.props;
		let dropDivBefore;
		let dropDivAfter;
		if (TableHelper.isDropColumn(column)) {
			let placeholderDiv = <PagingTableColumnHeader tableKey={'column_selector'}
														  column={TableHelper.dragColumn}
														  columnData={columnData}
														  onColumnOrderChanged={this.onColumnOrderChanged}
														  isForColumnSelector={true}
														  placeholderColumn={column}/>;
			if (TableHelper.dragColumn.index > column.index) {
				dropDivBefore = placeholderDiv;
			}
			else {
				dropDivAfter = placeholderDiv;
			}
		}

		return (
			<Fragment key={column.key}>
				{dropDivBefore}
				<PagingTableColumnHeader tableKey={'column_selector'}
										 column={column}
										 columnData={columnData}
										 onColumnOrderChanged={this.onColumnOrderChanged}
										 isForColumnSelector={true}
				/>
				{dropDivAfter}
			</Fragment>
		);
	}

	closeDialog(revertColumns = true) {
		let {columnData, onColumnOrderChanged} = this.props;
		if (revertColumns) {
			columnData.length = 0;
			this.columnDataCloneForCancel.forEach((column) => {
				columnData.push(column);
			});
			onColumnOrderChanged();
		}
		this.clearSelectedColumnHeaders();
		this.setState({isDialogOpen: false, search: null});
	}

	render() {
		let hideButton = this.countVisibleColumnButtons === 0 && !this.state.search;
		return (
			<Fragment>
				{!hideButton &&
				 <SecondaryButton icon={<ViewColumn/>} toolTip={'Columns'} onClick={() => {
					 this.columnDataCloneForCancel = _.cloneDeep(this.props.columnData);
					 this.setState({isDialogOpen: true});
				 }}/>}
				<Drawer style={{zIndex: 10000}} anchor={'right'}
						open={this.state.isDialogOpen}
						onClose={this.closeDialog}>
					<div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
						{this.renderToolbar()}
						{this.renderColumnButtons()}
						{this.renderActionButtons()}
					</div>
				</Drawer>
			</Fragment>
		);
	}
}

ColumnSelector.propTypes = {
	columnData: PropTypes.array.isRequired,
	onColumnActiveChanged: PropTypes.func.isRequired,
	onColumnOrderChanged: PropTypes.func.isRequired
};

export default ColumnSelector;
