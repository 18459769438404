import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import {MenuItem} from '@mui/material';
import SheetReassignDialog from './SheetReassignDialog';
import SheetDeleteDialog from './SheetDeleteDialog';
import SheetAuditDialog from './SheetAuditDialog';
import FeatureHelper, {FEATURE_CHANNEL_AUDIT_PARTNER_SHEET} from '../../helpers/FeatureHelper';
import {AUDIT_COMPLETE, AUDIT_ENABLED} from '../../globals/Enums';
import {PERSON_QUEUE_STATUS_PROCESSING, PERSON_QUEUE_STATUS_READY} from '../../helpers/UploadHelper';
import LinkButton from './LinkButton';

class SheetRecordActions extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {anchorElement: null};
		this.onOpenMenu = this.onOpenMenu.bind(this);
		this.onCloseMenu = this.onCloseMenu.bind(this);
		this.closeAllDialogs = this.closeAllDialogs.bind(this);
		this.onReassignClick = this.onReassignClick.bind(this);
		this.onAuditClick = this.onAuditClick.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
	}

	onOpenMenu(event) {
		if (event) {
			event.stopPropagation();
		}
		this.setState({
			anchorElement: event.currentTarget,
			showReassignDialog: false,
			showDeleteDialog: false,
			showAuditDialog: false
		});
	}

	onCloseMenu(event) {
		if (event) {
			event.stopPropagation();
		}
		this.setState({anchorElement: null});
	}

	closeAllDialogs(reloadTable) {
		this.setState({
			showReassignDialog: false,
			showDeleteDialog: false,
			showAuditDialog: false
		});
		if (reloadTable) {
			this.props.refreshTable();
		}
	}

	onReassignClick() {
		this.onCloseMenu();
		this.setState({showReassignDialog: true});
	}

	onDeleteClick() {
		this.onCloseMenu();
		this.setState({showDeleteDialog: true});
	}

	onAuditClick() {
		this.onCloseMenu();
		this.setState({showAuditDialog: true});
	}

	render() {
		const {rowData, isPartnerSheet} = this.props;
		const isProcessing = rowData.status === PERSON_QUEUE_STATUS_PROCESSING || rowData.status === PERSON_QUEUE_STATUS_READY;
		const isAuditButtonDisabled = isProcessing || rowData.auditStatus === AUDIT_ENABLED || rowData.auditStatus === AUDIT_COMPLETE;
		return (
			<div>
				{this.state.showReassignDialog &&
				 <SheetReassignDialog rowData={rowData} onClose={this.closeAllDialogs}/>}
				{this.state.showDeleteDialog &&
				 <SheetDeleteDialog rowData={rowData} onClose={this.closeAllDialogs}/>}
				{this.state.showAuditDialog &&
				 <SheetAuditDialog rowData={rowData}
								   onClose={this.closeAllDialogs}
								   isFeatureEnabled={FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_AUDIT_PARTNER_SHEET)}/>}
				<div style={{textAlign: 'center'}}>
					<IconButton
						aria-label={'more'}
						onClick={this.onOpenMenu}>
						<MoreVertIcon/>
					</IconButton>
					<Menu
						id={'menu'}
						anchorEl={this.state.anchorElement}
						onClose={this.onCloseMenu}
						open={Boolean(this.state.anchorElement)}>
						{
							isPartnerSheet &&
							<MenuItem key={'Reassign'}>
								<LinkButton label={'Reassign'} onClick={this.onReassignClick}/>
							</MenuItem>
						}
						{
							isPartnerSheet &&
							<MenuItem key={'Audit'} disabled={isAuditButtonDisabled}>
								<LinkButton label={'Audit'} onClick={this.onAuditClick}/>
							</MenuItem>
						}
						<MenuItem key={'Delete'}>
							<LinkButton label={'Delete'} onClick={this.onDeleteClick}/>
						</MenuItem>
					</Menu>
				</div>
			</div>
		);
	}
}

SheetRecordActions.propTypes = {
	rowData: PropTypes.object.isRequired,
	refreshTable: PropTypes.func.isRequired,
	isPartnerSheet: PropTypes.bool
};

export default SheetRecordActions;
