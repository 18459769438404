import PersistenceHelper from 'helpers/PersistenceHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {ACCOUNTS_ASSET, CATEGORY_GLOBAL_REPORTS, OPPORTUNITIES_ASSET} from '../../../../globals/Enums';
import ChannelHelper, * as ReportType from '../../../../helpers/ChannelHelper';
import {REPORT_MY_PARTNER_LIST, REPORT_PARTNER_LIST, REPORT_USER_ENGAGEMENT} from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import StringHelper from '../../../../helpers/StringHelper';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SaveReportButton from '../../../../ui/buttons/SaveReportButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import Loading from '../../../../ui/Loading';
import PopoverSearchList from '../../../../ui/PopoverSearchList';
import CoSellSaveButtonLive from '../../../../ui/buttons/CoSellSaveButtonLive';

class GlobalReportPage extends PagingBase {

	constructor(props, context) {
		super(props, context);
		const reportSettings = JSON.parse(this.getParamValue('reportSettings') || '{}');
		this.state.preSelectedPartnerValues = reportSettings[this.partnerOrgStorageKey]?.split(',');
		this.state.loading = false;
		this.state.partnerOrgCode = this.getParamValue('partnerOrgCode'); // from dashboard
		this.state.partnerSelectValues = null;
		this.state.filterSelectorFunction = (args, search, filters, filterField, currentPage, pageSize) => {
			args.channelReportType = ChannelHelper.getChannelReportType(this.reportType);
			args.reportType = this.reportType;
			args.partnerOrgCodes = this.partnerOrgCodes;
			return ChannelMappingEndpoints.getFilterData(args, search, filters, filterField, currentPage, pageSize);
		};
		this.state.filterSelectorMounted = false;

		this.fetchColumns = this.fetchColumns.bind(this);
		this.resetPartnerOrgCodes = this.resetPartnerOrgCodes.bind(this);
	}

	componentDidMount() {
		if (!this.isReportUserEngagement) {
			this.fetchPartners();
		}
		else {
			this.fetchColumns();
		}
		this.persistPartnerOrgCodes();
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get isReportPartnerListByOrg() {
		return this.reportType === REPORT_PARTNER_LIST;
	}

	get isReportUserEngagement() {
		return this.reportType === REPORT_USER_ENGAGEMENT;
	}

	get isReportPartners() {
		return this.reportType === REPORT_MY_PARTNER_LIST || this.reportType === REPORT_PARTNER_LIST;
	}

	get partnerOrgCodes() {
		if (this.state.partnerOrgCode) {
			return [this.state.partnerOrgCode];
		}
		else if (this.state.partnerSelectValues) {
			return this.state.partnerSelectValues.filter((partnerOrg) => partnerOrg.active).map((partnerOrg) => partnerOrg.orgCode);
		}
		return [];
	}

	resetPartnerOrgCodes() {
		this.state.partnerSelectValues.forEach((partnerOrg) => partnerOrg.active = true);
		this.persistPartnerOrgCodes();
		this.fetchColumns();
	}

	persistPartnerOrgCodes() {
		PersistenceHelper.setValue(this.partnerOrgStorageKey, this.partnerOrgCodes);
	}

	get storageKeyBase() {
		return 'ecosystem_global_report' + (this.reportType ? '_' + this.reportType : '');
	}

	get partnerOrgStorageKey() {
		return this.storageKeyBase + '_partner_org_codes';
	}

	get title() {
		return ChannelHelper.getReportTypeTitle(this.getParamValue('reportType'));
	}

	get icon() {
		return ChannelHelper.getReportTypeIcon(this.getParamValue('reportType'));
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	buildComparablePartnerSelectedValues(selectedValues) {
		return selectedValues?.filter(v => v.active).map(v => v.orgCode) || '';
	}

	get requiresPartnerSelector() {
		let {partnerOrgCode, partnerSelectValues} = this.state;
		return !this.isReportPartners &&
			   !partnerOrgCode &&
			   partnerSelectValues &&
			   this.reportType !== ReportType.REPORT_PIPELINE &&
			   this.reportType !== ReportType.REPORT_SOURCED_PIPELINE &&
			   this.reportType !== ReportType.REPORT_GLOBAL_UNMATCHED;
	}

	get isCoSell() {
		return this.reportType === ReportType.REPORT_GLOBAL_OVERLAP ||
			   this.reportType === ReportType.REPORT_MUTUAL_PROSPECTS ||
			   this.reportType === ReportType.REPORT_HOT_PROSPECTS ||
			   this.reportType === ReportType.REPORT_MUTUAL_CUSTOMERS ||
			   this.reportType === ReportType.REPORT_MY_OPPORTUNITIES ||
			   this.reportType === ReportType.REPORT_PIPELINE ||
			   this.reportType === ReportType.REPORT_BENEFICIAL_CUSTOMERS;
	}

	//This is onlyUsed by coSell thats why only includes these reports
	get CoSellAssetType() {
		if (this.reportType === ReportType.REPORT_PIPELINE || this.reportType === ReportType.REPORT_MY_OPPORTUNITIES) {
			return OPPORTUNITIES_ASSET;
		}

		return ACCOUNTS_ASSET;
	}

	get coSellReportConfigLive() {
		let {partnerSelectValues} = this.state;
		const selected = this.partnerOrgCodes;
		const partnerOrgPublicIds = partnerSelectValues ? partnerSelectValues
		.filter(v => selected.includes(v.orgCode))
		.map(v => v.partnerOrgPublicId) : [];

		return {
			reportData: this.downloadReportConfig,
			partnerOrgPublicIds: partnerOrgPublicIds,
			assetType: this.CoSellAssetType,
			rowCount: this.state.rowCount
		};
	}

	get additionalToolbarButtons() {
		let {partnerSelectValues, filterValues} = this.state;
		let buttons = [];

		if (this.requiresPartnerSelector) {
			let partnerSelectValuesOld = this.buildComparablePartnerSelectedValues(partnerSelectValues ? [...partnerSelectValues] : null);
			buttons.push(<PopoverSearchList key={'partners_button'}
											label={'Partner'}
											list={partnerSelectValues}
											isMultipleChoice={true}
											labelRenderer={(item) => item.orgName}
											isCheckedFunction={(item) => item.active}
											setCheckedFunction={(item, isChecked) => {
												item.active = isChecked;
												this.persistPartnerOrgCodes();
											}}
											searchByObjectKeys={['orgName']}
											onItemSelected={() => {
												if (partnerSelectValuesOld !== this.buildComparablePartnerSelectedValues(partnerSelectValues)) {
													this.setState({columnData: null, filterSelectorMounted: false});
													this.persistPartnerOrgCodes();
													this.fetchColumns();
												}
											}}
											minWidth={200}
											maxWidth={200}/>);
		}

		if (this.isCoSell) {
			buttons.push(<CoSellSaveButtonLive callingPage={this} key={this.state.rowCount}/>);
		}

		buttons.push(<DownloadButton callingPage={this}/>);
		buttons.push(<SaveReportButton reportType={this.reportType}
									   reportCategory={CATEGORY_GLOBAL_REPORTS}
									   reportSettings={{
										   reportSettings: {[this.partnerOrgStorageKey]: PersistenceHelper.getValue(this.partnerOrgStorageKey)},
										   baseSettings: ChannelHelper.baseSettings(this.storageKeyBase),
										   filter: filterValues
									   }}
									   reportParams={{}}
									   showSaveDialog={this.showSaveDialog}/>);

		return buttons;
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: StringHelper.formatKey(ChannelHelper.getReportTypeTitle(this.reportType)),
			exportFields: DownloadHelper.getFields(this.state.columnData),
			selectedColumns: this.activeColumns,
			totalElements: '0',
			personQueueIds: [this.props.personQueueId],
			personId: this.partnerPersonId,
			partnerOrgCodes: this.partnerOrgCodes,
			reportType: this.reportType,
			channelReportType: ChannelHelper.getChannelReportType(this.reportType),
			byOrg: this.isReportPartnerListByOrg
		};
	}

	get filterSelectorArgs() {
		if (!this.isReportUserEngagement) {
			return {
				reportType: this.reportType,
				partnerOrgCodes: this.partnerOrgCodes,
				byOrg: this.isReportPartnerListByOrg
			};
		}
		return super.filterSelectorArgs;
	}

	fetchPartners() {
		const {preSelectedPartnerValues} = this.state;
		ChannelMappingEndpoints.getCompanyPartners()
		.then((result) => {
			if (this.unmounted) return;
			const selectedPartners = preSelectedPartnerValues ?
				result.payload.map(p => ({
					...p,
					active: preSelectedPartnerValues.includes(p.orgCode)
				})) :
				result.payload;

			this.setState({partnerSelectValues: selectedPartners, preSelectedPartnerValues: null});
			this.persistPartnerOrgCodes();
			this.fetchColumns();
		});
	}

	fetchColumns() {
		ChannelMappingEndpoints.getColumns({channelReportType: ChannelHelper.getChannelReportType(this.reportType), partnerOrgCodes: this.partnerOrgCodes})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getGlobalReportCompanyAssetColumns', error);
		});
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let {rowCount} = this.state;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				channelReportType: ChannelHelper.getChannelReportType(this.reportType),
				partnerOrgCodes: this.partnerOrgCodes,
				isReportPartnerListByOrg: this.isReportPartnerListByOrg,
				reportType: this.reportType,
				byOrg: this.isReportPartnerListByOrg
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			rowCount
		)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
					ChannelHelper.processRowData(result.payload, this.reportType);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage && !this.state.partnerOrgCode) {
			let selectedOrgCount = this.partnerOrgCodes.length;
			if (selectedOrgCount !== this.state.partnerSelectValues.length) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							No results found with {selectedOrgCount} partner org{selectedOrgCount === 1 ? '' : 's'} selected
						</div>
						<PrimaryButton label={'CLEAR ORG SELECTION'} onClick={this.resetPartnerOrgCodes}/>
					</div>
				);
			}
		}
		return super.noDataMessage;
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		if (this.isSavedReport) {
			return DialogHelper.renderSavedReportDialogButtons(super.render(), () => this.forceUpdate(), this.closeSavedReportDialog);
		}
		return super.render();
	}
}

GlobalReportPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(GlobalReportPage));
