import React, {useState} from 'react';
import AiQuestionsSelector from './AiQuestionsSelector';

import {questionsData} from './questionsConfig';
import {flowsConfig} from './flowsConfig';
import FlowSection from './FlowSection';
import {Routes} from '../../../globals/Routes';
import { useNavigate } from 'react-router-dom';
import EnvHelper from '../../../helpers/EnvHelper';

const itemStyle = {
	padding: '15px',
	background: '#FFFFFF',
	borderRadius: '8px',
	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
	cursor: 'pointer',
	width: "400px",
};


const WatsonContainer = () => {
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [flowSelections, setFlowSelections] = useState({});

	const handleQuestionSelect = (sectionId, question) => {
		setSelectedQuestion({
			...question,
			section: sectionId
		});
	};


	const handleStepComplete = (stepIndex, selections) => {
		setFlowSelections(prev => {
			const currentQuestionSelections = prev[selectedQuestion.id] || {};
			const updatedQuestionSelections = {
				...currentQuestionSelections,
				[stepIndex]: selections[stepIndex]
			};

			return {
				...prev,
				[selectedQuestion.id]: updatedQuestionSelections
			};
		});

		// If this was the last step, concatenate question and selections
		if (stepIndex === flowsConfig[selectedQuestion.flow].steps.length - 1) {
			const updatedSelections = {
				...flowSelections,
				[selectedQuestion.id]: {
					...(flowSelections[selectedQuestion.id] || {}),
					[stepIndex]: selections[stepIndex]
				}
			};

			// Get all selections as a flat array
			const allSelections = Object.entries(updatedSelections[selectedQuestion.id])
			.map(([index, selections]) => {
				const stepTitle = flowsConfig[selectedQuestion.flow].steps[index].id;
				return `${stepTitle}: ${selections.join(', ')}`;
			})
			.join('\n. ');

			// Concatenate question text and selections
			const concatenatedString = `${selectedQuestion.text}?\nUsing these filters ${allSelections}`;
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.AI_ANSWERS.CHAT.ROUTE,null, {question: concatenatedString});
		}
	};

	return (
		<div className="WatsonContainer" style={{display: 'flex',}}>
			{!selectedQuestion ? (
				// Show section selection
				<div className="QuestionsContainer" style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					margin: '0 auto'
				}}>
					{Object.values(questionsData).map((section) => (
						<AiQuestionsSelector
							key={section.id}
							width={500}
							title={section.title}
						>
							<div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
								{questionsData[section.id].questions.map((question) => (
									<div
										key={question.id}
										style={itemStyle}
										onClick={() => handleQuestionSelect(section, question)}
									>
										{question.text}
									</div>
								))}
							</div>
						</AiQuestionsSelector>
					))}
				</div>
			) : (
				// Show flow for selected question
				<div style={{
					margin: '0 auto'
				}}>
					<FlowSection
						flowConfig={flowsConfig[selectedQuestion.flow]}
						onBack={() => setSelectedQuestion(null)}
						onStepComplete={handleStepComplete}
					/>
				</div>

			)}
		</div>
	);
};

export default WatsonContainer;