import React, {Component} from 'react';
import TextInputBox from '../../../ui/TextInputBox';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import LinkButton from '../../../ui/buttons/LinkButton';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapInactive, partnerTapLightestGrey, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import * as PropTypes from 'prop-types';
import {queryClient} from '../../App';
import {QueryKeys} from '../../../helpers/QueryClientHelper';
import {
	fetchWorkflowSequenceTemplates,
	resetWorkflowSequenceTemplates,
	sendAdminCenterEmailPreview,
	updateWorkflowSequenceTemplates
} from '../../../endpoints/CoSellEngineEndpoints';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import FeatureHelper, {FEATURE_COSELL_EDIT_WORKFLOW_TEMPLATES} from '../../../helpers/FeatureHelper';
import Dialog from '../../../ui/Dialog';
import {CO_SELL_CONCIERGE} from '../../../globals/Enums';
import PartnerTapBulletList from '../../shared/PartnerTapBulletList';

class CoSellWorkflowEmailTemplateEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			templateUpdates: [],
			initialTemplates: [],
			isEditMode: false,
			isMergeFieldsDialogOpen: false,
			previewDisabledMap: {},
			showResetSequencesDialog: false,
			showPreviewDialog: false
		};

		this.saveSequenceTemplates = this.saveSequenceTemplates.bind(this);
		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.cancelEditMode = this.cancelEditMode.bind(this);
		this.toggleMergeFieldsDialog = this.toggleMergeFieldsDialog.bind(this);
		this.sendEmailPreview = this.sendEmailPreview.bind(this);
		this.resetSequenceTemplates = this.resetSequenceTemplates.bind(this);
		this.closePreviewDialog = this.closePreviewDialog.bind(this);
	}

	async componentDidMount() {
		await this.getSequenceTemplates();
	}

	async getSequenceTemplates() {
		const {sequenceRecipePublicId} = this.props;
		try {
			const workflowSequenceTemplates = await queryClient.fetchQuery(
				[QueryKeys.WORKFLOW_SEQUENCE_TEMPLATES, sequenceRecipePublicId],
				() => fetchWorkflowSequenceTemplates(sequenceRecipePublicId)
			);

			const sortedTemplates = workflowSequenceTemplates.templates.sort((a, b) => a.order - b.order);

			const templateUpdates = sortedTemplates.map(template => ({
				order: template.order || 0,
				title: template.title || '',
				subject: template.subject || '',
				intro: template.intro || '',
				conclusion: template.conclusion || '',
				customMessageEnabled: template.customMessageEnabled || false,
				stepRecipePublicId: template.stepRecipePublicId,
				mergeFields: template.mergeFieldsAllowed,
			}));

			this.setState({
				templateUpdates,
				initialTemplates: templateUpdates,
			});
		}
		catch (error) {
			EnvHelper.serverError('Error from getSequenceTemplates', error);
		}
	}

	saveSequenceTemplates() {
		const {sequenceRecipePublicId} = this.props;
		const {templateUpdates} = this.state;

		const requestBody = {
			templates: templateUpdates,
		};

		updateWorkflowSequenceTemplates(sequenceRecipePublicId, requestBody)
		.then(() => {
			this.setState({
				initialTemplates: templateUpdates,
				previewDisabledMap: {},
			});
			this.toggleEditMode();
		})
		.catch(error => {
			EnvHelper.serverError('Error from saveSequenceTemplates', error);
		});
	}

	async resetSequenceTemplates() {
		const {sequenceRecipePublicId} = this.props;

		this.setState({showResetSequencesDialog: false, previewDisabledMap: {}});

		try {
			await resetWorkflowSequenceTemplates(sequenceRecipePublicId);
			await this.getSequenceTemplates();
			this.toggleEditMode();
		}
		catch (error) {
			EnvHelper.serverError('Error from resetSequenceTemplates', error);
		}
	}

	sendEmailPreview(template) {
		this.setState((prevState) => ({
			previewDisabledMap: {
				...prevState.previewDisabledMap,
				[template.stepRecipePublicId]: true,
			},
		}));

		sendAdminCenterEmailPreview(template.stepRecipePublicId, template)
		.then(() => {
			// Show the preview dialog when the preview is successfully sent
			this.setState({
				showPreviewDialog: true
			});
		})
		.catch(error => {
			EnvHelper.serverError('Error from sendEmailPreview', error);
			this.setState((prevState) => ({
				previewDisabledMap: {
					...prevState.previewDisabledMap,
					[template.stepRecipePublicId]: false,
				},
			}));
		});
	}

	closePreviewDialog() {
		this.setState({showPreviewDialog: false});
	}

	handleTemplateChange(stepRecipePublicId, field, value) {
		this.setState(prevState => ({
			templateUpdates: prevState.templateUpdates.map(template =>
				template.stepRecipePublicId === stepRecipePublicId
					? {...template, [field]: value}
					: template
			),
			previewDisabledMap: {
				...prevState.previewDisabledMap,
				[stepRecipePublicId]: false,
			},
		}));
	}

	toggleEditMode() {
		this.setState((prevState) => ({
			isEditMode: !prevState.isEditMode
		}));
	}

	cancelEditMode() {
		this.setState((prevState) => ({
			isEditMode: false,
			templateUpdates: prevState.initialTemplates,
			previewDisabledMap: {}
		}));
	}

	toggleMergeFieldsDialog() {
		this.setState((prevState) => ({
			isMergeFieldsDialogOpen: !prevState.isMergeFieldsDialogOpen,
		}));
	}

	renderEmailTemplates() {
		const {templateUpdates, isEditMode} = this.state;

		return templateUpdates.map((template, index) => {
			return isEditMode
				? this.renderEmailTemplateEditMode(template, index)
				: this.renderEmailTemplateViewOnlyMode(template, index);
		});
	}

	renderEmailTemplateEditMode(template, index) {
		const {order, title, subject, intro, conclusion, customMessageEnabled, stepRecipePublicId, mergeFields} = template;
		const {previewDisabledMap} = this.state;
		const emailLabel = this.getEmailLabel(index);

		const isPreviewDisabled = previewDisabledMap[stepRecipePublicId] || false;
		const spacingStyle = {marginTop: '10px'};

		return (
			<div key={index} style={templateSectionStyle}>
				<div>
					<strong>{title}</strong> <br/>
					<small>{emailLabel}</small>
				</div>

				<div style={spacingStyle}>
					<TextInputBox
						key={`subject-${order}`}
						value={subject}
						hintText={'Subject'}
						doNotAutoFocus={true}
						enableIntellisense={true}
						maxChars={255}
						mergeFields={mergeFields}
						onChange={(value) => {
							this.handleTemplateChange(stepRecipePublicId, 'subject', value);
						}}
					/>
				</div>

				<div style={spacingStyle}>
					<TextInputBox
						key={`body_intro-${order}`}
						value={intro}
						hintText={'Intro'}
						doNotAutoFocus={true}
						multiLine={true}
						rows={3}
						maxChars={1000}
						enableIntellisense={true}
						mergeFields={mergeFields}
						onChange={(value) => {
							this.handleTemplateChange(stepRecipePublicId, 'intro', value);
						}}
					/>
				</div>

				<div style={{...spacingStyle, backgroundColor: partnerTapLightestGrey, border: '1px solid ' + partnerTapStroke, borderRadius: 4, padding: 5}}>
					<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
						<CheckboxButton
							checked={customMessageEnabled}
							style={{padding: 0}}
							onChange={() => {
								this.handleTemplateChange(stepRecipePublicId, 'customMessageEnabled', !customMessageEnabled);
							}}
						/>
						Custom message
					</div>
					<div style={!customMessageEnabled ? {color: partnerTapInactive} : {}}>
						When this field is enabled, users can add custom messages to the workflow emails.
					</div>
				</div>

				<div style={spacingStyle}>
					<TextInputBox
						key={`body_conclusion-${order}`}
						value={conclusion}
						hintText={'Conclusion'}
						doNotAutoFocus={true}
						multiLine={true}
						rows={3}
						maxChars={1000}
						enableIntellisense={true}
						mergeFields={mergeFields}
						onChange={(value) => {
							this.handleTemplateChange(stepRecipePublicId, 'conclusion', value);
						}}
					/>
				</div>

				<div style={{paddingTop: 8, display: 'flex', justifyContent: 'flex-end'}}>
					<PrimaryButton label={'PREVIEW'} disabled={isPreviewDisabled} onClick={() => this.sendEmailPreview(template)}/>
				</div>
			</div>
		);
	}

	renderEmailTemplateViewOnlyMode(template, index) {
		const {title, subject, intro, conclusion, customMessageEnabled} = template;
		const emailLabel = this.getEmailLabel(index);

		return (
			<div key={index} style={templateSectionStyle}>
				<div>
					<strong>{title}</strong> <br/>
					<small>{emailLabel}</small>
				</div>
				<div style={textDisplayStyle}>
					<strong>Subject: </strong>{subject}
				</div>
				<div style={textDisplayStyle}>
					<strong>Intro: </strong>
					<div style={{ whiteSpace: 'pre-line' }}>{intro}</div>
				</div>
				{customMessageEnabled && (
					<div style={customMessageStyle}>
						[When enabled, Co-Sell author's custom message will display here.]
					</div>
				)}
				<div style={textDisplayStyle}>
					<strong>Conclusion: </strong>
					<div style={{ whiteSpace: 'pre-line' }}>{conclusion}</div>
				</div>
			</div>
		);
	}

	getEmailLabel(index) {
		switch (index) {
			case 0:
				return 'Sent upon workflow confirmation';
			case 1:
				return 'Sent upon Partner opt in';
			case 2:
				return 'Sent upon Sales Rep opt in';
			default:
				return '';
		}
	}

	renderInfoBox() {
		const {isEditMode} = this.state;

		return (
			<div style={mainContainerStyle}>
				<div style={containerStyle}>
					<p style={{fontSize: '15px', marginBottom: 5}}>
						Any changes made to this template will become the new default copy for all workflows using this sequence.
					</p>
					{isEditMode ? (
						<p style={{fontSize: '15px'}}>
							<div style={{marginBottom: '10px'}}>
								To add a <LinkButton label={'merge field'} onClick={() => this.toggleMergeFieldsDialog()}/>:
							</div>
							<PartnerTapBulletList bulletList={[
								'Type the dollar symbol followed by the curly bracket: \$\{',
								'This will automatically display a drop down with all the available merge fields',
								'Choose a merge field from the drop down list',
								'Ensure the merge field includes the closed curly bracket: e.g. \$\{mergeField\}',
								'Preview emails will populate the merge fields with sample data'
							]}/>
						</p>
					) : (
						<p style={{fontSize: '15px'}}>Click "EDIT" to make changes. <br/><br/></p>
					)}
				</div>
			</div>
		)
			;
	}

	renderButtons() {
		const {isEditMode} = this.state;
		const isEditWorkflowTemplatesFeatureEnabled = FeatureHelper.isFeatureEnabled(FEATURE_COSELL_EDIT_WORKFLOW_TEMPLATES);

		if (!isEditWorkflowTemplatesFeatureEnabled) return null;

		return (
			<div style={buttonsContainerStyle}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					{isEditMode ? (
						<>
							<SecondaryButton label={'CANCEL'} onClick={this.cancelEditMode}/>
							<div style={{padding: 10}}/>
							<PrimaryButton label={'SAVE'} onClick={this.saveSequenceTemplates}/>
							<div style={{padding: 10}}/>
							<LinkButton label={'RESET'} style={{alignSelf: 'center'}} onClick={() => this.setState({showResetSequencesDialog: true})}/>
						</>
					) : (
						<PrimaryButton label={'EDIT'}
									   onClick={this.toggleEditMode}
									   disabled={EnvHelper.isSpoofing && FeatureHelper.roleCode !== CO_SELL_CONCIERGE}/>
					)}
				</div>
			</div>
		);
	}

	renderMergeFieldsInformation() {
		const {templateUpdates} = this.state;

		const allMergeFields = [...new Set(templateUpdates.flatMap(template => template.mergeFields))];

		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 10, minWidth: 600, maxWidth: 600}}>
				<p>
					Merge fields act as a placeholder to personalize emails in bulk. PartnerTap Co-Sell Workflow email templates support including merge fields
					in the email subject line, intro, and conclusion.
				</p>
				<p>The following merge fields are available:</p>
				<ul style={{paddingLeft: '20px'}}>
					{allMergeFields.map((field, index) => (
						<li key={index} style={{marginBottom: '5px'}}>{field}</li>
					))}
				</ul>
			</div>
		);
	}

	render() {
		const {workflowType, sequenceType, sequenceDescription} = this.props;
		const {isMergeFieldsDialogOpen, showResetSequencesDialog, showPreviewDialog} = this.state;

		return (
			<div>
				{isMergeFieldsDialogOpen && (
					<Dialog title={'Merge Fields'} message={this.renderMergeFieldsInformation()} noAction={this.toggleMergeFieldsDialog}/>
				)}

				{showPreviewDialog && (
					<Dialog
						title={'Preview email sent'}
						message={
							<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
								<p>A preview email has been sent to your user login email.</p>
							</div>
						}
						yesLabel={'OK'}
						yesAction={this.closePreviewDialog}
					/>
				)}

				{this.renderInfoBox()}

				<div style={mainContainerStyle}>
					<div style={{...containerStyle, display: 'flex', flexDirection: 'column', gap: '3px'}}>
						<div>
							<strong>Workflow type:</strong> {workflowType}
						</div>
						<div>
							<strong>Sequence type:</strong> {sequenceType}
						</div>
						<div>
							<strong>Sequence description:</strong> {sequenceDescription}
						</div>

						{this.renderButtons()}

						<div style={templateContainerStyle}>
							{this.renderEmailTemplates()}
						</div>
					</div>
				</div>
				{showResetSequencesDialog &&
				 <Dialog title={'Reset default copy'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
								 <div style={{fontSize: 16, fontWeight: 'bold'}}>
									 Resetting the workflow template will return all emails in <br/> this workflow sequence to the default copy.
								 </div>
								 <div style={{fontSize: 16, fontWeight: 'bold'}}>

								 </div>
								 <div style={{fontSize: 16, fontWeight: 'bold'}}>
									 Are you sure you want to continue?
								 </div>
							 </div>}
						 yesLabel={'OK'}
						 yesAction={() => this.resetSequenceTemplates()}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showResetSequencesDialog: false})}/>}
			</div>
		);
	}
}

// Styles
const textDisplayStyle = {
	padding: '10px 0',
};

const containerStyle = {
	backgroundColor: partnerTapWhite,
	border: '1px solid ' + partnerTapStroke,
	borderRadius: 5,
	padding: '15px',
	textAlign: 'left',
	fontSize: '14px',
	lineHeight: '1.5',
};

const mainContainerStyle = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	maxWidth: '1200px',
	gap: '10px',
	padding: '10px 20px',
	margin: '0 auto',
	overflowY: 'auto',
	maxHeight: '90vh',
};

const buttonsContainerStyle = {
	display: 'flex',
	justifyContent: 'flex-end',
	width: '100%',
	marginBottom: '20px',
};

const templateContainerStyle = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
	gap: '8px',
};

const templateSectionStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: 6,
	padding: 8,
	border: '1px solid #ccc',
	borderRadius: 10,
	backgroundColor: '#f9f9f9',
	minWidth: '280px',
};

const customMessageStyle = {
	display: 'flex',
	alignItems: 'center',
	gap: 5,
	padding: '5px 0',
};

CoSellWorkflowEmailTemplateEditor.propTypes = {
	workflowType: PropTypes.string.isRequired,
	sequenceType: PropTypes.string.isRequired,
	sequenceDescription: PropTypes.string.isRequired,
	sequenceRecipePublicId: PropTypes.string.isRequired
};

export default CoSellWorkflowEmailTemplateEditor;