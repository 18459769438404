import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import DetailHeader from '../shared/DetailHeader';
import {ForwardToInbox, VerifiedUser} from '@mui/icons-material';
import LinkButton from '../../../ui/buttons/LinkButton';
import EnvHelper from '../../../helpers/EnvHelper';
import {Routes} from '../../../globals/Routes';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import {partnerTapWhite} from '../../../styles/partnertap_theme';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import Tile from '../../../ui/Tile';
import GppGoodIcon from '@mui/icons-material/GppGood';
import PropTypes from 'prop-types';
import * as RestAdminCenterEndPoints from '../../../endpoints/RestAdminCenterEndPoints';
import Loading from '../../../ui/Loading';
import FeatureHelper, {FEATURE_CHANNEL_DATA_MANAGER} from '../../../helpers/FeatureHelper';

export const TYPES = 'types';
export const OPP_FIELDS = 'oppFields';

class CustomColumnEditorHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			accountsCustomColumns: [],
			oppsCustomColumns: []
		};
	}

	componentDidMount() {
		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER)) {
			return;
		}
		RestAdminCenterEndPoints.getCustomMetadata()
		.then(result => {
			this.setState({
				loading: false,
				accountsCustomColumns: result.payload.types,
				oppsCustomColumns: result.payload.oppFields
			});
		});

	}

	render() {
		let {loading} = this.state;
		if (loading) return <Loading>Loading...</Loading>;

		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER)) {
			return (
				<MessageBoxInfo hideIcon={true}>
					The Custom column editor requires administrator level access. Please reach out to your PartnerTap
					administrator.
				</MessageBoxInfo>
			);
		}

		return (
			<ScrollingContainer divId={'custom-column-editor'}>
				<DetailHeader MaterialIcon={VerifiedUser}
							  title={'Custom Column Editor'}
							  detailRight={<LinkButton label={'Admin Center'} onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE)}/>}/>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					{
						(this.state.accountsCustomColumns.length === 0 && this.state.oppsCustomColumns.length === 0) ?
							this.noCustomColumnsMessage() : this.customColumnsAvailablePage()
					}
				</div>
			</ScrollingContainer>
		);
	}

	noCustomColumnsMessage() {
		return (
			<div style={{display: 'flex', flexDirection: 'column', background: partnerTapWhite, margin: 10, padding: 20, maxWidth: 600}}>
				<div>
					In the Custom Column Editor, you can edit the display name of a column, hide it from view in reports, and tag columns as unique IDs and
					Personally Identifiable Information(PII).
					<div style={{padding: 10}}/>
					To get started, head to the <LinkButton label={'Data Manager'}
															onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE)}/> to import your custom
					columns by uploading a CSV or connect with your CRM
					<div style={{padding: 10}}/>
					If you need help importing your columns, please reach out to <a href={'mailto:support@partnertap.com'}>support@partnertap.com</a>
				</div>
			</div>);
	}

	customColumnsAvailablePage() {
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<MessageBoxInfo>
					<div>
						Use the Custom Column Editor to make changes including the display name of a column, inactivate it to hide it from view on reports or
						tag it as a unique ID and/or PII.
					</div>
					<div>
						Custom columns brought into PartnerTap via CSV upload or CRM integration will be available here.
					</div>
				</MessageBoxInfo>

				<div style={{display: 'flex', justifyContent: 'center'}}>
					<Tile
						title={'Custom Account Columns'}
						icon={GppGoodIcon}
						description={''}
						actionLabel={'View'}
						isDisabled={this.state.accountsCustomColumns.length === 0}
						actionFunction={() => EnvHelper.push(`${Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.CUSTOM_COLUMN_EDITOR.ROUTE}/${TYPES}`)}
					/>
					<Tile
						title={'Custom Opps Columns'}
						icon={ForwardToInbox}
						description={''}
						actionLabel={'View'}
						isDisabled={this.state.oppsCustomColumns.length === 0}
						actionFunction={() => EnvHelper.push(`${Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.CUSTOM_COLUMN_EDITOR.ROUTE}/${OPP_FIELDS}`)}
					/>
				</div>
			</div>
		);
	}
}

CustomColumnEditorHome.propTypes = {
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CustomColumnEditorHome));
