import * as ApiHelper from '../helpers/ApiHelper';
import EnvHelper from '../helpers/EnvHelper';

// LIVE
const endpointVersion = '/v1';
const endpointActionListBase = endpointVersion + '/cosell-action-list';
const endpointWorkflowBase = endpointVersion + '/cosell-workflow';
const endpointLandingBase = endpointVersion + '/cosell-landing';
const endpointAnalyticsBase = endpointVersion + '/cosell-analytics';

// Action Lists
export function getAllCoSellActionListsLive(search, page, pageSize, sort, filter) {
	const payload = {
		filters: filter || {},
	};
	return ApiHelper.sendPostCoSellActionListMetadata(ApiHelper.appendPageableParams(`${endpointActionListBase}/records`, page, pageSize, search, sort), payload);
}

export function getCoSellActionListMetadata(actionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListMetadata(endpointActionListBase + '/' + actionListMetadataPublicId);
}

export function updateCoSellActionListMetadata(actionListMetadataPublicId, payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + actionListMetadataPublicId, payload);
}

export function createCoSellActionListLive(payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase, payload);
}

export function cloneCoSellActionListLive(cosellActionListMetadataPublicId, payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + cosellActionListMetadataPublicId + '/clone', payload);
}

export function deleteCoSellActionListLive(cosellActionListMetadataPublicId) {
	return ApiHelper.sendDeleteCoSellActionListMetadata(endpointActionListBase + '/' + cosellActionListMetadataPublicId);
}

export function updateCoSellActionListRecords(cosellActionListMetadataPublicId, action, payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + cosellActionListMetadataPublicId + '/record/bulk-update?action=' + action, payload);
}

export async function getCoSellConciergePermissionStatus() {
	return ApiHelper.sendGetCoSellActionListMetadata(endpointActionListBase + '/admin-center/co-sell-concierge');
}

export function updateCoSellConciergePermission(enabled) {
	return ApiHelper.sendPutCoSellActionListMetadata(endpointActionListBase + '/admin-center/co-sell-concierge?enabled=' + enabled);
}

export function getActionListManualRecipients(actionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListMetadata(endpointActionListBase + '/' + actionListMetadataPublicId + '/manual_recipients');
}

export function updateActionListManualRecipients(actionListMetadataPublicId, requestBody) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + actionListMetadataPublicId + '/manual_recipients', requestBody);
}

// Workflows
export function getActionListWorkflows(cosellActionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/all-workflows/action-list/' + cosellActionListMetadataPublicId);
}

export function getActionListWorkflowsAvailable() {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/all-workflows');
}

export async function fetchWorkflows() {
	const response = await getActionListWorkflowsAvailable();
	return mapWorkflows(response.payload);
}

export async function fetchWorkflowSequences(workflowRecipeId) {
	const response = await getWorkflowSequences(workflowRecipeId);
	return response.payload;
}

export async function fetchWorkflowSequenceTemplates(sequenceRecipeId) {
	const response = await getActionListWorkflowSequenceTemplates(sequenceRecipeId);
	return response.payload;
}

export function updateWorkflowSequenceTemplates(sequenceRecipeId, payload) {
	return ApiHelper.sendPutCoSellActionListWorkflows(endpointWorkflowBase + '/admin-center/template/sequence/' + sequenceRecipeId, payload);
}

export function resetWorkflowSequenceTemplates(sequenceRecipeId) {
	return ApiHelper.sendDeleteCoSellWorkflow(endpointWorkflowBase + '/admin-center/template/sequence/' + sequenceRecipeId);
}

export function sendAdminCenterEmailPreview(stepRecipeId, payload) {
	return ApiHelper.sendPostCoSellActionListWorkflows(endpointWorkflowBase + '/admin-center/template/email-preview/step/' + stepRecipeId, payload);
}

export function getActionListDashboard(actionListMetadataPublicId, dashboardType) {
	return ApiHelper.sendGetCoSellAnalytics(`${endpointVersion}/cosell-analytics/dashboard/${dashboardType}/action-list/${actionListMetadataPublicId}`);
}

const iconMapper = {
	COSELL_PLAY: "ConnectWithoutContact",
	SALES_PLAY: "ForwardToInbox",
	THRU_CHANNEL_PLAY: "FollowTheSigns",
	CRM_TRACKING: "DriveFolderUpload",
	P2P: "Groups3",
	LEAD: "Telegram"
};

const mapWorkflows = (workflows) => {
	return Object.keys(workflows).reduce((acc, key) => {
		acc[key] = {
			...workflows[key],
			MaterialIcon: iconMapper[key] || null,
		};
		return acc;
	}, {});
};

export function getActiveActionListWorkflow(cosellActionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/active-workflow/action-list/' + cosellActionListMetadataPublicId);
}

export function getWorkflowSequences(workflowRecipeId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/' + workflowRecipeId + '/sequence');
}

export function getActionListWorkflowSequences(workflowRecipeId, actionListMetaDataPublicId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/' + workflowRecipeId + '/action-list/' + actionListMetaDataPublicId + '/sequence');
}

export function getActionListWorkflowSequenceTemplates(sequenceRecipeId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/template/sequence/' + sequenceRecipeId);
}

export function getWorkflowSummaryData(cosellActionListMetadataPublicId, ownerEmailColumn, ownerNameColumn, partnerEmailColumn, partnerNameColumn, sequenceRecipeId, recipients) {
	let payload = {
		ownerEmailColumn: ownerEmailColumn,
		ownerNameColumn: ownerNameColumn,
		partnerEmailColumn: partnerEmailColumn,
		partnerNameColumn: partnerNameColumn,
		recipients: recipients
	};
	return ApiHelper.sendPostCoSellActionListWorkflows(endpointWorkflowBase + '/action-list-summary/action-list/' + cosellActionListMetadataPublicId + '/sequenceRecipeId/' + sequenceRecipeId, payload);
}

export function startWorkflow(sequenceRecipeId, payload) {
	return ApiHelper.sendPostCoSellActionListWorkflows(endpointWorkflowBase + '/sequence/' + sequenceRecipeId, payload);
}

export function stopWorkflow(workflowRecipeId) {
	return ApiHelper.sendDeleteCoSellWorkflow(endpointWorkflowBase + '/' + workflowRecipeId);
}

// Analytics
export function fetchCosellAnalyticsReportTypes() {
	return ApiHelper.sendGetCoSellAnalytics(endpointAnalyticsBase + '/report/all-reports');
}

export function getCoSellAnalyticsRecords(coSellActionListMetadataPublicId, reportType, params) {
	let payload = {
		filters: params.filters || {},
		search: params.search || ''
	};

	let endpoint = endpointAnalyticsBase + '/report/' + reportType + '/action-list/' + coSellActionListMetadataPublicId + '/records' + '?page=' + params.page;
	if (params.sort) endpoint += '&sort=' + params.sort;
	if (params.pageSize) endpoint += '&size=' + params.pageSize;
	return ApiHelper.sendPostCoSellAnalytics(endpoint, payload);
}

export function getCoSellAnalyticsClone(payload) {
	const {reportType, actionListMetadataPublicId} = payload.queryParams;
	let endpoint = endpointAnalyticsBase + '/report/' + reportType + '/action-list/' + actionListMetadataPublicId + '/clone';
	return ApiHelper.sendPostCoSellAnalytics(endpoint, payload.body);
}

export function getCoSellAnalyticsColumns(coSellActionListMetadataPublicId, reportType) {
	return ApiHelper.sendGetCoSellAnalytics(endpointAnalyticsBase + '/report/' + reportType + '/action-list/' + coSellActionListMetadataPublicId + '/columns');
}

export function getCoSellAnalyticsFilters(args, search, filters, field, currentPage, pageSize) {
	let payload = {
		filters: filters || {},
		search: search || ""
	};
	const reportType = args.coSellReportType;
	return ApiHelper.sendPostCoSellAnalytics(endpointAnalyticsBase + '/report/' + reportType + '/action-list/' + args.coSellActionListMetadataPublicId + '/filter-data?field=' + field + '&page=' + currentPage + '&size=' + pageSize, payload);
}

export function getCoSellAnalyticsDownload(coSellActionListMetadataPublicId, reportType, filters, search, selectedColumns) {
	let payload = {
		filters: filters || {},
		search: search || "",
		selectColumns: selectedColumns || []
	};
	return ApiHelper.sendPostCoSellAnalyticsDownload(endpointAnalyticsBase + '/report/' + reportType + '/action-list/' + coSellActionListMetadataPublicId + '/download', payload, "workflow_report.csv");
}

// Landing
export function getWorkflowLandingForStep(stepInstancePublicId) {
	return ApiHelper.sendGetCoSellLanding(endpointLandingBase + '/' + stepInstancePublicId);
}

export function updateWorkflowLandingForStep(stepInstancePublicId, payload) {
	return ApiHelper.sendPostCoSellLanding(endpointLandingBase + '/' + stepInstancePublicId, payload);
}

export function downloadWorkflowLandingForStep(stepInstancePublicId) {
	let downloadCoSellLandingEndpoint = EnvHelper.coSellLandingUrl + endpointLandingBase + '/' + stepInstancePublicId + '/download';
	return fetch(downloadCoSellLandingEndpoint, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	});
}
