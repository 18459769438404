import {UploadFile} from '@mui/icons-material';
import React, {Component} from 'react';
import EnvHelper from '../../../../helpers/EnvHelper';
import {partnerTapSecondary} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';

class DropzoneContent extends Component {

	get isUploadDisabled() {
		const {allowSpoofing, isOrgDataUnavailable} = this.props;
		const isSpoofingWithoutPermission = EnvHelper.isSpoofing && !allowSpoofing;

		return isSpoofingWithoutPermission || isOrgDataUnavailable;
	}

	render() {
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<UploadFile style={{color: partnerTapSecondary, fontSize: 60}}/>
				<div style={{padding: 10}}>
					or
				</div>
				<PrimaryButton label={'SELECT CSV FILE'} disabled={this.isUploadDisabled}/>
			</div>
		);
	}
}

DropzoneContent.propTypes = {
	allowSpoofing: PropTypes.bool,
	isOrgDataUnavailable: PropTypes.bool
};

export default DropzoneContent;
