import {partnerTapAlert} from '../styles/partnertap_theme';
import PopoverSearchList from './PopoverSearchList';
import React from 'react';

function LabeledPopOverList({fieldName, fieldLabel, fieldValueMap, onItemSelected, isMandatoryField = false, onItemMissing}) {

	return (
		<div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
			<div style={{width: '35%'}}>
				<div style={{display: 'grid', alignItems: 'center', columnGap: '10px', flexShrink: 0}}>
					<div style={{width: '50%', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{fieldLabel}</div>
					{isMandatoryField && onItemMissing(fieldName) && <div style={{color: partnerTapAlert}}> This field is mandatory</div>}
				</div>
			</div>
			<div
				style={{
					flexShrink: 0,
					width: 300
				}}
			>
				<PopoverSearchList
					label={fieldLabel}
					list={[...fieldValueMap.keys()]}
					onItemSelected={(fieldKey) => onItemSelected(fieldName, fieldValueMap.get(fieldKey))}
					width={400}/>
			</div>
		</div>
	);

}

export default LabeledPopOverList;