import React, {useState} from 'react';
import AiQuestionsSelector from './AiQuestionsSelector';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';

const width = 700;

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '400px'
	},
	stepper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '100px',
		marginBottom: '16px'
	},
	dot: {
		width: '12px',
		height: '12px',
		borderRadius: '50%',
		backgroundColor: '#e5e7eb', // gray for future steps
		transition: 'background-color 0.2s'
	},
	dotActive: {
		backgroundColor: '#3b82f6' // blue for current step
	},
	dotCompleted: {
		backgroundColor: '#93c5fd' // light blue for completed steps
	},
	itemsContainer: {
		flex: 1,
		display: 'flex',
		width: width * 0.9,
		flexDirection: 'column',
		gap: '8px',
		marginBottom: '16px',
	},
	itemStyle: {
		padding: '15px',
		background: '#FFFFFF',
		borderRadius: '8px',
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
		cursor: 'pointer',
		transition: 'all 0.2s'
	},
	selectedItemStyle: {
		padding: '15px',
		background: '#f0f7ff',
		borderRadius: '8px',
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
		cursor: 'pointer',
		border: '2px solid #0066cc',
		transition: 'all 0.2s'
	},
	itemContent: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px'
	},
	checkbox: {
		width: '16px',
		height: '16px',
		border: '2px solid #d1d5db',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	checkboxSelected: {
		width: '16px',
		height: '16px',
		border: '2px solid #3b82f6',
		borderRadius: '4px',
		backgroundColor: '#3b82f6',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		fontSize: '12px'
	},
	button: {
		width: '100%',
		padding: '8px 16px',
		borderRadius: '8px',
		fontWeight: 500,
		border: 'none',
		cursor: 'pointer',
		transition: 'background-color 0.2s'
	},
	buttonEnabled: {
		backgroundColor: '#3b82f6',
		color: 'white',
		'&:hover': {
			backgroundColor: '#2563eb'
		}
	},
	buttonDisabled: {
		backgroundColor: '#e5e7eb',
		color: '#9ca3af',
		cursor: 'not-allowed'
	},
	title: {
		fontSize: '24px',
		fontWeight: '700',
		marginBottom: '20px',
	}
};

const Stepper = ({totalSteps, currentStep}) => {
	return (
		<div style={styles.stepper}>
			{Array.from({length: totalSteps}).map((_, index) => (
				<div
					key={index}
					style={{
						...styles.dot,
						...(index === currentStep ? styles.dotActive : {}),
						...(index < currentStep ? styles.dotCompleted : {})
					}}
				/>
			))}
		</div>
	);
};


const FlowSection = ({
	flowConfig,
	onBack,
	onStepComplete
}) => {
	const [stepIndex, setStepIndex] = useState(0);
	const [selections, setSelections] = useState({});
	const [stepSelections, setStepSelections] = useState(new Set());

	const goBack = () => {
		if (stepIndex > 0) {
			setStepIndex(prev => prev - 1);
			setStepSelections(new Set(selections[stepIndex - 1] || []));
		}
		else {
			onBack();
		}
	};

	const handleSelection = (itemId) => {
		const newStepSelections = new Set(stepSelections);
		if (newStepSelections.has(itemId)) {
			newStepSelections.delete(itemId);
		}
		else {
			newStepSelections.add(itemId);
		}
		setStepSelections(newStepSelections);
	};

	const handleNext = () => {
		const newSelections = {
			...selections,
			[stepIndex]: Array.from(stepSelections)
		};
		setSelections(newSelections);

		// Convert to text values only for the onStepComplete callback
		const textSelections = {
			[stepIndex]: Array.from(stepSelections).map(selectedId => {
				const selectedItem = currentStep.items.find(item => item.id === selectedId);
				return selectedItem.text;
			})
		};

		onStepComplete(stepIndex, textSelections);

		if (stepIndex >= flowConfig.steps.length - 1) {
			return;
		}

		setStepIndex(prev => prev + 1);
		setStepSelections(new Set(selections[stepIndex + 1] || []));
	};

	const currentStep = flowConfig.steps[stepIndex];

	return (
		<AiQuestionsSelector
			width={width}
			title={<Stepper
				totalSteps={flowConfig.steps.length}
				currentStep={stepIndex}
			/>}
			showBackButton={false}
		>
			<div style={{
				margin: '0 auto'
			}}>

			</div>
			<div style={styles.container}>
				<div style={styles.title}>
					{currentStep.title}
				</div>

				<div style={styles.itemsContainer}>
					{currentStep.items.map((item) => (
						<div
							key={item.id}
							style={stepSelections.has(item.id) ? styles.selectedItemStyle : styles.itemStyle}
							onClick={() => handleSelection(item.id)}
						>
							<div style={styles.itemContent}>
								<div style={stepSelections.has(item.id) ? styles.checkboxSelected : styles.checkbox}>
									{stepSelections.has(item.id) && '✓'}
								</div>
								{item.text}
							</div>
						</div>
					))}
				</div>

				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<SecondaryButton label={'Back'} onClick={goBack}/>
					<PrimaryButton label={stepIndex === flowConfig.steps.length - 1 ? 'Finish' : 'Next'}
								   disabled={stepSelections.size === 0}
								   onClick={handleNext}/>
				</div>

			</div>
		</AiQuestionsSelector>
	);
};

export default FlowSection;