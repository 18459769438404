import {Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

class ToolTipOverlay extends Component {

	render() {
		if (!this.props.title) return <div>{this.props.children}</div>;
		return (
			<Tooltip title={this.props.title} placement={this.props.placement} arrow={true} PopperProps={{style:{zIndex:20000}}}>
				<div>{this.props.children}</div>
			</Tooltip>
		);
	}
}

ToolTipOverlay.propTypes = {
	title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	placement: PropTypes.string
};

export default ToolTipOverlay;
