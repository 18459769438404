import {
	AccountCircle,
	AdminPanelSettings,
	Air,
	AlarmOn, AttachMoney,
	Block,
	Build,
	Business,
	ChecklistRtl,
	CloudDownload,
	CloudSync,
	Dataset,
	Details,
	Diversity3,
	Download,
	EmojiTransportation,
	Engineering,
	ExitToApp,
	Face,
	Forum,
	ForwardToInbox,
	Group,
	GroupAdd,
	Groups,
	Handshake,
	History,
	HowToReg,
	Hub,
	HubOutlined,
	Insights,
	JoinInner,
	JoinLeftOutlined,
	List,
	ListAlt,
	LiveHelp,
	LocalOffer,
	NoteAddOutlined,
	PersonAdd,
	PersonPin,
	PersonSearch,
	Poll,
	Public,
	ReadMore,
	Save,
	Settings,
	Star,
	Sync,
	TaskOutlined,
	UploadFile,
	VerifiedUser,
	ViewList,
	ChatBubble,
	AutoAwesome
} from '@mui/icons-material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import AdminCenter from '../app/channel_ecosystem/admin_center/AdminCenter';
import {
	BASE_OPPORTUNITIES_MANUAL,
	CRM_HUBSPOT,
	CRM_SALESFORCE,
	UPLOAD_DATA_MANAGER_ENRICH_SHEET,
	UPLOAD_DATA_MANAGER_SHEET,
	UPLOAD_FULL_SERVICE_CSV,
	UPLOAD_PARTNER_SHEET,
	UPLOAD_PII_SHARING_OPT_IN
} from '../globals/Enums';
import {Routes} from '../globals/Routes';
import {PERSISTENT_KEY} from '../globals/StorageKeys';
import * as AuthActions from '../redux/AuthActions';
import DataManagerHelper from './DataManagerHelper';
import EnvHelper from './EnvHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_AI_ASSISTANT,
	FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO,
	tempFeatureFlag_UploadFullServiceCsv
} from './FeatureHelper';
import ReportHelper from './ReportHelper';

export default class NavHelper {

	static set selectedNavButton(navButton) {
		this.navButton = navButton;
	}

	static get selectedNavButton() {
		return this.navButton;
	}

	static getCrmAccountLink(crmType, crmInstance, crmAccountId) {
		let link;
		switch (crmType) {
			case CRM_SALESFORCE:
				if (this.customCrmDomainValue) {
					link = this.customCrmDomainValue + crmAccountId;
				}
				else {
					link = 'https://na50.lightning.force.com/' + crmAccountId;
				}
				break;
			case CRM_HUBSPOT:
				link = ' https://app.hubspot.com/contacts/' + crmInstance + '/company/' + crmAccountId + '/';
		}
		return link;
	}

	static set returnToGettingStarted(active) {
		this.returnToGettingStartedValue = active;
	}

	static get returnToGettingStarted() {
		return this.returnToGettingStartedValue;
	}

	static getSalesNetworkData(props) {
		let pendingPartnerCount = 0;
		if (props.partnerRequestCount !== -1) pendingPartnerCount = props.partnerRequestCount;

		if (!this.salesNetworkNavData) {
			this.salesNetworkNavData = {navButtons: null, storageKey: PERSISTENT_KEY + '_sales_data'};
		}

		let navButtons = [
			{
				label: 'Tap',
				glyph: 'K',
				destination: Routes.DASHBOARD.TAP.ROUTE,
				children: [
					{
						label: 'Active Opps',
						glyph: 'G',
						destination: Routes.DASHBOARD.TAP.ACTIVE.ROUTE,
						activeKeys: [Routes.DASHBOARD.TAP.ROUTE]
					},
					{
						label: 'Share Opps',
						glyph: 'W',
						destination: Routes.DASHBOARD.TAP.SHARE.ROUTE
					}
				]
			},
			{
				label: 'Intel',
				MaterialIcon: Forum,
				destination: Routes.DASHBOARD.INTEL.ROUTE,
				children: [
					{
						label: 'All',
						glyph: 'C',
						destination: Routes.DASHBOARD.INTEL.ROUTE
					}
				]
			},
			{
				label: 'Partners',
				glyph: 'L',
				destination: Routes.DASHBOARD.PARTNERS.ACTIVE.ROUTE,
				children: [
					{
						label: 'Connections',
						MaterialIcon: Group,
						destination: Routes.DASHBOARD.PARTNERS.ACTIVE.ROUTE,
						activeKeys: [Routes.DASHBOARD.ROUTE, Routes.DASHBOARD.PARTNERS.ROUTE]
					},
					{
						label: 'Pending',
						MaterialIcon: AlarmOn,
						destination: Routes.DASHBOARD.PARTNERS.PENDING.ROUTE,
						alertCount: pendingPartnerCount
					},
					{
						label: 'Find',
						MaterialIcon: PersonSearch,
						destination: Routes.DASHBOARD.PARTNERS.DISCOVER.ROUTE
					},
					{
						label: 'Invite',
						MaterialIcon: PersonAdd,
						destination: Routes.DASHBOARD.PARTNERS.INVITE.ROUTE
					},
					{
						label: 'Invite History',
						MaterialIcon: History,
						destination: Routes.DASHBOARD.PARTNERS.INVITE.HISTORY.ROUTE
					}
				]
			},
			{
				label: 'Accounts',
				glyph: 'H',
				destination: Routes.DASHBOARD.ACCOUNTS.ALL.ROUTE,
				children: [
					{
						label: 'All',
						glyph: 'A',
						destination: Routes.DASHBOARD.ACCOUNTS.ALL.ROUTE
					},
					{
						label: 'Open Opps',
						glyph: '5',
						destination: Routes.DASHBOARD.ACCOUNTS.OPEN.ROUTE
					}
				]
			}
		];

		NavHelper.addMyProfileButton(navButtons, Routes.DASHBOARD.PROFILE.ROUTE, Routes.DASHBOARD.SETTINGS.ROUTE);
		this.salesNetworkNavData.navButtons = navButtons;
		NavHelper.addDynamicSalesNetworkData(this.salesNetworkNavData.navButtons);
		NavHelper.setNavButtonIds(this.salesNetworkNavData.navButtons);
		return this.salesNetworkNavData;
	}

	static addDynamicSalesNetworkData(navButtons) {
		const onTapSharePage = Boolean(EnvHelper.path.match(new RegExp(Routes.DASHBOARD.TAP.SHARE_PARTNERS.PATH + '.*')));
		if (onTapSharePage) {
			navButtons[0].children.push(
				{
					label: 'Share Settings',
					glyph: 'D',
					forceSelectDynamicNav: true
				}
			);
		}

		const onIntelChatPage = Boolean(EnvHelper.path.match(new RegExp(Routes.DASHBOARD.INTEL.ROUTE + '/.*')));
		if (onIntelChatPage) {
			navButtons[1].children.push(
				{
					label: 'Intel Chat',
					MaterialIcon: Forum,
					forceSelectDynamicNav: true
				}
			);
		}

		const onPartnerAccountsPage = Boolean(EnvHelper.path.match(new RegExp(Routes.DASHBOARD.PARTNERS.ROUTE + '/.*/.*/accounts')));
		const onPartnerIntelPage = Boolean(EnvHelper.path.match(new RegExp(Routes.DASHBOARD.PARTNERS.ROUTE + '/.*/.*/intel.*')));
		if (onPartnerAccountsPage || onPartnerIntelPage) {
			let basePath;
			if (onPartnerAccountsPage) {
				basePath = EnvHelper.path.substring(0, EnvHelper.path.lastIndexOf('/accounts'));
			}
			else if (onPartnerIntelPage) {
				basePath = EnvHelper.path.substring(0, EnvHelper.path.lastIndexOf('/intel'));
			}
			navButtons[2].children.push(
				{
					label: 'Partner Accounts',
					glyph: 'H',
					destination: basePath + '/accounts',
					forceSelectDynamicNav: onPartnerAccountsPage
				}
			);
			navButtons[2].children.push(
				{
					label: 'Partner Intel',
					MaterialIcon: Forum,
					destination: basePath + '/intel',
					forceSelectDynamicNav: onPartnerIntelPage
				}
			);
		}

		const onAccountPartnersPage = Boolean(EnvHelper.path.match(new RegExp(Routes.DASHBOARD.ACCOUNTS.ROUTE + '/.*/partners')));
		const onAccountIntelPage = Boolean(EnvHelper.path.match(new RegExp(Routes.DASHBOARD.ACCOUNTS.ROUTE + '/.*/intel.*')));
		if (onAccountPartnersPage || onAccountIntelPage) {
			let basePath;
			if (onAccountPartnersPage) {
				basePath = EnvHelper.path.substring(0, EnvHelper.path.lastIndexOf('/partners'));
			}
			else if (onAccountIntelPage) {
				basePath = EnvHelper.path.substring(0, EnvHelper.path.lastIndexOf('/intel'));
			}
			navButtons[3].children.push(
				{
					label: 'Account Partners',
					glyph: 'L',
					destination: basePath + '/partners',
					forceSelectDynamicNav: onAccountPartnersPage
				}
			);
			navButtons[3].children.push(
				{
					label: 'Account Intel',
					MaterialIcon: Forum,
					destination: basePath + '/intel',
					forceSelectDynamicNav: onAccountIntelPage
				}
			);
		}
	}

	static get managerAnalyticsData() {
		if (!this.managerAnalyticsNavData) {
			this.managerAnalyticsNavData = {navButtons: null, storageKey: PERSISTENT_KEY + 'manager_nav'};
		}

		let navButtons = [
			{
				label: 'Dashboard',
				glyph: '7',
				destination: Routes.MANAGER_ANALYTICS.DASHBOARD.ROUTE
			},
			{
				label: 'Sales Reps',
				glyph: 'W',
				destination: Routes.MANAGER_ANALYTICS.CONNECTIONS_ANALYTICS.ROUTE,
				children: [
					{
						label: 'All',
						glyph: 'X',
						destination: Routes.MANAGER_ANALYTICS.CONNECTIONS_ANALYTICS.ROUTE
					}
				]
			},
			{
				label: 'Accounts',
				glyph: 'H',
				destination: Routes.MANAGER_ANALYTICS.MATCHED_ACCOUNTS_ANALYTICS.ROUTE,
				children: [
					{
						label: 'Mapped',
						glyph: '5',
						destination: Routes.MANAGER_ANALYTICS.MATCHED_ACCOUNTS_ANALYTICS.ROUTE

					},
					{
						label: 'Unmapped',
						glyph: '6',
						destination: Routes.MANAGER_ANALYTICS.UNMATCHED_ACCOUNTS_ANALYTICS.ROUTE
					}
				]
			},
			{
				label: 'Intel Exchange',
				MaterialIcon: Forum,
				destination: Routes.MANAGER_ANALYTICS.INTEL_ANALYTICS.ROUTE,
				children: [
					{
						label: 'All',
						glyph: 'X',
						destination: Routes.MANAGER_ANALYTICS.INTEL_ANALYTICS.ROUTE
					}
				]
			},
			{
				label: 'Opps',
				glyph: 'K',
				destination: Routes.MANAGER_ANALYTICS.TAP.OUTBOUND,
				children: [
					{
						label: 'Org Opps',
						glyph: 'k',
						destination: Routes.MANAGER_ANALYTICS.TAP.OUTBOUND
					},
					{
						label: 'Partner Opps',
						glyph: 'D',
						destination: Routes.MANAGER_ANALYTICS.TAP.INBOUND
					}
				]
			}
		];

		if (EnvHelper.isDesktop) {
			navButtons.push({
				label: 'Download Reports',
				MaterialIcon: CloudDownload,
				destination: Routes.MANAGER_ANALYTICS.REPORTS.ROUTE
			});
		}

		NavHelper.addMyProfileButton(navButtons, Routes.MANAGER_ANALYTICS.PROFILE.ROUTE, Routes.MANAGER_ANALYTICS.SETTINGS.ROUTE);
		this.managerAnalyticsNavData.navButtons = navButtons;
		NavHelper.addDynamicManagerAnalyticsData(this.managerAnalyticsNavData.navButtons);
		NavHelper.setNavButtonIds(this.managerAnalyticsNavData.navButtons);
		return this.managerAnalyticsNavData;
	}

	static addDynamicManagerAnalyticsData(navButtons) {
		const onTapOrgPage = Boolean(EnvHelper.path.match(new RegExp(Routes.MANAGER_ANALYTICS.TAP.ORG.INBOUND))) ||
							 Boolean(EnvHelper.path.match(new RegExp(Routes.MANAGER_ANALYTICS.TAP.ORG.OUTBOUND)));
		if (onTapOrgPage) {
			navButtons[4].children.push(
				{
					label: 'Org Opp Details',
					MaterialIcon: Business,
					forceSelectDynamicNav: true
				});
		}
	}

	static get channelEcosystemData() {
		if (!this.channelEcosystemNavData) {
			this.channelEcosystemNavData = {navButtons: null, storageKey: PERSISTENT_KEY + '_channel_ecosystem'};
		}

		let navButtons = [
			{
				label: 'Getting Started',
				MaterialIcon: Star,
				destination: Routes.CHANNEL_ECOSYSTEM.GETTING_STARTED_WIZARD.ROUTE,
				activeKeys: [Routes.CHANNEL_ECOSYSTEM.ROUTE]
			},
		];

		if(FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_AI_ASSISTANT)) {
			navButtons.push({
				label: 'AI Answers',
				MaterialIcon: AutoAwesome,
				destination: Routes.CHANNEL_ECOSYSTEM.AI_ANSWERS.ROUTE,
				activeKeys: [Routes.CHANNEL_ECOSYSTEM.AI_ANSWERS.ROUTE]
			})
		}

		let partners = {
			label: 'Partners',
			MaterialIcon: Groups,
			destination: Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE,
			children: [
				{
					label: 'Organizations',
					MaterialIcon: Business,
					destination: Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORGS.PATH(),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORGS.PATH(ReportHelper.companyPartnerReportTypes[0].reportTitle),
						Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORGS.PATH(ReportHelper.companyPartnerReportTypes[1].reportTitle),
						Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORGS.PATH(ReportHelper.companyPartnerReportTypes[2].reportTitle)
					]
				},
				{
					label: 'Connections',
					MaterialIcon: Group,
					destination: Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE
				},
				{
					label: 'Sheets',
					MaterialIcon: ListAlt,
					destination: Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(UPLOAD_PARTNER_SHEET),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(UPLOAD_PARTNER_SHEET),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(UPLOAD_PARTNER_SHEET),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_PARTNER_SHEET)
					]
				},
				{
					label: 'Invite',
					MaterialIcon: PersonAdd,
					destination: Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH()
				},
				{
					label: 'Recommended',
					MaterialIcon: PersonSearch,
					destination: Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.RECOMMENDATIONS.ROUTE
				},
				{
					label: 'Collaborate',
					MaterialIcon: Forum,
					destination: Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INTEL.ROUTE,
					activeKeys: [Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INTEL.PARTNER.ROUTE]
				}
			]
		};
		navButtons.push(partners);

		const onPartnerOrgDetailPage = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.BASE_ROUTE) === 0;
		if (onPartnerOrgDetailPage) {
			partners.children.splice(1, 0, {
				label: 'Org Details',
				MaterialIcon: EmojiTransportation,
				forceSelectDynamicNav: true
			});
		}

		const onPartnerConnectionDetailPage = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.BASE_ROUTE) === 0;
		if (onPartnerConnectionDetailPage) {
			partners.children.splice(2, 0, {
				label: 'Connection Details',
				MaterialIcon: HowToReg,
				forceSelectDynamicNav: true
			});
		}

		const onPartnerSheetPage = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.BASE_ROUTE) === 0;
		if (onPartnerSheetPage) {
			partners.children.splice(3, 0, {
				label: 'Sheet Details',
				MaterialIcon: TaskOutlined,
				forceSelectDynamicNav: true
			});
		}

		let accountsReports = {
			label: 'Accounts',
			MaterialIcon: JoinInner,
			destination: Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.BASE_ROUTE,
			isAlwaysClickable: true,
			children: [
				{
					label: 'Account Lists',
					MaterialIcon: List,
					destination: Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.BASE_ROUTE,
					activeKeys: [Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.ROUTE]
				}
			]
		};
		navButtons.push(accountsReports);
		const onAccountsDetailsPage = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.ACCOUNT_DETAILS.PATH('')) === 0;
		if (onAccountsDetailsPage) {
			accountsReports.children.push(
				{
					label: 'Account Details',
					MaterialIcon: ReadMore,
					forceSelectDynamicNav: true
				});
		}

		let opportunitiesReports = {
			label: 'Opportunities',
			MaterialIcon: Hub,
			destination: Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.BASE_ROUTE,
			isAlwaysClickable: true,
			children: [
				{
					label: 'Opp Lists',
					MaterialIcon: List,
					destination: Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.BASE_ROUTE,
					activeKeys: [Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.ROUTE]
				}
			]
		};
		navButtons.push(opportunitiesReports);
		const onOpportunityDetailsPage = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.OPPORTUNITY_DETAILS.PATH('')) === 0;
		if (onOpportunityDetailsPage) {
			opportunitiesReports.children.push({
				label: 'Opp Details',
				MaterialIcon: ReadMore,
				forceSelectDynamicNav: true
			});
		}

		let coSellReports = {
			label: 'Co-Sell Engine',
			MaterialIcon: Diversity3,
			destination: Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE,
			isAlwaysClickable: true,
			children: [
				{
					label: 'Overview',
					MaterialIcon: ChecklistRtl,
					destination: Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE,
					activeKeys: [Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE]
				}
				// V2
				// {
				// 	label: 'Dashboard',
				// 	MaterialIcon: Insights,
				// 	destination: Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.DASHBOARD.ROUTE,
				// 	activeKeys: [Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.DASHBOARD.ROUTE]
				// }
			]
		};
		navButtons.push(coSellReports);
		const onActionListPage = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_RESULTS_LIVE.NAV_ROUTE) === 0 ||
								 EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_DASHBOARD.PATH('')) === 0 ||
								 EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH('')) === 0;
		if (onActionListPage) {
			coSellReports.children.splice(1, 0,
				{
					label: 'Action List',
					MaterialIcon: Air,
					forceSelectDynamicNav: true
				});
		}


		const ecosystemReports = {
			label: 'Ecosystem',
			MaterialIcon: Poll,
			destination: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.DASHBOARD.ROUTE,
			isAlwaysClickable: true,
			children: [
				{
					label: 'Dashboard',
					MaterialIcon: Insights,
					destination: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.DASHBOARD.ROUTE,
					isAlwaysClickable: true
				},
				{
					label: 'Global Reports',
					MaterialIcon: Public,
					destination: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.ROUTE
				},
				{
					label: 'Saved Reports',
					MaterialIcon: Save,
					destination: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.PERSONAL.ROUTE,
					activeKeys: [Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.ORG.ROUTE]
				},
				{
					label: 'Download Reports',
					MaterialIcon: Download,
					destination: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.DOWNLOADED.PATH()
				}
			]
		};
		navButtons.push(ecosystemReports);

		let ecosystemAdminCenter = {
			label: 'Admin Center',
			MaterialIcon: AdminPanelSettings,
			position: 'bottom',
			destination: Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE,
			isAlwaysClickable: true
		};
		navButtons.push(ecosystemAdminCenter);
		const onAdminCenterSectionDetail = EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.SECTION_DETAIL.ROUTE) === 0 &&
										   AdminCenter.sectionFromParams;
		if (onAdminCenterSectionDetail) {
			ecosystemAdminCenter.children = [
				{
					label: AdminCenter.sectionFromParams.sideNavTitle || AdminCenter.sectionFromParams.linkTitle || AdminCenter.sectionFromParams.title,
					MaterialIcon: VerifiedUser,
					forceSelectDynamicNav: true
				}
			];
		}

		let dataManager = {
			label: 'Data Manager',
			MaterialIcon: Dataset,
			position: 'bottom',
			destination: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE,
			isAlwaysClickable: true,
			children: [
				{
					label: 'Add Accounts',
					MaterialIcon: UploadFile,
					destination: Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_DATA_MANAGER_SHEET),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(UPLOAD_DATA_MANAGER_SHEET),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(UPLOAD_DATA_MANAGER_SHEET),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(UPLOAD_DATA_MANAGER_SHEET)
					]
				},
				{
					label: 'Enrich Accounts',
					MaterialIcon: NoteAddOutlined,
					destination: Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_DATA_MANAGER_ENRICH_SHEET),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(UPLOAD_DATA_MANAGER_ENRICH_SHEET),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(UPLOAD_DATA_MANAGER_ENRICH_SHEET),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(UPLOAD_DATA_MANAGER_ENRICH_SHEET)
					]
				},
				{
					label: 'Add Opportunities',
					MaterialIcon: AttachMoney,
					destination: Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(BASE_OPPORTUNITIES_MANUAL),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(BASE_OPPORTUNITIES_MANUAL),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(BASE_OPPORTUNITIES_MANUAL),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(BASE_OPPORTUNITIES_MANUAL)
					]
				},
				{
					label: 'My Accounts',
					MaterialIcon: JoinLeftOutlined,
					destination: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.ROUTE
				},
				{
					label: 'My Opportunities',
					MaterialIcon: HubOutlined,
					destination: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_OPPORTUNITIES.ROUTE
				}
			]
		};

		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO)) {
			dataManager.children.splice(2, 0,
				{
					label: 'Upload PII',
					MaterialIcon: GppGoodIcon,
					destination: Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_PII_SHARING_OPT_IN),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(UPLOAD_PII_SHARING_OPT_IN),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(UPLOAD_PII_SHARING_OPT_IN),
						Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(UPLOAD_PII_SHARING_OPT_IN)
					]
				});
		}

		if (tempFeatureFlag_UploadFullServiceCsv) {
			dataManager.children.splice(2, 0,
				{
					label: 'Full Service CSV',
					MaterialIcon: ForwardToInbox,
					destination: Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_FULL_SERVICE_CSV)
				});
		}

		if (DataManagerHelper.crmTypeSynced) {
			dataManager.children.splice(0, 0,
				{
					label: 'CRM Sync Summary',
					MaterialIcon: CloudSync,
					destination: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE
				}
			);
		}
		else {
			dataManager.children.splice(0, 0,
				{
					label: 'Connect CRM',
					MaterialIcon: CloudSync,
					destination: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH(),
					activeKeys: [
						Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.ROUTE,
						Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_2.ROUTE,
						Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_3.ROUTE
					]
				}
			);
		}
		navButtons.push(dataManager);
		const onSyncedAccountsPage = Boolean(EnvHelper.path.match(new RegExp(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.ROUTE + '/COMPANY_SALE_ACCOUNTS_(.*)')));
		if (onSyncedAccountsPage) {
			let crmName = DataManagerHelper.getCrmName(EnvHelper.path.substring(EnvHelper.path.lastIndexOf('/') + 1));
			dataManager.children.splice(1, 0,
				{
					label: crmName + ' Sync',
					MaterialIcon: Sync,
					forceSelectDynamicNav: true
				});
		}
		const onSheetReportPage = Boolean(EnvHelper.path.match(new RegExp(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.ROUTE + '/[0-9]+')));
		const onOppSheetReportPage = Boolean(EnvHelper.path.match(new RegExp(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_OPPORTUNITIES.ROUTE + '/[0-9]+')));
		if (onSheetReportPage || onOppSheetReportPage) {
			dataManager.children.splice(2, 0,
				{
					label: 'My Sheet',
					MaterialIcon: TaskOutlined,
					forceSelectDynamicNav: true
				});
		}

		NavHelper.addMyProfileButton(navButtons, Routes.CHANNEL_ECOSYSTEM.PROFILE.ROUTE, Routes.CHANNEL_ECOSYSTEM.SETTINGS.ROUTE);
		this.channelEcosystemNavData.navButtons = navButtons;
		NavHelper.setNavButtonIds(this.channelEcosystemNavData.navButtons);
		return this.channelEcosystemNavData;
	}

	static get adminToolsData() {
		if (!this.adminToolsNavData) {
			this.adminToolsNavData = {
				storageKey: PERSISTENT_KEY + '_admin_tools',
				navButtons: null
			};
		}

		let navButtons = [
			{
				label: 'Orgs',
				MaterialIcon: Business,
				destination: Routes.ADMIN_TOOLS.ORGS.ROUTE,
				children: [
					{
						label: 'All',
						MaterialIcon: ViewList,
						destination: Routes.ADMIN_TOOLS.ORGS.ROUTE
					},
					{
						label: 'Account Tags',
						MaterialIcon: LocalOffer,
						destination: Routes.ADMIN_TOOLS.ORGS.ACCOUNT_TAGS.ROUTE
					}
				]
			},
			{
				label: 'Org Partners',
				MaterialIcon: Handshake,
				destination: Routes.ADMIN_TOOLS.PARTNERS.EXCLUDED.ROUTE,
				children: [
					{
						label: 'Auto Partner',
						MaterialIcon: GroupAdd,
						destination: Routes.ADMIN_TOOLS.PARTNERS.AUTO_PARTNERED_ORGS.ROUTE
					},
					{
						label: 'Recommended',
						MaterialIcon: PersonSearch,
						destination: Routes.ADMIN_TOOLS.PARTNERS.RECOMMENDED_ORGS.ROUTE
					},
					{
						label: 'Upload Recommended',
						MaterialIcon: UploadFile,
						destination: Routes.ADMIN_TOOLS.PARTNERS.UPLOAD.PATH()
					},
					{
						label: 'Pre-Recommended',
						MaterialIcon: Build,
						destination: Routes.ADMIN_TOOLS.PARTNERS.PRE_RECOMMENDED_ORGS.ROUTE
					},
					{
						label: 'Excluded',
						MaterialIcon: Block,
						destination: Routes.ADMIN_TOOLS.PARTNERS.EXCLUDED.ROUTE
					}
				]
			},
			{
				label: 'Roles',
				MaterialIcon: Engineering,
				destination: Routes.ADMIN_TOOLS.ROLES.ROUTE,
				children: [
					{
						label: 'All',
						MaterialIcon: ViewList,
						destination: Routes.ADMIN_TOOLS.ROLES.ROUTE
					}
				]
			},
			{
				label: 'Users',
				MaterialIcon: PersonPin,
				destination: Routes.ADMIN_TOOLS.USERS.DETAILS.ROUTE,
				children: [
					{
						label: 'All',
						MaterialIcon: ViewList,
						destination: Routes.ADMIN_TOOLS.USERS.DETAILS.ROUTE
					},
					{
						label: 'Pre-Register',
						MaterialIcon: AdminPanelSettings,
						destination: Routes.ADMIN_TOOLS.USERS.PRE_REG.ROUTE
					},
					{
						label: 'Upload Pre-Reg',
						MaterialIcon: UploadFile,
						destination: Routes.ADMIN_TOOLS.USERS.UPLOAD.PATH()
					}
				]
			},
			{
				label: 'Reports',
				MaterialIcon: CloudDownload,
				destination: Routes.ADMIN_TOOLS.REPORTS.ROUTE
			}
		];

		const onOrgDetailPage = EnvHelper.path.indexOf(Routes.ADMIN_TOOLS.ORGS.ORG_DETAILS.BASE_ROUTE) !== -1;
		if (onOrgDetailPage) {
			navButtons[0].children.splice(1, 0,
				{
					label: 'Org Details',
					MaterialIcon: Details,
					forceSelectDynamicNav: true
				});
		}

		const onRoleDetailPage = EnvHelper.path.indexOf(Routes.ADMIN_TOOLS.ROLES.ROLE_DETAILS.BASE_ROUTE) !== -1;
		if (onRoleDetailPage) {
			navButtons[2].children.splice(1, 0,
				{
					label: 'Role Details',
					MaterialIcon: Details,
					forceSelectDynamicNav: true
				});
		}

		this.adminToolsNavData.navButtons = navButtons;
		NavHelper.addMyProfileButton(this.adminToolsNavData.navButtons, Routes.ADMIN_TOOLS.PROFILE.ROUTE);
		NavHelper.setNavButtonIds(this.adminToolsNavData.navButtons);
		return this.adminToolsNavData;
	}

	static addMyProfileButton(navButtons, profileDestination, settingsDestination) {
		let children = [
			{
				label: 'Profile',
				MaterialIcon: Face,
				position: 'bottom',
				destination: profileDestination,
				activeKeys: [
					Routes.DASHBOARD.PROFILE.PARTNER.ROUTE,
					Routes.MANAGER_ANALYTICS.PROFILE.PARTNER.ROUTE,
					Routes.CHANNEL_ECOSYSTEM.PROFILE.PARTNER.ROUTE,
					Routes.ADMIN_TOOLS.PROFILE.PARTNER.ROUTE
				]
			},
			{
				label: 'Support',
				MaterialIcon: LiveHelp,
				position: 'bottom',
				stateChangeFunction: () => window.open('https://partnertap.com/support')
			},
			{
				label: 'Logout',
				MaterialIcon: ExitToApp,
				position: 'bottom',
				stateChangeFunction: (caller) => {
					caller.props.dispatch(AuthActions.logout());
					EnvHelper.push(Routes.HOME.ROUTE);
				}
			}
		];

		if (settingsDestination) {
			children.splice(1, 0, {
				label: 'Settings',
				MaterialIcon: Settings,
				position: 'bottom',
				destination: settingsDestination
			});
		}

		navButtons.push({
			label: 'My Profile',
			MaterialIcon: AccountCircle,
			position: 'bottom',
			destination: profileDestination,
			children: children
		});
	}

	static setNavButtonIds(navButtons) {
		let parentId = 0;
		navButtons.forEach((navButton) => {
			parentId++;
			navButton.id = parentId;
			navButton.isParent = true;
			if (navButton.children) {
				let childId = 0;
				navButton.children.forEach((navButtonChild) => {
					childId++;
					navButtonChild.id = parentId * 1000 + childId;
					navButtonChild.parent = navButton;
					navButtonChild.parentId = parentId;
					navButtonChild.isChild = true;
				});
			}
		});
	}
}
