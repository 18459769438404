import {
	AccountTree,
	AvTimer,
	BubbleChart,
	ClearAll,
	DoubleArrow,
	Flip,
	GpsFixed,
	Group,
	Groups,
	Hub,
	LocalHospital,
	MonetizationOn,
	People,
	Public,
	RecordVoiceOver,
	Star,
	Store,
	VerifiedUser,
	Whatshot
} from '@mui/icons-material';
import {
	CATEGORY_COMPANY_PARTNER_ACCOUNTS,
	CATEGORY_COMPANY_PARTNER_OPPS,
	CATEGORY_GLOBAL_REPORTS,
	CATEGORY_MAPPED_ACCOUNTS,
	CATEGORY_PARTNER_ACCOUNTS,
	CATEGORY_PARTNER_LEADS,
	CATEGORY_PARTNER_OPPS,
	CATEGORY_SHEET_ACCOUNTS,
	CATEGORY_SHEET_OPPS
} from '../globals/Enums';
import {PERSISTENT_KEY} from '../globals/StorageKeys';
import {partnerTapTernary} from '../styles/partnertap_theme';
import ColorHelper from './ColorHelper';
import ColumnHelper from './ColumnHelper';
import DateHelper from './DateHelper';
import EcosystemShareHelper from './EcosystemShareHelper';
import EnvHelper from './EnvHelper';
import {FILTER_RANGE_DELIMITER} from './FilterHelper';
import NumberHelper from './NumberHelper';
import PartnerStatusHelper from './PartnerStatusHelper';
import PersistenceHelper from './PersistenceHelper';
import ReportHelper, {
	ASSET_TYPE_ACCOUNTS,
	ASSET_TYPE_OPPS,
	CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS,
	CHANNEL_REPORT_TYPE_GLOBAL_OPPORTUNITIES,
	CHANNEL_REPORT_TYPE_GLOBAL_PARTNERS,
	CHANNEL_REPORT_TYPE_GLOBAL_PIPELINE,
	CHANNEL_REPORT_TYPE_GLOBAL_SOURCED_PIPELINE,
	CHANNEL_REPORT_TYPE_GLOBAL_UNMATCHED_ACCOUNTS,
	CHANNEL_REPORT_TYPE_GLOBAL_USERS,
	MATCHING_TYPE_MUTUAL,
	MATCHING_TYPE_PARTNER_MUTUAL_OPPS
} from './ReportHelper';

// Global Reports
export const REPORT_MUTUAL_CUSTOMERS = 'REPORT_MUTUAL_CUSTOMERS';
export const REPORT_HOT_PROSPECTS = 'REPORT_HOT_PROSPECTS';
export const REPORT_BENEFICIAL_CUSTOMERS = 'REPORT_BENEFICIAL_CUSTOMERS';
export const REPORT_MUTUAL_PROSPECTS = 'REPORT_MUTUAL_PROSPECTS';
export const REPORT_GLOBAL_OVERLAP = 'REPORT_GLOBAL_OVERLAP';
export const REPORT_GLOBAL_UNMATCHED = 'REPORT_GLOBAL_UNMATCHED';
export const REPORT_MY_OPPORTUNITIES = 'REPORT_MY_OPPORTUNITIES';
export const REPORT_SOURCED_PIPELINE = 'REPORT_SOURCED_PIPELINE';
export const REPORT_USER_ENGAGEMENT = 'REPORT_USER_ENGAGEMENT';
export const REPORT_PIPELINE = 'REPORT_PIPELINE';
export const REPORT_PARTNER_LIST = 'REPORT_PARTNER_LIST';
export const REPORT_MY_PARTNER_LIST = 'REPORT_MY_PARTNER_LIST';
export const REPORT_DRILLDOWN = 'REPORT_DRILLDOWN';

export const REPORT_COMPANY_PARTNER_CONNECTED_PARTNERS = 'REPORT_COMPANY_PARTNER_CONNECTED_PARTNERS';
export const REPORT_MULTI_PARTNER_OPPORTUNITIES = 'REPORT_MULTI_PARTNER_OPPORTUNITIES';
export const REPORT_PIPELINE_OVERLAP = 'REPORT_PIPELINE_OVERLAP';
export const REPORT_RESELLER_OPPORTUNITIES = 'REPORT_RESELLER_OPPORTUNITIES';
export const REPORT_GREENFIELD_ACCOUNTS = 'REPORT_GREENFIELD_ACCOUNTS';
export const REPORT_AUDIT = 'REPORT_AUDIT';

export const REPORT_SEGMENT_STARTUP = 'REPORT_SEGMENT_STARTUP';
export const REPORT_SEGMENT_SMB = 'REPORT_SEGMENT_SMB';
export const REPORT_SEGMENT_MID_MARKET = 'REPORT_SEGMENT_MID_MARKET';
export const REPORT_SEGMENT_GLOBAL = 'REPORT_SEGMENT_GLOBAL';

export const REPORT_PROSPECT_OPEN_PIPELINE = 'REPORT_PROSPECT_OPEN_PIPELINE';
export const REPORT_CUSTOMER_OPEN_PIPELINE = 'REPORT_CUSTOMER_OPEN_PIPELINE';

// Channel Report filter types
export const CHANNEL_REPORT_ALL = 'report_all';
export const CHANNEL_REPORT_CUSTOMER_CUSTOMER = 'report_customer_customer';
export const CHANNEL_REPORT_PROSPECT_CUSTOMER = 'report_prospect_customer';
export const CHANNEL_REPORT_CUSTOMER_PROSPECT = 'report_customer_prospect';
export const CHANNEL_REPORT_PROSPECT_PROSPECT = 'report_prospect_prospect';
export const CHANNEL_REPORT_AUDITED = 'report_audited';
export const CHANNEL_REPORT_OPEN = 'report_open';
export const CHANNEL_REPORT_PARTNER_OPEN = 'report_partner_open';
export const CHANNEL_REPORT_GREENFIELD = 'report_greenfield';
export const CHANNEL_REPORT_MUTUAL_CUSTOMER = 'report_mutual_customer';
export const CHANNEL_REPORT_ACTIVE_PIPELINE = 'report_active_pipeline';
export const CHANNEL_REPORT_ACTIVE_PARTNER_PIPELINE = 'report_active_partner_pipeline';
export const CHANNEL_REPORT_PARTNER_CUSTOMER = 'report_partner_customer';
export const CHANNEL_REPORT_CONNECTIONS = 'connections';
export const CHANNEL_REPORT_SPREADSHEETS = 'spreadsheets';

const REPORT_TYPE_COLUMN_MAP = {
	REPORT_MUTUAL_CUSTOMERS: 'mutualClientsCount',
	REPORT_HOT_PROSPECTS: 'hotProspectsCount',
	REPORT_BENEFICIAL_CUSTOMERS: 'helpPartnersCount',
	REPORT_MUTUAL_PROSPECTS: 'mutualProspectsCount',

	REPORT_SEGMENT_STARTUP: 'startupCount',
	REPORT_SEGMENT_SMB: 'smbCount',
	REPORT_SEGMENT_MID_MARKET: 'midMarketCount',
	REPORT_SEGMENT_GLOBAL: 'globalCount'
};

const REPORT_TYPE_TITLE_MAP = {
	REPORT_MUTUAL_CUSTOMERS: 'Mutual Customers',
	REPORT_HOT_PROSPECTS: 'Hot Prospects',
	REPORT_BENEFICIAL_CUSTOMERS: 'Help My Partner',
	REPORT_MUTUAL_PROSPECTS: 'Mutual Prospects',

	REPORT_GLOBAL_OVERLAP: 'Global Overlap',
	REPORT_GLOBAL_UNMATCHED: 'Global Unmatched',
	REPORT_MY_OPPORTUNITIES: 'My Opps',
	REPORT_SOURCED_PIPELINE: 'Sourced Pipeline',
	REPORT_USER_ENGAGEMENT: 'User Engagement',
	REPORT_MULTI_PARTNER_OPPORTUNITIES: 'Multi-partner Opps',
	REPORT_PIPELINE_OVERLAP: 'Pipeline Overlap',
	REPORT_PIPELINE: 'Ecosystem Pipeline',
	REPORT_MY_PARTNER_LIST: 'My Partners',
	REPORT_PARTNER_LIST: 'All Partners',
	REPORT_RESELLER_OPPORTUNITIES: 'Reseller Opps',
	REPORT_GREENFIELD_ACCOUNTS: 'Greenfield Accounts',

	REPORT_SEGMENT_STARTUP: '1 - 50 Employees',
	REPORT_SEGMENT_SMB: '51 - 250 Employees',
	REPORT_SEGMENT_MID_MARKET: '251 - 2,000 Employees',
	REPORT_SEGMENT_GLOBAL: '2,000+ Employees',

	REPORT_PROSPECT_OPEN_PIPELINE: 'Active Prospects',
	REPORT_CUSTOMER_OPEN_PIPELINE: 'Active Mutual Customers',

	REPORT_AUDIT: 'Audit Breakdown'
};

const REPORT_TYPE_ICON_MAP = {
	REPORT_MUTUAL_CUSTOMERS: MonetizationOn,
	REPORT_HOT_PROSPECTS: Whatshot,
	REPORT_BENEFICIAL_CUSTOMERS: LocalHospital,
	REPORT_MUTUAL_PROSPECTS: GpsFixed,

	REPORT_GLOBAL_OVERLAP: Public,
	REPORT_GLOBAL_UNMATCHED: Flip,
	REPORT_MY_OPPORTUNITIES: Hub,
	REPORT_SOURCED_PIPELINE: AvTimer,
	REPORT_USER_ENGAGEMENT: RecordVoiceOver,
	REPORT_PIPELINE: ClearAll,
	REPORT_MY_PARTNER_LIST: Group,
	REPORT_PARTNER_LIST: Groups,

	REPORT_MULTI_PARTNER_OPPORTUNITIES: People,
	REPORT_PIPELINE_OVERLAP: DoubleArrow,
	REPORT_RESELLER_OPPORTUNITIES: AccountTree,
	REPORT_GREENFIELD_ACCOUNTS: Store,

	REPORT_SEGMENT_STARTUP: BubbleChart,
	REPORT_SEGMENT_SMB: BubbleChart,
	REPORT_SEGMENT_MID_MARKET: BubbleChart,
	REPORT_SEGMENT_GLOBAL: BubbleChart,

	REPORT_PROSPECT_OPEN_PIPELINE: Star,
	REPORT_CUSTOMER_OPEN_PIPELINE: Star,

	REPORT_AUDIT: VerifiedUser
};

const REPORT_TYPE_COLOR_MAP = {
	REPORT_GLOBAL_OVERLAP: partnerTapTernary,
	REPORT_GLOBAL_UNMATCHED: partnerTapTernary,
	REPORT_MY_OPPORTUNITIES: partnerTapTernary,
	REPORT_SOURCED_PIPELINE: partnerTapTernary,
	REPORT_USER_ENGAGEMENT: partnerTapTernary,

	REPORT_MUTUAL_PROSPECTS: '#E77CAD',
	REPORT_MUTUAL_CUSTOMERS: '#D46699',
	REPORT_BENEFICIAL_CUSTOMERS: '#AF316D',
	REPORT_HOT_PROSPECTS: '#80244F',

	REPORT_SEGMENT_STARTUP: '#EDC903',
	REPORT_SEGMENT_SMB: '#DCB306',
	REPORT_SEGMENT_MID_MARKET: '#CA9C08',
	REPORT_SEGMENT_GLOBAL: '#B8860B'
};

export const TITLE_ACCOUNTS_BY_COMPANY_SIZE = 'Accounts: Mapped by Company Size';
export const TITLE_ACCOUNTS_BY_INDUSTRY = 'Accounts: Mapped by Industry';
export const TITLE_ACCOUNTS_BY_COUNTRY = 'Accounts: Mapped by Country';
export const TITLE_ACCOUNTS_MUTUAL_OVERLAP = 'Accounts: Mutual Customer & Prospect Overlap';

export const TITLE_OPPS_BY_COMPANY_SIZE = 'Opps: Mapped by Company Size';
export const TITLE_OPPS_BY_INDUSTRY = 'Opps: Mapped by Industry';
export const TITLE_OPPS_BY_COUNTRY = 'Opps: Mapped by Country';
export const TITLE_OPPS_MUTUAL_OVERLAP = 'Opps: Mutual Customer & Prospect Overlap';

const EPOCH_TO_DAY = 60 * 60 * 24;

export default class ChannelHelper {

	static convertOtherJsonValues(payload) {
		payload.forEach((row) => {
			this.setRowValuesForKeysFromJsonString(row, 'customCrmFieldsJson');
			this.setRowValuesForKeysFromJsonString(row, 'oppsCustomCrmFieldsJson');
			this.setRowValuesForKeysFromJsonString(row, 'otherFieldsJson');
			this.setRowValuesForKeysFromJson(row, 'partnerFields');
			this.setRowValuesForDynamicKeys(row, 'customCrmObjectsJson');
			this.setRowValuesForDynamicKeys(row, 'matchedPartnersJson');
			this.setRowValuesForDeepKeys(row, 'matchedPartnersJson');
		});
	}

	static setRowValuesForKeysFromJsonString(row, jsonPrefix) {
		let customFields = row[jsonPrefix];
		if (customFields) {
			this.setRowValuesForKeys(row, JSON.parse(customFields));
		}
	}

	static setRowValuesForKeysFromJson(row, jsonPrefix) {
		this.setRowValuesForKeys(row, row[jsonPrefix]);
	}

	static setRowValuesForKeys(row, customJson) {
		if (customJson) {
			let keys = Object.keys(customJson);
			keys.forEach((key) => {
				row[key] = customJson[key];
			});
		}
	}

	static setRowValuesForDynamicKeys(row, jsonPrefix) {
		let customObjectsJson = row[jsonPrefix];
		if (customObjectsJson) {
			customObjectsJson = JSON.parse(customObjectsJson);
			Object.keys(customObjectsJson).forEach((key) => {
				let customObject = customObjectsJson[key];
				Object.keys(customObject).forEach((customKey) => {
					if (customKey.indexOf('hidden') < 0) {
						let dynamicKey = key + '.' + customKey;
						row[dynamicKey] = customObject[customKey];
					}
				});
			});
		}
	}

	static setRowValuesForDeepKeys(row, jsonPrefix) {
		let matchedPartnerJson = row[jsonPrefix];
		if (matchedPartnerJson) {
			matchedPartnerJson = JSON.parse(matchedPartnerJson);
			Object.keys(matchedPartnerJson).forEach((partner) => {
				let partnerObject = matchedPartnerJson[partner];
				Object.keys(partnerObject).forEach((partnerObjectKey) => {
					let partnerKeyObject = partnerObject[partnerObjectKey];
					Object.keys(partnerKeyObject).forEach((customKey) => {
						if (customKey.indexOf('hidden') < 0) {
							let dynamicKey = partner + '.' + partnerObjectKey + '.' + customKey;
							row[dynamicKey] = partnerKeyObject[customKey];
						}
					});
				});
			});
		}
	}

	// TODO: move this data processing to backend (so all enum values from backend are already converted to display names)
	static processRowData(payload, reportType) {
		if (reportType === REPORT_MY_PARTNER_LIST || reportType === REPORT_PARTNER_LIST || reportType === REPORT_COMPANY_PARTNER_CONNECTED_PARTNERS) {
			payload.forEach((rowItem) => {
				if (reportType === REPORT_MY_PARTNER_LIST) {
					rowItem.actions = PartnerStatusHelper.renderActionByStatus(rowItem);
				}
				ChannelHelper.convertEnums(rowItem);
			});
		}
	}

	static convertEnums(rowItem) {
		rowItem.status = PartnerStatusHelper.getStatusMessage(rowItem.status);
		rowItem.accountShareType = EcosystemShareHelper.labelForShareOption(rowItem.accountShareType);
		rowItem.partnerAccountShareType = EcosystemShareHelper.labelForShareOption(rowItem.partnerAccountShareType);
		rowItem.accountsSharingTo = EcosystemShareHelper.labelForShareOption(rowItem.accountsSharingTo);
		rowItem.accountsSharingFrom = EcosystemShareHelper.labelForShareOption(rowItem.accountsSharingFrom);
		rowItem.oppsSharingTo = EcosystemShareHelper.labelForShareOption(rowItem.oppsSharingTo);
		rowItem.oppsSharingFrom = EcosystemShareHelper.labelForShareOption(rowItem.oppsSharingFrom);
	}

	static convertEnumsInFilterValues(displayName, key, enumValue) {
		switch (key) {
			case 'status':
				return displayName || PartnerStatusHelper.getStatusMessage(enumValue);
			case 'accountShareType' :
				return EcosystemShareHelper.labelForShareOption(enumValue);
			case 'partnerAccountShareType' :
				return EcosystemShareHelper.labelForShareOption(enumValue);
			case 'accountsSharingTo' :
				return EcosystemShareHelper.labelForShareOption(enumValue);
			case 'accountsSharingFrom' :
				return EcosystemShareHelper.labelForShareOption(enumValue);
			case 'oppsSharingTo' :
				return EcosystemShareHelper.labelForShareOption(enumValue);
			case 'oppsSharingFrom' :
				return EcosystemShareHelper.labelForShareOption(enumValue);
		}
		return displayName;
	}

	static convertTypedValues(payload, columnData, forCsv) {
		const keyToColumnMap = Object.fromEntries(columnData.map(column => [column.key.toLowerCase(), column]));

		payload.forEach((row) => {
			Object.keys(row).forEach((col) => {
				let selectedColumn = keyToColumnMap[col.toLowerCase()];
				if (selectedColumn) {
					if (ColumnHelper.isSystem(selectedColumn)) return;
					if (ColumnHelper.isDate(selectedColumn)) {
						if (row[col] !== null && !isNaN(row[col])) {
							selectedColumn.dateTypeWasConverted = true;
							row[col] = DateHelper.epochToDate(row[col]);
						}
					}
					else if (ColumnHelper.isNumber(selectedColumn)) {
						row[col] = NumberHelper.formatNumber(row[col]);
					}
					else if (ColumnHelper.isCurrency(selectedColumn)) {
						row[col] = NumberHelper.formatCurrency(row[col]);
					}
					else if (forCsv && ColumnHelper.isBoolean(selectedColumn) && selectedColumn.renderFunction) {
						row[col] = selectedColumn.renderFunction(row[col], row);
					}
					else if (ColumnHelper.isArrayOfString(selectedColumn)) {
						if (Array.isArray(row[col])) {
							row[col] = row[col].join(', ');
						}
						else if (row[col]) {
							try {
								let tempValues = JSON.parse(row[col]);
								if (Array.isArray(tempValues)) {
									row[col] = tempValues.join(', ');
								}
							}
							catch (error) {
								console.error('Error from convertTypedValues: Failed to parse array of strings for column ', selectedColumn, ' value: ', row[col], error);
							}
						}
					}
				}
			});
		});
	}

	static baseSettings(storageKeyBase) {
		let baseSettings = {};
		let storageKeys = [
			'_page',
			'_page_size',
			'_scroll_top',
			'_scroll_left'
		];
		storageKeys.forEach((type) => {
			let key = storageKeyBase + type;
			let value = PersistenceHelper.getValue(key);
			if (value) {
				baseSettings[key] = value;
			}
		});

		let persistentStorageKeys = [
			'_sort',
			'_inverted_columns',
			'_sort_column',
			'_list_view',
			'_size_columns',
			'_order_columns'
		];
		persistentStorageKeys.forEach((type) => {
			let key = PERSISTENT_KEY + storageKeyBase + type;
			let value = PersistenceHelper.getValue(key);
			if (value) {
				baseSettings[key] = value;
			}
		});

		let filterSelectorKey = 'filter_values_' + storageKeyBase.replace(/\//g, '_');
		let value = PersistenceHelper.getValue(filterSelectorKey);
		if (value) {
			baseSettings[filterSelectorKey] = value;
		}
		return baseSettings;
	}

	static getReportTypeColumn(reportType) {
		return REPORT_TYPE_COLUMN_MAP[reportType];
	}

	static getReportTypeTitle(reportType) {
		return REPORT_TYPE_TITLE_MAP[reportType];
	}

	static getReportTypeIcon(reportType) {
		return REPORT_TYPE_ICON_MAP[reportType];
	}

	static getReportTypeColor(reportType) {
		return REPORT_TYPE_COLOR_MAP[reportType];
	}

	// Todo jared: this is a temp mapping so I don't have to refactor all routes for eco global reports
	static getChannelReportType(reportType) {
		if (reportType === REPORT_MY_OPPORTUNITIES) return CHANNEL_REPORT_TYPE_GLOBAL_OPPORTUNITIES;
		if (reportType === REPORT_SOURCED_PIPELINE) return CHANNEL_REPORT_TYPE_GLOBAL_SOURCED_PIPELINE;
		if (reportType === REPORT_PIPELINE) return CHANNEL_REPORT_TYPE_GLOBAL_PIPELINE;
		if (reportType === REPORT_PARTNER_LIST || reportType === REPORT_MY_PARTNER_LIST) return CHANNEL_REPORT_TYPE_GLOBAL_PARTNERS;
		if (reportType === REPORT_USER_ENGAGEMENT) return CHANNEL_REPORT_TYPE_GLOBAL_USERS;
		if (reportType === REPORT_GLOBAL_UNMATCHED) return CHANNEL_REPORT_TYPE_GLOBAL_UNMATCHED_ACCOUNTS;
		return CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS;
	}

	static getCompanySizeData(assetType, startupCount, smbCount, midMarketCount, globalCount) {
		let reportTitle = ChannelHelper.getReportTitle(assetType);
		let filterTitlePrefix = assetType === ASSET_TYPE_ACCOUNTS ? TITLE_ACCOUNTS_BY_COMPANY_SIZE : TITLE_OPPS_BY_COMPANY_SIZE;
		let colors = ColorHelper.gradientHexArray(16);
		return [
			{
				name: ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_STARTUP),
				value: startupCount,
				fill: colors[0],
				textColor: colors[0],
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ' - ' + ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_STARTUP),
				filterValue: {'numberOfEmployees': '1' + FILTER_RANGE_DELIMITER + '50'}
			},
			{
				name: ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_SMB),
				value: smbCount,
				fill: colors[1],
				textColor: colors[1],
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ' - ' + ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_SMB),
				filterValue: {'numberOfEmployees': '51' + FILTER_RANGE_DELIMITER + '250'}
			},
			{
				name: ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_MID_MARKET),
				value: midMarketCount,
				fill: colors[2],
				textColor: colors[2],
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ' - ' + ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_MID_MARKET),
				filterValue: {'numberOfEmployees': '251' + FILTER_RANGE_DELIMITER + '2000'}
			},
			{
				name: ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_GLOBAL),
				value: globalCount,
				fill: colors[3],
				textColor: colors[3],
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ' - ' + ChannelHelper.getReportTypeTitle(REPORT_SEGMENT_GLOBAL),
				filterValue: {'numberOfEmployees': '2000' + FILTER_RANGE_DELIMITER + '99999999'}
			}
		];
	}

	static getClientOverlapData(assetType, clientOverlapCount, prospectOverlapCount) {
		let reportTitle = ChannelHelper.getReportTitle(assetType);
		let filterTitlePrefix = assetType === ASSET_TYPE_ACCOUNTS ? 'Accounts' : 'Opps';
		let colors = ColorHelper.gradientHexArray(8);
		return [
			{
				name: 'Mutual Customers',
				value: clientOverlapCount,
				fill: colors[0],
				textColor: colors[0],
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ': Mutual Customers',
				filterValue: {isCustomer: true, partnerIsCustomer: true}
			},
			{
				name: 'Mutual Prospects',
				value: prospectOverlapCount,
				fill: colors[1],
				textColor: colors[1],
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ': Mutual Prospects',
				filterValue: {isCustomer: false, partnerIsCustomer: false}
			}
		];
	}

	static getCountByColumnData(assetType, counts, filterColumn, filterTitlePrefix) {
		let reportTitle = ChannelHelper.getReportTitle(assetType);
		let colors = ColorHelper.gradientHexArray(Math.max(counts.length, 8));
		return counts.map((item, index) => {
			let filterValue = {};
			filterValue[filterColumn] = item.name;
			let graphColor = colors[index];
			return {
				name: item.name,
				value: item.count,
				fill: graphColor,
				textColor: graphColor,
				reportTitle: reportTitle,
				filterTitle: filterTitlePrefix + ' - ' + item.name,
				filterValue: filterValue
			};
		});
	}

	static getCircleGraphObject(data) {
		let colors = ColorHelper.gradientHexArray(Math.max(data.length, 8));
		return data.map((item, index) => {
			let graphColor = colors[index];
			return {
				name: item.name,
				value: item.value,
				fill: graphColor,
				textColor: graphColor,
				toolTip: item.toolTip
			};
		});
	}

	static getReportTitle(assetType) {
		if (assetType === ASSET_TYPE_ACCOUNTS) {
			return ReportHelper.getReportTitle(ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_ALL);
		}
		else if (assetType === ASSET_TYPE_OPPS) {
			return ReportHelper.getReportTitle(ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_ALL);
		}
		else {
			return 'Invalid assetType for getCompanySizeChartData: ' + assetType;
		}
	}

	static getSavedReportCategoryTitle(reportCategory) {
		switch (reportCategory) {
			case CATEGORY_GLOBAL_REPORTS:
				return 'Global Report';
			case CATEGORY_PARTNER_ACCOUNTS :
				return 'Partner Account Report';
			case CATEGORY_PARTNER_LEADS :
				return 'Partner Lead Report';
			case CATEGORY_PARTNER_OPPS :
				return 'Partner Opp Report';
			case CATEGORY_SHEET_ACCOUNTS :
				return 'Partner Sheet Account Report';
			case CATEGORY_SHEET_OPPS :
				return 'Partner Sheet Opp Report';
			case CATEGORY_MAPPED_ACCOUNTS:
				return 'Matched Account Report';
			case CATEGORY_COMPANY_PARTNER_ACCOUNTS:
				return 'Partner Org Account Report';
			case CATEGORY_COMPANY_PARTNER_OPPS:
				return 'Partner Org Opportunity Report';
		}
		return 'Invalid reportCategory: ' + reportCategory;
	}

	static getDeepLinkFilters() {
		try {
			let matchDate = Number(EnvHelper.getUrlParam('match_date'));
			if (!matchDate) {
				return null;
			}
			matchDate = Math.floor(matchDate / EPOCH_TO_DAY) * EPOCH_TO_DAY;
			return {'matchDate': matchDate + FILTER_RANGE_DELIMITER + Math.round(Date.now() / 1000)};
		}
		catch (error) {
			return null;
		}
	}
}
