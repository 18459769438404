import React from 'react';
import {partnerTapAppBackground} from '../../../../styles/partnertap_theme';
import DateHelper from '../../../../helpers/DateHelper';
import Dialog from '../../../../ui/Dialog';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';

const SheetInfoHelper = {

	downloadError(message) {
		const blob = new Blob([message], {type: 'text/plain'});
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = 'error-details.txt';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	},

	renderDetails(sheetData, closeDetailsCallback) {
		const border = 'solid 1px ' + partnerTapAppBackground;
		const uploadDate = DateHelper.epochToDate(sheetData.createdDateMillis);
		const cellStyle = {
			padding: 10,
			borderRight: border,
			borderTop: border,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		};

		return (
			<Dialog
				title={`'${sheetData.batchName}' Sheet Details`}
				message={
					<div style={{padding: 20}}>
						<table style={{width: '100%', borderSpacing: 0, borderLeft: border, borderBottom: border}}>
							<tbody>
							<tr>
								<td style={cellStyle}>Upload Date</td>
								<td style={cellStyle}>{uploadDate}</td>
							</tr>
							<tr>
								<td style={cellStyle}>Rows Imported</td>
								<td style={cellStyle}>{sheetData.totalRows}</td>
							</tr>
							<tr>
								<td style={cellStyle}>Rows Failed</td>
								<td style={cellStyle}>{sheetData.errorRows}</td>
							</tr>
							</tbody>
						</table>
						<div style={{paddingTop: 10}}>
							<SecondaryButton
								disabled={sheetData.errorRows <= 0}
								label={'Download Error Messages'}
								onClick={() => SheetInfoHelper.downloadError(sheetData.errorMessage)}
							/>
						</div>
					</div>
				}
				yesAction={closeDetailsCallback}
			/>
		);
	},
};

export default SheetInfoHelper;