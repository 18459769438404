import ExpandableMessage from '../../../../../ui/messages/ExpandableMessage';
import DropdownMenu from '../../../../../ui/selectors/DropdownMenu';
import * as CoSellEngineHelperLive from '../../CoSellEngineHelperLive';
import React, {useState} from 'react';


const NudgesTile = ({
	nudges,
	onNudgeChange
}) => {

	return (
		<ExpandableMessage
			style={{marginLeft: 0, marginRight: 0}}
			message={{
				title: 'Schedule nudges',
				content: 'Schedule one or two nudges...',
				action: <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
					<DropdownMenu
						title={'Reminder'}
						options={CoSellEngineHelperLive.nudgeOptions}
						selectedOption={CoSellEngineHelperLive.nudgeOptions.find(v => v.value === nudges[0])}
						onSelect={(option) => onNudgeChange(0, option.value)}
					/>
					<DropdownMenu
						title={'Last Chance'}
						options={CoSellEngineHelperLive.nudgeOptions}
						selectedOption={CoSellEngineHelperLive.nudgeOptions.find(v => v.value === nudges[1])}
						disabled={!nudges.length}
						onSelect={(option) => onNudgeChange(1, option.value)}
					/>
				</div>
			}}
		/>

	);
};

export default NudgesTile;
