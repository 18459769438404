import React, {Component} from 'react';
import FeatureHelper, {FEATURE_CHANNEL_DATA_MANAGER} from '../../../helpers/FeatureHelper';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import {partnerTapAlert, partnerTapTernary} from '../../../styles/partnertap_theme';
import AdminEditorSection from '../../admin_tools/shared/AdminEditorSection';
import {Done, DoNotDisturb} from '@mui/icons-material';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import {queryClient} from '../../App';
import {QueryKeys} from '../../../helpers/QueryClientHelper';
import {getCoSellConciergePermissionStatus, updateCoSellConciergePermission} from '../../../endpoints/CoSellEngineEndpoints';
import Loading from '../../../ui/Loading';
import EnvHelper from '../../../helpers/EnvHelper';

const PermissionList = () => (
	<ul style={{paddingLeft: '20px', marginTop: '10px'}}>
		<li>Can create and delete action lists</li>
		<li>Can edit and clone all action lists</li>
		<li>Can run any type of co-sell workflow the org allows</li>
		<li>Can download action lists and results reports</li>
		<li>Cannot modify record data, share settings, user information, connected partners, orgs</li>
		<li>Cannot download non-action list reports</li>
	</ul>
);

class CoSellConciergeAdminPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: true,
			isCoSellConciergeEnabled: false,
			lastModifiedBy: null,
			lastModifiedDate: null
		};

		this.updateCoSellConciergePermission = this.updateCoSellConciergePermission.bind(this);
		this.cancelChanges = this.cancelChanges.bind(this);
	}

	async componentDidMount() {
		await this.getCoSellConciergePermissionStatus();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	async getCoSellConciergePermissionStatus() {
		try {
			const response = await queryClient.fetchQuery(QueryKeys.CO_SELL_CONCIERGE_ENABLED, getCoSellConciergePermissionStatus, {cacheTime: 0});
			if (this.unmounted) return;
			this.setState({
				isCoSellConciergeEnabled: response.payload.enabled,
				lastModifiedBy: response.payload.lastModifiedBy,
				lastModifiedDate: response.payload.lastModifiedDate,
				loading: false,
			}, () => {
				this.previousState = {...this.state};
			});
		}
		catch (error) {
			EnvHelper.serverError('Error while calling getCoSellConciergePermissionStatus', error);
		}
	}

	updateCoSellConciergePermission(successCallback) {
		let {isCoSellConciergeEnabled} = this.state;
		updateCoSellConciergePermission(isCoSellConciergeEnabled)
		.then(() => {
			this.previousState = {...this.state};
			successCallback();
		})
		.catch((error) => {
			EnvHelper.serverError('Error while calling updateCoSellConciergePermission', error);
		});
	}

	cancelChanges() {
		this.setState({...this.previousState});
	}

	renderAdminRoleView() {
		let {isCoSellConciergeEnabled, lastModifiedBy, lastModifiedDate} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
				{/* Parent container ensures both components have the same width */}
				<div style={{width: '100%', maxWidth: '600px', boxSizing: 'border-box', padding: '0 20px'}}>
					<MessageBoxInfo>
						Enabling Co-Sell Concierge grants permission and allows PartnerTap to create action lists and run workflows on behalf of your
						organization.
					</MessageBoxInfo>
				</div>
				<div style={{width: '100%', maxWidth: '600px', boxSizing: 'border-box', padding: '0 20px'}}>
					{this.renderSection('',
						[
							{
								name: <div>Enable Co-Sell Concierge</div>,
								value: isCoSellConciergeEnabled ?
									<Done style={{color: partnerTapTernary}}/> :
									<DoNotDisturb style={{color: partnerTapAlert}}/>,
								editor: <CheckboxButton
									checked={isCoSellConciergeEnabled}
									style={{padding: 0}}
									onChange={() => {
										this.setState({isCoSellConciergeEnabled: !isCoSellConciergeEnabled});
									}}
								/>,
								bodyText: <div>
									<div>
										When enabled, the co-sell concierge user has the following permissions:
									</div>
									<PermissionList/>
									<div style={{ marginTop: '20px' }}>
										<div>Last modified by: {lastModifiedBy}</div>
										<div>Last modified: {lastModifiedDate}</div>
									</div>
								</div>
							}
						]
					)}
				</div>
			</div>
		);
	}

	renderSection(sectionName, sectionValues) {
		return (
			<AdminEditorSection
				key={sectionName}
				sectionName={sectionName}
				sectionValues={sectionValues}
				isOpen={true}
				editFunction={() => {
				}}
				saveFunction={this.updateCoSellConciergePermission}
				cancelFunction={this.cancelChanges}
				displayCloseButton={false}
			/>
		);
	}

	render() {
		let {loading} = this.state;
		if (loading) return <Loading>Loading...</Loading>;

		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER)) {
			return (
				<MessageBoxInfo hideIcon={true}>
					The Co-Sell Concierge requires administrator level access. Please reach out to your PartnerTap administrator.
				</MessageBoxInfo>
			);
		}
		return this.renderAdminRoleView();
	}
}

export default CoSellConciergeAdminPage;