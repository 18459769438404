import {Sort} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import FilterHelper from '../../helpers/FilterHelper';
import {partnerTapDropShadow, partnerTapPrimary, partnerTapWhite} from '../../styles/partnertap_theme';
import MenuButton from '../buttons/MenuButton';
import ToggleButtonListView from '../buttons/ToggleButtonListView';
import ItemCount from '../ItemCount';
import PartnerTapIcon from '../PartnerTapIcon';
import ColumnSelector from '../selectors/ColumnSelector';
import FilterSelector from '../selectors/FilterSelector';
import PagingTable from './PagingTable';
import ScrollingContainer from './ScrollingContainer';
import ScrimMessage from '../messages/ScrimMessage';

class PagingToolbarTable extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {renderTiles: false, filterSelectorMounted: this.props.skipFilterLoading, headerHeight: 0};
		this.onListViewToggle = this.onListViewToggle.bind(this);
		this.getAdditionalToolbarButtons = this.getAdditionalToolbarButtons.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get hasRowData() {
		return this.props.rowCount > 0;
	}

	onListViewToggle(index) {
		this.setState({renderTiles: index === 0});
	}

	getAdditionalToolbarButtons(isList) {
		return this.props.additionalToolbarButtons?.map((component, index) => {
			if ((this.hasRowData || component.props.rowDataNotRequired) && ((isList && component.props.list) || (!isList && !component.props.list))) {
				return (
					<div key={'additional_toolbar_button_' + index} style={{paddingLeft: 10}}>
						{component}
					</div>
				);
			}
		});
	}

	render() {
		if (this.props.loading && this.state.filterSelectorMounted) this.props.getRowData();
		let icon;
		if (PartnerTapIcon.isGlyph(this.props.titleIcon)) {
			icon = <PartnerTapIcon glyph={this.props.titleIcon} style={PartnerTapIcon.headerIconStyle}/>;
		}
		else if (this.props.titleIcon) {
			let MaterialIcon = this.props.titleIcon;
			icon = <MaterialIcon fontSize={'large'} style={{paddingRight: 10, color: this.props.titleIconColor}}/>;
		}
		let addedFilterCount = FilterHelper.getAddedFilters(this.props.filterSelectorConfig).length;
		if (this.observer) {
			this.observer.disconnect();
		}
		return (
			<div key={this.props.currentPage} style={{display: 'flex', flexDirection: 'column'}}>
				{this.props.componentAboveToolbar}
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div style={{backgroundColor: partnerTapWhite, boxShadow: partnerTapDropShadow, marginBottom: 5}}
						 ref={(element) => {
							 if (element) {
								 if (this.observer) {
									 this.observer.disconnect();
								 }
								 this.observer = new ResizeObserver(() => {
									 // requestAnimationFrame is required to avoid ResizeObserver loop limit exceeded
									 window.requestAnimationFrame(() => {
										 if (this.unmounted || this.props.loading) return;
										 if (element.clientWidth !== this.state.headerWidth || element.clientHeight !== this.state.headerHeight) {
											 this.setState({headerWidth: element.clientWidth, headerHeight: element.clientHeight});
										 }
									 });
								 });
								 this.observer.observe(element);
							 }
						 }}>
						<div style={{
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'center',
							padding: 10,
							minHeight: 50
						}}>
							<div style={{paddingTop: 4, color: partnerTapPrimary}}>
								{icon}
							</div>
							<div style={{fontSize: 20, whiteSpace: 'nowrap', paddingRight: 10}}>
								{this.props.title}
							</div>

							{this.props.dropdownMenu && (
								<div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
									{this.props.dropdownMenu}
								</div>
							)}

							<div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
								{this.props.loading ?
									<div style={{display: 'flex', margin: 10, paddingLeft: 10}}>
										<CircularProgress size={30} style={{color: partnerTapPrimary}} disableShrink={true}/>
									</div>
									:
									(this.props.titleCount || this.props.titleCount === 0) &&
									<div style={{paddingLeft: 10}}>
										<ItemCount count={this.props.titleCount}/>
									</div>}
							</div>
							<div style={{flex: 3, paddingLeft: 10}}>
								{(this.hasRowData || this.props.searchValue || this.props.alwaysShowSearch) && this.props.searchComponent}
							</div>
							<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', padding: 5}}>
								{Boolean(this.props.rowRenderer && this.props.tileRenderer) &&
								 <div style={{paddingLeft: 10}}>
									 <ToggleButtonListView storageKey={this.props.storageKey} onToggle={this.onListViewToggle}/>
								 </div>}
								{this.getAdditionalToolbarButtons(true)}
								<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
									{this.getAdditionalToolbarButtons(false)}
									{(this.hasRowData && Boolean(this.props.sortData)) &&
									 <div style={{paddingLeft: 10}}>
										 <MenuButton label={'Sort'}
													 hideLabel={true}
													 MaterialIcon={Sort}
													 menuData={this.props.sortData}
													 onSelect={this.props.onSortChanged}/>
									 </div>}
									{(this.hasRowData && Boolean(this.props.columnData)) &&
									 <div style={{paddingLeft: 10}}>
										 <ColumnSelector columnData={this.props.columnData}
														 onColumnActiveChanged={this.props.onColumnActiveChanged}
														 onColumnOrderChanged={this.props.onColumnOrderChanged}/>
									 </div>}
								</div>
							</div>
							{Boolean(this.props.filterSelectorConfig) &&
							 <div style={{flex: 1, display: (this.hasRowData || addedFilterCount) ? null : 'none'}}>
								 <FilterSelector storageKey={this.props.storageKey}
												 config={this.props.filterSelectorConfig}
												 headerWidth={this.state.headerWidth || 0}
												 mountedFunction={() => this.setState({filterSelectorMounted: true})}/>
							 </div>}
						</div>
						{Boolean(this.props.componentBelowToolbar) && this.props.componentBelowToolbar}
					</div>
					{this.props.loading &&
					 <ScrimMessage message={'Loading ' + (typeof this.props.title === 'string' ? this.props.title : 'Report') + '...'}/>}
					{this.props.noDataMessage ?
						<ScrollingContainer divId={'paging_toolbar_table_no_data'}>
							<div style={{padding: 20, paddingTop: 0}}>
								{this.props.noDataMessage}
							</div>
						</ScrollingContainer>
						:
						<PagingTable key={this.state.headerHeight}
									 storageKey={this.props.storageKey}

									 columnData={this.props.columnData}
									 onColumnOrderChanged={this.props.onColumnOrderChanged}
									 onColumnSortChanged={this.props.onColumnSortChanged}

									 currentPage={this.props.currentPage}
									 pageCount={this.props.pageCount}
									 onPageChange={this.props.onPageChange}
									 pageSize={this.props.pageSize}
									 onPageSizeChange={this.props.onPageSizeChange}

									 rowData={this.props.rowData}
									 rowCount={this.props.rowCount}
									 rowRenderer={this.props.rowRenderer}
									 rowClick={this.props.rowClick}
									 tileRenderer={this.props.tileRenderer}
									 renderTiles={this.state.renderTiles}/>}
				</div>
			</div>
		);
	}
}

PagingToolbarTable.propTypes = {
	storageKey: PropTypes.string.isRequired,
	loading: PropTypes.bool,

	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
	titleIconColor: PropTypes.string,
	titleCount: PropTypes.number,
	noDataMessage: PropTypes.object,

	searchComponent: PropTypes.object,
	searchValue: PropTypes.string,
	alwaysShowSearch: PropTypes.bool,

	sortData: PropTypes.array,
	onSortChanged: PropTypes.func,

	componentAboveToolbar: PropTypes.object,
	additionalToolbarButtons: PropTypes.array,
	componentBelowToolbar: PropTypes.object,

	filterSelectorConfig: PropTypes.shape({
		filters: PropTypes.array.isRequired,
		initialFilterValues: PropTypes.object,
		getFilterDataFunction: PropTypes.func.isRequired,
		getFilterDataFunctionArgs: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		inherentReportFilters: PropTypes.object,
		blockPersistence: PropTypes.bool
	}),
	skipFilterLoading: PropTypes.bool.isRequired,

	columnData: PropTypes.array,
	onColumnActiveChanged: PropTypes.func,
	onColumnOrderChanged: PropTypes.func,
	onColumnSortChanged: PropTypes.func,

	pageCount: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,

	getRowData: PropTypes.func.isRequired,
	rowData: PropTypes.array.isRequired,
	rowCount: PropTypes.number.isRequired,
	rowRenderer: PropTypes.elementType,
	rowClick: PropTypes.func,
	tileRenderer: PropTypes.elementType,
	dropdownMenu: PropTypes.element
};

export default PagingToolbarTable;
