import {NavigateNext, Warning} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import {DropzoneArea} from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as AdminEndpoints from '../../../../endpoints/AdminEndpoints';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {
	BASE_OPPORTUNITIES_MANUAL,
	OK,
	PRE_PROCESSED_BY_WEB_WORKER,
	UPLOAD_DATA_MANAGER_ENRICH_SHEET,
	UPLOAD_DATA_MANAGER_SHEET,
	UPLOAD_FULL_SERVICE_CSV,
	UPLOAD_PARTNER_SHEET,
	UPLOAD_PII_SHARING_OPT_IN,
	UPLOAD_PRE_REG_USERS,
	UPLOAD_RECOMMENDED_PARTNER_ORGS
} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import CSVHelperClass from '../../../../helpers/CsvHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import {
	partnerTapAlert,
	partnerTapAppBackground,
	partnerTapDropShadow,
	partnerTapSecondary,
	partnerTapTernary,
	partnerTapWarn
} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import Dialog from '../../../../ui/Dialog';
import Loading from '../../../../ui/Loading';
import PopoverSearchList from '../../../../ui/PopoverSearchList';
import TextInputBox from '../../../../ui/TextInputBox';
import DropzoneContent from './DropzoneContent';
import StringHelper from '../../../../helpers/StringHelper';

const NO_PARTNER_ORG = 'no_partner_org';
const NEW_PARTNER_ORG = 'new_partner_org';

class SheetUploader extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: this.isPartnerSheet,
			uploadInProgress: false,
			uploadFile: null,
			fileName: null,
			isFileNameMissing: false,
			fileDescription: '',
			dropFileError: null,
			uploadError: null,
			selectedOrg: null,
			showNewPartnerOrgInput: false,
			newPartnerOrgName: null,
			newPartnerOrgWebsite: null,
			isNewPartnerOrgNameMissing: false,
			isNewPartnerOrgWebsiteMissing: false,
			isWebsiteInvalid: false,
			isHelpOpen: false,
			showVisibleOnlyToMeNote: false,
			errorCode: null
		};
		this.onDropFile = this.onDropFile.bind(this);
		this.onUpload = this.onUpload.bind(this);
		console.log(this.props);
	}

	componentDidMount() {
		if (this.isPartnerSheet) {
			this.getPartnerOrgs();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (CSVHelperClass.ignoreBlankHeaders && !this.blockComponentDidUpdate) {
			this.blockComponentDidUpdate = true;
			this.getPartnerOrgs(() => {
				this.onUpload();
				CSVHelperClass.ignoreBlankHeaders = false;
				this.blockComponentDidUpdate = false;
			});
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get uploadType() {
		return this.props.uploadType || this.props.match.params.uploadType;
	}

	get isPartnerSheet() {
		return this.uploadType === UPLOAD_PARTNER_SHEET;
	}

	get isDataManagerSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_SHEET;
	}

	get isDataManagerEnrichSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_ENRICH_SHEET;
	}

	get isBaseOpportunitiesManual() {
		return this.uploadType === BASE_OPPORTUNITIES_MANUAL;
	}

	get isPiiOptIn() {
		return this.uploadType === UPLOAD_PII_SHARING_OPT_IN;
	}

	get isFullServiceCsv() {
		return this.uploadType === UPLOAD_FULL_SERVICE_CSV;
	}

	get isAdminPreRegUsers() {
		return this.uploadType === UPLOAD_PRE_REG_USERS;
	}

	get isAdminRecommendedPartnerOrgs() {
		return this.uploadType === UPLOAD_RECOMMENDED_PARTNER_ORGS;
	}

	get isPreProcessedByWebWorker() {
		return this.uploadType === PRE_PROCESSED_BY_WEB_WORKER;
	}

	get isAdminUpload() {
		return this.isAdminPreRegUsers || this.isAdminRecommendedPartnerOrgs;
	}

	get uploadTypeLabel() {
		if (this.isPartnerSheet) return 'Partner Account';
		if (this.isDataManagerSheet) return 'Account';
		if (this.isDataManagerEnrichSheet) return 'Account Enrichment';
		if (this.isBaseOpportunitiesManual) return 'Opportunity';
		if (this.isPiiOptIn) return 'PII Opt-in';
		if (this.isFullServiceCsv) return 'Full Service';
		if (this.isAdminPreRegUsers) return 'Pre-Register User';
		if (this.isAdminRecommendedPartnerOrgs) return 'Recommended Partner Org';
		if (this.isPreProcessedByWebWorker) return ' ';
		return null;
	}

	get uploadAction() {
		if (this.isAdminUpload) return AdminEndpoints.uploadCSV;
		if (this.isPreProcessedByWebWorker) return this.props.webWorkerFunc;
		return ChannelMappingEndpoints.uploadCSV;
	}

	getPartnerOrgs(callback) {
		ChannelMappingEndpoints.getChannelPartnerOrgs()
		.then((result) => {
			if (this.unmounted) return;
			let {payload} = result;
			payload.unshift({orgCode: NO_PARTNER_ORG, name: 'VISIBLE ONLY TO ME'});
			payload.unshift({orgCode: NEW_PARTNER_ORG, name: 'NEW PARTNER ORG'});
			this.setState({orgList: payload, loading: false});
			if (callback) callback();
		});
	}

	onDropFile(file) {
		if (this.unmounted) return;
		if (file[0] && file[0].name) {
			this.setState({fileName: file[0].name, uploadFile: file[0], showUploadDialog: true, dropFileError: null});
		}
		else {
			this.setState({dropFileError: 'Error onDropFile: ' + file});
			console.error('Error onDropFile', file);
		}
	}

	getErrorTextForPartnerWebsite = () => {
		let {isNewPartnerOrgWebsiteMissing, isWebsiteInvalid} = this.state;
		return isNewPartnerOrgWebsiteMissing ? 'Partner Website is required' :
			(isWebsiteInvalid ? 'Partner Website is Invalid' : '');
	};

	onUpload() {
		let {uploadFile, fileName, fileDescription, orgList, selectedOrg, newPartnerOrgName, newPartnerOrgWebsite} = this.state;
		if (!uploadFile) {
			this.setState({uploadError: 'File is required'});
			return;
		}
		if (!fileName) {
			this.setState({isFileNameMissing: true});
			return;
		}
		let orgCode = selectedOrg?.orgCode;
		let orgId = selectedOrg?.orgId;
		let companyPartnerPublicId = selectedOrg?.companyPartnerPublicId;
		if (this.isPartnerSheet) {
			if (!selectedOrg) {
				this.setState({uploadError: 'Please select a Partner Org'});
				return;
			}
			if (newPartnerOrgName) {
				this.setState({isNewPartnerOrgNameMissing: false});
				if (!newPartnerOrgWebsite) {
					this.setState({isNewPartnerOrgWebsiteMissing: true});
					return;
				} else if (!StringHelper.validateWebsite(newPartnerOrgWebsite)) {
					this.setState({isWebsiteInvalid: true});
					return;

				} else {
					this.setState({isNewPartnerOrgWebsiteMissing: false, isWebsiteInvalid: false});
				}
				let existingOrg = orgList.find((org) => org.name === newPartnerOrgName);
				if (existingOrg) {
					newPartnerOrgName = null;
					newPartnerOrgWebsite = null;
					orgCode = null;
					orgId = null;
					companyPartnerPublicId = existingOrg.companyPartnerPublicId;
					this.setState({selectedOrg: existingOrg, newPartnerOrgName: null, newPartnerOrgWebsite: null});
				}
			}
			else if (orgCode === NEW_PARTNER_ORG) {
				this.setState({isNewPartnerOrgNameMissing: true, isNewPartnerOrgWebsiteMissing: !newPartnerOrgWebsite});
				return;
			}
			if (orgCode === NO_PARTNER_ORG) {
				orgCode = null;
			}
		}
		else if (this.isAdminUpload) {
			orgCode = this.props.orgCode;
			orgId = this.props.orgId;
		}
		this.setState({
			uploadInProgress: true,
			isFileNameMissing: false,
			showUploadDialog: false,
			showNewPartnerOrgInput: false,
			showVisibleOnlyToMeNote: false
		});
		this.uploadAction(uploadFile, fileName, fileDescription, this.uploadType, orgCode, orgId, companyPartnerPublicId, newPartnerOrgName, newPartnerOrgWebsite, CSVHelperClass.ignoreBlankHeaders)
		.then((result) => {
			if (this.unmounted) return;
			if (this.isPreProcessedByWebWorker) {
				this.setState({uploadInProgress: false});
				this.props.onUploadSuccess();
				return;
			}
			this.setState({errorCode: result.payload.responseCodes, uploadInProgress: false});
			this.props.onUploadSuccess(result.payload.numRecords, result.payload);
		})
		.catch((error) => {
			this.setState({uploadInProgress: false, errorCode: 'ERROR'});
			EnvHelper.serverError('Error from onUpload', error);
		});
	}

	renderUploadDialog() {
		if (!this.state.showUploadDialog) return;
		let {
			orgList,
			selectedOrg,
			fileName,
			isFileNameMissing,
			fileDescription,
			newPartnerOrgName,
			newPartnerOrgWebsite,
			isNewPartnerOrgNameMissing,
			showNewPartnerOrgInput,
			showVisibleOnlyToMeNote
		} = this.state;
		return (
			<Dialog title={'Upload ' + this.uploadTypeLabel + ' Sheet'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', gap: 20, minWidth: 400}}>
							{this.state.uploadError &&
							 <div style={{fontSize: 16, color: partnerTapAlert, textAlign: 'center'}}>
								 {this.state.uploadError}
							 </div>}
							{this.isPartnerSheet &&
							 <Fragment>
								 <PopoverSearchList label={'Select Partner Org'}
													list={orgList}
													preselectedItem={selectedOrg}
													labelRenderer={(org) => org.name}
													valueRenderer={(org) => org.orgCode}
													onItemSelected={(org) => {
														this.setState({
															selectedOrg: org,
															showNewPartnerOrgInput: org.orgCode === NEW_PARTNER_ORG,
															showVisibleOnlyToMeNote: org.orgCode === NO_PARTNER_ORG,
															isNewPartnerOrgNameMissing: false,
															uploadError: null
														});
													}}
													searchByObjectKeys={['name']}
													width={400}/>
								 {showVisibleOnlyToMeNote &&
								  <div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, maxWidth: 400}}>
									  <Warning style={{color: partnerTapWarn}}/>
									  VISIBLE ONLY TO ME accounts will not be accessible to others, nor included in Ecosystem Reports or managed package syncs.
								  </div>}
								 {showNewPartnerOrgInput &&
								  <Fragment>
									  <div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, maxWidth: 400}}>
										  <Warning style={{color: partnerTapWarn}}/>
										  NEW PARTNER ORG will create a new organization for these accounts.
										  Please confirm this org does not already exist in the list above.
										  Select an existing org, or name the new org in the field below.
									  </div>
									  <TextInputBox hintText={'Partner Org Name'}
													errorText={isNewPartnerOrgNameMissing ? 'Partner Org Name is required' : ''}
													onChange={(value) => this.setState({newPartnerOrgName: value})}
													maxChars={40 + (newPartnerOrgName ? newPartnerOrgName.length - newPartnerOrgName.trim().length : 0)}/>
									  <TextInputBox hintText={'Partner Website'}
													errorText={this.getErrorTextForPartnerWebsite()}
													onChange={(value) => this.setState({newPartnerOrgWebsite: value})}
													maxChars={40 + (newPartnerOrgWebsite ? newPartnerOrgWebsite.length - newPartnerOrgWebsite.trim().length : 0)}/>
								  </Fragment>}
							 </Fragment>}
							<TextInputBox hintText={'File Name'}
										  errorText={isFileNameMissing ? 'File Name is required' : ''}
										  value={fileName}
										  onChange={(value) => this.setState({fileName: value, isFileNameMissing: false})}
										  maxChars={128}
										  doNotAutoFocus={true}/>
							{!this.isAdminUpload &&
							 <TextInputBox hintText={'File Description'}
										   value={fileDescription}
										   onChange={(value) => this.setState({fileDescription: value})}
										   multiLine={true}
										   rows={5}
										   maxChars={2048}/>}
						</div>
					}
					yesLabel={'UPLOAD'}
					yesAction={this.onUpload}
					noLabel={'CANCEL'}
					noAction={() => {
						this.setState({
							selectedOrg: null,
							showUploadDialog: false,
							showNewPartnerOrgInput: false,
							showVisibleOnlyToMeNote: false,
							uploadError: null
						});
					}}/>
		);
	}


	render() {
		if (!this.uploadTypeLabel) {
			return 'INVALID UPLOAD TYPE';
		}
		if (this.state.loading) return <Loading>Loading...</Loading>;

		let dropText = 'Drop CSV Here';
		const {
			allowSpoofing,
			authState: {
				person: {orgHasBaseAccountData},
			},
		} = this.props;
		const isOrgDataUnavailable = (this.isBaseOpportunitiesManual || this.isPartnerSheet) && !orgHasBaseAccountData;

		let dropIcon = () => <DropzoneContent allowSpoofing={allowSpoofing} isOrgDataUnavailable={isOrgDataUnavailable} />;
		if (this.state.uploadInProgress) {
			dropText = 'Uploading your file';
			dropIcon = CircularProgress;
		}

		return (
			<div style={{margin: 'auto'}}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 20,
					margin: 10,
					padding: 20,
					borderRadius: 10,
					boxShadow: partnerTapDropShadow,
					backgroundColor: partnerTapAppBackground
				}}>
					{this.state.dropFileError &&
					 <div style={{fontSize: 16, color: partnerTapAlert, textAlign: 'center'}}>
						 {this.state.dropFileError}
					 </div>}
					<div data-cy={'drop_zone_area'}
						 style={{color: this.state.uploadInProgress ? partnerTapTernary : partnerTapSecondary, width: 260, height: 260}}>
						<DropzoneArea dropzoneText={dropText}
									  Icon={dropIcon}
									  showAlerts={['error']}
									  showPreviewsInDropzone={false}
									  filesLimit={1}
									  maxFileSize={null}
									  acceptedFiles={['.csv, text/csv']}
									  onDrop={this.onDropFile}
									  dropzoneProps={{disabled: (EnvHelper.isSpoofing && !this.props.allowSpoofing) || this.state.uploadInProgress || isOrgDataUnavailable}}/>
					</div>
					{(this.state.errorCode === OK && this.props.authState.welcomeMode) &&
					 <PrimaryButton label={'NEXT'} icon={<NavigateNext/>} onClick={() => EnvHelper.push(Routes.DASHBOARD.PARTNERS.INVITE.ROUTE)}/>}
					{this.renderUploadDialog()}
				</div>
			</div>

		);
	}
}

SheetUploader.propTypes = {
	onUploadSuccess: PropTypes.func,
	webWorkerFunc: PropTypes.func,
	orgCode: PropTypes.string,
	orgId: PropTypes.string,
	uploadType: PropTypes.string,
	match: PropTypes.object.isRequired,
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

SheetUploader.propTypes = {
	allowSpoofing: PropTypes.bool
};

export default withRouter(connect(mapStateToProps)(SheetUploader));
