import {Group, ListAlt, PersonAdd, PersonSearch} from '@mui/icons-material';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import * as PartnersEndpoints from '../../../../endpoints/PartnersEndpoints';
import {
	INVITE_STATUS_ACCEPTED,
	INVITE_STATUS_INITIATED,
	INVITE_STATUS_RECOMMENDED,
	INVITE_STATUS_REQUESTED,
	UPLOAD_PARTNER_SHEET
} from '../../../../globals/Enums';
import {ACTIONS} from '../../../../helpers/ColumnHelper';
import {SHARE_SUMMARY} from '../../../../helpers/EcosystemShareHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_PARTNER_INVITE_PERMISSION,
	FEATURE_CHANNEL_SHARE_OPPS,
	FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS,
	PRODUCT_CHANNEL_ECOSYSTEM
} from '../../../../helpers/FeatureHelper';
import PartnerStatusHelper from '../../../../helpers/PartnerStatusHelper';
import {partnerTapAlert, partnerTapSecondary, partnerTapTernary} from '../../../../styles/partnertap_theme';
import PagingBase from '../../../../ui/lists/PagingBase';
import PartnerConnectionTileRenderer from './PartnerConnectionTileRenderer';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import EnvHelper from '../../../../helpers/EnvHelper';
import {Routes} from '../../../../globals/Routes';
import TableHelper from '../../../../helpers/TableHelper';
import HackHelper from '../../../../helpers/HackHelper';
import LinkButton from '../../../../ui/buttons/LinkButton';
import MessageBoxInfo from '../../../../ui/messages/MessageBoxInfo';

class PartnerConnectionPage extends PagingBase {

	constructor(props, context) {
		super(props, context);
		this.updatePartner = this.updatePartner.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
		HackHelper.horribleHack = null;
	}

	get storageKeyBase() {
		return 'ecosystem_channel_partners';
	}

	get title() {
		return 'Partner Connections';
	}

	get icon() {
		return Group;
	}

	get columnData() {
		return [
			{
				title: 'Partner Name',
				key: 'partnerName',
				renderFunction: (columnValue, rowData) => {
					if (rowData.partnerStatus === INVITE_STATUS_ACCEPTED) {
						return <LinkButton label={columnValue} onClick={() => PartnerStatusHelper.navigateToPartnerDetails(rowData)}/>;
					}
					return columnValue;
				}
			},
			{title: 'Partner Title', key: 'ptJobTitle'},
			{title: 'Partner Organization', key: 'partnerOrganization'},
			{title: 'Status', key: 'partnerStatusMessage', sortDisabled: true},
			{
				title: 'Actions',
				key: ACTIONS,
				renderFunction: (columnValue, rowData) => {
					if (rowData.partnerStatus !== INVITE_STATUS_ACCEPTED) {
						return PartnerStatusHelper.renderAction(rowData, true);
					}
				}
			}
		];
	}

	get alwaysShowSearch() {
		return true;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let status = params.search ? [] : [INVITE_STATUS_ACCEPTED, INVITE_STATUS_REQUESTED, INVITE_STATUS_RECOMMENDED, INVITE_STATUS_INITIATED];
		PartnersEndpoints.searchPartners(params.page, params.search, params.sort, 0, status)
		.then((result) => {
			if (this.unmounted) return;
			HackHelper.horribleHack = this.renderNavTiles();
			if (this.hasRowData(result)) {
				result.payload.forEach((partnerItem) => {
					partnerItem.partnerName = partnerItem.firstName + ' ' + partnerItem.lastName;
					partnerItem.updatePartner = this.updatePartner;
					if (partnerItem.shareSettings) {
						partnerItem.partnerStatusMessage = this.getSharingTable(partnerItem);
					}
				});
			}
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_PARTNER_INVITE_PERMISSION)) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
					<MessageBoxInfo title={'You do not have access to Connect Partners Feature. Please reach out to your PartnerTap administrator'}
									hideIcon={true}/>
				</div>
			);
		}

		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			HackHelper.horribleHack = null;
			return (
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', overflow: 'scroll'}}>
					{this.renderNavTiles()}
				</div>
			);
		}
		return super.noDataMessage;
	}

	renderNavTiles() {
		let tileData = [
			{
				title: 'Invite Partners',
				icon: PersonAdd,
				description: 'Send email invites to your partners',
				actionLabel: 'INVITE',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH())
			},
			{
				title: 'Partner Sheets',
				icon: ListAlt,
				description: 'Upload your partner account data',
				actionLabel: 'UPLOAD',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_PARTNER_SHEET))
			},
			{
				title: 'Recommended Partners',
				icon: PersonSearch,
				description: 'Find partners already using PartnerTap',
				actionLabel: 'VIEW',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.RECOMMENDATIONS.ROUTE)
			}
		];
		return (
			<Fragment>
				{tileData.map((tileData) => this.renderNavTile(tileData))}
			</Fragment>
		);
	}

	renderNavTile(tileData) {
		let MaterialIcon = tileData.icon;
		return (
			<div key={tileData.title} style={TableHelper.tileStyle(250, 350, partnerTapSecondary, 10, 'pointer')} onClick={tileData.actionFunction}>
				<div style={{fontSize: 20, fontWeight: 'bold'}}>
					{tileData.title}
				</div>
				<MaterialIcon style={{fontSize: 60, color: partnerTapSecondary}}/>
				{tileData.description}
				<PrimaryButton label={tileData.actionLabel} onClick={tileData.actionFunction}/>
			</div>
		);
	}

	getSharingTable(partnerItem) {
		let {accountShareSettings, oppShareSettings} = partnerItem.shareSettings;
		let ownerNotSharingAccounts = partnerItem.shareSettings && accountShareSettings.ownerAssetShareOption === SHARE_SUMMARY;
		let partnerNotSharingAccounts = partnerItem.shareSettings && accountShareSettings.partnerAssetShareOption === SHARE_SUMMARY;
		let ownerNotSharingOpps = partnerItem.shareSettings && oppShareSettings.ownerAssetShareOption === SHARE_SUMMARY;
		let partnerNotSharingOpps = partnerItem.shareSettings && oppShareSettings.partnerAssetShareOption === SHARE_SUMMARY;
		let red = <div style={{color: partnerTapAlert}}>&#9673;</div>;
		let green = <div style={{color: partnerTapTernary}}>&#9673;</div>;
		return (
			<table style={{width: '80%', fontSize: 12, textAlign: 'center'}}>
				<thead>
				<tr>
					<td style={{fontWeight: 'bold'}}>Sharing</td>
					<td>Me</td>
					<td>Partner</td>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Acct</td>
					<td>{ownerNotSharingAccounts ? red : green}</td>
					<td>{partnerNotSharingAccounts ? red : green}</td>
				</tr>
				{(FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS) ||
				  FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS)) &&
				 <tr>
					 <td>Opp</td>
					 <td>{ownerNotSharingOpps ? red : green}</td>
					 <td>{partnerNotSharingOpps ? red : green}</td>
				 </tr>}
				</tbody>
			</table>
		);
	}

	get tileRenderer() {
		return PartnerConnectionTileRenderer;
	}

	updatePartner(itemData, status) {
		PartnersEndpoints.updatePartner(itemData.partnerId, status, false, PRODUCT_CHANNEL_ECOSYSTEM)
		.then((result) => {
			if (this.unmounted) return;
			itemData.partnerStatus = status;
			itemData.partnerStatusMessage = PartnerStatusHelper.renderStatusMessage(itemData);
			this.setState({forceUpdateKey: this.state.forceUpdateKey + 1});
		})
		.catch((error) => {
				console.error('Error updatePartner cannot ' + status + ' partner', error);
			}
		);
	}
}

export default connect()(PartnerConnectionPage);
