import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {PERSISTENT_KEY} from '../../globals/StorageKeys';
import ColumnHelper from '../../helpers/ColumnHelper';
import DialogHelper from '../../helpers/DialogHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FilterHelper from '../../helpers/FilterHelper';
import {PAGE_SIZE_DEFAULT} from '../../helpers/PageableHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import TableHelper from '../../helpers/TableHelper';
import {partnerTapPrimary} from '../../styles/partnertap_theme';
import PrimaryButton from '../buttons/PrimaryButton';
import SupportButton from '../buttons/SupportButton';
import Loading from '../Loading';
import SearchBox from '../SearchBox';
import BasicRowRenderer from './BasicRowRenderer';
import PagingToolbarTable from './PagingToolbarTable';

class PagingBase extends Component {

	constructor(props, context) {
		super(props, context);

		this.unmounted = false;
		this.gettingRowData = false;

		this.state = {};
		this.state.loading = true;
		this.state.filterSelectorMounted = !this.filterSelectorConfig;
		this.state.filterValues = this.initFilterData;
		this.state.searchValue = this.initSearchData;
		this.state.currentPage = this.initCurrentPageData;
		this.state.pageSize = this.initPageSizeData;
		this.state.pageCount = 0;
		this.state.columnData = this.initColumnData();
		this.state.sortData = this.initSortData;
		this.state.rowData = [];
		this.state.rowCount = 0;
		this.state.dialogDetail = null;
		this.state.downloading = false;
		this.state.forceUpdateKey = 0;

		this.onFilterSelectorChanged = this.onFilterSelectorChanged.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onSortChanged = this.onSortChanged.bind(this);
		this.onColumnActiveChanged = this.onColumnActiveChanged.bind(this);
		this.onColumnOrderChanged = this.onColumnOrderChanged.bind(this);
		this.onColumnSortChanged = this.onColumnSortChanged.bind(this);
		this.activateAllColumns = this.activateAllColumns.bind(this);

		this.processParameters = this.processParameters.bind(this);
		this.getRowData = this.getRowData.bind(this);
		this.hasRowData = this.hasRowData.bind(this);
		this.processData = this.processData.bind(this);
		this.processError = this.processError.bind(this);

		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.closeSavedReportDialog = this.closeSavedReportDialog.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	initColumnData(columns = null, initiateLoading = true) {
		let hasStaticColumns = false;
		if (!columns) {
			columns = this.columnData;
			hasStaticColumns = Boolean(columns);
		}

		if (columns) {
			let invertedColumns = [];
			let storedInvertedColumns = PersistenceHelper.getValue(this.invertedColumnsStorageKey);
			if (storedInvertedColumns) invertedColumns = JSON.parse(storedInvertedColumns);

			let storedSortColumn = PersistenceHelper.getValue(this.sortColumnStorageKey);
			if (storedSortColumn) storedSortColumn = JSON.parse(storedSortColumn);

			TableHelper.initColumnCustomizations(this.storageKeyBase, columns);

			columns.forEach((column) => {
				if (!Object.prototype.hasOwnProperty.call(column, 'active')) column.active = true;

				let storedInverted = invertedColumns.find((invertedColumnKey) => {
					return column.key === invertedColumnKey;
				});
				if (storedInverted) {
					column.activeInverted = true;
				}

				if (storedSortColumn) {
					if (column.key === storedSortColumn.key) {
						column.sort = storedSortColumn.sort;
					}
				}
			});

			if (!hasStaticColumns) {
				this.setState({loading: initiateLoading, columnData: columns});
			}
		}
		return columns;
	}

	get initSearchData() {
		let paramSearchValue = this.getParamValue('search');
		if (paramSearchValue) return paramSearchValue;
		return '';
	}

	get initCurrentPageData() {
		let storedCurrentPage = PersistenceHelper.getValue(this.pageStorageKey);
		return storedCurrentPage ? parseInt(storedCurrentPage) : 1;
	}

	get initPageSizeData() {
		let storedPageSize = PersistenceHelper.getValue(this.pageSizeStorageKey);
		return storedPageSize ? parseInt(storedPageSize) : PAGE_SIZE_DEFAULT;
	}

	get initFilterData() {
		let filterParam = this.getParamValue('filter');
		return filterParam ? JSON.parse(filterParam) : {};
	}

	get initSortData() {
		let sorts = this.sortData;
		if (sorts) {
			let storedSortType = PersistenceHelper.getValue(this.sortStorageKey);
			if (storedSortType) {
				sorts.forEach((item) => item.selected = item.key === storedSortType);
			}
		}
		return sorts;
	}

	getParamValue(paramName) {
		return this.props.location ? EnvHelper.getParam(paramName, this.props.location.search) : EnvHelper.getUrlParam(paramName);
	}

	get isSavedReport() {
		return Boolean(this.getParamValue('savedReportId'));
	}

	get storageKeyBase() {
		throw new Error('PagingBase.storageKeyBase must be overridden!');
	}

	get sortStorageKey() {
		return PERSISTENT_KEY + this.storageKeyBase + '_sort';
	}

	get invertedColumnsStorageKey() {
		return PERSISTENT_KEY + this.storageKeyBase + '_inverted_columns';
	}

	get sortColumnStorageKey() {
		return PERSISTENT_KEY + this.storageKeyBase + '_sort_column';
	}

	get pageStorageKey() {
		return this.storageKeyBase + '_page';
	}

	get pageSizeStorageKey() {
		return this.storageKeyBase + '_page_size';
	}

	get title() {
		throw new Error('PagingBase.title must be overridden!');
	}

	get dropDownMenu() {
		return null
	}

	get notFoundMessage() {
		return null;
	}

	get icon() {
		throw new Error('PagingBase.icon must be overridden!');
	}

	get iconColor() {
		return partnerTapPrimary;
	}

	get sortData() {
		return null;
	}

	get componentAboveToolbar() {
		return null;
	}

	get additionalToolbarButtons() {
		return null;
	}

	get componentBelowToolbar() {
		return null;
	}

	get filterSelectorColumns() {
		return this.state.columnData;
	}

	get filterSelectorArgs() {
		return this.state.filterSelectorArgs || {};
	}

	get filterSelectorConfig() {

		if (this.state.filterSelectorFunction && this.filterSelectorColumns) {
			if (!this.initialFilterValues) {
				this.initialFilterValues = this.state.filterValues;
			}
			if (DialogHelper.savedReportData.isDirty) {
				this.initialFilterValues = this.state.filterValues;
			}
			let blockPersistenceForSavedReports = DialogHelper.savedReportData.settings && !DialogHelper.savedReportData.isEditable;
			return {
				filters: this.filterSelectorColumns,
				initialFilterValues: this.initialFilterValues,
				getFilterDataFunction: this.state.filterSelectorFunction,
				getFilterDataFunctionArgs: this.filterSelectorArgs,
				onChange: this.onFilterSelectorChanged,
				inherentReportFilters: null, // set by overrides
				blockPersistence: blockPersistenceForSavedReports  // also set by overrides
			};
		}
		return null;
	}

	get skipFilterLoading() {
		return !this.state.filterSelectorFunction;
	}

	onFilterSelectorChanged(filterValues, isMount) {
		let reload = JSON.stringify(this.state.filterValues) !== JSON.stringify(filterValues) || !this.state.filterSelectorMounted || this.state.loading;
		this.setState({filterValues: filterValues, filterSelectorMounted: true, loading: reload, currentPage: isMount ? this.state.currentPage : 1});
		if (!isMount) {
			DialogHelper.savedReportData.isDirty = true;
		}
	}

	onSearch(search) {
		this.setState({loading: true, currentPage: 1, searchValue: search});
	}

	onSortChanged(sortKey) {
		if (this.state.sortData) {
			this.state.sortData.forEach((item) => item.selected = item.key === sortKey);
			PersistenceHelper.setValue(this.sortStorageKey, sortKey);
			this.setState({loading: true, currentPage: 1, sortKey: sortKey});
		}
	}

	onColumnActiveChanged() {
		if (this.state.columnData) {
			let invertedColumnKeys = [];
			this.state.columnData.forEach((columnItem) => {
				if (!ColumnHelper.isVisible(columnItem) && columnItem.sort) {
					let firstColumn = ColumnHelper.getFirstVisibleColumn(this.state.columnData);
					if (firstColumn && ColumnHelper.isSortable(firstColumn)) {
						firstColumn.sort = 'asc';
						this.onColumnSortChanged(firstColumn);
					}
				}
				if (columnItem.activeInverted) {
					invertedColumnKeys.push(columnItem.key);
				}
			});
			PersistenceHelper.setValue(this.invertedColumnsStorageKey, JSON.stringify(invertedColumnKeys));
			this.setState({columnData: [...this.state.columnData]});
		}
	}

	onColumnOrderChanged(storeColumnCustomizations) {
		if (storeColumnCustomizations) {
			TableHelper.storeColumnCustomizations(this.storageKeyBase, this.state.columnData);
		}
		this.setState({columnData: [...this.state.columnData]});
	}

	onColumnSortChanged(column) {
		if (this.state.columnData) {
			this.state.columnData.forEach((columnItem) => {
				if (columnItem !== column) columnItem.sort = null;
			});
			PersistenceHelper.setValue(this.sortColumnStorageKey, JSON.stringify({key: column.key, sort: column.sort}));
			this.setState({loading: true, sortColumn: column});
		}
	}

	get columnData() {
		return null;
	}

	get activeColumns() {
		let active = '';
		if (this.state.columnData) {
			this.state.columnData.forEach((columnItem) => {
				if (ColumnHelper.isVisible(columnItem)) {
					if (columnItem.isFromOtherFields) {
						active = active + columnItem.otherFieldsPrefix + '->' + columnItem.key + ',';
					}
					else {
						active = active + columnItem.key + ',';
					}
				}
			});
			if (active.length) active = active.substring(0, active.length - 1);
		}
		return active;
	}

	activateAllColumns() {
		this.state.columnData.forEach((columnItem) => {
			columnItem.activeInverted = !columnItem.active;
		});
		this.onColumnActiveChanged();
	}

	get sortClause() {
		let sortColumn;
		let sort;
		if (this.state.columnData && this.state.columnData.length) {
			sortColumn = this.state.columnData.find((columnItem) => {
				return columnItem.sort && ColumnHelper.isVisible(columnItem) && ColumnHelper.isSortable(columnItem);
			});

			if (sortColumn && sortColumn.isFromOtherFields) {
				let updatedKey = sortColumn.key.replace('.', '\'->\'');
				sort = sortColumn.otherFieldsPrefix + '->\'' + updatedKey + '\',' + sortColumn.sort;
			}
			else {
				sort = sortColumn ? sortColumn.key + ',' + sortColumn.sort : null;
			}
		}
		else if (this.state.sortData) {
			let selectedSort = this.state.sortData.find((item) => {
				return item.selected;
			});

			if (selectedSort && selectedSort.key) {
				sort = selectedSort.key;
			}
			else {
				sort = this.state.sortData[0].key;
				console.warn('Error sorting: no key found', selectedSort);
			}
		}
		return sort;
	}

	processParameters(forDownload = false) {
		if (!forDownload) this.gettingRowData = true;
		return {
			search: this.state.searchValue ? this.state.searchValue.trim() : '',
			sort: this.sortClause,
			page: this.state.currentPage ? this.state.currentPage - 1 : 0,
			pageSize: this.state.pageSize,
			filters: this.state.filterValues
		};
	}

	getRowData() {
		throw new Error('PagingBase.getRowData must be overridden!');
	}

	hasRowData(result) {
		return result && result.payload && result.payload.length && (!result.metaData || result.metaData.totalElements);
	}

	get hasColumns() {
		return this.state.columnData && this.state.columnData.length;
	}

	get noDataMessage() {
		if (this.state.loading || !this.state.filterSelectorMounted) return null;
		if (this.state.rowData && this.state.rowData.length && (!this.hasColumns || this.activeColumns)) return null;
		let title = this.title;
		let notFoundMessage = this.notFoundMessage;
		if (typeof title !== 'string') title = 'this report';
		let messageStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16};
		if (this.state.searchValue) {
			return (
				<div style={messageStyle}>
					{notFoundMessage ?
						<div style={{padding: 20}}>
							{this.notFoundMessage} '{this.state.searchValue}'
						</div>
						:
						<div style={{padding: 20}}>
							Nothing found in {title} for search '{this.state.searchValue}'
						</div>}
					<PrimaryButton label={'CLEAR SEARCH'} onClick={() => this.onSearch('')}/>
				</div>
			);
		}
		else if (this.hasColumns && !this.activeColumns) {
			return (
				<div style={messageStyle}>
					<div style={{padding: 20}}>
						There are no active columns.
					</div>
					<PrimaryButton label={'ACTIVATE ALL COLUMNS'} onClick={this.activateAllColumns}/>
				</div>
			);
		}
		else if (this.filterSelectorConfig) {
			let activeFilterCount = FilterHelper.getAddedFilters(this.filterSelectorConfig).length;
			if (activeFilterCount) {
				let initialFilterValueCount = this.filterSelectorConfig.initialFilterValues ? Object.keys(this.filterSelectorConfig.initialFilterValues).length : 0;
				let inherentReportFilterCount = this.filterSelectorConfig.inherentReportFilters ? Object.keys(this.filterSelectorConfig.inherentReportFilters).length : 0;
				if (activeFilterCount === initialFilterValueCount + inherentReportFilterCount) {
					return null;
				}
				return (
					<div style={messageStyle}>
						<div style={{padding: 20}}>
							Nothing found in {title} with {activeFilterCount} filter{activeFilterCount !== 1 && 's'} applied.
						</div>
						<div>
							Please update your filters.
						</div>
					</div>
				);
			}
		}
		return (
			<div style={messageStyle}>
				<div style={{padding: 20}}>
					Sorry, there are no results for this report.
				</div>
				<div>
					We'd be happy to help find your data! Please reach out...
				</div>
				<div style={{padding: 10}}>
					<SupportButton/>
				</div>
			</div>
		);
	}

	get hasGeneralNoDataMessage() {
		return this.state.loading || Boolean(this.state.searchValue) || (this.state.columnData && !this.activeColumns) || FilterHelper.getAddedFilters(this.filterSelectorConfig).length;
	}

	processData(params, result, stateUpdates) {
		if (this.hasRowData(result)) {
			this.setState({
				searchValue: params.search,
				currentPage: params.page + 1,
				pageCount: result.metaData ? result.metaData.totalPages : 1,
				rowData: result.payload,
				rowCount: result.metaData ? (result.metaData.totalPages ? result.metaData.totalElements : 0) : result.payload.length,
				loading: false
			});
			PersistenceHelper.setValue(this.pageStorageKey, params.page + 1);
		}
		else {
			this.setState({
				searchValue: params.search,
				currentPage: 1,
				pageCount: 0,
				rowData: [],
				rowCount: 0,
				loading: false
			});
		}
		if (stateUpdates) this.setState(stateUpdates);
		this.gettingRowData = false;
	}

	processError(error) {
		this.setState({loading: false, searchValue: '', rowData: [], rowCount: 0});
		EnvHelper.serverError('Error from getRowData', error);
	}

	get rowRenderer() {
		return BasicRowRenderer;
	}

	get rowClick() {
		return null;
	}

	get tileRenderer() {
		return null;
	}

	get supportsPaging() {
		return true;
	}

	get searchComponent() {
		return <SearchBox defaultValue={this.state.searchValue.trim()} onSearch={this.onSearch}/>;
	}

	get alwaysShowSearch() {
		return false;
	}

	onPageChange(page) {
		this.setState({currentPage: page, loading: true});
	}

	onPageSizeChange(value) {
		let pageSize = value ? parseInt(value) : PAGE_SIZE_DEFAULT;
		PersistenceHelper.setValue(this.pageSizeStorageKey, pageSize);
		this.setState({pageSize: pageSize, loading: true, currentPage: 1});
	}

	openDetailDialog(title, path, params) {
		DialogHelper.openDetailDialog(title, path, params, this.setState.bind(this));
	}

	closeSavedReportDialog() {
		this.showSaveDialog = true;
		this.forceUpdate();
		setTimeout(() => this.showSaveDialog = false);
	}

	render() {
		if (this.props.location && this.props.location.search && this.props.location.search.indexOf('reload') !== -1 && !this.reloaded) {
			this.reloaded = true;
			setTimeout(() => this.setState({loading: true}));
		}
		if (!this.state.rowData) return <Loading>Initializing {this.title}...</Loading>;
		return (
			<Fragment key={this.state.sortKey + this.state.forceUpdateKey}>
				{this.state.detailDialog}
				<PagingToolbarTable key={this.state.searchValue + this.state.forceUpdateKey}
									storageKey={this.storageKeyBase}
									loading={this.state.loading}

									title={this.title}
									titleIcon={this.icon}
									titleIconColor={this.iconColor}
									titleCount={this.state.rowCount}
									noDataMessage={this.noDataMessage}

									searchComponent={this.searchComponent}
									searchValue={this.state.searchValue}
									alwaysShowSearch={this.alwaysShowSearch}

									sortData={this.state.sortData}
									onSortChanged={this.onSortChanged}

									componentAboveToolbar={this.componentAboveToolbar}
									additionalToolbarButtons={this.additionalToolbarButtons}
									componentBelowToolbar={this.componentBelowToolbar}

									filterSelectorConfig={this.filterSelectorConfig}
									skipFilterLoading={this.skipFilterLoading}

									columnData={this.state.columnData}
									onColumnActiveChanged={this.onColumnActiveChanged}
									onColumnOrderChanged={this.onColumnOrderChanged}
									onColumnSortChanged={this.onColumnSortChanged}

									currentPage={this.state.currentPage}
									onPageChange={this.onPageChange}
									pageCount={this.state.pageCount}
									pageSize={this.supportsPaging ? this.state.pageSize : 0}
									onPageSizeChange={this.onPageSizeChange}

									getRowData={this.getRowData}
									rowData={this.state.rowData}
									rowCount={this.state.rowCount}
									rowRenderer={this.rowRenderer}
									rowClick={this.rowClick}
									tileRenderer={this.tileRenderer}
									dropdownMenu={this.dropDownMenu}
				/>
			</Fragment>
		);
	}
}

PagingBase.propTypes = {
	location: PropTypes.object
};

export default PagingBase;
