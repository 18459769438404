import {Star} from '@mui/icons-material';
import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import PageHeader from '../../../ui/PageHeader';
import {WebChatCustomElement} from '@ibm-watson/assistant-web-chat-react';
import EnvHelper from '../../../helpers/EnvHelper';

const webChatOptions = {
	integrationID: "ee12e9e3-0a02-4878-97f3-889fd0176666",
	region: "eu-gb",
	serviceInstanceID: "a57bf4cf-b39d-4bb9-9df6-334be9e4650b",
	layout: {
		showFrame: true,
		hasContentMaxWidth: false, // Remove max width constraint
	},
	headerConfig: {
		hideMinimizeButton: true,
		showRestartButton: false,
		showCloseAndRestartButton: false
	},
	analytics: {
		disabled: true,
		messageRequest: {disabled: true},
		messageResponse: {disabled: true}
	},
	openChatByDefault: true,
	showLauncher: false,
	enableFocusTrap: false,
	shouldTakeFocusIfOpensAutomatically: true,
	styles: {
		mainWindow: {
			position: 'fixed', // Changed from absolute to fixed
			zIndex: 1000,
			width: '100vw',  // Full viewport width
			height: '100vh', // Full viewport height
			top: 0,
			left: 0,
			margin: 0,
			maxWidth: 'none', // Remove any max width restrictions
			borderRadius: 0   // Remove rounded corners
		}
	}
};

const AiChatPage = () => {
	const { state } = useLocation();
	const { question } = state || {};


	console.log(question);
	const [jwt, setJwt] = React.useState(null);

	const clearChat = async (instance) => {
		try {
			await instance.messaging.clearConversation();
			await instance.restartConversation();
		}
		catch (error) {
			console.error('Error clearing chat:', error);
		}
	};

	React.useEffect(() => {
		console.log(EnvHelper.token);
		setJwt(EnvHelper.token);
	}, [EnvHelper.token]);

	return (
		<ScrollingContainer divId={'channel_wizard_page'}>
			<PageHeader title={'PartnerTap AI Answers'} icon={<Star fontSize={'large'}/>}/>
			<div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
				<WebChatCustomElement
					config={webChatOptions}
					className="MyCustomElement"
					onBeforeRender={async (instance) => {
						// You can do any pre-render setup here
						console.log('Watson Assistant is about to render');
						await clearChat(instance);
					}}
					identityToken={jwt}
					onAfterRender={async (instance) => {
						console.log('Watson Assistant has rendered');
						await instance.send(question);
					}}
				/>
			</div>
		</ScrollingContainer>
	);
};

export default AiChatPage;
