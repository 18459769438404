import PagingBase from '../../../ui/lists/PagingBase';
import React from 'react';
import {FileCopyOutlined, VerifiedUser} from '@mui/icons-material';
import LinkButton from '../../../ui/buttons/LinkButton';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import TextInputBox from '../../../ui/TextInputBox';
import Dialog from '../../../ui/Dialog';
import * as ApiKeyGeneratorEndpoints from '../../../endpoints/ApiKeyGeneratorEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import {partnerTapAlert, partnerTapDefaultText} from '../../../styles/partnertap_theme';
import EnvHelper from '../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_REQUIRE_API_KEY_MANAGEMENT, PRODUCT_CHANNEL_ECOSYSTEM} from '../../../helpers/FeatureHelper';
import WidthAjustedMargin from '../../../ui/lists/WidthAjustedMargin';
import DateHelper from '../../../helpers/DateHelper';

const ACTION_CREATE = 'create';
const ACTION_UPDATE = 'update';
const ACTION_DELETE = 'delete';
const ACTION_SAVE = 'save';

const COPY = 'COPY';
const COPIED = 'COPIED';
const COPY_ERROR = 'SORRY, COPY ERROR!';

export class ApiKeysManagementPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.actionToApply = null;
		this.state.apiKeyId = null;
		this.state.name = null;
		this.state.desciption = null;
		this.state.apiKeyCreated = null;
		this.state.showApiKeyUpdateDialog = null;
		this.state.showApiKeyDeleteDialog = null;
		this.state.copyLabel = COPY;
		this.performAction = this.performAction.bind(this);
		this.clearActionState = this.clearActionState.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get storageKeyBase() {
		return 'api_key_management';
	}

	get title() {
		return 'API Keys';
	}

	get icon() {
		return VerifiedUser;
	}

	get renderCreateOrEditDialog() {
		const {actionToApply} = this.state;
		const title = actionToApply === ACTION_CREATE ? 'Create' : 'Update';
		return (
			<Dialog title={title + ' API Key'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400, margin: 'auto'}}>
							<TextInputBox hintText={'Name'}
										  value={this.state.name}
										  onChange={(value) => this.setState({name: value})}
										  doNotAutoFocus={true}
										  maxChars={255}/>
							<TextInputBox hintText={'Description'}
										  value={this.state.description}
										  onChange={(value) => this.setState({description: value})}
										  multiLine={true}
										  rows={3}
										  maxChars={512}/>
						</div>}
					yesDisable={!this.state.name?.trim() || !this.state.description?.trim()}
					yesLabel={actionToApply.toUpperCase()}
					yesAction={() => this.performAction()}
					noLabel={'CANCEL'}
					noAction={this.clearActionState}/>
		);
	}

	get renderDeleteDialog() {
		return (
			<Dialog title={'Delete API Key'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							<div style={{fontSize: 16}}>
								Are you sure you want to delete the API key '{this.state.name}'?
							</div>
							<div style={{color: partnerTapAlert}}>
								Deleting this key will cause data requests to fail.
							</div>
						</div>}
					yesLabel={'DELETE KEY'}
					yesAction={this.performAction}
					noLabel={'CANCEL'}
					noAction={this.clearActionState}/>
		);
	}

	get renderSaveApiKeyDialog() {
		return (
			<Dialog title={'Save API Key'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400}}>
							<div style={{fontSize: 16}}>
								Please <LinkButton label={'copy this key'} onClick={this.copyToClipboard}/> and save it somewhere safe.
							</div>
							<div style={{color: partnerTapAlert}}>
								For security reasons, we cannot show it to you again.
							</div>
							<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
								<TextInputBox value={this.state.apiKeyCreated} isSimple={true} doNotAutoFocus={true} onChange={() => {
									// nothing to do
								}}/>
								<PrimaryButton label={this.state.copyLabel} icon={<FileCopyOutlined/>} onClick={this.copyToClipboard}/>
							</div>
						</div>}
					yesDisable={this.state.copyLabel !== COPIED}
					yesLabel={'DONE'}
					yesAction={this.clearActionState}/>
		);
	}

	clearActionState() {
		this.setState({actionToApply: null, apiKeyId: null, name: null, description: null});
	}

	copyToClipboard() {
		navigator.clipboard.writeText(this.state.apiKeyCreated)
		.then(() => {
			if (this.unmounted) return;
			this.setState({copyLabel: COPIED});
		})
		.catch((error) => {
			this.setState({copyLabel: COPY_ERROR});
			console.error('Error writing to clipboard', error);
		});
	}

	get apiKeyAction() {
		let {actionToApply, name, description, apiKeyId} = this.state;
		switch (actionToApply) {
			case ACTION_CREATE:
				return () => ApiKeyGeneratorEndpoints.createApiKey({name: name.trim(), description: description.trim()});
			case ACTION_UPDATE:
				return () => ApiKeyGeneratorEndpoints.updateApiKey(apiKeyId, {name: name.trim(), description: description.trim()});
			case ACTION_DELETE:
				return () => ApiKeyGeneratorEndpoints.deleteApiKey(apiKeyId);
		}
		return null;
	}

	performAction() {
		let {actionToApply} = this.state;
		this.clearActionState();
		this.setState({saving: true});
		this.apiKeyAction()
		.then((result) => {
			if (this.unmounted) return;
			this.setState({
				loading: true,
				saving: false,
				apiKeyCreated: actionToApply === ACTION_CREATE ? result.payload.apiKey : null,
				actionToApply: actionToApply === ACTION_CREATE ? ACTION_SAVE : null
			});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from performApiKeyAction', error);
		});
	}

	get additionalToolbarButtons() {
		return [
			<PrimaryButton key={'generate_key_button'}
						   label={'Generate Key'}
						   rowDataNotRequired={true}
						   onClick={() => this.setState({actionToApply: ACTION_CREATE})}/>
		];
	}

	getTextColorBasedOnExpiration = (expirationDate) => {
		return DateHelper.isTodayAfter(expirationDate) ? {color: partnerTapAlert} : {color: partnerTapDefaultText};
	};

	get columnData() {
		return [
			{
				title: 'Actions',
				key: ACTIONS,
				renderFunction: (value, rowData) =>
					<div style={{display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', gap: 10}}>
						<LinkButton label={'UPDATE'} onClick={() => this.setState({actionToApply: ACTION_UPDATE, ...rowData})}/>
						<LinkButton label={'DELETE'} onClick={() => this.setState({actionToApply: ACTION_DELETE, ...rowData})}/>
					</div>
			},
			{
				title: 'Name', key: 'name', cellStyleFunction: (_, rowData) => this.getTextColorBasedOnExpiration(rowData.expirationDate)
			},
			{title: 'Description', key: 'description', cellStyleFunction: (_, rowData) => this.getTextColorBasedOnExpiration(rowData.expirationDate)},
			{title: 'Created By', key: 'createdBy', cellStyleFunction: (_, rowData) => this.getTextColorBasedOnExpiration(rowData.expirationDate)},
			{
				title: 'Created Date',
				key: 'createdAt',
				type: 'date',
				sort: 'asc',
				cellStyleFunction: (_, rowData) => this.getTextColorBasedOnExpiration(rowData.expirationDate)
			},
			{title: 'Last Call', key: 'lastCall', type: 'date', cellStyleFunction: (_, rowData) => this.getTextColorBasedOnExpiration(rowData.expirationDate)},
			{
				title: 'Expires',
				key: 'expirationDate',
				type: 'date',
				cellStyleFunction: (_, rowData) => this.getTextColorBasedOnExpiration(rowData.expirationDate)
			}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {columnData} = this.state;
		let params = this.processParameters();
		ApiKeyGeneratorEndpoints.getAllApiKeys(params.search, params.page, params.pageSize, params.sort)
		.then((result) => {
				if (this.unmounted) return;
				ChannelHelper.convertTypedValues(result.payload, columnData);
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						API keys will be listed here
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	render() {
		if (!FeatureHelper.isProductPaid(PRODUCT_CHANNEL_ECOSYSTEM)) {
			return (
				<MessageBoxInfo hideIcon={true}>
					<div>
						API Keys allows administrators manage API keys created by the organization. They can generate new keys or edit and delete existing ones.
						<br/>
						<br/>
						This is a paid feature. To enable it, please reach out to <a href={'mailto:support@partnertap.com'}>support@partnertap.com</a> to
						upgrade.
					</div>
				</MessageBoxInfo>
			);
		}
		if (!FeatureHelper.isFeatureEnabled(FEATURE_REQUIRE_API_KEY_MANAGEMENT)) {
			return (
				<MessageBoxInfo hideIcon={true}>
					Viewing API Keys requires administrator level access. Please reach out to your PartnerTap administrator.
				</MessageBoxInfo>
			);
		}
		let {actionToApply} = this.state;

		return (
			<WidthAjustedMargin divId={"api-key-management-page"}>
				<MessageBoxInfo hideIcon={true}>
					Manage API keys created by your organization. Generate new keys or edit, renew, and delete existing ones.
				</MessageBoxInfo>
				{(actionToApply === ACTION_CREATE || actionToApply === ACTION_UPDATE) && this.renderCreateOrEditDialog}
				{actionToApply === ACTION_DELETE && this.renderDeleteDialog}
				{actionToApply === ACTION_SAVE && this.renderSaveApiKeyDialog}
				{super.render()}
			</WidthAjustedMargin>
		);
	}
}

export default ApiKeysManagementPage;
