import React from 'react';

const PartnerTapBulletList = ({bulletList}) => {
	return (
		<>
			<ul style={{paddingLeft: '20px'}}>
				{bulletList.map((item, index) => (
					<li key={index} style={{marginBottom: '2px'}}>{item}</li>
				))}
			</ul>
		</>
	);
};

export default PartnerTapBulletList;