import {Badge, Button} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {matchPath, withRouter} from 'react-router-dom';
import EnvHelper from '../../helpers/EnvHelper';
import StringHelper from '../../helpers/StringHelper';
import {partnerTapAppBackground, partnerTapPrimary, partnerTapSecondary, partnerTapWhite} from '../../styles/partnertap_theme';
import ToolTipOverlay from '../ToolTipOverlay';

class SubNavButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.onClick = this.onClick.bind(this);
	}

	onClick(event) {
		event.preventDefault();
		EnvHelper.push(this.props.subNav.route);
	}

	render() {
		let {subNav, history} = this.props;
		let {label, MaterialIcon, alertCount} = subNav;
		const subNavWithLocationRegex = new RegExp(`^${subNav.route}(\/true)?$`);
		let isActive = subNavWithLocationRegex.test(history.location.pathname);
		if (!isActive) {
			subNav.activeRoutes?.some((key) => {
				let matches = matchPath(EnvHelper.path, {path: key, exact: true, strict: true});
				if (matches || key === EnvHelper.path) isActive = true;
				return isActive;
			});
		}

		let button =
			<Button style={{
				color: isActive ? partnerTapPrimary : partnerTapSecondary,
				backgroundColor: isActive ? partnerTapWhite : partnerTapAppBackground,
				borderBottom: isActive ? '4px solid ' + partnerTapPrimary : '',
				borderRadius: '10px 10px 0 0',
				padding: 20,
				height: EnvHelper.isDesktop ? 40 : 36
			}}
					icon={<MaterialIcon/>}
					fullWidth={false}
					onClick={this.onClick}
					data-cy={StringHelper.formatKey(label) + '_sub_nav_button'}>
				<Badge badgeContent={isActive ? 0 : alertCount} color={'primary'} overlap={'circular'}>
					{<MaterialIcon/>}
				</Badge>
				<div style={{padding: 2}}/>
				{EnvHelper.isDesktop && label}
			</Button>;

		if (!EnvHelper.isDesktop) {
			return (
				<ToolTipOverlay title={label}>
					{button}
				</ToolTipOverlay>
			);
		}
		else {
			return button;
		}
	}
}

SubNavButton.propTypes = {
	subNav: PropTypes.object.isRequired
};

export default withRouter(SubNavButton);
