import {Business, DomainAdd} from '@mui/icons-material';
import React, {Fragment} from 'react';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import {PARTNER_SHEETS_FIRST_VISIT_KEY} from '../../../../globals/StorageKeys';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import {ACTIONS} from '../../../../helpers/ColumnHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY, PRODUCT_CHANNEL_ECOSYSTEM} from '../../../../helpers/FeatureHelper';
import PersistenceHelper from '../../../../helpers/PersistenceHelper';
import ReportHelper, {CHANNEL_REPORT_TYPE_PARTNER_ORG_PARTNER_SHEETS} from '../../../../helpers/ReportHelper';
import StringHelper from '../../../../helpers/StringHelper';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import LinkButton from '../../../../ui/buttons/LinkButton';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SupportButton from '../../../../ui/buttons/SupportButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import MessageBox from '../../../../ui/messages/MessageBox';
import DetailHeader from '../../shared/DetailHeader';
import ReportMenu from '../../shared/ReportMenu';
import DialogSheetError from '../../shared/sheets/DialogSheetError';
import PartnerOrgEditorDialog from './PartnerOrgEditorDialog';
import Dialog from '../../../../ui/Dialog';
import SheetDeleteDialog from '../../../../ui/buttons/SheetDeleteDialog';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class PartnerOrgListPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		let reportTypes = ReportHelper.companyPartnerReportTypes;
		let currentReport;
		if (this.reportType) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, decodeURIComponent(this.reportType));
		}
		if (!currentReport) {
			currentReport = reportTypes[0];
		}

		this.state.loading = false;
		this.state.showOrgEditorDialog = false;
		this.state.showUpsellDialog = false;
		this.state.selectedErrors = null;
		this.state.showFirstVisitHelp = !PersistenceHelper.getValue(PARTNER_SHEETS_FIRST_VISIT_KEY);
		this.state.accountReportTypes = reportTypes;
		this.state.currentReportType = currentReport;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {matchingType: currentReport.matchingType, channelReportType: currentReport.channelReportType};

		this.renderReportMenu = this.renderReportMenu.bind(this);
		this.onUpdateReportType = this.onUpdateReportType.bind(this);
	}

	componentDidMount() {
		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY) && FeatureHelper.isProductPaid(PRODUCT_CHANNEL_ECOSYSTEM)) {
			this.fetchPartnerOrgColumns();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get storageKeyBase() {
		return 'ecosystem_company_partner';
	}

	get title() {
		return this.renderReportMenu();
	}

	get icon() {
		return null;
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get displayActions() {
		return this.state.currentReportType.hasActionColumn;
	}

	get additionalToolbarButtons() {
		return [
			<PrimaryButton key={'add_company_partner'}
						   rowDataNotRequired={true}
						   label={'ADD PARTNER ORG'}
						   icon={<DomainAdd/>}
						   onClick={() => this.setState({showOrgEditorDialog: true, companyPartnerPublicId: null})}/>,
			<DownloadButton key={'download_button'} callingPage={this}/>
		];
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		let {currentReportType, columnData} = this.state;
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			channelReportType: currentReportType.channelReportType,
			matchingType: currentReportType.matchingType,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: currentReportType.reportTitle,
			exportFields: DownloadHelper.getFields(columnData),
			selectedColumns: currentReportType.activeColumns,
			totalElements: '0'
		};
	}

	renderReportMenu() {
		let {accountReportTypes, currentReportType} = this.state;
		return <ReportMenu reportTypes={accountReportTypes}
						   currentReportType={currentReportType}
						   onUpdateReportType={this.onUpdateReportType}
						   data-cy={'partner_orgs_report_menu'}/>;
	}

	onUpdateReportType(reportType) {
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORGS.PATH(reportType.reportTitle));
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	fetchPartnerOrgColumns() {
		let {currentReportType} = this.state;
		ChannelMappingEndpoints.getColumns({channelReportType: currentReportType.channelReportType})
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				let partnerName = result.payload.find((row) => row.key === 'partnerName');
				if (partnerName) {
					let route = Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS;
					partnerName.renderFunction = (columnValue, rowData) => {
						if (!rowData) return columnValue;
						return <LinkButton label={columnValue}
										   onClick={() => EnvHelper.push(route.ORG.PATH(rowData.companyPartnerPublicId, route.SUMMARY.PAGE_NAME))}
										   data-cy={StringHelper.formatKey(columnValue)}/>;
					};
				}
				if (this.displayActions) {
					result.payload.push({title: 'Actions', key: ACTIONS});
				}

				let partnerSheetName = result.payload.find((row) => row.key === 'partnerBatchName');
				if (partnerSheetName) {
					partnerSheetName.renderFunction = (columnValue, rowData) => {
						if (!rowData) return columnValue;
						let baseRoute = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS;
						let path = baseRoute.PATH(rowData.personQueueId, baseRoute.DASHBOARD.PAGE_NAME, null, rowData.partnerCompanyName);
						return <LinkButton label={columnValue} onClick={() => EnvHelper.push(path)}/>;
					};
				}

				this.initColumnData(result.payload);
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchPartnerOrgColumns', error);
		});
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		let {currentReportType} = this.state;
		ChannelMappingEndpoints.getRecords(
			{channelReportType: currentReportType.channelReportType},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then(result => {
			if (this.unmounted && (!result || result.payload.length <= 0)) return;
			this.buildModel(result.payload);
			ChannelHelper.convertTypedValues(result.payload, this.columnData);
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	buildModel(rows) {
		rows.forEach((rowData) => {
			let skippedRows = rowData.errorMessage && rowData.errorMessage.split('\n').length || 0;
			if (skippedRows) {
				rowData.errorRows =
					<LinkButton label={skippedRows + ' SKIPPED'}
								onClick={() => this.setState({selectedErrors: rowData.errorMessage})}/>;
			}
			if (this.displayActions) {
				rowData.actions = this.getActionColumn(rowData);
			}
		});
	}

	getActionColumn(rowData) {
		if (this.state.currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_ORG_PARTNER_SHEETS) {
			return (
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<LinkButton label={'DELETE'}
								onClick={() => this.setState({
									deleteDialog: <SheetDeleteDialog rowData={rowData}
																	 notYourSheet={this.props.authState.person.id !== rowData.uploadedById}
																	 onClose={() => this.setState({deleteDialog: null})}/>
								})}/>
				</div>
			);
		}
		return (
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<LinkButton label={'EDIT'}
							onClick={() => this.setState({showOrgEditorDialog: true, companyPartnerPublicId: rowData.companyPartnerPublicId})}/>
			</div>
		);
	}


	get renderError() {
		let {selectedErrors} = this.state;
		if (selectedErrors) {
			return <DialogSheetError errorMessage={selectedErrors} action={() => this.setState({selectedErrors: null})}/>;
		}
		return null;
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {currentReportType} = this.state;
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						Sorry, there are no results for '{currentReportType.reportTitle}'
					</div>
					<div>
						Please try a different report.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	getDeleteDialog(rowData) {
		return (
			<Dialog title={'Please Confirm'}
					message={'Are you sure you want to delete ' + rowData.name + '?'}
					yesAction={() => {
						ChannelMappingEndpoints.deleteSavedReport(rowData.id)
						.then((result) => {
							if (this.unmounted) return;
							this.setState({deleteDialog: null, loading: true});
						})
						.catch((error) => {
							this.displayErrorDialog('You don\'t have permission to delete this report.');
						});
					}}
					noAction={() => this.setState({deleteDialog: null})}/>
		);
	}

	render() {
		let upsell;
		if (!FeatureHelper.isProductPaid(PRODUCT_CHANNEL_ECOSYSTEM)) {
			upsell =
				<MessageBox title={'Welcome to Partner Organizations'} hideIcon={true}>
					<div>
						Partner Organizations gives users a combined view of all the partner data shared to your company.
						Your administrator controls who can see the aggregate data shared by each partner organization.
					</div>
					<div>
						In addition, our Partner Team Access Controls let you decide which users at your company can see data shared by each partner.
					</div>
					<div>
						Contact our Support Team to upgrade.
					</div>
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<SupportButton/>
					</div>
				</MessageBox>;
		}
		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY)) {
			upsell =
				<MessageBox title={'PartnerTap Organizations'} hideIcon={true}>
					<div>
						Partner Organizations gives users a combined view of all the partner data shared to your company.
						Your administrator controls who can see the aggregate data shared by each partner organization.
					</div>
					<div>
						Example: You have one team of partner managers working with a large technology partner, and another team working with a competing
						technology partner.
						With this feature, everyone on each team can see all the data shared by their partner, but users from the competing partner team cannot.
					</div>
					<div>
						Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																						onClick={() => window.open('https://partnertap.com/support/')}/> for
						more information.
					</div>
				</MessageBox>;
		}

		let {deleteDialog, showOrgEditorDialog, companyPartnerPublicId} = this.state;
		return (
			<Fragment>
				<DetailHeader title={'Partner Organizations'} MaterialIcon={Business}/>
				{upsell ? upsell : super.render()}
				{deleteDialog}
				{showOrgEditorDialog &&
				 <PartnerOrgEditorDialog companyPartnerPublicId={companyPartnerPublicId}
										 onClose={() => this.setState({showOrgEditorDialog: false, loading: true})}/>}
				{this.renderError}
			</Fragment>
		);
	}
}

PartnerOrgListPage.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(PartnerOrgListPage));

