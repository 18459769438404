import {CheckCircle} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import * as TrackingEndpoints from '../endpoints/TrackingEndpoints';
import {partnerTapDropShadow, partnerTapPrimary, partnerTapTernary, partnerTapWhite} from '../styles/partnertap_theme';
import PrimaryButton from './buttons/PrimaryButton';
import SupportButton from './buttons/SupportButton';
import ScrollingContainer from './lists/ScrollingContainer';
import FeatureHelper, {PRODUCT_CHANNEL_ECOSYSTEM} from '../helpers/FeatureHelper';
import Dialog from './Dialog';

class UpdatedUpsell extends Component {

	constructor(props, context) {
		super(props, context);
	}
	render() {
		let title = 'Upgrade Required';
		let message = (<>Viewing global ecosystem reports is a paid feature. Please reach out to <a href={'mailto:sales@partnertap.com'}>sales@partnertap.com</a> to
			upgrade.</>);
		if (this.props.isProductPaid) {
			title = 'Permission Required';
			message = <>This feature is currently disabled. Please contact your PartnerTap Administrator</>;
		}
		return (<Dialog title={title}
						message={message}
						yesAction={() => this.props.onYesAction()}
		/>);
	}
}

UpdatedUpsell.propTypes = {
	isProductPaid: PropTypes.bool,
	onYesAction: PropTypes.func
};

export default UpdatedUpsell;
