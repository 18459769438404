import * as ApiHelper from '../helpers/ApiHelper';
import {MEDIA_TYPE_MULTIPART} from '../helpers/ApiHelper';

export function uploadCSV(file, fileName, fileDescription, importType, orgCode, orgId) {
	let endpoint = '/admin/upload?fileName=' + encodeURIComponent(fileName) + '&fileDescription=' + encodeURIComponent(fileDescription) + '&importType=' + importType + '&orgCode=' + orgCode + '&orgId=' + orgId;
	return ApiHelper.sendPost(endpoint, file, MEDIA_TYPE_MULTIPART);
}

export function fetchPreRecommendedPartnerOrgs(orgId) {
	return ApiHelper.sendGet('/admin/recommendedpartners/prerecommendedorgs?orgId=' + orgId);
}

export function fetchRecommendedPartners(orgId) {
	return ApiHelper.sendGet('/admin/recommendedpartners?orgId=' + orgId);
}

export function setRecommendedPartners(orgId, partnerOrgId, isRecommended) {
	return ApiHelper.sendPost('/admin/recommendedpartners?orgId=' + orgId + '&partnerOrgId=' + partnerOrgId + '&isRecommended=' + isRecommended);
}

export function fetchExcludedPartners(orgId) {
	return ApiHelper.sendGet('/admin/partner/excludedpartners?orgId=' + orgId);
}

export function saveExcludedPartner(orgId, partnerOrgId, enabled) {
	return ApiHelper.sendPost('/admin/partner/saveexcludedpartner?orgId=' + orgId + '&partnerOrgId=' + partnerOrgId + '&enabled=' + enabled);
}

export function fetchOrgList() {
	return ApiHelper.sendGet('/admin/org/orglist');
}

export function fetchRoles(orgId) {
	return ApiHelper.sendGet('/admin/roles/role' + (orgId ? '?orgId=' + orgId : ''));
}

export function fetchRole(roleId) {
	return ApiHelper.sendGet('/admin/roles/role/' + roleId);
}

export function cloneRole(orgId, roleId) {
	return ApiHelper.sendPost('/admin/roles/role/' + roleId + '/clone?orgId=' + orgId);
}

export function deleteRole(roleId) {
	return ApiHelper.sendDelete('/admin/roles/role/' + roleId);
}

export function updateRole(roleId, roleData) {
	return ApiHelper.sendPost('/admin/roles/role/' + roleId, roleData);
}

export function spoofUser(spoofPersonId) {
	return ApiHelper.sendGet('/admin/spoof?spoofPersonId=' + spoofPersonId);
}

export function clearSpoof(restorePersonId) {
	return ApiHelper.sendGet('/admin/clearspoof?restorePersonId=' + restorePersonId);
}

export function fetchBillingReport() {
	return ApiHelper.sendGet('/admin/report/billing');
}

export function fetchAllUsersByOrgReport() {
	return ApiHelper.sendGet('/admin/report/allusersbyorg');
}

export function fetchAuditsByOrgReport() {
	return ApiHelper.sendGet('/admin/report/auditsbyorg');
}

export function fetchAuditsInQueueReport() {
	return ApiHelper.sendGet('/admin/report/auditsinqueue');
}

export function fetchAllStats() {
	return ApiHelper.sendGet('/admin/report/allstats');
}

export function fetchReportInvites(startDate, endDate, page, pageSize, search, sort, orgCode) {
	return ApiHelper.sendGet(ApiHelper.appendPageableParams('/admin/report/invites?orgCode=' + orgCode, page, pageSize, search, sort));
}

export function fetchAllExcluded() {
	return ApiHelper.sendGet('/admin/report/allexcluded');
}

export function fetchAllRecommended() {
	return ApiHelper.sendGet('/admin/report/allrecommended');
}

export function fetchChannelShareAlerts() {
	return ApiHelper.sendGet('/admin/report/channelpartnersharing');
}

export function fetchChannelMapperInvites() {
	return ApiHelper.sendGet('/admin/report/channelmappers/invites');
}

export function fetchChannelMappers() {
	return ApiHelper.sendGet('/admin/report/channelmappers/report');
}

export function fetchChannelMappersNoData() {
	return ApiHelper.sendGet('/admin/report/channelmappersnodata/report');
}

export function fetchAllChannelMappers(orgId) {
	return ApiHelper.sendGet('/admin/report/allchannelmappers?orgId=' + orgId);
}

export function fetchAllChannelPartners(orgId) {
	return ApiHelper.sendGet('/admin/report/allchannelpartners?orgId=' + orgId);
}

export function fetchAllPartners(ownerId) {
	return ApiHelper.sendGet('/admin/report/allpartners?ownerId=' + ownerId);
}

export function fetchAllUserReport(startDate, endDate, page, pageSize, search, sort, orgCode) {
	return ApiHelper.sendGet(ApiHelper.appendPageableParams('/analytics/canned/alluserslist?orgCode=' + orgCode, page, pageSize, search, sort));
}

export function fetchUserColumns(isPreReg) {
	return ApiHelper.sendGetReport('/admin/profile/columns?preReg=' + Boolean(isPreReg));
}

export function fetchUserProfiles(orgId, search, filters, currentPage, pageSize, sort, isPreReg) {
	let endpoint = '/admin/profile/all?page=' + currentPage + '&size=' + pageSize;
	if (sort) endpoint += '&sort=' + sort;
	let payload = {
		orgId: orgId,
		preReg: Boolean(isPreReg),
		search: search || '',
		filters: filters || {}
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function fetchUserFilterData(args, search, filters, filterField, currentPage, pageSize) {
	let endpoint = '/admin/profile/filterdata?page=' + currentPage + '&size=' + pageSize + '&filterField=' + filterField;
	let payload = {
		orgId: args.orgId,
		preReg: Boolean(args.isPreReg),
		search: search || '',
		filters: filters || {}
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function fetchOrgsColumns() {
	return ApiHelper.sendGetReport('/admin/orgs/columns');
}

export function fetchOrgsList(search, filters, currentPage, pageSize, sort) {
	let endpoint = '/admin/orgs/list?page=' + currentPage + '&size=' + pageSize;
	if (sort) endpoint += '&sort=' + sort;
	let payload = {
		search: search || '',
		filters: filters || {}
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function fetchOrgsFilterData(args, search, filters, filterField, currentPage, pageSize) {
	let endpoint = '/admin/orgs/filterdata?page=' + currentPage + '&size=' + pageSize + '&filterField=' + filterField;
	let payload = {
		search: search || '',
		filters: filters || {}
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function fetchOrgReportColumns(orgReportType) {
	return ApiHelper.sendGetReport('/admin/orgreport/columns?orgReportType=' + orgReportType);
}

export function fetchOrgReportList(orgId, search, filters, currentPage, pageSize, sort, orgReportType) {
	let endpoint = '/admin/orgreport/list?page=' + currentPage + '&size=' + pageSize;
	if (sort) endpoint += '&sort=' + sort;
	let payload = {
		orgId: orgId,
		search: search || '',
		filters: filters || {},
		orgReportType: orgReportType
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function fetchOrgReportFilterData(args, search, filters, filterField, currentPage, pageSize) {
	let endpoint = '/admin/orgreport/filterdata?page=' + currentPage + '&size=' + pageSize + '&filterField=' + filterField;
	if (args.orgReportType) endpoint += '&orgReportType=' + args.orgReportType;
	let payload = {
		orgId: args.orgId,
		search: search || '',
		filters: filters || {}
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function updateOrgReportDetail(partnerOrgId, orgId, isEnabled, orgReportType) {
	let endpoint = '/admin/orgreport/update?partnerOrgId=' + partnerOrgId;
	if (orgId) endpoint += '&orgId=' + orgId;
	endpoint += '&isEnabled=' + isEnabled;
	if (orgReportType) endpoint += '&orgReportType=' + orgReportType;
	return ApiHelper.sendPostReport(endpoint);
}

export function saveManualPreRegUser(orgId, firstName, lastName, emailAddress, rolePublicId) {
	let payload = {orgId: orgId, firstName: firstName, lastName: lastName, emailAddress: emailAddress, rolePublicId: rolePublicId};
	return ApiHelper.sendPostReport('/admin/saveprereguser', payload);
}

export function saveCRMPreRegUser(orgId, user) {
	let payload = {
		orgId: orgId,
		personId: user.personId,
		rolePublicId: user.rolePublicId,
		firstName: user.firstName,
		lastName: user.lastName,
		emailAddress: user.emailAddress,
		userName: user.userName
	};
	return ApiHelper.sendPostReport('/admin/activateprereguser', payload);
}

export function deletePreRegUser(orgId, user) {
	let payload = {
		orgId: orgId,
		personId: user.personId,
		rolePublicId: user.rolePublicId,
		firstName: user.firstName,
		lastName: user.lastName,
		emailAddress: user.emailAddress,
		userName: user.userName
	};
	return ApiHelper.sendPostReport('/admin/deleteprereguser', payload);
}

export function updateUserProfile(personId, roleCode, status) {
	let endpoint = '/admin/profile/update?personId=' + personId;
	if (roleCode) endpoint += '&roleCode=' + roleCode;
	if (status) endpoint += '&status=' + status;
	return ApiHelper.sendPostReport(endpoint);
}

export function terminateUser(userId) {
	return ApiHelper.sendPost('/admin/terminate?userId=' + userId);
}

export function restoreUser(userId) {
	return ApiHelper.sendPost('/admin/restore?userId=' + userId);
}

export function fetchOrg(orgId) {
	return ApiHelper.sendGet('/admin/org/' + orgId);
}

export function updateOrg(orgId, orgData) {
	return ApiHelper.sendPost('/admin/org/' + orgId, orgData);
}

export function createOrg(name, domains, status, defaultRole, createDemoData) {
	let payload = {
		name: name,
		domains: domains,
		status: status,
		defaultRole: defaultRole,
		createDemoData: createDemoData
	};
	return ApiHelper.sendPost('/admin/org/createorg', payload);
}

export function fetchAllShareOptions(assetType) {
	return ApiHelper.sendGet('/admin/share/share-options?assetType=' + assetType);
}

export function fetchOrgAdminProfiles(orgId) {
	return ApiHelper.sendGet('/admin/org/users/' + orgId);
}

export function fetchAllOrgAccountTags(orgId) {
	return ApiHelper.sendGet('/admin/org/tags?orgId=' + orgId);
}

export function fetchAllAvailableAccountTags(orgId) {
	return ApiHelper.sendGet('/admin/org/tags/available?orgId=' + orgId);
}

export function deleteAccountTag(tagId) {
	return ApiHelper.sendPost('/admin/org/tags/delete?tagId=' + tagId);
}

export function saveAccountTag(orgId, partnerOrgId, tag) {
	return ApiHelper.sendPost('/admin/org/tags/add?orgId=' + orgId + '&partnerOrgId=' + partnerOrgId + '&tag=' + tag);
}

export function updateOrgLogo(updateType, base64Data, orgCode) {
	return ApiHelper.sendPut('/admin/org/image?updateType=' + updateType + '&orgCode=' + orgCode, base64Data);
}

export function fetchRolePartnerOrgs(orgId) {
	return ApiHelper.sendGet('/admin/companypartners/' + orgId);
}
