import React, {Component} from 'react';
import * as Icons from '@mui/icons-material';

class DynamicIcon extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IconComponent: null,
		};
	}

	componentDidMount() {
		this.loadIcon(this.props.iconName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.iconName !== this.props.iconName) {
			this.loadIcon(this.props.iconName);
		}
	}

	loadIcon(iconName) {
		if (!iconName) {
			return;
		}
		this.setState({IconComponent: Icons[iconName]});
	}

	render() {
		const {IconComponent} = this.state;
		const {style, className} = this.props;

		if (!IconComponent) {
			return null; // Or return a placeholder/loading indicator
		}

		return <IconComponent style={style} className={className}/>;
	}
}

export default DynamicIcon;
