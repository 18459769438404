import FeatureHelper, {FEATURE_COSELL_LEAD_WORKFLOW} from '../../../../helpers/FeatureHelper';
import Dialog from '../../../../ui/Dialog';
import MessageBox from '../../../../ui/messages/MessageBox';
import LinkButton from '../../../../ui/buttons/LinkButton';
import EnvHelper from '../../../../helpers/EnvHelper';
import {Routes} from '../../../../globals/Routes';
import Loading from '../../../../ui/Loading';
import WorkFlowSummary from './steps_components/WorkFlowSummary';
import React from 'react';
import LeadTile from './steps_components/LeadTile';
import LeadSummary from './steps_components/LeadSummary';

export const leadSteps = [{label: 'Lead Template', active: true}, {label: 'Confirm'}];

const LeadWorkflowSteps = ({
	actionListMetadata,
	currentStep,
	workflowName,
	templates,
	sequenceName,
	setCustomMessage,
	onStepComplete,
	summaryData
}) => {


	if (!FeatureHelper.isFeatureEnabled(FEATURE_COSELL_LEAD_WORKFLOW)) {
		return (<Dialog title={'Upgrade Required'}
						message={<MessageBox hideIcon={true}>
							<div>
								To run a Lead workflow, you must have the <LinkButton label={'Co-Sell Engine'}
																					 onClick={() => window.open('https://partnertap.com/products/co-sell-automation/')}/> enabled.
							</div>
							<div>
								The Lead Workflow allows you to create co-sell plays between two partners.
							</div>
							<div>
								Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																								onClick={() => window.open('https://partnertap.com/support/')}/> to
								start using Lead Workflows.
							</div>
						</MessageBox>}
						yesAction={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE)}
						noContentPadding={true}/>);
	}

	switch (currentStep) {
		case 0: {
			return (
				<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
					{templates.map((template, index) =>
						<LeadTile
							key={index}
							template={template}
							setCustomMessage={setCustomMessage}
							onStepComplete={onStepComplete}
						/>)}
				</div>
			);
		}
		case 1: {
			return (
				!summaryData ? <Loading>Loading summary...</Loading> :
					<WorkFlowSummary
						summaryData={summaryData}
						displayName={sequenceName}
						summaryComponent={LeadSummary}
					/>
			);
		}
	}
};

export default LeadWorkflowSteps;