import React from 'react';
import DynamicsIcon from '../../static/images/crm-icon-dynamics.svg';
import FreshSalesIcon from '../../static/images/crm-icon-freshsales.svg';
import HubspotIcon from '../../static/images/crm-icon-hubspot.png';
import SalesforceIcon from '../../static/images/crm-icon-salesforce.png';
import SharpSpringIcon from '../../static/images/crm-icon-sharpspring.png';
import PartnerTapIcon from '../../static/images/partnertap-logo.png';
import {CRM_DYNAMICS, CRM_HUBSPOT, CRM_SALESFORCE, MSFT, SFDC_SANDBOX} from '../globals/Enums';
import {freshsalesColor, hubspotColor, microsoftColor, partnerTapAlert, partnerTapWhite, salesforceColor, sharpSpringColor} from '../styles/partnertap_theme';
import FeatureHelper, {
	FEATURE_CHANNEL_CRM_SYNC,
	FEATURE_CHANNEL_DATA_MANAGER,
	FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO,
	FEATURE_CHANNEL_UPLOAD_ACCOUNTS_MANUALLY,
	FEATURE_CHANNEL_UPLOAD_AUGMENTED_ACCOUNT_DATA, FEATURE_CHANNEL_UPLOAD_OPPORTUNITIES_MANUALLY
} from './FeatureHelper';
import Upsell from '../ui/Upsell';

// import types
export const COMPANY_SALE_ACCOUNTS_SFDC = 'COMPANY_SALE_ACCOUNTS_SFDC';
export const COMPANY_SALE_ACCOUNTS_DYNAMICS = 'COMPANY_SALE_ACCOUNTS_DYNAMICS';
export const COMPANY_SALE_ACCOUNTS_HUBSPOT = 'COMPANY_SALE_ACCOUNTS_HUBSPOT';
export const COMPANY_SALE_ACCOUNTS_SHARP_SPRING = 'COMPANY_SALE_ACCOUNTS_SHARP_SPRING';
export const COMPANY_SALE_ACCOUNTS_FRESH_SALES = 'COMPANY_SALE_ACCOUNTS_FRESH_SALES';

export default class DataManagerHelper {

	static getCrmType(importType) {
		if (importType === COMPANY_SALE_ACCOUNTS_SFDC) {
			return CRM_SALESFORCE;
		}
		else if (importType === COMPANY_SALE_ACCOUNTS_DYNAMICS) {
			return CRM_DYNAMICS;
		}
		else if (importType === COMPANY_SALE_ACCOUNTS_HUBSPOT) {
			return CRM_HUBSPOT;
		}
		return null;
	}

	static getCrmName(crmOrImportType) {
		if (crmOrImportType === CRM_SALESFORCE || crmOrImportType === SFDC_SANDBOX || crmOrImportType === COMPANY_SALE_ACCOUNTS_SFDC) {
			return 'Salesforce' + (crmOrImportType === SFDC_SANDBOX ? ' Sandbox' : '');
		}
		else if (crmOrImportType === MSFT || crmOrImportType === CRM_DYNAMICS || crmOrImportType === COMPANY_SALE_ACCOUNTS_DYNAMICS) {
			return 'Dynamics 365';
		}
		else if (crmOrImportType === CRM_HUBSPOT || crmOrImportType === COMPANY_SALE_ACCOUNTS_HUBSPOT) {
			return 'HubSpot';
		}
		else if (crmOrImportType === COMPANY_SALE_ACCOUNTS_SHARP_SPRING) {
			return 'SharpSpring';
		}
		else if (crmOrImportType === COMPANY_SALE_ACCOUNTS_FRESH_SALES) {
			return 'Freshsales';
		}
		return crmOrImportType;
	}

	static getCrmIcon(crmOrImportType) {
		let icon = PartnerTapIcon;
		let iconBackgroundColor = partnerTapAlert;

		if (crmOrImportType === CRM_SALESFORCE || crmOrImportType === SFDC_SANDBOX || crmOrImportType === COMPANY_SALE_ACCOUNTS_SFDC) {
			icon = SalesforceIcon;
			iconBackgroundColor = salesforceColor;
		}
		else if (crmOrImportType === MSFT || crmOrImportType === CRM_DYNAMICS || crmOrImportType === COMPANY_SALE_ACCOUNTS_DYNAMICS) {
			icon = DynamicsIcon;
			iconBackgroundColor = microsoftColor;
		}
		else if (crmOrImportType === CRM_HUBSPOT || crmOrImportType === COMPANY_SALE_ACCOUNTS_HUBSPOT) {
			icon = HubspotIcon;
			iconBackgroundColor = hubspotColor;
		}
		else if (crmOrImportType === COMPANY_SALE_ACCOUNTS_SHARP_SPRING) {
			icon = SharpSpringIcon;
			iconBackgroundColor = sharpSpringColor;
		}
		else if (crmOrImportType === COMPANY_SALE_ACCOUNTS_FRESH_SALES) {
			icon = FreshSalesIcon;
			iconBackgroundColor = freshsalesColor;
		}

		return (
			<div style={{
				width: 110,
				minWidth: 110,
				height: 110,
				minHeight: 110,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 10,
				borderRadius: 5,
				color: partnerTapWhite,
				backgroundColor: iconBackgroundColor
			}}>
				<img src={icon} alt={crmOrImportType} style={{width: 50}}/>
				{this.getCrmName(crmOrImportType)}
			</div>
		);
	}

	static getAssetType(importType) {
		if (importType.indexOf('COMPANY_SALE_ACCOUNTS_') === 0) {
			return 'Accounts';
		}
		else if (importType.indexOf('COMPANY_OPPORTUNITIES') === 0) {
			return 'Opps';
		}
		return 'Unknown';
	}

	static get crmTypeSynced() {
		return this.dataManagerCrmTypeSynced;
	}

	static set crmTypeSynced(crmType) {
		this.dataManagerCrmTypeSynced = crmType;
	}

	static get dataManagerCrmWizardAccess() {
		return this.dataManagerAccess(FEATURE_CHANNEL_CRM_SYNC,
			'Connecting CRM requires administrator level access. Please reach out to your PartnerTap administrator.');
	}

	static get dataManagerAddAccountsAccess() {
		return this.dataManagerAccess(FEATURE_CHANNEL_UPLOAD_ACCOUNTS_MANUALLY,
			'Uploading accounts requires administrator level access. Please reach out to your PartnerTap administrator.');
	}

	static get dataManagerAddOpportunitiesAccess() {
		return this.dataManagerAccess(FEATURE_CHANNEL_UPLOAD_OPPORTUNITIES_MANUALLY,
			'Uploading opportunities requires administrator level access. Please reach out to your PartnerTap administrator.');
	}

	static get dataManagerEnrichAccountsAccess() {
		return this.dataManagerAccess(FEATURE_CHANNEL_UPLOAD_AUGMENTED_ACCOUNT_DATA,
			'Enriching accounts requires administrator level access. Please reach out to your PartnerTap administrator.');
	}

	static get dataManagerPiiAccess() {
		return this.dataManagerAccess(FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO,
			'Uploading PII opt-in lists requires administrator level access. Please reach out to your PartnerTap administrator.');
	}

	static dataManagerAccess(feature, message) {
		const hasFeature = FeatureHelper.isFeatureEnabled(feature);
		const hasDataManagerFeature = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER);
		if (!hasFeature || !hasDataManagerFeature) {
			return <Upsell headline={message} showSupportButton={true}/>;
		}
		return null;
	}
}
