import {AutoAwesome} from '@mui/icons-material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import PageHeader from '../../../ui/PageHeader';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import AiQuestionsSelector from './AiQuestionsSelector';
import WatsonContainer from './WatsonContainer';


export class AiAnswersPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
		};
	}

	render() {
		let {loading, isOrientationLandscape, isAdmin} = this.state;
		if (loading) return <Loading>Loading Data...</Loading>;
		return (
			<ScrollingContainer divId={'channel_wizard_page'}>
				<PageHeader title={'PartnerTap AI Answers'} icon={<AutoAwesome fontSize={'large'}/>}/>
				<div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
					<MessageBoxInfo title={'Once you have mapped data with partners you can use AI to answer your most important sales questions'}
									hideIcon={true}/>
				</div>
				<div style={{padding: 5}}/>
				<WatsonContainer/>
			</ScrollingContainer>
		);
	}
}

export default connect()(AiAnswersPage);
