import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import SortHelper from '../../../helpers/SortHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapPrimary, partnerTapWhite} from '../../../styles/partnertap_theme';
import BasicRowRenderer from '../../../ui/lists/BasicRowRenderer';
import {PagingTable} from '../../../ui/lists/PagingTable';

class DashboardTable extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {columnData: this.props.columnData, rowData: this.props.rowData};
		this.onColumnOrderChanged = this.onColumnOrderChanged.bind(this);
		this.onColumnSortChanged = this.onColumnSortChanged.bind(this);
	}

	get storageKey() {
		if (this.props.title) {
			return 'dashboard_table_' + this.props.title.toLowerCase().replace(/\s/g, '_');
		}
		else {
			return 'dashboard_table';
		}
	}

	onColumnOrderChanged(storeColumnCustomizations) {
		if (storeColumnCustomizations) {
			TableHelper.storeColumnCustomizations(this.storageKey, this.state.columnData);
		}
		this.setState({columnData: [...this.state.columnData]});
	}

	onColumnSortChanged(column) {
		if (this.state.columnData) {
			this.state.columnData.forEach((columnItem) => {
				if (columnItem !== column) columnItem.sort = null;
			});
			let rowData = column.sort ? SortHelper.sortByNullLast(this.state.rowData, column.key, column.sort) : [...this.state.rowData];
			this.setState({rowData: rowData, columnData: [...this.state.columnData]});
		}
	}

	render() {
		let {MaterialIcon, noMargins, maxHeight} = this.props;
		return (
			<div style={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: partnerTapWhite,
				boxShadow: partnerTapDropShadow,
				padding: 10,
				marginBottom: noMargins ? 0 : 10,
				marginLeft: noMargins ? 0 : 5,
				marginRight: noMargins ? 0 : 5,
				minWidth: 300,
				maxHeight: maxHeight || 500
			}}>
				{this.props.title &&
				 <div style={{display: 'flex', alignItems: 'center', paddingBottom: 10}}>
					 <div style={{display: 'flex', alignItems: 'center'}}>
						 {Boolean(MaterialIcon) &&
						  <MaterialIcon style={{color: partnerTapPrimary, paddingLeft: 10}} fontSize={'large'}/>}
						 <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 10}}>
							 <div style={{fontSize: 20}}>
								 {this.props.title}
							 </div>
							 {this.props.message &&
							  <div style={{color: partnerTapInactive}}>
								  {this.props.message}
							  </div>}
						 </div>
					 </div>
					 {this.props.elementRight &&
					  <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', paddingRight: 10}}>
						  {this.props.elementRight}
					  </div>}
				 </div>}
				{(this.state.rowData && this.state.rowData.length) > 0 ?
					<PagingTable storageKey={this.storageKey}
								 columnData={this.state.columnData}
								 onColumnOrderChanged={this.onColumnOrderChanged}
								 onColumnSortChanged={this.onColumnSortChanged}
								 rowData={this.state.rowData}
								 rowRenderer={(props) => <BasicRowRenderer {...props} expandDescription={true} />}
								 rowClick={this.props.rowClick}
								 hasScrollingContainerParent={this.props.hasScrollingContainerParent}/>
					:
					<div style={{display: 'flex', padding: 20}}>
						Sorry, no data currently available
					</div>}
			</div>
		);
	}
}

DashboardTable.propTypes = {
	MaterialIcon: PropTypes.elementType,
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	elementRight: PropTypes.element,
	columnData: PropTypes.array.isRequired,
	rowData: PropTypes.array,
	rowClick: PropTypes.func,
	hasScrollingContainerParent: PropTypes.bool,
	noMargins: PropTypes.bool,
	maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default connect()(DashboardTable);
