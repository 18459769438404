import React from 'react';

const LeadSummary = ({summaryData}) => {
	return (
		<>
			<div>
				Total leads: {summaryData?.totalLeads||0}
			</div>
		</>
	);
};

export default LeadSummary;
