import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import React from 'react';
import PopoverSearchList from '../../../../../ui/PopoverSearchList';
import TextInputBox from '../../../../../ui/TextInputBox';

const RECIPIENT_FROM_ACTION_LIST = 'action-list';
const RECIPIENT_FROM_MANUAL_ENTRY = 'manual-entry';

const RecipientForm = ({
	orgList,
	contactGroups,
	onSelected,
	manualRecipientPrefix,
	recipient,
	formState,
	onFormStateChange
}) => {
	const handleManualFieldChange = (field, value) => {
		onFormStateChange({[field]: value});
	};

	const orgListWithNone = [...orgList, { partnerOrgPublicId: "", partnerOrgName: 'None'}];

	const handleRecipientFromChange = (value) => {
		onFormStateChange({ recipientFrom: value });

		if (value === RECIPIENT_FROM_MANUAL_ENTRY) {
			onSelected({
				id: manualRecipientPrefix,
				partnerOrgPublicId: recipient.partnerOrgPublicId,
				isContactGroup: false,
				ownerOrgPublicId: recipient.ownerOrgPublicId
			});
		} else {
			onSelected({
				id: recipient.id,
				isContactGroup: true,
				ownerOrgPublicId: recipient.ownerOrgPublicId
			});
		}
	};

	const handlePartnerSelect = (org, isManualEntry) => {
		if (isManualEntry) {
			onSelected({
				partnerOrgPublicId: org.partnerOrgPublicId,
				isContactGroup: false,
				id: manualRecipientPrefix
			});
		} else {
			onSelected({
				partnerOrgPublicId: org.partnerOrgPublicId,
				id: ''
			});
		}
	};

	return (
		<>
			<RadioGroup
				value={formState.recipientFrom}
				onChange={(e) => handleRecipientFromChange(e.target.value)}
			>
				<FormControlLabel value={RECIPIENT_FROM_ACTION_LIST} control={<Radio/>} label="Select from action list"/>
				{RECIPIENT_FROM_ACTION_LIST === formState.recipientFrom && (
					<div style={{paddingBottom: 10}}>
						<PopoverSearchList
							label={'Partner'}
							list={orgListWithNone || []}
							isMultipleChoice={false}
							labelRenderer={(org) => org.partnerOrgName}
							valueRenderer={(org) => org.partnerOrgPublicId || org.ownerOrgPublicId}
							onItemSelected={(org) => handlePartnerSelect(org, false)}
							preselectedItem={(orgList || []).find(org => org.partnerOrgPublicId === recipient.partnerOrgPublicId || org.partnerOrgPublicId === recipient.ownerOrgPublicId)}
							searchByObjectKeys={['partnerOrgName']}
							minWidth={250}
							maxWidth={250}
						/>
					</div>
				)}
				{RECIPIENT_FROM_ACTION_LIST === formState.recipientFrom && (
					<div style={{paddingBottom: 10}}>
						<PopoverSearchList
							label={'Contact Group'}
							list={contactGroups[recipient.partnerOrgPublicId] || contactGroups[recipient.ownerOrgPublicId] || []}
							disabled={!contactGroups[recipient.partnerOrgPublicId] && !contactGroups[recipient.ownerOrgPublicId]}
							isMultipleChoice={false}
							labelRenderer={(option) => option.contactGroupName}
							valueRenderer={(option) => option.contactGroupId}
							onItemSelected={(option) => {
								onSelected({
									partnerOrgPublicId: recipient.partnerOrgPublicId || recipient.ownerOrgPublicId,
									id: option.contactGroupId,
									isContactGroup: true
								});
							}}
							minWidth={250}
							maxWidth={250}
							searchByObjectKeys={['title']}
							preselectedItem={(contactGroups[recipient.partnerOrgPublicId] || contactGroups[recipient.ownerOrgPublicId] || [])
							.find(group => group.contactGroupId === recipient.id)}
						/>
					</div>
				)}
				<FormControlLabel value={RECIPIENT_FROM_MANUAL_ENTRY} control={<Radio/>} label="Manual entry"/>
			</RadioGroup>
			{RECIPIENT_FROM_MANUAL_ENTRY === formState.recipientFrom && (
				<div style={{paddingBottom: 10}}>
					<PopoverSearchList
						label={'Partner'}
						list={orgListWithNone || []}
						isMultipleChoice={false}
						labelRenderer={(org) => org.partnerOrgName}
						valueRenderer={(org) => org.partnerOrgPublicId}
						onItemSelected={(org) => handlePartnerSelect(org, true)}
						preselectedItem={(orgList || []).find(org => org.partnerOrgPublicId === recipient.partnerOrgPublicId || org.partnerOrgPublicId === recipient.ownerOrgPublicId)}
						searchByObjectKeys={['partnerOrgName']}
						minWidth={250}
						maxWidth={250}
					/>
				</div>
			)}

			{RECIPIENT_FROM_MANUAL_ENTRY === formState.recipientFrom && (
				<>
					<div style={{paddingBottom: 10}}>
						<TextInputBox
							hintText="Name"
							maxChars={100}
							isSimple={true}
							value={formState.manualName}
							onChange={(value) => handleManualFieldChange('manualName', value)}
						/>
					</div>
					<div style={{paddingBottom: 10}}>
						<TextInputBox
							hintText="Email"
							maxChars={100}
							isSimple={true}
							value={formState.manualEmail}
							onChange={(value) => handleManualFieldChange('manualEmail', value)}
						/>
					</div>
					<div style={{paddingBottom: 10}}>
						<TextInputBox
							hintText="Title"
							maxChars={100}
							isSimple={true}
							value={formState.manualTitle}
							onChange={(value) => handleManualFieldChange('manualTitle', value)}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default RecipientForm;