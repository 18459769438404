import {partnerTapInactive, partnerTapPrimary} from '../../../../../styles/partnertap_theme';
import {Email} from '@mui/icons-material';

import AuthHelper from '../../../../../helpers/AuthHelper';
import TextInputBox from '../../../../../ui/TextInputBox';
import TableHelper from '../../../../../helpers/TableHelper';
import React from 'react';

const EmailPreviewTile = ({
	emailTemplate,
	subject,
	message,
	onSubjectChange,
	onMessageChange
}) => {

	return (
		<div style={{
			...TableHelper.tileStyle(480, 500, partnerTapPrimary, 10),
			height: null, justifyContent: null, paddingBottom: 20
		}}>
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10}}>
				<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', gap: 10}}>
					<Email style={{color: partnerTapPrimary}}/>
					<div style={{fontSize: 18, fontWeight: 'bold'}}>
						{emailTemplate.title}
					</div>
				</div>
				{emailTemplate.orderText &&
				 <div style={{fontSize: 16, fontWeight: 'bold'}}>
					 {emailTemplate.orderText}
				 </div>}
				<div style={{color: partnerTapInactive}}>
					{emailTemplate.sendPrerequisite}
				</div>
			</div>
			<div style={{display: 'flex', flexDirection: 'column', gap: 10, paddingLeft: 10, paddingRight: 10, textAlign: 'left'}}>
				<hr style={{height: 1, margin: 0}}/>
				<div style={{display: 'flex', alignItems: 'top'}}>
					<div style={{fontWeight: 'bold', minWidth: 60}}>
						To:
					</div>
					<div>
						{emailTemplate.to}
					</div>
				</div>
				<div style={{display: 'flex', alignItems: 'top'}}>
					<div style={{fontWeight: 'bold', minWidth: 60}}>
						From:
					</div>
					<div>
						{AuthHelper.userProfile.emailAddress}
					</div>
				</div>
				<div style={{
					display: 'flex',
					alignItems: 'top'
				}}>
					<div style={{display: 'flex', alignItems: 'center', fontWeight: 'bold', minWidth: 60}}>
						Subject:
					</div>
					<div style={{flex: 1}}>
						<TextInputBox
							hintText={'Subject'}
							value={subject}
							multiLine={true}
							maxChars={255}
							enableIntellisense={true}
							mergeFields={emailTemplate.mergeFieldsAllowed}
							isSimple={true}
							fontSize={14}
							onChange={onSubjectChange}
						/>
					</div>
				</div>
				<hr style={{height: 1, margin: 0}}/>
				<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
					<div style={{whiteSpace: 'pre-wrap'}}>
						{emailTemplate.intro}
					</div>
					{emailTemplate.customMessageEnabled &&
					 <TextInputBox
						 hintText={'Optional message'}
						 value={message}
						 multiLine={true}
						 fontSize={14}
						 maxChars={1000}
						 onChange={onMessageChange}
					 />
					}
					<div style={{whiteSpace: 'pre-wrap'}}>
						{emailTemplate.conclusion}
					</div>
					<div style={{whiteSpace: 'pre-wrap'}}>
						{emailTemplate.signature}
					</div>
				</div>
				<hr style={{height: 1, margin: 0}}/>
			</div>
		</div>
	);
};

export default EmailPreviewTile;
