import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PartnerStatusHelper from '../../../../helpers/PartnerStatusHelper';
import TableHelper from '../../../../helpers/TableHelper';
import {partnerTapPrimary} from '../../../../styles/partnertap_theme';
import Pic from '../../../../ui/Pic';
import {withRouter} from 'react-router-dom';
import {Routes} from '../../../../globals/Routes';

class PartnerConnectionTileRenderer extends Component {

	constructor(props, context) {
		super(props, context);
	}

	get isInviteButtonDisable() {
		return this.props.authState.profile.orgId === this.props.rowData.partnerOrganizationId;
	}

	render() {
		let {
			partnerOrganization,
			partnerOrganizationId,
			partnerName,
			personId,
			partnerId,
			ptJobTitle,
			partnerStatusMessage
		} = this.props.rowData;
		let isRecommendedPage = this.props.location.pathname === Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.RECOMMENDATIONS.ROUTE;
		let tileWidth = 250;
		console.log("this.props.authState", this.props.authState.profile.orgId);
		return (
			<div
				style={
					TableHelper.tileStyle(tileWidth, isRecommendedPage ? 300 : 350, partnerTapPrimary, isRecommendedPage ? 'default' : 'pointer')
				}>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<Pic orgId={partnerOrganizationId} picSize={40}/>
					<div style={{fontWeight: 'bold', paddingTop: 4, textTransform: 'capitalize'}}>
						{partnerOrganization.toLowerCase()}
					</div>
				</div>
				<hr style={{height: 1, width: '100%'}}/>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', whiteSpace: 'nowrap'}}>
					<Pic partnerId={partnerId} personId={personId} picSize={40} disableClick={isRecommendedPage}/>
					<div style={{color: partnerTapPrimary, paddingTop: 4, overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: tileWidth - 40}}>
						{partnerName}
					</div>
					<div style={{fontSize: 12, height: 20, overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: tileWidth - 40}}>
						{ptJobTitle}
					</div>
				</div>
				<hr style={{height: 1, width: '100%'}}/>
				{partnerStatusMessage}
				{PartnerStatusHelper.renderAction(this.props.rowData)}
			</div>
		);
	}
}

PartnerConnectionTileRenderer.propTypes = {
	rowData: PropTypes.shape({
		partnerOrganization: PropTypes.string.isRequired,
		partnerOrganizationId: PropTypes.string,
		partnerName: PropTypes.string,
		personId: PropTypes.string,
		partnerId: PropTypes.string,
		ptJobTitle: PropTypes.string,
		partnerStatus: PropTypes.string,
		partnerStatusMessage: PropTypes.object
	}).isRequired,
	authState: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(PartnerConnectionTileRenderer));
