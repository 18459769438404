import {Cancel, Edit, KeyboardArrowDown, KeyboardArrowRight, Save} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapAppBackground, partnerTapDefaultText, partnerTapInactive, partnerTapStroke} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import Dialog from '../../../ui/Dialog';

class AdminEditorSection extends Component {

	constructor(props, context) {
		super(props, context);

		this.openHeight = null;
		this.wasEditingSection = false;
		this.lastSectionValueCount = -1;
		this.wasEmptyMessage = -1;
		this.state = {editingSection: null, showSaveWarning: null, abandonEditsConfirmed: false};

		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.unmounted = true;
	}

	handleResize() {
		let {sectionName} = this.props;
		let element = document.getElementById(sectionName);
		if (element) {
			element.style.height = null;
			this.openHeight = null;
			this.setState({abandonEditsConfirmed: false});
		}
	}

	render() {
		let {sectionName, sectionValues, isOpen, isDisabled, viewFunction, closeFunction, editFunction, saveFunction, saveWarning, cancelFunction, displayCloseButton} = this.props;
		let {editingSection, showSaveWarning, abandonEditsConfirmed} = this.state;
		let isEditingSection = editFunction && saveFunction && cancelFunction && editingSection === sectionName;
		let wasClosedDuringEdit = this.wasEditingSection && !isOpen && !abandonEditsConfirmed;
		return (
			<div id={sectionName}
				 ref={(element) => {
					 if (element) {
						 let isEmptyMessage = sectionValues.length === 1 && !sectionValues[0].value;
						 if (this.wasEditingSection !== isEditingSection || this.lastSectionValueCount !== sectionValues.length || this.wasEmptyMessage !== isEmptyMessage) {
							 this.wasEditingSection = isEditingSection;
							 this.wasEmptyMessage = isEmptyMessage;
							 this.lastSectionValueCount = sectionValues.length;
							 this.handleResize();
						 }
						 else {
							 if (!this.openHeight && !isEditingSection) {
								 this.openHeight = (element.clientHeight - (isOpen ? 20 : 10)) + 'px';
							 }

							 if (isOpen) {
								 element.style.height = this.openHeight;
								 setTimeout(() => {
									 if (this.unmounted) return;
									 element.scrollIntoView({behavior: 'smooth', block: 'nearest'});
								 }, 210);
							 }
							 else {
								 element.style.height = '40px';
							 }
						 }
					 }
				 }}
				 style={{
					 display: 'flex',
					 flexDirection: 'column',
					 padding: 10,
					 marginTop: 10,
					 maxWidth: 600,
					 width: 'calc(100% - 40px)',
					 border: '1px solid ' + (isDisabled ? partnerTapInactive : 'black'),
					 borderRadius: 20,
					 backgroundColor: partnerTapAppBackground,
					 transition: 'height 0.2s ease-out'
				 }}>
				{wasClosedDuringEdit &&
				 <Dialog title={'Edit in progress'}
						 message={'You currently are editing ' + sectionName + '. Do you want to abandon any changes and open the new section?'}
						 yesLabel={'Open New Section'}
						 yesAction={() => {
							 if (cancelFunction) cancelFunction();
							 this.setState({abandonEditsConfirmed: true});
						 }}
						 noLabel={'Go Back'}
						 noAction={() => {
							 viewFunction();
							 editFunction();
						 }}/>}
				{showSaveWarning &&
				 <Dialog title={'Are you sure?'}
						 message={showSaveWarning}
						 yesLabel={'Save Changes'}
						 yesAction={() => {
							 saveFunction(() => this.setState({editingSection: null, showSaveWarning: null}));
						 }}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showSaveWarning: null})}/>}
				<div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: isOpen ? 10 : 0}}>
					<div style={{
						flex: 1,
						fontSize: 18,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						color: (isDisabled ? partnerTapInactive : partnerTapDefaultText)
					}}>
						{sectionName}
					</div>
					{isDisabled &&
					 <div style={{whiteSpace: 'nowrap', color: partnerTapInactive}}>
						 (Feature disabled)
					 </div>}
					{!isOpen ?
						<PrimaryButton label={'OPEN'}
									   icon={<KeyboardArrowRight/>}
									   disabled={isDisabled}
									   onClick={() => {
										   viewFunction();
										   this.setState({editingSection: null});
									   }}/>
						:
						isEditingSection ?
							<div style={{display: 'flex', gap: 10}}>
								<PrimaryButton label={'CANCEL'}
											   icon={<Cancel/>}
											   onClick={() => {
												   cancelFunction();
												   this.setState({editingSection: null});
											   }}/>
								<PrimaryButton label={'SAVE'}
											   icon={<Save/>}
											   onClick={() => {
												   if (saveWarning) {
													   this.setState({showSaveWarning: saveWarning});
												   }
												   else {
													   saveFunction(() => this.setState({editingSection: null, showSaveWarning: null}));
												   }
											   }}/>
							</div>
							:
							<div style={{display: 'flex', gap: 10}}>
								{Boolean(editFunction) &&
								 <PrimaryButton label={'EDIT'}
												icon={<Edit/>}
												onClick={() => {
													this.setState({editingSection: sectionName});
													editFunction();
												}}/>}
								{displayCloseButton && (
									<PrimaryButton label={'CLOSE'} icon={<KeyboardArrowDown />} onClick={closeFunction} />
								)}
							</div>}
				</div>
				{sectionValues.map((sectionValue, index) => {
					return (
						<div
							key={index}
							style={{
								visibility: isOpen ? 'visible' : 'hidden',
								opacity: isOpen ? 1 : 0,
								transition: isOpen ? '0.4s ease-in' : '0.1s ease-out',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								overflow: 'auto',
								minHeight: 50,
								padding: 5,
								paddingLeft: EnvHelper.isDesktop ? 10 : 0,
								paddingRight: EnvHelper.isDesktop ? 10 : 0,
								 borderTop: '1px solid ' + partnerTapStroke
							 }}>
							<div style={{fontSize: 16, fontWeight: 'bold', whiteSpace: 'nowrap', marginRight: 10}}>
								{sectionValue.name}
							</div>
							<div style={{ display: sectionValue.bodyText ? 'flex' : 'block' }}>
								{editingSection === sectionName ? sectionValue.editor : sectionValue.value}
							</div>
							{sectionValue.bodyText && (
								<div>
									{sectionValue.bodyText}
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	}
}

AdminEditorSection.defaultProps = {
	displayCloseButton: true,
};

AdminEditorSection.propTypes = {
	sectionName: PropTypes.string.isRequired,
	sectionValues: PropTypes.array.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool,
	viewFunction: PropTypes.func.isRequired,
	closeFunction: PropTypes.func.isRequired,
	editFunction: PropTypes.func,
	saveFunction: PropTypes.func,
	saveWarning: PropTypes.string,
	cancelFunction: PropTypes.func,
	displayCloseButton: PropTypes.bool
};

export default connect()(AdminEditorSection);