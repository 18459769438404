import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {AdminDistributeRecordsExternal} from '../co_sell_engine/admin_editors/AdminDistributeRecordsExternal';
import {AdminDistributeRecordsInternal} from '../co_sell_engine/admin_editors/AdminDistributeRecordsInternal';
import {AdminIntroEmail} from '../co_sell_engine/admin_editors/AdminIntroEmail';
import {queryClient} from '../../App';
import {fetchWorkflows} from '../../../endpoints/CoSellEngineEndpoints';
import {QueryKeys} from '../../../helpers/QueryClientHelper';
import {WORKFLOW_COSELL_PLAY, WORKFLOW_P2P, WORKFLOW_SALES_PLAY, WORKFLOW_THRU_CHANNEL_PLAY} from '../co_sell_engine/CoSellEngineHelperLive';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import DetailHeader from '../shared/DetailHeader';
import {VerifiedUser} from '@mui/icons-material';
import LinkButton from '../../../ui/buttons/LinkButton';
import EnvHelper from '../../../helpers/EnvHelper';
import {Routes} from '../../../globals/Routes';

export default class CoSellAdminPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			workflows: {},
			editor: null,
			matchedWorkflow: null
		};
	}

	async componentDidMount() {
		try {
			const workflows = await queryClient.fetchQuery(QueryKeys.ALL_WORKFLOWS, fetchWorkflows);
			const matchedWorkflow = Object.values(workflows).find(workflow =>
				workflow.workflowRecipePublicId === this.props.match.params.workflowRecipePublicId
			);
			this.setState({
				workflows: workflows,
				matchedWorkflow: matchedWorkflow,
				editor: this.getEditor(matchedWorkflow)
			});
		}
		catch {
			this.setState({editor: null});
		}
	}

	getEditor(matchedWorkflow) {
		if (!matchedWorkflow) return null;

		switch (matchedWorkflow.name) {
			case WORKFLOW_SALES_PLAY:
				return <AdminDistributeRecordsInternal workflowRecipeId={matchedWorkflow.workflowRecipePublicId} workflowName={matchedWorkflow.label}/>;
			case WORKFLOW_THRU_CHANNEL_PLAY:
				return <AdminDistributeRecordsExternal workflowRecipeId={matchedWorkflow.workflowRecipePublicId} workflowName={matchedWorkflow.label}/>;
			case WORKFLOW_COSELL_PLAY:
			case WORKFLOW_P2P:
				return <AdminIntroEmail workflowRecipeId={matchedWorkflow.workflowRecipePublicId} workflowName={matchedWorkflow.label}/>;
			default:
				return null;
		}
	}

	render() {
		const {workflows, editor, matchedWorkflow} = this.state;

		if (!workflows || !matchedWorkflow) {
			return (
				<div style={{flex: 1, display: 'flex', flexDirection: 'column', paddingTop: 10}}>
					Loading workflows...
				</div>
			);
		}

		return (
			<ScrollingContainer divId={'co_sell_admin_page'}>
				<DetailHeader
					MaterialIcon={VerifiedUser}
					title={`Co-Sell Automation: ${matchedWorkflow.label} Workflow`}
					detailRight={
						<LinkButton
							label={'Admin Center'}
							onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE)}
						/>
					}
				/>
				<div style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
					{editor ? editor : 'Invalid workflow configuration'}
				</div>
			</ScrollingContainer>
		);
	}
}

CoSellAdminPage.propTypes = {
	workflowConfig: PropTypes.string.isRequired,
	workflowRecipeId: PropTypes.string.isRequired
};