import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import salesforce_icon from '../../../static/images/crm-icon-salesforce.png';
import AuthHelper, {REDIRECT_PATH_SIGNUP, SFDC_SCOPE_OAUTH} from '../../helpers/AuthHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {salesforceColor} from '../../styles/partnertap_theme';
import TermsOfServiceDialog from '../TermsOfServiceDialog';
import PrimaryButton from './PrimaryButton';
import {Routes} from '../../globals/Routes';

class SalesforceButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showUserAgreementDialog: false, isTosChecked: false};
		this.onSalesForceButtonClick = this.onSalesForceButtonClick.bind(this);
		this.onUserAgreementDialogYes = this.onUserAgreementDialogYes.bind(this);
		this.onUserAgreementDialogNo = this.onUserAgreementDialogNo.bind(this);
	}

	onSalesForceButtonClick() {
		let {showTerms, disableLogin, registrationState, useSandbox, scope, onClick} = this.props;
		if (showTerms && !this.state.isTosChecked) {
			this.setState({showUserAgreementDialog: true});
		}
		else if (!disableLogin) {
			AuthHelper.salesforceLogin(registrationState.profileInformation.email, useSandbox, REDIRECT_PATH_SIGNUP, Routes.OAUTH.PATH, scope || SFDC_SCOPE_OAUTH);
		}

		if (onClick) {
			onClick();
		}
	}

	onUserAgreementDialogYes() {
		this.setState({isTosChecked: true, showUserAgreementDialog: false}, this.onSalesForceButtonClick);
	}

	onUserAgreementDialogNo() {
		this.setState({showUserAgreementDialog: false});
	}

	render() {
		return (
			<div>
				<PrimaryButton label={this.props.label}
							   color={salesforceColor}
							   icon={<img src={EnvHelper.serviceUrl + salesforce_icon} style={{height: 18}}/>}
							   onClick={this.onSalesForceButtonClick}
							   fullWidth={true}/>
				{this.state.showUserAgreementDialog &&
				 <TermsOfServiceDialog onUserAgreementDialogYes={this.onUserAgreementDialogYes} onUserAgreementDialogNo={this.onUserAgreementDialogNo}/>}
			</div>
		);
	}
}

SalesforceButton.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	useSandbox: PropTypes.bool,
	showTerms: PropTypes.bool,
	disableLogin: PropTypes.bool,
	registrationState: PropTypes.object.isRequired,
	scope: PropTypes.string.isRequired
};

function mapStateToProps(state) {
	return {
		registrationState: state.registrationState
	};
}

export default withRouter(connect(mapStateToProps)(SalesforceButton));
