import _ from 'lodash';
import React, {Component} from 'react';
import EnvHelper from '../../../../helpers/EnvHelper';
import StringHelper from '../../../../helpers/StringHelper';
import LinkButton from '../../../../ui/buttons/LinkButton';
import Loading from '../../../../ui/Loading';
import DashboardTable from '../../shared/DashboardTable';
import * as PropTypes from 'prop-types';
import {fetchWorkflowSequences} from '../../../../endpoints/CoSellEngineEndpoints';
import {queryClient} from '../../../App';
import {QueryKeys} from '../../../../helpers/QueryClientHelper';
import CoSellWorkflowEmailTemplateEditor from '../../admin_center/CoSellWorkflowEmailTemplateEditor';
import {partnerTapStroke, partnerTapWhite} from '../../../../styles/partnertap_theme';

const WORKFLOW_CONFIGS = {
	'Thru-Channel Play': {
		message: 'Use the Thru-Channel Play Workflow template editor to review and edit the email template. Click on a sequence to begin.',
		title: 'Standard thru-channel sequence',
	},
	'Co-Sell Play': {
		message: 'Use the Co-Sell Workflow template editor to review and edit the email templates for each co-sell workflow sequence. Click on a sequence to begin.',
		title: 'Standard co-sell sequences',
	},
	'Direct Sales Play': {
		message: 'Use the Sales Play Workflow template editor to review and edit the email template. Click on a sequence to begin.',
		title: 'Standard direct sales play sequence',
	},
	"P2P Play": {
		message: 'Use the P2P Play Workflow template editor to review and edit the email templates for each workflow sequence. Click on a sequence to begin.',
		title: 'Standard P2P co-sell sequences',
	}
};

export class AdminConfigBase extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			adminSequenceConfigs: null,
			editingAdminSequenceConfig: null,
		};

		this.getWorkflowConfigs = this.getWorkflowConfigs.bind(this);
		this.onEditAdminConfig = this.onEditAdminConfig.bind(this);
	}

	async componentDidMount() {
		await this.getWorkflowConfigs();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get columnData() {
		return [
			{
				title: 'Name',
				key: 'displayName',
				active: true,
				renderFunction: (columnValue, rowData) => <LinkButton label={columnValue} onClick={() => this.onEditAdminConfig(rowData)}/>
			},
			{title: 'Description', key: 'description', active: true}
		];
	}

	async getWorkflowConfigs() {
		const {workflowRecipeId} = this.props;
		try {
			const workflowSequences = await queryClient.fetchQuery(
				[QueryKeys.WORKFLOW_SEQUENCES, workflowRecipeId],
				() => fetchWorkflowSequences(workflowRecipeId)
			);
			if (this.unmounted) return;
			this.setState({loading: false, adminSequenceConfigs: workflowSequences.sequences});
		}
		catch (error) {
			EnvHelper.serverError('Error from getWorkflowConfigs', error);
		}
	}

	onEditAdminConfig(adminConfig) {
		this.setState({
			editingAdminSequenceConfig: _.clone(adminConfig),
		});
	}

	renderInfoBox() {
		const {workflowName} = this.props;
		const config = WORKFLOW_CONFIGS[workflowName];

		return (
			<div style={mainContainerStyle}>
				<div style={containerStyle}>
					<p>{config.message}</p>
				</div>
			</div>
		);
	}

	get title() {
		const {workflowName} = this.props;
		const config = WORKFLOW_CONFIGS[workflowName];
		return config.title;
	}

	render() {
		let {loading, adminSequenceConfigs, editingAdminSequenceConfig} = this.state;
		const {workflowName} = this.props;

		if (loading) return <Loading>Loading...</Loading>;

		return (
			<div style={{padding: 0, paddingTop: 0}}>
				{!editingAdminSequenceConfig && (
					<>
						{this.renderInfoBox()}
						<DashboardTable
							key={StringHelper.formatKey(workflowName)}
							title={this.title}
							columnData={this.columnData}
							rowData={adminSequenceConfigs}
							maxHeight={'calc(100vh - 190px)'}
							hasScrollingContainerParent={true}
							noMargins={true}
						/>
					</>
				)}

				{editingAdminSequenceConfig && (
					<CoSellWorkflowEmailTemplateEditor
						workflowType={workflowName}
						sequenceType={editingAdminSequenceConfig.displayName}
						sequenceDescription={editingAdminSequenceConfig.description}
						sequenceRecipePublicId={editingAdminSequenceConfig.sequenceRecipePublicId}
					/>
				)}
			</div>
		);
	}
}

// Styles
const containerStyle = {
	backgroundColor: partnerTapWhite,
	border: '1px solid ' + partnerTapStroke,
	borderRadius: 5,
	padding: '15px',
	textAlign: 'left',
	fontSize: '14px',
	lineHeight: '1.5',
};

const mainContainerStyle = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	paddingTop: '15px',
	paddingBottom: '15px',
};

AdminConfigBase.propTypes = {
	workflowRecipeId: PropTypes.string,
	workflowName: PropTypes.string
};
