import * as ApiHelper from '../helpers/ApiHelper';
import {MEDIA_TYPE_TEXT_CSV} from '../helpers/ApiHelper';

export function getCSVDownloadsList(page, pageSize, sort, search ) {
	return ApiHelper.sendGet(ApiHelper.appendPageableParams('/csv/downloadlist', page, pageSize, search, sort));
}

export function getCSVDownload(downloadId) {
	return ApiHelper.sendGetReport('/csv/download?downloadId=' + downloadId, null, MEDIA_TYPE_TEXT_CSV);
}
