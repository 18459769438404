import {CloudSync, Dataset, ForwardToInbox, HubOutlined, JoinLeftOutlined, NoteAddOutlined, UploadFile, AttachMoney} from '@mui/icons-material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import React, {Component} from 'react';
import * as DataManagerEndpoints from '../../../endpoints/DataManagerEndpoints';
import {
	BASE_OPPORTUNITIES_MANUAL,
	UPLOAD_DATA_MANAGER_ENRICH_SHEET,
	UPLOAD_DATA_MANAGER_SHEET,
	UPLOAD_FULL_SERVICE_CSV,
	UPLOAD_PII_SHARING_OPT_IN
} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO, tempFeatureFlag_UploadFullServiceCsv} from '../../../helpers/FeatureHelper';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import {PageHeader} from '../../../ui/PageHeader';
import Upsell from '../../../ui/Upsell';
import UploadHelper from '../../../helpers/UploadHelper';
import ExpandableMessage from '../../../ui/messages/ExpandableMessage';
import Tile from '../../../ui/Tile';

class DataManagerPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true};
		this.getCrmSync = this.getCrmSync.bind(this);
	}

	componentDidMount() {
		this.getCrmSync();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getCrmSync() {
		DataManagerEndpoints.syncSummary()
		.then((result) => {
			if (this.unmounted) return;
			this.buildModel(result.payload);
		})
		.catch((error) => {
			console.error('Error from getCrmSync', error);
		});
	}

	buildModel(crmSyncs) {
		let hasCrmSync = Array.isArray(crmSyncs) && crmSyncs?.length;
		let tileData = [
			{
				title: 'Add Accounts',
				icon: UploadFile,
				description: 'Add your CRM account data by importing CSV sheets',
				actionLabel: 'UPLOAD',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_DATA_MANAGER_SHEET)),
				actionLabel2: 'VIEW',
				actionFunction2: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(UPLOAD_DATA_MANAGER_SHEET))
			},
			{
				title: 'Enrich Accounts',
				icon: NoteAddOutlined,
				description: 'Enrich existing accounts with additional CSV sheets',
				actionLabel: 'UPLOAD',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_DATA_MANAGER_ENRICH_SHEET)),
				actionLabel2: 'VIEW',
				actionFunction2: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(UPLOAD_DATA_MANAGER_ENRICH_SHEET))
			},
			{
				title: 'Add Opportunities',
				icon: AttachMoney,
				description: 'Add your CRM opportunity data by importing CSV sheets',
				actionLabel: 'UPLOAD',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(BASE_OPPORTUNITIES_MANUAL)),
				actionLabel2: 'VIEW',
				actionFunction2: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(BASE_OPPORTUNITIES_MANUAL))
			},
		];

		if (!hasCrmSync && !DataManagerHelper.crmTypeSynced) {
			tileData.unshift({
				title: 'Connect CRM',
				icon: CloudSync,
				description: 'Connect and sync CRM data',
				actionLabel: 'CONNECT',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH())
			});
		}
		else {
			tileData.unshift({
				title: 'CRM Sync Summary',
				icon: CloudSync,
				description: 'View your CRM connection details and sync history',
				actionLabel: 'VIEW',
				actionFunction: () => {
					if (!hasCrmSync && DataManagerHelper.crmTypeSynced) {
						EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_3.PATH(DataManagerHelper.crmTypeSynced));
					}
					else {
						EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE);
					}
				}
			});
		}

		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO)) {
			tileData.push({
				title: 'Upload PII',
				icon: GppGoodIcon,
				description: 'Upload a CSV of opt ins for consent to share PII',
				actionLabel: 'UPLOAD',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_PII_SHARING_OPT_IN))
			});
		}

		if (tempFeatureFlag_UploadFullServiceCsv) {
			tileData.push({
				title: 'Full Service CSV',
				icon: ForwardToInbox,
				description: 'Send us a CSV with data from any source',
				actionLabel: 'SEND',
				actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_FULL_SERVICE_CSV))
			});
		}

		tileData.push({
			title: 'My Accounts',
			icon: JoinLeftOutlined,
			description: 'View accounts from all account sources',
			actionLabel: 'VIEW',
			actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.ROUTE)
		});

		tileData.push({
			title: 'My Opportunities',
			icon: HubOutlined,
			description: 'View opportunities from all account sources',
			actionLabel: 'VIEW',
			actionFunction: () => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_OPPORTUNITIES.ROUTE)
		});

		this.setState({tileData: tileData, loading: false});
	}
	render() {
		if (this.state.loading) return <Loading>Loading...</Loading>;
		let {tileData, showUpsellDialog, showUpsellWithSupport} = this.state;
		return (
			<ScrollingContainer divId={'data_manager_overview'}>
				<div style={{width: '100%'}}>
					<PageHeader title={'Data Manager'} icon={<Dataset fontSize={'large'}/>}/>
				</div>
				<ExpandableMessage message={UploadHelper.getUploadHelp(UPLOAD_DATA_MANAGER_SHEET, -1)}/>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', overflow: 'scroll'}}>
					{tileData.map((tileData) => <React.Fragment key={tileData.title}> <Tile {...tileData}/> </React.Fragment>)}
				</div>
				{showUpsellDialog &&
				 <Dialog title={showUpsellWithSupport ? 'Channel Admin Required' : 'Upgrade Required'}
						 message={<Upsell feature={'Channel Ecosystem'} headline={showUpsellDialog} showSupportButton={showUpsellWithSupport}/>}
						 yesAction={() => this.setState({showUpsellDialog: null, showUpsellWithSupport: false})}
						 noContentPadding={true}/>}
				{this.renderError}
			</ScrollingContainer>
		);
	}
}

export default DataManagerPage;
