import {partnerTapInactive, partnerTapPrimary} from '../../../../../styles/partnertap_theme';
import TextInputBox from '../../../../../ui/TextInputBox';
import TableHelper from '../../../../../helpers/TableHelper';
import React, {useEffect, useState} from 'react';
import {Telegram} from '@material-ui/icons';

const LeadTile = ({
	onStepComplete,
	template,
	setCustomMessage
}) => {
	const [body, setMessage] = useState(template.body || "");

	useEffect(() => {
		setCustomMessage(template.stepRecipePublicId, body);
	}, [body]);

	return (
		<div style={{
			...TableHelper.tileStyle(480, 500, partnerTapPrimary, 10),
			height: null, justifyContent: null, paddingBottom: 20
		}}>
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10}}>
				<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', gap: 10, flexDirection: 'row'}}>
					<Telegram style={{color: partnerTapPrimary}}/>
					<div style={{fontSize: 18, fontWeight: 'bold'}}>
						New Lead Description
					</div>
				</div>
				<div style={{color: partnerTapInactive}}>
					{template.sendPrerequisite}
				</div>
			</div>
			<div style={{display: 'flex', flexDirection: 'column', gap: 10, paddingLeft: 10, paddingRight: 10, textAlign: 'left', width: '100%'}}>
				<hr style={{height: 1, margin: 0}}/>
				<TextInputBox
					hintText={'Body'}
					value={body}
					multiLine={true}
					maxChars={5000}
					enableIntellisense={true}
					mergeFields={template.mergeFieldsAllowed}
					fontSize={14}
					onChange={(text) => {
						setMessage(text);
						onStepComplete(true);
					}}
				/>
				<hr style={{height: 1, margin: 0}}/>
			</div>
		</div>
	);
};

export default LeadTile;
