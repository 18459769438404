import React, {Component, Fragment} from 'react';
import LinkButton from '../../../../ui/buttons/LinkButton';
import * as PropTypes from 'prop-types';
import {
	BASE_OPPORTUNITIES_MANUAL,
	UPLOAD_DATA_MANAGER_ENRICH_SHEET,
	UPLOAD_DATA_MANAGER_SHEET,
	UPLOAD_PARTNER_SHEET,
	UPLOAD_PII_SHARING_OPT_IN
} from '../../../../globals/Enums';
import {fileDownload} from '../../../../helpers/CsvHelper';
import FeatureHelper, {FEATURE_CHANNEL_UPLOAD_PARTNER_SHEETS} from '../../../../helpers/FeatureHelper';
import UploadHelper from '../../../../helpers/UploadHelper';

class CsvBestPractices extends Component {

	get uploadType() {
		return this.props.uploadType;
	}

	get isPartnerSheet() {
		return this.uploadType === UPLOAD_PARTNER_SHEET;
	}

	get isDataManagerSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_SHEET;
	}

	get isDataManagerEnrichSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_ENRICH_SHEET;
	}

	get isBaseOpportunitiesManual() {
		return this.uploadType === BASE_OPPORTUNITIES_MANUAL;
	}

	get isPiiOptIn() {
		return this.uploadType === UPLOAD_PII_SHARING_OPT_IN;
	}

	render() {
		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 20}}>

				{(this.isPartnerSheet && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_UPLOAD_PARTNER_SHEETS)) &&
				 <div style={{paddingTop: 10}}>
					 With the free version of PartnerTap you can upload up to five partner account sheets.
					 You can still connect directly with your partners, but you will need to upgrade to upload more sheets.
				 </div>}

				<div style={{display: 'flex', gap: 80, padding: 10, textAlign: 'left'}}>
					<div>
						<div style={{fontWeight: 'bold', marginBottom: 10}}>
							CSV UPLOAD BEST PRACTICES
						</div>
						<ul style={{marginLeft: 20}}>
							<li style={{marginBottom: 10}}>Save your {UploadHelper.uploadCsvLabel(this.uploadType)} data as a CSV file.</li>
							<li style={{marginBottom: 10}}>Make sure your sheet does not have multiple tabs.</li>
							<li style={{marginBottom: 10}}>Make sure every column has a named column header.</li>
							{(this.isPartnerSheet || this.isDataManagerSheet) &&
							 <Fragment>
								 <li style={{marginBottom: 10}}>Include required columns:</li>
								 <ul style={{marginLeft: 20}}>
									 <li style={{marginBottom: 10}}>Account Name</li>
									 <li style={{marginBottom: 10}}>Account Type (e.g. Customer or Prospect)</li>
								 </ul>
								 {this.isDataManagerSheet &&
								  <Fragment>
									  <li style={{marginBottom: 10}}>Include recommended columns:</li>
									  <ul style={{marginLeft: 20}}>
										  <li style={{marginBottom: 10}}>Unique account identifier (e.g. CRM ID, PRM ID, DUNS)</li>
									  </ul>
								  </Fragment>}
							 </Fragment>}
							{(this.isDataManagerEnrichSheet) &&
							 <Fragment>
								 <li style={{marginBottom: 10}}>Include required column:</li>
								 <ul style={{marginLeft: 20}}>
									 <li style={{marginBottom: 10}}>Unique account identifier (e.g. CRM ID, PRM ID, DUNS)</li>
								 </ul>
								 <li style={{marginBottom: 10}}>Include recommended columns:</li>
								 <ul style={{marginLeft: 20}}>
									 <li style={{marginBottom: 10}}>Account Name</li>
									 <li style={{marginBottom: 10}}>Account Type (e.g. Customer or Prospect)</li>
								 </ul>
							 </Fragment>}
							{(this.isBaseOpportunitiesManual) &&
							 <Fragment>
								 <li style={{marginBottom: 10}}>Include required columns:</li>
								 <ul style={{marginLeft: 20}}>
									 <li style={{marginBottom: 10}}>Account ID</li>
									 <li style={{marginBottom: 10}}>Opportunity Name</li>
									 <li style={{marginBottom: 10}}>Opportunity ID</li>
									 <li style={{marginBottom: 10}}>Opportunity Stage</li>
									 <li style={{marginBottom: 10}}>Created Date</li>
									 <li style={{marginBottom: 10}}>Is Won</li>
									 <li style={{marginBottom: 10}}>Is Closed</li>
								 </ul>
								 <li style={{marginBottom: 10}}>Include recommended columns:</li>
								 <ul style={{marginLeft: 20}}>
									 <li style={{marginBottom: 10}}>Closed date, probability, amount, owner</li>
									 <li style={{marginBottom: 10}}>Any opportunity fields you want to filter by</li>
									 <li style={{marginBottom: 10}}>Any other unique opportunity identifier (e.g. Deal reg ID)</li>
								 </ul>
							 </Fragment>}
							{this.isDataManagerSheet &&
							 <li style={{marginBottom: 10}}>Include as much account data as possible.</li>}
						</ul>
					</div>
					<div>
						<div style={{fontWeight: 'bold', marginBottom: 10}}>
							ADDITIONAL RESOURCES
						</div>
						<div style={{marginLeft: 10}}>
							{!this.isPiiOptIn &&
							 <div style={{marginBottom: 10}}>
								 <LinkButton
									 label={'Review our CSV guide'}
									 onClick={() => {
										 const url = this.isBaseOpportunitiesManual
											 ? 'https://partnertap.com/wp-content/uploads/2025/02/Updated-PartnerTap-CSV-Oppty-Upload-Guide-2025.pdf'
											 : 'https://partnertap.com/wp-content/uploads/2024/04/Updated-PartnerTap-CSV-Upload-Guide-2024.pdf';

										 window.open(url, '_blank');
									 }}
								 />
							 </div>}
							{(this.isPartnerSheet || this.isDataManagerSheet) &&
							 <div style={{marginBottom: 10}}>
								 <LinkButton label={'Check out this help article'}
											 onClick={() => {
												 window.open(this.isPartnerSheet ?
													 'https://partnertap.com/help/how-to-upload-partner-spreadsheets' :
													 'https://partnertap.com/help/how-to-add-your-data-to-partnertap', '_blank');
											 }}/>
							 </div>}
							<div style={{marginBottom: 10}}>
								<LinkButton label={'Download a sample sheet'}
											onClick={() => {
										const fileName = this.isPiiOptIn
											? 'PartnerTap_PII_Optin_Sample_Sheet.csv'
											: this.isBaseOpportunitiesManual
												? 'baseOpportunitiesManualTemplate_samplesheet.csv'
												: 'channelMappingUploadTemplate_samplesheet.csv';
										fileDownload(fileName);
									}}
								/>
							</div>
							<div style={{marginBottom: 10}}>
								<LinkButton label={'Contact support'} onClick={() => window.open('https://partnertap.com/support')}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CsvBestPractices.propTypes = {
	uploadType: PropTypes.string.isRequired
};

export default CsvBestPractices;
