import {ArrowBack} from '@mui/icons-material';
import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {getActionListManualRecipients, updateActionListManualRecipients} from '../../../endpoints/CoSellEngineEndpoints';
import * as CoSellEngineHelperLive from './CoSellEngineHelperLive';
import {
	ACTION_LIST_COLUMNS,
	ACTION_LIST_REPORT_TYPE,
	emailConfig,
	WORKFLOW_COSELL_PLAY,
	WORKFLOW_CRM_TRACKING,
	WORKFLOW_LEAD,
	WORKFLOW_P2P,
	WORKFLOW_SALES_PLAY,
	WORKFLOW_THRU_CHANNEL_PLAY
} from './CoSellEngineHelperLive';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapAppBackground, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import {WorkflowIntroEmailLive} from './workflow_editors/WorkflowIntroEmailLive';
import {WorkflowDistributeRecordsInternalLive} from './workflow_editors/WorkflowDistributeRecordsInternalLive';
import {WorkflowDistributeRecordsExternalLive} from './workflow_editors/WorkflowDistributeRecordsExternalLive';
import {WorkflowCrmTrackingLive} from './workflow_editors/WorkflowCrmTrackingLive';
import Stepper from '../../../ui/Stepper';
import {Routes} from '../../../globals/Routes';
import TableHelper from '../../../helpers/TableHelper';
import EmailWorkflowEditorLive from './workflow_editors/EmailWorkflowEditorLive';
import MessageBoxAlert from '../../../ui/messages/MessageBoxAlert';
import P2pWorkflowSteps, {p2pSteps} from './workflow_editors/P2pWorkflowSteps';
import FeatureHelper from '../../../helpers/FeatureHelper';
import {CO_SELL_CONCIERGE} from '../../../globals/Enums';
import LeadWorkflowSteps, {leadSteps} from './workflow_editors/LeadWorkflowSteps';

class CoSellWorkflowConfigLive extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			error: false,
			saving: false,
			newFlow: false,
			editor: null,
			steps: null,
			currentConfigStep: 0,
			summaryData: null,
			actionListMetadata: null,
			radioGroupValue: '',
			emailCustomizations: [],
			recipients: [],
			manualFields: {
				manualRecipient1Name: '',
				manualRecipient1Email: '',
				manualRecipient1Title: '',
				manualRecipient2Name: '',
				manualRecipient2Email: '',
				manualRecipient2Title: ''
			},
			crmTrackingOption: '',
			nudges: []
		};

		this.updateSteps = this.updateSteps.bind(this);
		this.renderConfigStep = this.renderConfigStep.bind(this);
		this.renderConfirmConfig = this.renderConfirmConfig.bind(this);
		this.renderSteppingButtons = this.renderSteppingButtons.bind(this);
		this.gotoPreviousStep = this.gotoPreviousStep.bind(this);
		this.gotoNextStep = this.gotoNextStep.bind(this);
		this.completeFinalStep = this.completeFinalStep.bind(this);
		this.getWorkflowSummary = this.getWorkflowSummary.bind(this);
		this.startWorkflow = this.startWorkflow.bind(this);
		this.cancelConfig = this.cancelConfig.bind(this);
		this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get cosellActionListMetadataPublicId() {
		return this.props.match.params.actionListId;

	}

	get isActionListUsed() {
		let {actionListMetadata} = this.state;
		return actionListMetadata.activeWorkflow;
	}

	get sequenceRecipePublicId() {
		return this.props.match.params.sequenceRecipePublicId;
	}

	getEditor(sequenceInfo) {
		switch (sequenceInfo.workflowName) {
			case WORKFLOW_COSELL_PLAY:
				return new WorkflowIntroEmailLive(WORKFLOW_COSELL_PLAY);
			case WORKFLOW_P2P:
				return {steps: p2pSteps, newFlow: true, sequence: WORKFLOW_P2P};
			case WORKFLOW_SALES_PLAY:
				return new WorkflowDistributeRecordsInternalLive();
			case WORKFLOW_THRU_CHANNEL_PLAY:
				return new WorkflowDistributeRecordsExternalLive();
			case WORKFLOW_CRM_TRACKING:
				return new WorkflowCrmTrackingLive();
			case WORKFLOW_LEAD:
				return {steps: leadSteps, newFlow: true, sequence: WORKFLOW_LEAD};
		}
		return null;
	}

	async init() {
		try {
			const [actionListMetadata, workflowSequenceTemplate, actionListManualRecipients] = await Promise.all([
				CoSellEngineEndpoints.getCoSellActionListMetadata(this.cosellActionListMetadataPublicId),
				CoSellEngineEndpoints.getActionListWorkflowSequenceTemplates(this.sequenceRecipePublicId),
				getActionListManualRecipients(this.cosellActionListMetadataPublicId)
			]);
			if (this.unmounted || !actionListMetadata || !workflowSequenceTemplate || !actionListManualRecipients) return;
			let {templates, sequenceInfo} = workflowSequenceTemplate.payload;
			templates.sort((a, b) => a.order > b.order ? 1 : -1);
			let editor = this.getEditor(sequenceInfo);
			this.setState({
				actionListMetadata: actionListMetadata.payload,
				loading: false,
				newFlow: !!editor.newFlow,
				templates: templates,
				emailCustomizations: templates.map(template => ({
					stepRecipePublicId: template.stepRecipePublicId,
					customSubject: template.subject || "",
					customMessage: ""
				})),
				editor: editor,
				steps: editor.steps,
				sequenceInfo: sequenceInfo
			});

			this.getManualRecipients(actionListManualRecipients);
		}
		catch (error) {
			this.setState({loading: false, error: true});
			EnvHelper.serverError('Error from workflow config init ', error);
		}
	}

	getWorkflowSummary() {
		this.updateManualRecipientColumns();

		CoSellEngineEndpoints.getWorkflowSummaryData(
			this.cosellActionListMetadataPublicId,
			emailConfig.emailWorkflow.ownerColumn.emailColumn,
			emailConfig.emailWorkflow.ownerColumn.nameColumn,
			emailConfig.emailWorkflow.partnerColumn.emailColumn,
			emailConfig.emailWorkflow.partnerColumn.nameColumn,
			this.sequenceRecipePublicId,
			this.state.recipients
		)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({summaryData: result.payload});
		})
		.catch((error) => {
			this.setState({summaryDataError: error});
			EnvHelper.serverError('Error from getWorkflowSummaryData', error);
		});
	}

	startWorkflow() {
		const {sequenceInfo} = this.state;
		const {workflowName} = sequenceInfo;
		this.setState({saving: true});
		emailConfig.actionListMetadataPublicId = this.cosellActionListMetadataPublicId;

		if (workflowName !== WORKFLOW_P2P) {
			this.updateManualRecipientColumns();
		}

		const updatedEmailConfig = this.buildEmailConfig(workflowName);

		CoSellEngineEndpoints.startWorkflow(this.sequenceRecipePublicId, updatedEmailConfig)
		.then((result) => {
			if (this.unmounted) return;
			CoSellEngineHelperLive.clearEmailConfig();
			this.completeFinalStep();
			this.setState({saving: false});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from startWorkflow', error);
		});
	}

	updateManualRecipientColumns() {
		const isRecipient1Manual = emailConfig.emailWorkflow.isRecipient1FromActionList === false;
		const isRecipient2Manual = emailConfig.emailWorkflow.isRecipient2FromActionList === false;

		if (isRecipient1Manual) {
			emailConfig.emailWorkflow.ownerColumn = {
				emailColumn: ACTION_LIST_COLUMNS.manualRecipient1.email,
				nameColumn: ACTION_LIST_COLUMNS.manualRecipient1.name,
				titleColumn: ACTION_LIST_COLUMNS.manualRecipient1.title
			};
		}

		if (isRecipient2Manual) {
			emailConfig.emailWorkflow.partnerColumn = {
				emailColumn: ACTION_LIST_COLUMNS.manualRecipient2.email,
				nameColumn: ACTION_LIST_COLUMNS.manualRecipient2.name,
				titleColumn: ACTION_LIST_COLUMNS.manualRecipient2.title
			};
		}
	}

	buildEmailConfig(workflowName) {
		if (workflowName !== WORKFLOW_P2P) {
			return emailConfig;
		}
		const {recipients, crmTrackingOption, nudges, emailCustomizations} = this.state;
		return {
			actionListMetadataPublicId: this.cosellActionListMetadataPublicId,
			emailWorkflow: {
				isRecipient1FromActionList: !!recipients[0]?.id?.includes('manual'),
				isRecipient2FromActionList: !!recipients[1]?.id?.includes('manual'),
				customizations: emailCustomizations,
				nudge: nudges,
				crmTrackingOption: crmTrackingOption,
				recipients: recipients
			}
		};
	}

	updateSteps(currentConfigStep) {
		let {steps} = this.state;
		steps.forEach((step, index) => {
			step.active = index === currentConfigStep;
			step.complete = index < currentConfigStep;
		});
		this.setState({currentConfigStep: currentConfigStep});
	}

	updateCustomization(stepRecipePublicId, key, value) {
		// Optional cleanup for specific fields
		if (key === 'customSubject') {
			value = value.replace(/[\r\n]+/g, '');
		}

		this.setState(prevState => {
			// Find the current customization in the previous state
			const currentCustomization = prevState.emailCustomizations.find(
				customization => customization.stepRecipePublicId === stepRecipePublicId
			);

			// Create a new customization object with the updated key-value pair
			const customization = {
				...currentCustomization,
				stepRecipePublicId,
				[key]: value
			};

			// Return new state with updated customizations array
			return {
				emailCustomizations: [
					...prevState.emailCustomizations.filter(
						c => c.stepRecipePublicId !== stepRecipePublicId
					),
					customization
				]
			};
		});
	}

	onSelectRecipient(position, value) {
		let {ownerOrgPublicId} = this.state.actionListMetadata.ownerOrg;
		this.setState(prevState => {
			const newRecipients = [...prevState.recipients];
			newRecipients[position] = value;
			if (value.partnerOrgPublicId === ownerOrgPublicId) {
				newRecipients[position]['ownerOrgPublicId'] = ownerOrgPublicId;
				newRecipients[position]['partnerOrgPublicId'] = null;
			}
			else {
				newRecipients[position]['ownerOrgPublicId'] = null;
			}
			return {recipients: newRecipients};
		});
	}

	renderNewFlowSequence(workflow) {
		let {actionListMetadata, sequenceInfo} = this.state;
		let {summaryData, summaryDataError} = this.state;
		let {templates, editor, currentConfigStep} = this.state;
		const {workflowName, sequenceName} = sequenceInfo;
		if (workflow.sequence === WORKFLOW_LEAD) {
			return <LeadWorkflowSteps
				actionListMetadata={actionListMetadata}
				currentStep={currentConfigStep}
				workflowName={workflowName}
				templates={templates}
				sequenceName={sequenceName}
				setCustomMessage={(stepRecipePublicId, value) => this.updateCustomization(stepRecipePublicId, 'customMessage', value)}
				onStepComplete={(isConfigStepComplete = true) => {
					this.setState({isConfigStepComplete: isConfigStepComplete});
				}}
				summaryData={summaryData}
			/>;
		}
		else {
			return <P2pWorkflowSteps
				actionListMetadata={actionListMetadata}
				currentStep={currentConfigStep}
				workflowName={workflowName}
				templates={templates}
				sequenceName={sequenceName}
				onSelectRecipient1={(value) => this.onSelectRecipient(0, value)}
				onSelectRecipient2={(value) => this.onSelectRecipient(1, value)}
				setCustomMessage={(stepRecipePublicId, value) => this.updateCustomization(stepRecipePublicId, 'customMessage', value)}
				setCustomSubject={(stepRecipePublicId, value) => this.updateCustomization(stepRecipePublicId, 'customSubject', value)}
				onCrmTrackingChange={(value) => this.setState({crmTrackingOption: value})}
				currentCrmOptions={this.state.crmTrackingOption}
				onNudgeChange={(value) => this.setState({nudges: value})}
				onStepComplete={(isConfigStepComplete = true) => {
					this.setState({isConfigStepComplete: isConfigStepComplete});
				}}
				summaryData={summaryData}
				manualFields={this.state.manualFields}
				setManualFields={(manualFields) => {
					this.setState({manualFields: manualFields});
				}}
			/>;
		}
	}

	renderConfigStep() {
		let {actionListMetadata, sequenceInfo} = this.state;
		let {summaryData, summaryDataError} = this.state;
		let {templates, editor, currentConfigStep} = this.state;
		const {workflowName, sequenceName} = sequenceInfo;

		if (this.isConfirmStep) {
			if (!summaryData && !summaryDataError) {
				this.getWorkflowSummary();
				if (!editor.newFlow) {
					return <Loading>Loading summary...</Loading>;
				}
			}
			if (!editor.newFlow) {
				return this.renderConfirmConfig();
			}
		}
		let isExpired = actionListMetadata.expirationOnDate < (new Date().getTime() / 1000);
		let expiredWarning;
		if (isExpired && currentConfigStep === 0) {
			expiredWarning =
				<MessageBoxAlert title={'This action list has expired'}>
					To ensure you have the most up-to-date data for your workflow, we recommend cloning this action list or creating a new one.
				</MessageBoxAlert>;
		}
		return (
			<Fragment>
				{expiredWarning}
				{editor.newFlow ? this.renderNewFlowSequence(editor)
					: <EmailWorkflowEditorLive actionListMetadata={actionListMetadata}
											   sequenceInfo={sequenceInfo}
											   templates={templates}
											   workflowEditor={editor}
											   currentConfigStep={currentConfigStep}
											   onStepComplete={(isConfigStepComplete = true) => {
												   this.setState({isConfigStepComplete: isConfigStepComplete});
											   }}
											   onRadioGroupChange={this.handleRadioGroupChange}
					/>}
			</Fragment>
		);
	}

	renderConfirmConfig() {
		let {editor, summaryData, sequenceInfo} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
				<div style={TableHelper.boxStyle}>
					<div style={{fontSize: 20}}>
						{sequenceInfo.displayName}
					</div>
					{summaryData ?
						<div style={{display: 'flex', flexDirection: 'column'}}>
							{editor.renderSummaryData(summaryData)}
							<div style={{marginTop: 20}}>
								<div style={{fontSize: 18}}>
									Are you ready to start this workflow?
								</div>
								<div>
									Starting this workflow will lock your action list. You will no longer be able to make any changes to this list or use
									this list for any other workflows.
								</div>
							</div>
						</div>
						:
						<div>Sorry, summary data is unavailable</div>}
				</div>
			</div>
		);
	}

	renderSteppingButtons() {
		let {currentConfigStep, isConfigStepComplete, summaryData} = this.state;
		const isRecipientInformationComplete = this.isRecipientInformationComplete;
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				gap: 20,
				padding: 10,
				background: partnerTapWhite,
				borderTop: '1px solid ' + partnerTapStroke,
				borderBottom: '1px solid ' + partnerTapStroke
			}}>
				{currentConfigStep === 0 &&
				 <SecondaryButton label={'CANCEL'} onClick={this.cancelConfig}/>}
				{(currentConfigStep > 0 && !this.isActionListUsed) &&
				 <SecondaryButton label={'BACK'} icon={<ArrowBack/>} onClick={this.gotoPreviousStep}/>}
				{this.isConfirmStep ?
					<PrimaryButton label={this.isActionListUsed ? 'DONE' : 'START WORKFLOW'}
								   disabled={!summaryData || (EnvHelper.isSpoofing && FeatureHelper.roleCode !== CO_SELL_CONCIERGE)}
								   onClick={this.isActionListUsed ? this.cancelConfig : this.startWorkflow}/> :
					<PrimaryButton label={currentConfigStep === 0 ? 'SAVE & CONTINUE' : 'CONTINUE'}
								   disabled={!isConfigStepComplete || !isRecipientInformationComplete}
								   onClick={this.gotoNextStep}/>}
			</div>
		);
	}

	handleRadioGroupChange(value) {
		this.setState({radioGroupValue: value});
	}

	cancelConfig() {
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH(this.cosellActionListMetadataPublicId, ACTION_LIST_REPORT_TYPE));
	}

	gotoPreviousStep() {
		let {currentConfigStep} = this.state;
		this.updateSteps(currentConfigStep - 1);
	}

	gotoNextStep() {
		let {currentConfigStep, newFlow} = this.state;

		if (currentConfigStep === 0) {
			this.saveManualRecipients();
		}
		else {
			this.updateSteps(currentConfigStep + 1);
		}
	}

	getManualRecipients(actionListManualRecipients) {
		const isRecipientFromActionList = (name, email) => !(name || email);

		const recipientPayload = actionListManualRecipients.payload;
		emailConfig.emailWorkflow.isRecipient1FromActionList = isRecipientFromActionList(recipientPayload.manualRecipient1Name, recipientPayload.manualRecipient1Email);
		emailConfig.emailWorkflow.isRecipient2FromActionList = isRecipientFromActionList(recipientPayload.manualRecipient2Name, recipientPayload.manualRecipient2Email);

		emailConfig.emailWorkflow.manualRecipient1 = {
			name: recipientPayload.manualRecipient1Name,
			email: recipientPayload.manualRecipient1Email,
			title: recipientPayload.manualRecipient1Title
		};

		emailConfig.emailWorkflow.manualRecipient2 = {
			name: recipientPayload.manualRecipient2Name,
			email: recipientPayload.manualRecipient2Email,
			title: recipientPayload.manualRecipient2Title
		};
	}

	async saveManualRecipients() {
		const {currentConfigStep, newFlow, manualFields} = this.state;
		const requestBody = {
			...manualFields
		};

		if (!newFlow) {
			const {
				manualRecipient1: {name: recipient1Name, email: recipient1Email, title: recipient1Title} = {},
				manualRecipient2: {name: recipient2Name, email: recipient2Email, title: recipient2Title} = {},
			} = emailConfig.emailWorkflow;
			requestBody.manualRecipient1Name = recipient1Name || '';
			requestBody.manualRecipient1Email = recipient1Email || '';
			requestBody.manualRecipient1Title = recipient1Title || '';
			requestBody.manualRecipient2Name = recipient2Name || '';
			requestBody.manualRecipient2Email = recipient2Email || '';
			requestBody.manualRecipient2Title = recipient2Title || '';
		}

		try {
			if (!EnvHelper.isSpoofing || FeatureHelper.roleCode === CO_SELL_CONCIERGE) {
				await updateActionListManualRecipients(this.cosellActionListMetadataPublicId, requestBody);
			}
			this.updateSteps(currentConfigStep + 1);
		}
		catch (error) {
			EnvHelper.serverError('Error from saveManualRecipients', error);
		}
	}

	get isConfirmStep() {
		let {steps, currentConfigStep} = this.state;
		return currentConfigStep === steps?.length - 1;
	}

	completeFinalStep() {
		let {steps} = this.state;
		let lastStep = steps[steps.length - 1];
		lastStep.active = false;
		lastStep.complete = true;
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH(this.cosellActionListMetadataPublicId, ACTION_LIST_REPORT_TYPE));
	}

	get isRecipientInformationComplete() {
		const {sequenceInfo} = this.state;
		const {workflowName} = sequenceInfo;
		const {
			ownerColumn,
			partnerColumn,
			manualRecipient1,
			manualRecipient2,
			isRecipient1FromActionList,
			isRecipient2FromActionList
		} = emailConfig.emailWorkflow;

		const isRecipientRequired = (workflowNames) => workflowNames.includes(workflowName);

		const isRecipientComplete = (isRequired, columnData, manualData, isRecipientFromActionList) => {
			const {emailColumn, nameColumn} = columnData;
			const {name, email} = manualData;

			return !isRequired || (isRecipientFromActionList ? !!(emailColumn && nameColumn) : !!(name && email));
		};

		const isRecipient1Complete = () => isRecipientComplete(
			isRecipientRequired([WORKFLOW_COSELL_PLAY, WORKFLOW_SALES_PLAY]),
			ownerColumn,
			manualRecipient1,
			isRecipient1FromActionList
		);

		const isRecipient2Complete = () => isRecipientComplete(
			isRecipientRequired([WORKFLOW_COSELL_PLAY, WORKFLOW_THRU_CHANNEL_PLAY]),
			partnerColumn,
			manualRecipient2,
			isRecipient2FromActionList
		);
		return (isRecipient1Complete() && isRecipient2Complete());
	}

	render() {
		let {loading, error, steps, saving} = this.state;
		if (loading) return <Loading>Loading config...</Loading>;
		if (error) return <MessageBoxAlert>There was an unexpected error loading this workflow.</MessageBoxAlert>;
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				{saving &&
				 <ScrimMessage message={'Saving changes...'}/>}
				<ScrollingContainer divId={'co_sell_configuration'}>
					<Stepper steps={steps}/>
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 10,
						padding: 20,
						backgroundColor: partnerTapAppBackground,
						overflow: 'scroll'
					}}>
						{this.renderConfigStep()}
					</div>
					{this.renderSteppingButtons()}
				</ScrollingContainer>
			</div>
		);
	}
}

export default withRouter(CoSellWorkflowConfigLive);
