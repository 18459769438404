import React from 'react';

export class WorkflowCrmTrackingLive {

	constructor() {
	}

	get steps() {
		return [];
	}

	validate() {
		return true;
	}

	renderSummaryData(summaryData) {
		return (
			<div>
				Total CRM Activities: {summaryData?.totalActionListAccounts}
			</div>
		);
	}
}
