import {PersonPin} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {STATUS_ACTIVE, STATUS_INACTIVE, STATUS_PREREGISTERED, STATUS_TERMINATED, SUPERADMIN, CO_SELL_CONCIERGE} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import AuthHelper from '../../helpers/AuthHelper';
import {ACTIONS} from '../../helpers/ColumnHelper';
import EnvHelper from '../../helpers/EnvHelper';
import TableHelper from '../../helpers/TableHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import Dialog from '../../ui/Dialog';
import PagingBase from '../../ui/lists/PagingBase';
import ScrimMessage from '../../ui/messages/ScrimMessage';
import PopoverSearchList from '../../ui/PopoverSearchList';
import OrgSelector from './shared/OrgSelector';
import LinkButton from '../../ui/buttons/LinkButton';

class AdminUsersPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.selectedOrg = null;
		this.state.filterSelectorFunction = AdminEndpoints.fetchUserFilterData;
		this.state.filterSelectorMounted = false;
		this.state.allRoles = null;
		this.state.showUpdateWarning = false;
		this.state.errorMessage = null;

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
		this.handleSpoofUser = this.handleSpoofUser.bind(this);
		this.setUserStatus = this.setUserStatus.bind(this);
	}

	handleOrgSelection(org) {
		this.fetchUserColumns(org);
	}

	get storageKeyBase() {
		return 'admin_users';
	}

	get title() {
		return 'All Users';
	}

	get icon() {
		return PersonPin;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	fetchUserColumns(org) {
		AdminEndpoints.fetchUserColumns()
		.then((result) => {
			if (this.unmounted) return;
			let joinMeUriColumn = result.payload.find((row) => row.key === 'joinMeUri');
			if (joinMeUriColumn) {
				joinMeUriColumn.active = true;
				joinMeUriColumn.sortDisabled = true;
				joinMeUriColumn.renderFunction = (columnValue, rowData) => {
					if (!rowData) return columnValue;
					if (rowData.status !== STATUS_TERMINATED && rowData.productCode) {
						let joinMeUri = EnvHelper.webUrl + '/#' + Routes.REGISTRATION.PATH(rowData.productCode, Routes.REGISTRATION.PROFILE.PAGE_NAME, org.orgId, org.orgCode, rowData.personId);
						return TableHelper.copyRenderer(this, joinMeUri, rowData.personId);
					}
					return '-';
				};
			}

			let roleCodeColumn = result.payload.find((row) => row.key === 'roleCode');
			if (roleCodeColumn) {
				roleCodeColumn.renderFunction = (columnValue, rowData) => {
					if (!rowData) return columnValue;
					if (rowData.roleCode !== SUPERADMIN && rowData.roleCode !== CO_SELL_CONCIERGE && rowData.status !== STATUS_TERMINATED) {
						let {allRoles} = this.state;
						const filteredRoles = allRoles.filter(role => role.roleCode !== CO_SELL_CONCIERGE);
						return <PopoverSearchList label={'Select Role'}
												  list={filteredRoles}
												  preselectedItem={filteredRoles.find((role) => role.roleCode === rowData.roleCode)}
												  labelRenderer={(role) => role.displayName}
												  searchByObjectKeys={['displayName']}
												  onItemSelected={(role) => this.handleUpdateRole(rowData, role)}
												  maxWidth={240}/>;
					}
					return columnValue;
				};
			}

			result.payload.push({
				title: 'Actions',
				key: ACTIONS,
				renderFunction: (columnValue, rowData) => {
					let actionButtons = [];
					if (rowData.status === STATUS_TERMINATED) {
						actionButtons.push(<LinkButton key={'activate_user'}
													   label={'ACTIVATE'}
													   disabled={rowData.roleCode === CO_SELL_CONCIERGE}
													   onClick={() => this.setUserStatus(rowData.personId, STATUS_ACTIVE)}/>);
					}
					else if (rowData.status !== STATUS_INACTIVE && rowData.status !== STATUS_PREREGISTERED) {
						actionButtons.push(<LinkButton key={'spoof_login'}
													   label={'SPOOF'}
													   disabled={EnvHelper.isSpoofing || rowData.roleCode === SUPERADMIN}
													   onClick={() => this.handleSpoofUser(rowData)}/>);
						actionButtons.push(<LinkButton key={'terminate_user'}
													   label={'TERMINATE'}
													   disabled={rowData.roleCode === CO_SELL_CONCIERGE}
													   onClick={() => this.setUserStatus(rowData.personId, STATUS_TERMINATED)}
													   confirmationMessage={'Are you sure you want to terminate this user?'}/>);
					}
					return (
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
							{actionButtons}
						</div>
					);
				}
			});

			this.initColumnData(result.payload, false);
			this.fetchRoles(org);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchUserColumns', error);
		});
	}

	fetchRoles(org) {
		Promise.all([AdminEndpoints.fetchRoles(), AdminEndpoints.fetchRoles(org.orgId)])
		.then((results) => {
			if (this.unmounted) return;
			let standardRoles = results[0].payload.filter((role) => role.roleCode !== SUPERADMIN);
			let customRoles = results[1].payload;
			this.setState({allRoles: [...standardRoles, ...customRoles], selectedOrg: org, loading: true});
		})
		.catch((error) => {
			console.error('Error fetching roles', error);
			this.setState({errorMessage: 'Failed to fetching roles: ' + error.message});
		});
	}

	setUserStatus(personId, status) {
		AdminEndpoints.updateUserProfile(personId, null, status)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				this.setState({loading: true});
			}
		});
	}

	handleUpdateRole(person, role) {
		this.setState({showUpdateWarning: true, updatePerson: person, updateRole: role});
	}

	updateProfile(personId, roleCode) {
		AdminEndpoints.updateUserProfile(personId, roleCode)
		.then(() => {
			if (this.unmounted) return;
			this.setState({loading: true});
		});
	}

	handleSpoofUser(personData) {
		if (personData.personId === this.props.authState.person.id) {
			this.setState({errorMessage: 'No spoofing yourself, ' + this.props.authState.profile.firstName + '.'});
		}
		else {
			this.setState({spoofingPersonData: personData});
			setTimeout(() => AuthHelper.spoofUser(personData.personId), 1000);
		}
	}

	get filterSelectorArgs() {
		return {orgId: this.state.selectedOrg.orgId, isPreReg: false};
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let {selectedOrg} = this.state;
		let params = this.processParameters();
		AdminEndpoints.fetchUserProfiles(selectedOrg.orgId, params.search, params.filters, params.page, params.pageSize, params.sort)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		let {selectedOrg, showUpdateWarning, updatePerson, updateRole, forceUpdateKey, spoofingPersonData} = this.state;
		return (
			<div>
				{this.state.errorMessage &&
				 <Dialog title={'Error Message'} message={this.state.errorMessage} yesAction={() => this.setState({errorMessage: null})}/>}
				{spoofingPersonData &&
				 <ScrimMessage message={'Spoofing login for ' + spoofingPersonData.firstName + ' ' + spoofingPersonData.lastName + '...'}/>}
				{showUpdateWarning &&
				 <Dialog title={'Please Confirm'}
						 message={'Change the role for user ' + updatePerson.firstName + ' ' + updatePerson.lastName + ' to ' + updateRole.displayName + '?'}
						 yesAction={() => {
							 this.updateProfile(updatePerson.personId, updateRole.roleCode);
							 this.setState({showUpdateWarning: false});
						 }}
						 noAction={() => {
							 this.setState({forceUpdateKey: forceUpdateKey + 1, showUpdateWarning: false});
						 }}/>}
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: partnerTapWhite}}>
					<OrgSelector onOrgSelection={this.handleOrgSelection}/>
				</div>
				{Boolean(selectedOrg) &&
				 super.render()}
			</div>
		);
	}
}

AdminUsersPage.propTypes = {
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(AdminUsersPage);