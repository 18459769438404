import {
	INVALID_MERGED_FIELDS,
	SHEET_ERROR_BLANK_HEADERS,
	SHEET_ERROR_FORBIDDEN_HEADERS,
	SHEET_ERROR_MISSING_MANDATORY_SAMPLES,
	SHEET_ERROR_OVERSIZED_HEADERS
} from './UploadHelper';
import {partnerTapInactive} from '../styles/partnertap_theme';
import React from 'react';
import SheetErrorTable from '../app/channel_ecosystem/shared/sheets/SheetErrorTable';
import {Report} from '@mui/icons-material';
import LinkButton from '../ui/buttons/LinkButton';

const supportMessage =
	<div style={{fontSize: 14}}>
		If you are unable to resolve the issue, please <LinkButton label={'contact support'}
																   onClick={() => window.open('https://partnertap.com/support')}/>
	</div>;

export default class ErrorMessageHelper {

	static renderErrorMessage(errorMessage) {
		let errorContent = <div/>;
		try {
			const errorJson = JSON.parse(errorMessage);
			if (errorJson.httpStatus) {
				const messages = errorJson.error.message.split("|");
				errorContent =
					<div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
						<div style={{fontSize: 16, maxWidth: 600, wordWrap: 'break-word'}}>
							{messages[0]}
						</div>
						{this.handleRichErrors(errorJson)}
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							{supportMessage}
							<div style={{fontSize: 12, color: partnerTapInactive}}>
								Issue ID: {errorJson.uuid}
							</div>
						</div>
					</div>;
			}
		}
		catch (e) {
			errorContent =
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20}}>
					<div style={{fontSize: 16, maxWidth: 600, wordWrap: 'break-word'}}>
						{errorMessage}
					</div>
					{supportMessage}
				</div>;
		}
		return errorContent;
	}

	static handleRichErrors(errorJson) {
		if (errorJson.error.type === SHEET_ERROR_BLANK_HEADERS || errorJson.error.type === SHEET_ERROR_MISSING_MANDATORY_SAMPLES) {
			return <SheetErrorTable errorType={errorJson.error.type} errorData={errorJson.error.data}/>;
		}
		if (errorJson.error.type === SHEET_ERROR_OVERSIZED_HEADERS ||
			errorJson.error.type === SHEET_ERROR_FORBIDDEN_HEADERS ||
			errorJson.error.type === INVALID_MERGED_FIELDS) {

			const afterDataMsg = errorJson.error.type === SHEET_ERROR_FORBIDDEN_HEADERS ? errorJson.error.message.split('|')[1] : "";
			return (
				<div>
					{errorJson.error.data.map((errorItem, index) => {
						return (
							<div key={index} style={{display: 'flex', alignItems: 'center', padding: 10, gap: 10, fontWeight: 'bold'}}>
								<Report/>
								<div>{errorItem}</div>
							</div>
						);
					})}
					<div style={{fontSize: 16, maxWidth: 600, wordWrap: 'break-word'}}>
						{afterDataMsg}
					</div>
				</div>
			);
		}
	}

	static isMissingHeaderError(errorMessage) {
		try {
			const errorJson = JSON.parse(errorMessage);
			return errorJson.error.type === SHEET_ERROR_BLANK_HEADERS;
		}
		catch (e) {
			return false;
		}
	}
}