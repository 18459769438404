import * as ApiHelper from '../helpers/ApiHelper';

export function getAdminPartnerSheets(page, pageSize, search, sort) {
	let endPoint = `/rest-admin-center/partnersheet?page=${page}`;
	if (pageSize) endPoint += '&size=' + pageSize;
	if (search) endPoint += '&search=' + search;
	if (sort) endPoint += '&sort=' + sort;
	return ApiHelper.sendGet(endPoint);
}

export function reassignPartnerOrgs(payload) {
	return ApiHelper.sendPut('/rest-admin-center/partnersheet', payload);
}

export function deletePartnerOrgs(payload) {
	return ApiHelper.sendPost('/rest-admin-center/delete-partnersheet', payload);
}

export function getCustomMetadata() {
	return ApiHelper.sendGet('/rest-admin-center/custom-columns');
}

export function updateCustomMetadata(payload) {
	return ApiHelper.sendPut('/rest-admin-center/update-custom-columns', payload);
}

export function createContactGroup(payload) {
	return ApiHelper.sendPost('/rest-admin-center/contact-group', payload);
}

export function deleteContactGroup(payload) {
	let queryParams = new URLSearchParams();
	payload.contactGroupUUIDs.forEach(uuid => queryParams.append('contactGroupUUIDs', uuid));
	queryParams.append('assetType', payload.assetType);

	return ApiHelper.sendDelete(`/rest-admin-center/contact-group?${queryParams.toString()}`);
}
