import {partnerTapPrimary} from '../../../../../styles/partnertap_theme';
import {ContactMail} from '@mui/icons-material';
import TableHelper from '../../../../../helpers/TableHelper';
import * as CoSellEngineHelperLive from '../../CoSellEngineHelperLive';
import React from 'react';

const RecipientTile = ({
	children,
	recipientType,
	toOrFrom,
	workflowName,
}) => {
	return (
		<div style={{
			...TableHelper.tileStyle(400, 260, partnerTapPrimary, 10),
			justifyContent: 'top',
			textAlign: 'left',
			height: null,
		}}>
			<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
				<ContactMail style={{color: partnerTapPrimary}}/>
				<div style={{fontSize: 18, fontWeight: 'bold'}}>
					{CoSellEngineHelperLive.getRecipientDisplayName(recipientType, workflowName)}
				</div>
			</div>
			<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', textAlign: 'left', gap: 10}}>
				<div style={{fontWeight: 'bold', paddingTop: 10, minWidth: 50}}>
					{toOrFrom}:
				</div>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'top', alignItems: 'left', textAlign: 'left'}}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default RecipientTile;
