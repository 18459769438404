const regexDecimal = /\./g;
const regexInsertCommas = /\B(?=(\d{3})+(?!\d))/g;
const regexNonNumeric = /[^-^\d^\.\s]/ig;

export default class NumberHelper {

	static formatCurrency(value, isMin = false, isMax = false) {
		let formattedNumber = this.formatNumber(value, isMin, isMax);
		if (formattedNumber) return '$' + formattedNumber;
		return '';
	}

	static formatNumber(value, isMin = false, isMax = false) {
		if (value === 0) return '0';
		value = this.roundNumber(value, 2, isMin, isMax);
		if (!value) return '0';
		value = String(value).replace(regexInsertCommas, ',');
		if (value.indexOf('.') !== -1) {
			let numbersToTheRightOfDecimal = value.split('.')[1];
			switch (numbersToTheRightOfDecimal.length) {
				case 0:
					return value.replace(regexDecimal, '');
				case 1:
					return value + '0';
			}
		}
		return value;
	}

	static roundNumber(value, numberOfDecimalPlaces = 2, isMin = false, isMax = false) {
		if (!value || value === 0 || value === '0') return 0;
		if (typeof value === 'string') {
			value = parseFloat(this.ensureNumeric(value));
			if (isNaN(value)) return 0;
		}
		let roundingFunc = this.getRoundingFunction(isMin, isMax);
		return roundingFunc(value * Math.pow(10, numberOfDecimalPlaces)) / Math.pow(10, numberOfDecimalPlaces);
	}

	static getRoundingFunction(isMin = false, isMax = false) {
		if(isMin) {
			return Math.floor;
		}
		else if(isMax) {
			return Math.ceil;
		}
		else {
			return Math.round;
		}
	}

	static ensureNumeric(value) {
		return String(value).replace(regexNonNumeric, '');
	}

	static ordinalSuffix(value) {
		let j = value % 10;
		let k = value % 100;
		if (j === 1 && k !== 11) return value + 'st';
		if (j === 2 && k !== 12) return value + 'nd';
		if (j === 3 && k !== 13) return value + 'rd';
		return value + 'th';
	}

	static shortNumber(num) {
		if (num >= 1_000_000) {
			return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
		} else if (num >= 1_000) {
			return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
		} else {
			return Math.round(num).toString();
		}
	}

	static estimatedNumber(num) {
		if (num >= 1_000_000) {
			return (num / 1_000_000).toFixed(0).replace(/\.0$/, '') + 'M +';
		} else if (num >= 1_000) {
			return (num / 1_000).toFixed(0).replace(/\.0$/, '') + 'k';
		} else {
			return Math.round(num).toString();
		}
	}
}
