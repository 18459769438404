export const flowsConfig = {
	// Flow for product selection
	"product-selection": {
		steps: [
			{
				id: "region",
				title: "Which region(s) do you want to generate for?",
				items: [
					{ id: "AMER", text: "AMER" },
					{ id: "EMEA", text: "EMEA" },
					{ id: "APAC", text: "Marketing Cloud" },
					{ id: "LATAM", text: "LATAM" },
				]
			},
			{
				id: "products",
				title: "Which product(s) do you want to generate for?",
				items: [
					{ id: "sales-cloud", text: "Sales Cloud" },
					{ id: "service-cloud", text: "Service Cloud" },
					{ id: "marketing-cloud", text: "Marketing Cloud" },
					{ id: "data-cloud", text: "Data Cloud" },
					{ id: "ai", text: "AI" }
				]
			},
			{
				id: "territory",
				title: "Which territory do you own? ",
				items: [
					{ id: "AMER – COMML – TMT – NE1", text: "AMER – COMML – TMT – NE1" },
					{ id: "AMER – COMML – TMT – NE2", text: "AMER – COMML – TMT – NE2" },
					{ id: "AMER – COMML – TMT – SE1", text: "AMER – COMML – TMT – SE1" },
					{ id: "AMER – COMML – TMT – SE2 ", text: "AMER – COMML – TMT – SE2 " },
				]
			},
		]
	},
	// Flow for partner selection
	"partner-selection": {
		steps: [
			{
				id: "region",
				title: "Which region(s) do you want to generate for?",
				items: [
					{ id: "AMER", text: "AMER" },
					{ id: "EMEA", text: "EMEA" },
					{ id: "APAC", text: "Marketing Cloud" },
					{ id: "LATAM", text: "LATAM" },
				]
			},
			{
				id: "products",
				title: "Which product(s) do you want to generate for?",
				items: [
					{ id: "sales-cloud", text: "Sales Cloud" },
					{ id: "service-cloud", text: "Service Cloud" },
					{ id: "marketing-cloud", text: "Marketing Cloud" },
					{ id: "data-cloud", text: "Data Cloud" },
					{ id: "ai", text: "AI" }
				]
			},
			{
				id: "territory",
				title: "Which territory do you own? ",
				items: [
					{ id: "AMER – COMML – TMT – NE1", text: "AMER – COMML – TMT – NE1" },
					{ id: "AMER – COMML – TMT – NE2", text: "AMER – COMML – TMT – NE2" },
					{ id: "AMER – COMML – TMT – SE1", text: "AMER – COMML – TMT – SE1" },
					{ id: "AMER – COMML – TMT – SE2 ", text: "AMER – COMML – TMT – SE2 " },
				]
			},
		]

	},
	// Flow for account selection
	"account-selection": {
		steps: [
			{
				id: "region",
				title: "Which region(s) do you want to generate for?",
				items: [
					{ id: "AMER", text: "AMER" },
					{ id: "EMEA", text: "EMEA" },
					{ id: "APAC", text: "Marketing Cloud" },
					{ id: "LATAM", text: "LATAM" },
				]
			},
			{
				id: "products",
				title: "Which product(s) do you want to generate for?",
				items: [
					{ id: "sales-cloud", text: "Sales Cloud" },
					{ id: "service-cloud", text: "Service Cloud" },
					{ id: "marketing-cloud", text: "Marketing Cloud" },
					{ id: "data-cloud", text: "Data Cloud" },
					{ id: "ai", text: "AI" }
				]
			},
			{
				id: "territory",
				title: "Which territory do you own? ",
				items: [
					{ id: "AMER – COMML – TMT – NE1", text: "AMER – COMML – TMT – NE1" },
					{ id: "AMER – COMML – TMT – NE2", text: "AMER – COMML – TMT – NE2" },
					{ id: "AMER – COMML – TMT – SE1", text: "AMER – COMML – TMT – SE1" },
					{ id: "AMER – COMML – TMT – SE2 ", text: "AMER – COMML – TMT – SE2 " },
				]
			},
		]

	}
};