import {ArrowDropDown, ArrowDropUp, DragIndicator} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ColumnHelper from '../../helpers/ColumnHelper';
import StringHelper from '../../helpers/StringHelper';
import TableHelper from '../../helpers/TableHelper';
import {partnerTapPrimary, partnerTapSecondary, partnerTapTernary, partnerTapWhite} from '../../styles/partnertap_theme';
import CheckboxButton from '../buttons/CheckboxButton';
import PagingTableColumnSizer from './PagingTableColumnSizer';
import ToolTipOverlay from '../ToolTipOverlay';

let selectedForOrdinalList = [];

class PagingTableColumnHeader extends Component {

	static get selectedForOrdinal() {
		return selectedForOrdinalList;
	}

	constructor(props, context) {
		super(props, context);

		this.onClick = this.onClick.bind(this);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.setColumnWidth = this.setColumnWidth.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onClick() {
		let {column, isForColumnSelector} = this.props;
		if (isForColumnSelector) {
			column.selectedForOrdinal = !column.selectedForOrdinal;
			let indexInList = PagingTableColumnHeader.selectedForOrdinal.indexOf(column);
			if (indexInList === -1 && column.selectedForOrdinal) {
				PagingTableColumnHeader.selectedForOrdinal.push(column);
			}
			else if (indexInList !== -1 && !column.selectedForOrdinal) {
				PagingTableColumnHeader.selectedForOrdinal.splice(indexInList, 1);
			}
			this.forceUpdate();
			return;
		}

		if (!this.props.onColumnSortChanged || TableHelper.blockSortOnResize) return;
		if (ColumnHelper.isSortable(column)) {
			if (column.sort === 'asc') {
				column.sort = 'desc';
			}
			else if (column.sort === 'desc') {
				column.sort = 'asc';
			}
			else {
				column.sort = 'asc';
			}
			this.props.onColumnSortChanged(column);
		}
	}

	onDragStart(e) {
		if (!this.props.onColumnOrderChanged) return;
		TableHelper.dragTable = this.props.tableKey;
		TableHelper.dragColumn = this.props.column;
		e.dataTransfer.effectAllowed = 'move';
	}

	onDrag() {
		if (TableHelper.dropColumnDirty) {
			TableHelper.dropColumnDirty = false;
			if (this.props.onColumnOrderChanged) this.props.onColumnOrderChanged();
		}
	}

	onDragOver(event) {
		event.preventDefault(); // to allow a drop, we must prevent the default handling
		let column = this.props.placeholderColumn || this.props.column;
		if (!TableHelper.isDropColumn(column) && TableHelper.dragColumn && column.key !== TableHelper.dragColumn.key && TableHelper.dragTable === this.props.tableKey) {
			TableHelper.dropColumnDirty = true;
			TableHelper.dropColumn = column;
		}
	}

	onDragLeave() {
		TableHelper.dropColumnDirty = Boolean(TableHelper.dropColumn);
		TableHelper.dropColumn = null;
	}

	onDrop(event) {
		event.preventDefault(); // to allow a drop, we must prevent the default handling
		let {columnData, isForColumnSelector, onColumnOrderChanged} = this.props;
		let haveDragAndDrop = TableHelper.dragColumn && TableHelper.dropColumn;
		if (haveDragAndDrop && TableHelper.dragColumn.index !== TableHelper.dropColumn.index) {
			let targetIndex = TableHelper.dropColumn.index;
			if (!isForColumnSelector || PagingTableColumnHeader.selectedForOrdinal.indexOf(TableHelper.dragColumn) === -1) {
				columnData.splice(TableHelper.dragColumn.index, 1);
				columnData.splice(targetIndex, 0, TableHelper.dragColumn);
				TableHelper.updateColumnOrderAttributes(columnData, isForColumnSelector);
				targetIndex = TableHelper.dragColumn.index;
			}

			if (isForColumnSelector) {
				PagingTableColumnHeader.selectedForOrdinal.forEach((column) => {
					columnData.splice(column.index, 1);
					columnData.splice(targetIndex, 0, column);
					TableHelper.updateColumnOrderAttributes(columnData, isForColumnSelector);
					targetIndex++;
				});
			}
		}

		TableHelper.dragTable = null;
		TableHelper.dragColumn = null;
		TableHelper.dropColumn = null;

		if (haveDragAndDrop && onColumnOrderChanged) {
			onColumnOrderChanged(true);
		}

		return false;
	}

	setColumnWidth() {
		let {column, isForColumnSelector} = this.props;
		if (!column.width) {
			let element = document.getElementById(TableHelper.columnHeaderKey(this.props.tableKey, column, isForColumnSelector));
			if (element) {
				let minWidth = element.clientHeight * 2;
				if (element.clientWidth > minWidth) {
					column.width = element.clientWidth;
					column.enforceMinWidth = false;
				}
				else {
					column.width = minWidth;
					column.enforceMinWidth = true;
				}
				this.props.onColumnWidthChanged();
			}
		}
	}

	renderForTable() {
		let {column} = this.props;
		let isColumnSortable = ColumnHelper.isSortable(column);
		let isColumnResizeable = ColumnHelper.isResizeable(column);
		let sortArrowColor = {...ColumnHelper.columnHeaderControlStyle};
		if (column.sort) sortArrowColor.opacity = 1;
		let isDraggable = Boolean(this.props.onColumnOrderChanged);
		return (
			<div style={{
				flex: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: 5,
				marginLeft: 5,
				marginRight: 5,
				cursor: isDraggable ? 'grab' : 'default'
			}}>
				{isColumnSortable && this.props.onColumnSortChanged ?
					<div style={{cursor: 'pointer', ...sortArrowColor}}>
						{column.sort === 'asc' ? <ArrowDropUp fontSize={'large'}/> : <ArrowDropDown fontSize={'large'}/>}
					</div>
					:
					<div style={{minWidth: 20}}/>}
				{column.title}
				{isColumnResizeable ?
					<PagingTableColumnSizer tableKey={this.props.tableKey} column={column} onColumnWidthChanged={this.props.onColumnWidthChanged}/> :
					<div style={{minWidth: 20}}/>}
			</div>
		);
	}

	renderForColumnSelector() {
		let {column, placeholderColumn} = this.props;
		let ordinal = placeholderColumn ? placeholderColumn.ordinal : column.ordinal;
		if (TableHelper.dragColumn && TableHelper.dropColumn) {
			if (TableHelper.dragColumn.index < TableHelper.dropColumn.index) {
				if (column.index > TableHelper.dragColumn.index && column.index <= TableHelper.dropColumn.index) {
					ordinal--;
				}
			}
			else {
				if (column.index < TableHelper.dragColumn.index && column.index >= TableHelper.dropColumn.index) {
					ordinal++;
				}
			}
		}

		return (
			<ToolTipOverlay title={<div style={{fontSize: 14}}>{column.title}</div>} placement={'left'}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					fontWeight: 'bold',
					color: partnerTapWhite,
					background: column.isPartnerData ? partnerTapTernary : partnerTapPrimary,
					border: 'solid 1px ' + partnerTapWhite,
					borderRadius: 2,
					cursor: 'grab',
					opacity: placeholderColumn ? '0.5' : '1'
				}}>
					<div style={{backgroundColor: partnerTapWhite, margin: 1}}>
						<CheckboxButton checked={ColumnHelper.isVisible(column)}
										onChange={() => {
											column.activeInverted = !column.activeInverted;
											if (this.props.onColumnActiveChanged) this.props.onColumnActiveChanged();
										}}
										onClick={(e) => {
											e.stopPropagation(); // so column is not selected by this click
											this.forceUpdate(); // so checkboxes update immediately
										}}
										inputProps={{'data-cy': 'column_checkbox_' + StringHelper.formatKey(column.key)}}/>

					</div>
					<div style={{
						display: 'block',
						width: '100%',
						padding: 5,
						paddingLeft: 10,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}>
						{column.title}
					</div>
					<div style={{paddingRight: 5, paddingLeft: 10, ...ColumnHelper.columnHeaderControlStyle}}>{ordinal}</div>
					<DragIndicator style={{paddingRight: 5, ...ColumnHelper.columnHeaderControlStyle}}/>
				</div>
			</ToolTipOverlay>
		);
	}

	render() {
		let {column, isForColumnSelector, placeholderColumn} = this.props;
		let isDraggable = Boolean(this.props.onColumnOrderChanged || isForColumnSelector);
		let style = {};
		if (isForColumnSelector) {
			style = {padding: 1, maxWidth: '40vw'};
			if (!placeholderColumn) {
				if (TableHelper.dropColumn && TableHelper.isDragColumn(column)) {
					style.width = 0;
					style.visibility = 'hidden';
				}
				else if (column.selectedForOrdinal) {
					style.background = partnerTapSecondary;
					style.borderRadius = 5;
					style.width = 'auto';
				}
			}
		}

		let divId = TableHelper.columnHeaderKey(this.props.tableKey, column, isForColumnSelector) + (placeholderColumn ? '_placeholder' : '');
		return (
			<div ref={(element) => {
				if (element && !isForColumnSelector) {
					this.setColumnWidth();
				}
			}}
				 id={divId}
				 style={style}
				 draggable={isDraggable}
				 onDragStart={this.onDragStart}
				 onDrag={this.onDrag}
				 onDragOver={this.onDragOver}
				 onDragLeave={this.onDragLeave}
				 onDrop={this.onDrop}
				 onClick={this.onClick}>
				{isForColumnSelector ? this.renderForColumnSelector() : this.renderForTable()}
			</div>
		);
	}
}

PagingTableColumnHeader.propTypes = {
	tableKey: PropTypes.string.isRequired,
	column: PropTypes.object.isRequired,
	columnData: PropTypes.array.isRequired,
	onColumnWidthChanged: PropTypes.func,
	onColumnSortChanged: PropTypes.func,
	onColumnActiveChanged: PropTypes.func,
	onColumnOrderChanged: PropTypes.func,
	isForColumnSelector: PropTypes.bool,
	widthLargestColumnHeader: PropTypes.number,
	placeholderColumn: PropTypes.object
};

export default PagingTableColumnHeader;
