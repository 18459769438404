import {ChecklistRtl, Diversity3} from '@mui/icons-material';
import React from 'react';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {fetchWorkflows} from '../../../endpoints/CoSellEngineEndpoints';
import {Routes} from '../../../globals/Routes';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapSecondary} from '../../../styles/partnertap_theme';
import LinkButton from '../../../ui/buttons/LinkButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import Dialog from '../../../ui/Dialog';
import PagingBase from '../../../ui/lists/PagingBase';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import PageHeader from '../../../ui/PageHeader';
import PopoverSearchList from '../../../ui/PopoverSearchList';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import * as CoSellEngineHelperLive from './CoSellEngineHelperLive';
import {ACTION_LIST_REPORT_TYPE, canUserEditActionLists} from './CoSellEngineHelperLive';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import TextInputBox from '../../../ui/TextInputBox';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import ReportHelper from '../../../helpers/ReportHelper';
import MessageBox from '../../../ui/messages/MessageBox';
import FeatureHelper, {PRODUCT_CO_SELL_ENGINE} from '../../../helpers/FeatureHelper';
import * as PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import DynamicIcon from '../../../ui/DynamicIcon';
import {queryClient} from '../../App';
import {QueryKeys} from '../../../helpers/QueryClientHelper';
import PersistenceHelper from '../../../helpers/PersistenceHelper';
import {MAX_PAGE_SIZE} from '../../../helpers/PageableHelper';

const ACTION_LIST_FILTER_ALL = 'All';
const ACTION_LIST_FILTER_READY = 'Ready';
const ACTION_LIST_FILTER_STOPPED = 'Stopped';
const ACTION_LIST_FILTER_COMPLETE = 'Complete';

class CoSellOverviewPageLive extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.actionListFilter = ACTION_LIST_FILTER_ALL;
		this.state.showDialogDeleteActionList = null;
		this.state.showDialogWorkflow = null;
		this.state.showDialogCloneActionList = null;
		this.state.actionListToClone = null;
		this.state.workflows = {};
		this.state.fetchStatus = "loading";
		this.state.workflowNotAvailableError = null;

		this.cloneCoSellActionList = this.cloneCoSellActionList.bind(this);
		this.filterActionLists = this.filterActionLists.bind(this);
		this.cancelWorkflowSelection = this.cancelWorkflowSelection.bind(this);

		this.selectActionList = this.selectActionList.bind(this);
		this.getActionListWorkflowSequences = this.getActionListWorkflowSequences.bind(this);
		this.selectSequence = this.selectSequence.bind(this);
		this.goToActionListConfig = this.goToActionListConfig.bind(this);
	}

	async componentDidMount() {
		try {
			const workflows = await queryClient.fetchQuery(QueryKeys.ALL_WORKFLOWS, fetchWorkflows);
			this.setState({
				workflows: workflows,
				fetchStatus: "done"
			});
		}
		catch {
			this.setState({fetchStatus: "error"});
		}
	}

	get storageKeyBase() {
		return 'co_sell_engine';
	}

	get title() {
		return 'Action Lists';
	}

	get icon() {
		return ChecklistRtl;
	}

	get initPageSizeData() {
		let storedPageSize = PersistenceHelper.getValue(this.pageSizeStorageKey);
		return storedPageSize ? parseInt(storedPageSize) : MAX_PAGE_SIZE;
	}

	get componentAboveToolbar() {
		let tileStyle = TableHelper.tileStyle(250, 250, partnerTapSecondary, 10, 'pointer');
		tileStyle.margin = 5;

		let {workflows} = this.state;

		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<div style={{width: '100%'}}>
					<PageHeader title={'Co-Sell Engine'} icon={<Diversity3 fontSize={'large'}/>}/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', marginBottom: 10}}>
					{Object.values(workflows || {}).map((navTile, index) => {
						let actionFunction = () => {
							CoSellEngineHelperLive.clearEmailConfig();
							if (!navTile.isEnabled) {
								this.setState({
									showUpsellMessage: true,
									upsellMessageTitle: 'Permission Required',
									upsellMessageContent: (
										<div>
											You do not have access to this feature. Please reach out to your PartnerTap administrator.
										</div>
									)
								});
							}
							else {
								if (navTile.navRoute) {
									EnvHelper.push(navTile.navRoute);
								}
								else {
									this.setState({showDialogWorkflow: navTile});
								}
							}
						};
						return (
							<div key={index} style={tileStyle} onClick={actionFunction}>
								<div style={{fontSize: 20, fontWeight: 'bold'}}>
									{navTile.label}
								</div>
								<DynamicIcon iconName={navTile.MaterialIcon} style={{fontSize: 60, color: partnerTapSecondary, padding: 5}}/>
								{navTile.description}
								<PrimaryButton label={navTile.navLabel || 'START'} onClick={actionFunction}/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	get selectActionListDialog() {
		let {showDialogWorkflow, rowData, selectedActionList, selectionMissing, sequences, selectedSequence, workflowNotAvailableError} = this.state;
		let availableActionLists = rowData.filter((actionListMetadata) => CoSellEngineHelperLive.isWorkflowAvailable(actionListMetadata));
		let hasActionLists = availableActionLists.length > 0;
		let hasMultipleSequences = sequences?.length > 1;
		let canContinue = hasActionLists && selectedSequence;
		return (
			<Dialog title={'Start ' + showDialogWorkflow.label}
					message={hasActionLists ?
						<div>
							<div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 10}}>
								Select an action list {hasMultipleSequences ? ' and sequence ' : ''} for your {showDialogWorkflow.label}
							</div>
							<DropdownMenu title={(!selectedActionList ? 'Select ' : '') + 'Action List'}
										  options={availableActionLists}
										  optionLabel={'name'}
										  selectedOption={selectedActionList}
										  isSelectionMissing={selectionMissing}
										  selectionMissingMessage={'Please select an Action List'}
										  onSelect={this.selectActionList}/>
							{hasMultipleSequences &&
							 <DropdownMenu title={(!selectedSequence ? 'Select ' : '') + 'Sequence'}
										   options={sequences || [{label: 'Action List Required'}]}
										   optionLabel={'displayName'}
										   selectedOption={selectedSequence}
										   disabled={!sequences}
										   onSelect={this.selectSequence}/>}
							{workflowNotAvailableError &&
							 <Dialog title={'Action List Selection Failure'}
									 message={
										 <div>
											 <div>
												 <pre style={{margin: 0, fontFamily: 'inherit'}}>{workflowNotAvailableError}</pre>
											 </div>
										 </div>}
									 yesAction={() => this.setState({workflowNotAvailableError: null, sequences: null})}/>
							}
						</div>
						:
						<div>
							You don't have any action lists available for the {showDialogWorkflow.label} workflow
						</div>}
					yesLabel={canContinue ? 'CONTINUE' : 'CANCEL'}
					yesAction={canContinue ? this.goToActionListConfig : this.cancelWorkflowSelection}
					noLabel={canContinue ? 'CANCEL' : null}
					noAction={canContinue ? this.cancelWorkflowSelection : null}/>
		);
	}

	cancelWorkflowSelection() {
		this.setState({showDialogWorkflow: null, selectedActionList: null, sequences: null, selectedSequence: null});
	}

	selectActionList(selectedActionList) {
		this.setState({selectedActionList: selectedActionList});
		CoSellEngineEndpoints.getActionListWorkflows(selectedActionList.cosellActionListMetadataPublicId)
		.then((result) => {
			if (this.unmounted) return;
			let {workflows} = result.payload;
			let {showDialogWorkflow} = this.state;
			let selectedWorkflow = workflows.find((workflow) => workflow.name === showDialogWorkflow.name);
			//If the user selects a workflow (e.g. P2P), and then a single-partner action list which partner Org is PII enabled, selectedWorkflow will be undefined.
			if (selectedWorkflow) {
				this.getActionListWorkflowSequences(selectedWorkflow, selectedActionList.cosellActionListMetadataPublicId);
			}
			else {
				this.setState({
					workflowNotAvailableError: 'Sorry, there are no valid sequences available for this action list.\n' +
											   'Please, select another action list and try again.'
				});
			}
		})
		.catch((error) => {
			this.setState({savingActionListMetadata: false});
			EnvHelper.serverError('Error from getWorkflows', error);
		});
	}

	getActionListWorkflowSequences(selectedWorkflow, actionListSelected) {
		CoSellEngineEndpoints.getActionListWorkflowSequences(selectedWorkflow.recipePublicId, actionListSelected)
		.then((result) => {
			if (this.unmounted) return;
			let {sequences} = result.payload;
			sequences.sort((a, b) => CoSellEngineHelperLive.sequenceOrdinal[a.name] > CoSellEngineHelperLive.sequenceOrdinal[b.name] ? 1 : -1);
			this.setState({sequences: sequences});
			if (sequences.length === 1) {
				this.selectSequence(sequences[0]);
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getWorkflowSequences', error);
		});
	}

	selectSequence(selectedSequence) {
		this.setState({selectedSequence: selectedSequence});
	}

	goToActionListConfig() {
		let {selectedActionList, selectedSequence} = this.state;
		if (selectedActionList) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.WORKFLOW_WIZARD.PATH(selectedActionList.cosellActionListMetadataPublicId, selectedSequence.sequenceRecipePublicId));
		}
		else {
			this.setState({selectionMissing: true});
		}
	}

	get additionalToolbarButtons() {
		const workflowLabels = Object.values(this.state.workflows || {}).map((workflow) => workflow.label);
		return [
			<PopoverSearchList key={'filter'}
							   list={[
								   ACTION_LIST_FILTER_ALL,
								   ACTION_LIST_FILTER_READY,
								   ACTION_LIST_FILTER_STOPPED,
								   ACTION_LIST_FILTER_COMPLETE,
								   ...workflowLabels
							   ]}
							   preselectedItem={this.state.actionListFilter}
							   labelRenderer={(filter) => 'Show ' + filter}
							   onItemSelected={this.filterActionLists}/>
		]
			;
	}

	get columnData() {
		return [
			{
				title: 'Name',
				key: 'name',
				renderFunction: (value, rowData) => {
					return <LinkButton disabled={rowData.processingStatus !== CoSellEngineHelperLive.ACTION_LIST_PROCESSING_STATUS_AVAILABLE}
									   label={value}
									   onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.PATH(rowData.cosellActionListMetadataPublicId, ACTION_LIST_REPORT_TYPE))}/>;
				}
			},
			{
				title: 'Actions',
				key: ACTIONS,
				renderFunction: (columnValue, rowData) => {
					let isActionListUsed = Boolean(rowData.activeWorkflow);
					if (rowData.processingStatus === CoSellEngineHelperLive.ACTION_LIST_PROCESSING_STATUS_FAILED) {
						return (
							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
								<LinkButton label={'DELETE'}
											disabled={isActionListUsed || !this.canUserEditActionLists(rowData)}
											onClick={() => this.setState({showDialogDeleteActionList: rowData})}/>
							</div>
						);
					}
					else if (rowData.processingStatus === CoSellEngineHelperLive.ACTION_LIST_PROCESSING_STATUS_READY_TO_PROCESS ||
							 rowData.processingStatus === CoSellEngineHelperLive.ACTION_LIST_PROCESSING_STATUS_PROCESSING) {
						return (
							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
								<LinkButton disabled={this.state.loading} label={'REFRESH'} onClick={() => this.setState({loading: true})}/>
							</div>
						);
					}
					else {
						return (
							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
								<LinkButton label={'CLONE'}
											disabled={!this.canUserEditActionLists(rowData)}
											onClick={() => {
												this.setState({
													showDialogCloneActionList: true,
													actionListToClone: rowData,
													actionListName: rowData.name,
													actionListDescription: rowData.description
												});
											}}/>
								<LinkButton label={'DELETE'}
											disabled={isActionListUsed || !this.canUserEditActionLists(rowData)}
											onClick={() => this.setState({showDialogDeleteActionList: rowData})}/>
							</div>
						);
					}
				}
			},
			{title: 'Description', key: 'description', active: false},
			{title: 'Partner Org', key: 'companyPartner'},
			{title: 'Workflow Type', key: 'activeWorkflow'},
			{title: 'Workflow Status', key: 'workflowStatus'},
			{title: 'ActionList Status', key: 'processingStatus', renderFunction: (v, r) => this.getActionListStatusDisplayName(v, r)},
			{title: 'Record Type', key: 'assetType', renderFunction: (columnValue, rowData) => ReportHelper.getAssetTypeDisplayName(columnValue)},
			{title: 'Created By', key: 'createdByPerson'},
			{title: 'Created On', key: 'createdOnDate', type: 'date'},
			{title: 'Expires On', key: 'expirationOnDate', type: 'date', active: false},
			{title: 'Records Count', key: 'actionListSize', type: 'number'},
			{title: 'Workflow Started', key: 'workflowStartedOn', type: 'date'},
			{title: 'Workflow Sequence Type', key: 'workflowSequenceType'},
			{title: 'Created From', key: 'reportType'},
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {columnData} = this.state;
		let params = this.processParameters();
		CoSellEngineEndpoints.getAllCoSellActionListsLive(params.search, params.page, params.pageSize, params.sort, this.filters)
		.then((result) => {
				if (this.unmounted) return;
				if (result.payload) {
					ChannelHelper.convertTypedValues(result.payload, columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get filters() {
		let {actionListFilter, workflows} = this.state;
		switch (actionListFilter) {
			case ACTION_LIST_FILTER_ALL:
				return {};
			case ACTION_LIST_FILTER_READY:
				return {
					"workflow_status": CoSellEngineHelperLive.ACTION_LIST_WORKFLOW_STATUS_READY.toUpperCase(),
					"processing_status": CoSellEngineHelperLive.ACTION_LIST_PROCESSING_STATUS_AVAILABLE.toUpperCase(),
					"active_workflow": null
				};
			case ACTION_LIST_FILTER_COMPLETE:
				return {
					"workflow_status": CoSellEngineHelperLive.ACTION_LIST_WORKFLOW_STATUS_COMPLETE.toUpperCase(),
				};
			case ACTION_LIST_FILTER_STOPPED:
				return {
					"workflow_status": CoSellEngineHelperLive.ACTION_LIST_WORKFLOW_STATUS_STOPPED.toUpperCase(),
				};
			default:
				return {
					"active_workflow": Object.entries(workflows).find(([_, v]) => v.label === actionListFilter)?.[0]?.toUpperCase(),
				};
		}
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {actionListFilter} = this.state;
			if (actionListFilter !== ACTION_LIST_FILTER_ALL) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							You don't have any {actionListFilter} action lists.
						</div>
						<PrimaryButton label={'CLEAR FILTER'} onClick={() => this.filterActionLists(ACTION_LIST_FILTER_ALL)}/>
					</div>
				);
			}

			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						You haven't saved any action lists. Action lists can be saved from account and opportunity reports.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	cloneCoSellActionList() {
		let {actionListToClone, actionListName, actionListDescription} = this.state;
		let payload = {name: actionListName, description: actionListDescription};
		this.setState({saving: true});
		CoSellEngineEndpoints.cloneCoSellActionListLive(actionListToClone.cosellActionListMetadataPublicId, payload)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({
				loading: true,
				saving: false,
				showDialogCloneActionList: null,
				actionListToClone: null,
				actionListName: null,
				actionListDescription: null
			});
		})
		.catch((error) => {
			this.setState({saving: false, showDialogCloneActionList: null, actionListToClone: null, actionListName: null, actionListDescription: null});
			EnvHelper.serverError('Error from cloneCoSellActionList', error);
		});
	}

	deleteCoSellActionList(actionList) {
		this.setState({saving: true, showDialogDeleteActionList: null});
		CoSellEngineEndpoints.deleteCoSellActionListLive(actionList.cosellActionListMetadataPublicId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({loading: true, saving: false});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from deleteCoSellActionList', error);
		});
	}

	filterActionLists(actionListFilter) {
		this.setState(
			{actionListFilter: actionListFilter},
			() => this.getRowData()
		);
	}

	getActionListStatusDisplayName(statusColumnValue, actionListRow) {
		if (statusColumnValue === CoSellEngineHelperLive.ACTION_LIST_PROCESSING_STATUS_FAILED) {
			return <LinkButton label={'Failed'} onClick={() => this.setState({showDialogFailure: actionListRow.failureReason})}/>;
		}
		return statusColumnValue;
	}

	canUserEditActionLists(actionListMetadata) {
		const {authState} = this.props;
		return canUserEditActionLists(actionListMetadata, authState);
	}

	render() {
		if (!FeatureHelper.isProductPaid(PRODUCT_CO_SELL_ENGINE)) {
			return (
				<MessageBox title={'Co-Sell Engine'} hideIcon={true}>
					<div>
						Use the Co-Sell Engine to automate sales plays for your sales teams and partners.
					</div>
					<div style={{fontWeight: 'bold'}}>
						How to run automated sales plays with PartnerTap’s Ecosystem Platform and Co-Sell Engine
					</div>
					<ol style={{paddingLeft: 40}}>
						<li>Securely share and receive data from partners in digital clean rooms</li>
						<li>Automate account mapping and opportunity mapping</li>
						<li>Use reports to zero in on the highest value accounts for your sales reps and partners to focus on</li>
						<li>Save these reports as actions lists</li>
						<li>Run co-sell workflows to automate direct sales plays, through-channel plays, and co-sell plays</li>
						<li>Track and measure the net-new pipeline generated by each workflow with closed-loop attribution</li>
						<li>Get real-time visibility to partner pipeline and collaborate on deals as they move through the sales process</li>
					</ol>
					<div>
						Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																						onClick={() => window.open('https://partnertap.com/support/')}/> to
						access Co-Sell Engine.
					</div>
				</MessageBox>
			);
		}

		let {
			saving,
			showDialogDeleteActionList,
			showDialogCloneActionList,
			showDialogWorkflow,
			showDialogFailure,
			actionListName,
			actionListDescription,
			showUpsellMessage,
			upsellMessageTitle,
			upsellMessageContent
		} = this.state;

		return (
			<ScrollingContainer divId={'co_sell_overview'}>
				<div style={{overflow: 'scroll'}}>
					{saving && <ScrimMessage message={'Saving...'}/>}
					{showDialogCloneActionList &&
					 <Dialog title={'Clone Action List'}
							 message={
								 <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400, margin: 'auto'}}>
									 <div>Your action list clone will be an exact copy of the original action list.</div>
									 <div style={{paddingBottom: 10}}>You'll have the option to remove and restore rows and columns prior to running a
										 workflow.
									 </div>
									 <TextInputBox
										 hintText={'Action List Name'}
										 value={actionListName}
										 onChange={(value) => this.setState({actionListName: value})}
										 errorText={!actionListName ? 'Name is required' : null}
										 doNotAutoFocus={true}
										 maxChars={100}
									 />
									 <TextInputBox
										 hintText={'Action List Description'}
										 value={actionListDescription}
										 placeholderText={'Please tell your co-sell automation leader the strategy and next steps for this Action List'}
										 onChange={(value) => this.setState({actionListDescription: value})}
										 errorText={!actionListDescription ? 'Description is required' : null}
										 multiLine={true}
										 rows={3}
										 maxChars={500}
									 />
								 </div>
							 }
							 yesDisable={!actionListName || !actionListDescription}
							 yesLabel={'Save'}
							 noLabel={'Cancel'}
							 noAction={() => this.setState({
								 showDialogCloneActionList: null,
								 actionListToClone: null,
								 actionListName: null,
								 actionListDescription: null
							 })}
							 yesAction={() => this.cloneCoSellActionList()}
					 />}
					{showDialogDeleteActionList &&
					 <Dialog title={'Delete Action List'}
							 message={
								 <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
									 <div style={{fontSize: 16, fontWeight: 'bold'}}>
										 You are about to delete the action list '{showDialogDeleteActionList.name}'.
									 </div>
									 <div>
										 Deleted action lists are not recoverable.
									 </div>
									 <div>
										 Do you want to continue?
									 </div>
								 </div>}
							 yesLabel={'DELETE'}
							 yesAction={() => this.deleteCoSellActionList(showDialogDeleteActionList)}
							 noLabel={'CANCEL'}
							 noAction={() => this.setState({showDialogDeleteActionList: null})}/>}
					{showDialogWorkflow && this.selectActionListDialog}
					{showDialogFailure &&
					 <Dialog title={'Action List Creation Failure'}
							 message={
								 <div>
									 <div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 20}}>
										 A failure occurred during the creation of your action list.
									 </div>
									 <div>
										 {showDialogFailure}
									 </div>
									 <div>
										 <LinkButton label={'SUPPORT'} onClick={() => window.open('https://partnertap.com/support')}/>
									 </div>
								 </div>}
							 yesAction={() => this.setState({showDialogFailure: null})}/>
					}
					{showUpsellMessage &&
					 <Dialog title={upsellMessageTitle}
							 message={
								 <div>
									 {upsellMessageContent}
								 </div>
							 }
							 yesLabel={'OK'}
							 yesAction={() => this.setState({showUpsellMessage: false})}
					 />}
					{super.render()}
				</div>
			</ScrollingContainer>
		);
	}
}

CoSellOverviewPageLive.propTypes = {
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CoSellOverviewPageLive));
