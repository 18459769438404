import ColumnHelper from './ColumnHelper';

export default class DownloadHelper {

	static getFields(columns) {
		let fields = [];
		columns.forEach((column) => {
			if (ColumnHelper.isVisible(column) && column.key.toLowerCase() !== "actions") {
				fields.push(column.key);
			}
		});
		return fields;
	}
}
