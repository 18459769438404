import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableHelper from '../helpers/TableHelper';
import {partnerTapInactive, partnerTapSecondary} from '../styles/partnertap_theme';
import PrimaryButton from './buttons/PrimaryButton';

class Tile extends Component {
	render() {
		const {
			title, icon, description, actionLabel, actionFunction, actionLabel2, actionFunction2, isDisabled
		} = this.props;
		const MaterialIcon = icon;
		const iconColor = isDisabled ? partnerTapInactive : partnerTapSecondary;

		return (
			<div style={TableHelper.tileStyle(250, 250, iconColor, 10, 'pointer', isDisabled)} onClick={actionFunction} onKeyDown={actionFunction}>
				<div style={{fontSize: 20, fontWeight: 'bold'}}>
					{title}
				</div>
				<MaterialIcon style={{fontSize: 60, color: iconColor}}/>
				{description}
				<div style={{display: 'flex', gap: 10}}>
					<PrimaryButton disabled={isDisabled} key={'actionButton1'} label={actionLabel} onClick={actionFunction}/>
					{actionFunction2 &&
					 <PrimaryButton disabled={isDisabled} key={'actionButton2'} label={actionLabel2} onClick={actionFunction2}/>}
				</div>
			</div>
		);
	}
}

Tile.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.object,
	isDisabled: PropTypes.bool,
	description: PropTypes.string,
	actionLabel: PropTypes.string,
	actionFunction: PropTypes.func,
	actionLabel2: PropTypes.string,
	actionFunction2: PropTypes.func
};

export default Tile;

