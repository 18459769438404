import {Download} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as CsvDownloadEndpoints from '../../../endpoints/CsvDownloadEndpoints';
import {DOWNLOAD_STATUS_PROCESSED} from '../../../globals/Enums';
import AuthHelper from '../../../helpers/AuthHelper';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import SortHelper from '../../../helpers/SortHelper';
import {partnerTapSecondary, partnerTapStroke, partnerTapTernaryLight} from '../../../styles/partnertap_theme';
import PagingBase from '../../../ui/lists/PagingBase';
import PersistenceHelper from '../../../helpers/PersistenceHelper';
import {PAGE_SIZE_DEFAULT} from '../../../helpers/PageableHelper';

class CsvDownloadPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.downloadedIds = [];

		this.downloadLink = this.downloadLink.bind(this);
		this.isLinkAlreadyUsed = this.isLinkAlreadyUsed.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get storageKeyBase() {
		return 'csv_download';
	}

	get title() {
		return 'Ecosystem Download Reports';
	}

	get icon() {
		return Download;
	}

	get columnData() {
		return [
			{
				title: 'Name',
				key: 'downloadFileName',
				cellColorFunction: (columnValue, rowData) => this.isLandingDownloadId(rowData.downloadId) && partnerTapTernaryLight
			},
			{title: 'Description', key: 'fileDescription'},
			{title: 'Created Date', key: 'createdDate', type: 'date'},
			{title: 'Status', key: 'status'},
			{
				title: 'Actions',
				key: 'downloadId',
				sortDisabled: true,
				cellColorFunction: (columnValue) => this.isLandingDownloadId(columnValue) && partnerTapTernaryLight,
				renderFunction:
					(columnValue, rowData) => {
						if (this.isLinkAlreadyUsed(columnValue)) {
							return (
								<div style={{display: 'flex', justifyContent: 'center', color: partnerTapStroke}}>
									DOWNLOADED
								</div>
							);
						}
						let isProcessing = rowData.status !== DOWNLOAD_STATUS_PROCESSED;
						if (isProcessing) {
							return (
								<div style={{display: 'flex', justifyContent: 'center', color: partnerTapStroke}}>
									PROCESSING
								</div>
							);
						}
						return this.downloadLink('DOWNLOAD', 'center', columnValue);
					}
			}
		];
	}

	isLinkAlreadyUsed(columnValue) {
		let {downloadedIds} = this.state;
		return downloadedIds.indexOf(columnValue) !== -1;
	}

	downloadLink(label, alignment, downloadId) {
		let {downloadedIds, forceUpdateKey} = this.state;
		return (
			<div onClick={() => {
				downloadedIds.push(downloadId);
				this.setState({forceUpdateKey: forceUpdateKey + 1});
			}}>
				<a style={{display: 'flex', justifyContent: alignment, color: partnerTapSecondary}}
				   href={EnvHelper.reportUrl + '/csv/download?currentUserPersonId=' + AuthHelper.userPerson.id + '&downloadId=' + downloadId}>
					{label}
				</a>
			</div>
		);
	}

	isLandingDownloadId(csvFileDownload) {
		let landingDownloadId = EnvHelper.getUrlParam('downloadId');
		return csvFileDownload && landingDownloadId && landingDownloadId === csvFileDownload;
	}

	get supportsPaging() {
		return true;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {columnData} = this.state;
		let params = this.processParameters();
		CsvDownloadEndpoints.getCSVDownloadsList(params.page, params.pageSize, params.sort, params.search)
		.then((result) => {
			if (this.unmounted) return;
			if (this.hasRowData(result)) {
				result.payload = SortHelper.filterSearchObjectContains(params.search, result.payload, SortHelper.keysFromColumns(columnData));
				ChannelHelper.convertTypedValues(result.payload, columnData);
			}
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{display: 'flex', alignItems: 'center', padding: 20}}>
						Downloaded reports will be listed here
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}
}

export default connect()(CsvDownloadPage);
