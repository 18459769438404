import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import * as AuthActions from '../../redux/AuthActions';
import LinkButton from '../buttons/LinkButton';
import Dialog from '../Dialog';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import {DEEP_LINK_KEY, LAST_ROUTE_KEY} from '../../globals/StorageKeys';
import CSVHelper from '../../helpers/CsvHelper';
import ErrorMessageHelper from '../../helpers/ErrorMessageHelper';

class ErrorMessageDialog extends Component {

	constructor(props, context) {
		super(props, context);
		this.onLogOut = this.onLogOut.bind(this);
		this.dismissError = this.dismissError.bind(this);
	}

	onLogOut() {
		this.props.dispatch(AuthActions.logout());
		this.props.dispatch(AuthActions.clearServerError());
		EnvHelper.push(Routes.HOME.ROUTE);
	}

	dismissError() {
		if (this.props.authState.httpCode === 428) {
			this.props.onGoToLogin();
		}
		PersistenceHelper.removeValue(LAST_ROUTE_KEY);
		PersistenceHelper.removeValue(DEEP_LINK_KEY);
		EnvHelper.dispatch(AuthActions.clearServerError());
	}

	ignoreMissingHeaderError() {
		CSVHelper.ignoreBlankHeaders = true;
		EnvHelper.dispatch(AuthActions.clearServerError());
	}

	render() {
		let {errorMessage} = this.props.authState;
		let supportMessage =
			<div style={{fontSize: 14}}>
				If you are unable to resolve the issue, please <LinkButton label={'contact support'}
																		   onClick={() => window.open('https://partnertap.com/support')}/>
			</div>;
		let dialogContent = ErrorMessageHelper.renderErrorMessage(errorMessage);
		let isMissingHeaderError = ErrorMessageHelper.isMissingHeaderError(errorMessage);
		if (isMissingHeaderError) {
			return (
				<Dialog title={'Error Message'}
						message={dialogContent}
						yesLabel={'IGNORE COLUMNS'}
						yesAction={this.ignoreMissingHeaderError}
						noLabel={'CANCEL'}
						noAction={this.dismissError}/>
			);
		}

		return (
			<Dialog title={'Error Message'} message={dialogContent} yesAction={this.dismissError}/>
		);
	}
}

ErrorMessageDialog.propTypes = {
	onGoToLogin: PropTypes.func.isRequired,
	authState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(ErrorMessageDialog);
