import PropTypes from 'prop-types';
import React, {Component} from 'react';
import NumberHelper from '../helpers/NumberHelper';
import {partnerTapPrimary, partnerTapWhite} from '../styles/partnertap_theme';
import ToolTipOverlay from './ToolTipOverlay';

const ESTIMATION_CUTOFF = 250000;

class ItemCount extends Component {

	get valueToRender() {
		const {customContent, count} = this.props;
		if (customContent) {
			return customContent;
		}
		if (this.isEstimated) {
			return NumberHelper.estimatedNumber(count);
		}
		return NumberHelper.formatNumber(this.props.count);

	}

	get isEstimated() {
		const {count} = this.props;
		return count > ESTIMATION_CUTOFF;
	}

	get toolTip() {
		if (this.isEstimated) {
			return "This is an estimate. For an exact count, filter your results down to " + NumberHelper.shortNumber(ESTIMATION_CUTOFF) + " rows or less.";
		}
		return "";
	}

	render() {
		return (
			<ToolTipOverlay title={this.toolTip} placement={'bottom'}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: this.props.backgroundColor || partnerTapPrimary,
					minWidth: 32,
					height: 32,
					borderRadius: 16,
					fontSize: 16,
					fontWeight: 'bold',
					color: partnerTapWhite
				}}>
					<div data-cy={'item_count'} style={{padding: this.props.customContent ? 0 : 10}}>
						{this.valueToRender}
					</div>
				</div>
			</ToolTipOverlay>
		);
	}
}

ItemCount.propTypes = {
	count: PropTypes.number.isRequired,
	backgroundColor: PropTypes.string,
	customContent: PropTypes.element
};

export default ItemCount;
