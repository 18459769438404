import {ReadMore} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AccountDetailHeader from '../../../app/channel_ecosystem/accounts/AccountDetailHeader';
import DashboardTable from '../../../app/channel_ecosystem/shared/DashboardTable';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {COLUMN_KEY_OPPORTUNITY_NAME} from '../../../globals/Enums';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import PartnerStatusHelper from '../../../helpers/PartnerStatusHelper';
import {CHANNEL_REPORT_TYPE_BASE_ACCOUNTS} from '../../../helpers/ReportHelper';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import DetailHeader from '../shared/DetailHeader';

class AccountDetail extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, details: {}, showAlertMessage: false, alertMessage: '', partnershipCreated: false};

		this.handlePartnershipAction = this.handlePartnershipAction.bind(this);
	}

	componentDidMount() {
		this.fetchDashboard();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get matchedPartnerTableColumnData() {
		return [
			{title: 'Partner Company', key: 'companyName', isPartnerData: true, active: true},
			{title: 'Partner Is Customer', key: 'partnerIsCustomerField', isPartnerData: true, active: true, type: 'boolean'},
			{title: 'Open Opps', key: 'openOppCount', isPartnerData: true, active: true},
			{title: 'Closed Won Opps', key: 'closedWonOppCount', isPartnerData: true, active: true},
			{title: 'Chats', key: 'chatCount', active: true}
		];
	}

	get salesOwnerTableColumnData() {
		return [
			{title: 'Name', key: 'name', active: true},
			{title: 'Title', key: 'title', active: true},
			{title: 'Phone #', key: 'phone', active: true},
			{title: 'Email', key: 'email', active: true}
		];
	}

	get partnerOwnerTableColumnData() {
		return [
			{title: 'Name', key: 'name', isPartnerData: true, active: true},
			{title: 'Title', key: 'title', isPartnerData: true, active: true},
			{title: 'Company Name', key: 'companyName', isPartnerData: true, active: true},
			{title: 'Phone #', key: 'phone', isPartnerData: true, active: true},
			{title: 'Email', key: 'email', isPartnerData: true, active: true},
			{
				title: 'Actions',
				key: ACTIONS,
				active: true,
				renderFunction: (columnValue, rowData) => PartnerStatusHelper.renderChannelPartnershipAction(rowData, this.handlePartnershipAction)
			}
		];
	}

	get opportunitiesTableColumnData() {
		return [
			{title: 'Name', key: COLUMN_KEY_OPPORTUNITY_NAME, active: true},
			{title: 'Stage Name', key: 'stageName', active: true},
			{title: 'Status', key: 'opportunityStatus', active: true},
			{title: 'Owner Name', key: 'opportunityOwnerName', active: true},
			{title: 'Is Won', key: 'won', type: 'boolean', active: true},
			{title: 'Amount', key: 'amount', type: 'currency', active: true}
		];
	}

	handlePartnershipAction(created, message) {
		this.setState({showAlertMessage: true, alertMessage: message, partnershipCreated: created, loading: true});
		this.fetchDashboard();
	}

	fetchDashboard() {
		ChannelMappingEndpoints.getDashboard({crmAccountId: this.props.match.params.crmAccountId, channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS})
		.then((result) => {
			if (this.unmounted) return;
			let {details, partnerCompaniesList, salesOwnerList, partnerOwnerList, opportunitiesList} = result.payload;
			partnerOwnerList.forEach((partnerItem) => {
				partnerItem.partnerOrganizationId = details.orgId;
				partnerItem.partnerOwnerId = details.ownerId;
			});
			let dataTables = [
				this.createDataTable(partnerCompaniesList, this.matchedPartnerTableColumnData, 'Matched Partners'),
				this.createDataTable(salesOwnerList, this.salesOwnerTableColumnData, 'Sales Owners'),
				this.createDataTable(partnerOwnerList, this.partnerOwnerTableColumnData, 'Partner Owners'),
				this.createDataTable(opportunitiesList, this.opportunitiesTableColumnData, 'My Opps')
			];
			this.setState({loading: false, details: details, dataTables: dataTables});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getAccountDetails', error);
		});
	}

	createDataTable(rowData, columnData, title) {
		return (
			<DashboardTable key={'account_detail_' + title.toLowerCase().replace(/\s/g, '_')}
							title={title}
							columnData={columnData}
							rowData={rowData}
							hasScrollingContainerParent={true}/>
		);
	}

	render() {
		let {loading, dataTables, showAlertMessage, alertMessage, partnershipCreated, details} = this.state;
		if (loading) return <Loading>Loading Account Detail...</Loading>;
		return (
			<ScrollingContainer divId={'ecosystem_mapped_account_detail'}>
				<DetailHeader title={'Account Details'} MaterialIcon={ReadMore}/>
				<AccountDetailHeader details={details}/>
				<div style={{overflow: 'scroll', marginLeft: 5, marginRight: 5}}>
					{dataTables}
				</div>
				{showAlertMessage &&
				 <Dialog title={partnershipCreated ? 'Success!' : 'Error!'}
						 message={alertMessage}
						 yesAction={() => this.setState({showAlertMessage: false})}/>}
			</ScrollingContainer>
		);
	}
}

AccountDetail.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(AccountDetail));
