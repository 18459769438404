import * as CoSellEngineHelperLive from '../CoSellEngineHelperLive';
import NudgesTile from './steps_components/NudgesTile';
import CrmTrackingTile from './steps_components/CrmTrackingTile';
import EmailPreviewTile from './steps_components/EmailPreviewTile';
import React, {useState} from 'react';
import WorkFlowSummary from './steps_components/WorkFlowSummary';
import P2pSummary from './steps_components/p2p/P2pSummary';
import Loading from '../../../../ui/Loading';
import ValidatedP2pWorkflow from './steps_components/p2p/ValidatedP2pWorkflow';
import FeatureHelper, {FEATURE_COSELL_P2P_WORKFLOW} from '../../../../helpers/FeatureHelper';
import Dialog from '../../../../ui/Dialog';
import MessageBox from '../../../../ui/messages/MessageBox';
import LinkButton from '../../../../ui/buttons/LinkButton';
import EnvHelper from '../../../../helpers/EnvHelper';
import {Routes} from '../../../../globals/Routes';

const seqKeyToDisplayName = {
	[CoSellEngineHelperLive.WORKFLOW_SEQUENCE_P2P_INTRO]: "P2P Play One Step: Partner Intro",
	[CoSellEngineHelperLive.WORKFLOW_SEQUENCE_P2P_OPT_IN_THEN_INTRO]: "P2P Play Two Step: Partner 1 Opt In > Intro",
	[CoSellEngineHelperLive.WORKFLOW_SEQUENCE_P2P_DOUBLE_OPT_IN_THEN_INTRO]: "P2P Play Three Step: Partner 1 Opt In > Partner 2 Opt In > Intro"
};

export const p2pSteps = [{label: 'Recipients', active: true}, {label: 'Messages'}, {label: 'Follow Ups'}, {label: 'Confirm'}];
const P2pWorkflowSteps = ({
	actionListMetadata,
	currentStep,
	templates,
	sequenceName,
	onNudgeChange,
	onCrmTrackingChange,
	currentCrmOptions,
	setCustomSubject,
	setCustomMessage,
	workflowName,
	onSelectRecipient1,
	onSelectRecipient2,
	onStepComplete,
	summaryData,
	setManualFields,
	manualFields
}) => {
	// State for EmailPreviewTile
	const [emailCustomizations, setEmailCustomizations] = useState(
		templates.map(template => ({
			stepRecipePublicId: template.stepRecipePublicId,
			customSubject: template.subject || "",
			customMessage: ""
		}))
	);

// State for NudgesTile
	const [nudges, setNudges] = useState([null, null]);

// Recipients state
	const [recipient1, setRecipient1] = useState({
		partnerOrgPublicId: '',
		id: '',
		isContactGroup: true,
		ownerOrgPublicId: null
	});

	const [recipient2, setRecipient2] = useState({
		partnerOrgPublicId: '',
		id: '',
		isContactGroup: true,
		ownerOrgPublicId: null
	});

// Form states
	const [recipientForms, setRecipientForms] = useState({
		recipient1: {
			recipientFrom: 'action-list',
			manualName: '',
			manualEmail: '',
			manualTitle: ''
		},
		recipient2: {
			recipientFrom: 'action-list',
			manualName: '',
			manualEmail: '',
			manualTitle: ''
		}
	});

	// Add validation logic here
	const isWorkflowValid = () => {
		// Partners must be different
		if (recipient1.partnerOrgPublicId && recipient2.partnerOrgPublicId &&
			recipient1.partnerOrgPublicId === recipient2.partnerOrgPublicId) {
			console.log('same partner');
			return false;
		}
		// Contact groups must be selected if that option is chosen
		if (recipient1.isContactGroup && recipient1.partnerOrgPublicId && !recipient1.id) {
			console.log('Contact groups must be selected if that option is chosen');
			return false;
		}
		if (recipient2.isContactGroup && recipient2.partnerOrgPublicId && !recipient2.id) {
			console.log('Contact groups must be selected if that option is chosen');
			return false;
		}
		// Manual recipients must have name and email and Partner
		if (!recipient1.isContactGroup) {
			if (!recipientForms.recipient1.manualName || !recipientForms.recipient1.manualEmail || !(recipient1.partnerOrgPublicId || recipient1.ownerOrgPublicId)) {
				console.log('invalid manual 1');
				return false;
			}
		}
		if (!recipient2.isContactGroup) {
			if (!recipientForms.recipient2.manualName || !recipientForms.recipient2.manualEmail || !(recipient2.partnerOrgPublicId || recipient2.ownerOrgPublicId)) {
				console.log('invalid manual 2');
				return false;
			}
		}
		return recipient1.id && recipient2.id && recipient1.id !== recipient2.id;
	};

	const mapRecipientFormsToManualFields = (recipientForms) => {
		const isRecipient1Manual = recipientForms.recipient1.recipientFrom !== 'action-list';
		const isRecipient2Manual = recipientForms.recipient2.recipientFrom !== 'action-list';
		return {
			manualRecipient1Name: isRecipient1Manual ? recipientForms.recipient1.manualName : "",
			manualRecipient1Email: isRecipient1Manual ? recipientForms.recipient1.manualEmail : "",
			manualRecipient1Title:  isRecipient1Manual ? recipientForms.recipient1.manualTitle : "",
			manualRecipient2Name: isRecipient2Manual ? recipientForms.recipient2.manualName : "",
			manualRecipient2Email: isRecipient2Manual ? recipientForms.recipient2.manualEmail : "",
			manualRecipient2Title:  isRecipient2Manual ? recipientForms.recipient2.manualTitle : "",
		};
	};

	// Update step completion whenever relevant data changes
	React.useEffect(() => {
		setManualFields(mapRecipientFormsToManualFields(recipientForms));
		onStepComplete(isWorkflowValid());
	}, [recipient1, recipient2, recipientForms]);

	const handleSubjectChange = (stepRecipePublicId, newSubject) => {
		setEmailCustomizations(prev =>
			prev.map(custom =>
				custom.stepRecipePublicId === stepRecipePublicId
					? {...custom, customSubject: newSubject}
					: custom
			)
		);
		setCustomSubject(stepRecipePublicId, newSubject);
	};

	const handleMessageChange = (stepRecipePublicId, newMessage) => {
		setEmailCustomizations(prev =>
			prev.map(custom =>
				custom.stepRecipePublicId === stepRecipePublicId
					? {...custom, customMessage: newMessage}
					: custom
			)
		);
		setCustomMessage(stepRecipePublicId, newMessage);
	};

	const handleNudgeChange = (index, option) => {
		const updatedNudges = [...nudges];
		updatedNudges[index] = option;
		setNudges(updatedNudges);
		onNudgeChange(updatedNudges.filter(nudge => nudge !== null));
	};

	const handlePartnerChange = (recipientNumber, recipient) => {
		if (recipientNumber === 1) {
			setRecipient1(prev => {
				const value = { ...prev, ...recipient };
				onSelectRecipient1(value);
				return value;
			});
		} else {
			setRecipient2(prev => {
				const value = { ...prev, ...recipient };
				onSelectRecipient2(value);
				return value;
			});
		}
	};

	const handleRecipientFormChange = (recipientNumber, changes) => {
		const recipientKey = `recipient${recipientNumber}`;
		setRecipientForms(prev => ({
			...prev,
			[recipientKey]: { ...prev[recipientKey], ...changes }
		}));
	};

	const handleManualRecipientChange = (recipientNumber, fields) => {
		setManualFields({ ...manualFields, ...fields });

		const value = {
			isContactGroup: false,
			id: recipientNumber === 1 ? 'manual_recipient1' : 'manual_recipient2'
		};

		if (recipientNumber === 1) {
			setRecipient1(prev => {
				onSelectRecipient1({ ...prev, ...value });
				return { ...prev, ...value };
			});
		} else {
			setRecipient2(prev => {
				onSelectRecipient2({ ...prev, ...value });
				return { ...prev, ...value };
			});
		}
	};

	const hideNudge = sequenceName === CoSellEngineHelperLive.WORKFLOW_SEQUENCE_P2P_INTRO;
	const hideCrmTracking = false;

	const contactGroups = actionListMetadata.partnerOrgs.reduce((acc, org) => {
		acc[org.partnerOrgPublicId] = org.contactGroups.map(group => ({
			contactGroupId: group.contactGroupId,
			contactGroupName: group.contactGroupName
		}));
		return acc;
	}, {});

	const orgList = actionListMetadata.partnerOrgs.map(org => ({
		partnerOrgPublicId: org.partnerOrgPublicId,
		partnerOrgName: org.partnerOrgName
	}));

	const {
		ownerOrgPublicId: partnerOrgPublicId,
		ownerOrgName: partnerOrgName
	} = actionListMetadata.ownerOrg;

	const contactGroupsWithOwner = {
		...contactGroups,
		[partnerOrgPublicId]: actionListMetadata.ownerOrg.ownerContactGroups
		.map(group => ({
			contactGroupId: group.contactGroupId,
			contactGroupName: group.contactGroupName
		}))
	};

	const orgListWithOwner = actionListMetadata.ownerOrg.ownerContactGroups.length === 0 ? orgList : [
		...orgList,
		{partnerOrgPublicId, partnerOrgName}
	];

	if (!FeatureHelper.isFeatureEnabled(FEATURE_COSELL_P2P_WORKFLOW)) {
		return (<Dialog title={'Upgrade Required'}
						message={<MessageBox hideIcon={true}>
							<div>
								To run a P2P workflow, you must have the <LinkButton label={'Co-Sell Engine'}
																					 onClick={() => window.open('https://partnertap.com/products/co-sell-automation/')}/> enabled.
							</div>
							<div>
								The P2P Workflow allows you to create co-sell plays between two partners.
							</div>
							<div>
								Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																								onClick={() => window.open('https://partnertap.com/support/')}/> to
								start using P2P Workflows.
							</div>
						</MessageBox>}
						yesAction={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE)}
						noContentPadding={true}/>);
	}

	switch (currentStep) {
		case 0: {
			return (
				<ValidatedP2pWorkflow
					workflowName={workflowName}
					orgList={orgListWithOwner}
					contactGroups={contactGroupsWithOwner}
					recipient1={recipient1}
					recipient2={recipient2}
					recipientForm1={recipientForms.recipient1}
					recipientForm2={recipientForms.recipient2}
					onPartnerChange={handlePartnerChange}
					onRecipientFormChange={handleRecipientFormChange}
				/>
			);
		}
		case 1: {
			return (
				<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
					{templates.map((template, index) =>
						<EmailPreviewTile
							key={index}
							emailTemplate={template}
							subject={emailCustomizations.find(c => c.stepRecipePublicId === template.stepRecipePublicId)?.customSubject}
							message={emailCustomizations.find(c => c.stepRecipePublicId === template.stepRecipePublicId)?.customMessage}
							onSubjectChange={(newSubject) => handleSubjectChange(template.stepRecipePublicId, newSubject)}
							onMessageChange={(newMessage) => handleMessageChange(template.stepRecipePublicId, newMessage)}
						/>
					)}
				</div>
			);
		}
		case 2: {
			return (
				<div style={{display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'top', justifyContent: 'center'}}>
					{!hideNudge &&
					 <NudgesTile
						 nudges={nudges}
						 onNudgeChange={handleNudgeChange}
					 />
					}
					{!hideCrmTracking &&
					<CrmTrackingTile
						onChange={onCrmTrackingChange}
						currentCrmOptions={currentCrmOptions}
					/>
					}
				</div>
			);
		}
		case 3: {
			return (
				!summaryData ? <Loading>Loading summary...</Loading> :
					<WorkFlowSummary
						summaryData={summaryData}
						displayName={seqKeyToDisplayName[sequenceName]}
						summaryComponent={P2pSummary}
					/>
			);
		}
	}
};

export default P2pWorkflowSteps;
