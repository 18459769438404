import {linearLightBlueToPink, partnerTapWhite, whiteWithTransparency} from '../../../styles/partnertap_theme';
import MessageBox from '../../../ui/messages/MessageBox';
import {ArrowBack, AutoAwesome} from '@mui/icons-material';
import React from 'react';
import ActionButton from '../../../ui/buttons/ActionButton';

const noBorderWithShadow = {
	backgroundColor: undefined,
	boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
	border: undefined,
	padding: '20px',  // Add padding to outer box
	marginTop: undefined,
	marginBottom: undefined,
	marginLeft: undefined,
	marginRight: undefined,
};

const titleFont = {
	fontSize: '24px',
	fontWeight: '700',
}

const AiQuestionsSelector = ({
	title,
	width,
	backButtonAction,
	showBackButton,
	children
}) => {
	return (<div
		style={{
			display: 'flex',
			alignItems: 'center',
			width: width,
		}}
	>
		<div style={{
			width: '64px',
			height: '64px',
			background: 'linear-gradient(45deg, #4169E1 20%, #9370DB 50%)',
			borderRadius: '16px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
			flexShrink: 0,
			marginRight: '-40px',
			zIndex: 1
		}}>
			<AutoAwesome fontSize={'large'} style={{color: partnerTapWhite}}/>
		</div>

		<MessageBox hideIcon={true}
					noWrapperOnChildren={true} style={{
			background: linearLightBlueToPink,
			flexGrow: 1,
			...noBorderWithShadow,
		}}>
			<MessageBox
				titleStyle={{
					...titleFont,
					marginBottom: '20px',
				}}
				title={!showBackButton && title}
				hideIcon={true}
				// noWrapperOnChildren={true}
				style={{
					background: whiteWithTransparency,
					backdropFilter: 'blur(10px)',
					width: width * 0.9,
					margin: '0 auto',
					...noBorderWithShadow
				}}>
				{showBackButton &&
				 <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
					 <ActionButton icon={<ArrowBack/>} onAction={() => backButtonAction()}/>
					 <p style={titleFont}>Select a product</p>
				 </div>
				 }
				{children}
			</MessageBox>
		</MessageBox>


	</div>);
};

export default AiQuestionsSelector;