import {JoinLeftOutlined, Sync, TaskOutlined} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {CHANNEL_REPORT_TYPE_BASE_ACCOUNTS} from '../../../helpers/ReportHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';
import PartnerTapIcon from "../../../ui/PartnerTapIcon";
import SecondaryButton from "../../../ui/buttons/SecondaryButton";
import SheetInfoHelper from '../shared/sheets/SheetInfoHelper';

class MyAccountsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {personQueueIds: [this.personQueueId], channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS};
		this.state.filterSelectorMounted = false;
		this.state.showDetails = false;
	}

	componentDidMount() {
		this.fetchCompanyAccountColumns();
	}

	fetchCompanyAccountColumns() {
		let actions = [ChannelMappingEndpoints.getColumns({channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS})];
		if(this.personQueueId) {
			actions.push(ChannelMappingEndpoints.getUploadDataForId(this.personQueueId));
		}
		Promise.all(actions)
		.then((results) => {
			if (this.unmounted) return;
			this.setState({sheetData: results[1]?.payload || {}});
			this.initColumnData(results[0].payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCompanyAccountColumns', error);
		});
	}

	get storageKeyBase() {
		return 'ecosystem_my_accounts';
	}

	get title() {
		if (this.crmType) {
			let crmName = DataManagerHelper.getCrmName(this.props.match.params.personQueueId);
			return crmName + ' Sync';
		}
		if (this.personQueueId) return 'My Sheet';
		return 'My Accounts';
	}

	get icon() {
		if (this.crmType) return Sync;
		if (this.personQueueId) return TaskOutlined;
		return JoinLeftOutlined;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get personQueueId() {
		if (this.crmType) return null;
		return this.props.match.params.personQueueId;
	}

	get crmType() {
		if (this.props.match.params.personQueueId && this.props.match.params.personQueueId.indexOf('COMPANY_SALE_ACCOUNTS_') === 0) {
			return DataManagerHelper.getCrmType(this.props.match.params.personQueueId);
		}
		return null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				selectedColumns: this.activeColumns,
				personQueueIds: this.personQueueId ? [this.personQueueId] : [],
				crmType: this.crmType,
				channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get additionalToolbarButtons() {
		return this.personQueueId ? [<SecondaryButton key={'csv_info_button'} label={'CSV INFO'}
								 icon={<PartnerTapIcon glyph={'I'} style={PartnerTapIcon.buttonIconStyle}/>}
								 onClick={() => this.setState({showDetails: true})}/>] : [];
	}

	renderCsvDetails() {
		let {sheetData} = this.state;
		return SheetInfoHelper.renderDetails(sheetData, () => this.setState({showDetails: false}));
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
				{this.state.showDetails && this.renderCsvDetails()}
				{super.render()}
			</div>
		);
	}
}

MyAccountsPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default connect()(MyAccountsPage);
