import {AdminPanelSettings, PersonAdd} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {CHANNEL_SALES_REP, STATUS_PREREGISTERED, SUPERADMIN} from '../../globals/Enums';
import {ACTIONS} from '../../helpers/ColumnHelper';
import EnvHelper from '../../helpers/EnvHelper';
import StringHelper from '../../helpers/StringHelper';
import {partnerTapAlert, partnerTapSecondary, partnerTapWhite} from '../../styles/partnertap_theme';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Dialog from '../../ui/Dialog';
import PagingBase from '../../ui/lists/PagingBase';
import ScrimMessage from '../../ui/messages/ScrimMessage';
import PopoverSearchList from '../../ui/PopoverSearchList';
import TextInputBox from '../../ui/TextInputBox';
import OrgSelector from './shared/OrgSelector';
import LinkButton from '../../ui/buttons/LinkButton';
import ErrorMessageHelper from '../../helpers/ErrorMessageHelper';

class AdminPreRegUsersPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.selectedOrg = null;
		this.state.filterSelectorFunction = AdminEndpoints.fetchUserFilterData;
		this.state.filterSelectorMounted = false;
		this.state.allRoles = null;
		this.state.firstName = '';
		this.state.lastName = '';
		this.state.emailAddress = '';
		this.state.roleCode = CHANNEL_SALES_REP;
		this.state.isMissingRequiredValue = false;
		this.state.errorMessage = null;

		this.inputFirstName = this.inputFirstName.bind(this);
		this.inputLastName = this.inputLastName.bind(this);
		this.inputEmail = this.inputEmail.bind(this);
		this.inputRolePublicId = this.inputRolePublicId.bind(this);
		this.preRegManualUser = this.preRegManualUser.bind(this);
		this.handleOrgSelection = this.handleOrgSelection.bind(this);
	}

	handleOrgSelection(org) {
		this.fetchPreRegUserColumns(org);
	}

	get storageKeyBase() {
		return 'admin_pre_reg_users';
	}

	get title() {
		return 'Pre-Register Users';
	}

	get icon() {
		return AdminPanelSettings;
	}

	fetchPreRegUserColumns(org) {
		AdminEndpoints.fetchUserColumns(true)
		.then((result) => {
			if (this.unmounted) return;
			let roleCode = result.payload.find((row) => row.key === 'roleCode');
			if (roleCode) {
				roleCode.renderFunction = (columnValue, rowData) => {
					if (!rowData) return columnValue;
					if (rowData.status !== STATUS_PREREGISTERED) {
						return this.renderRolePopover((value) => rowData.rolePublicId = value.rolePublicId, rowData, 240);
					}
					else {
						let role = this.state.allRoles.find((role) => role.roleCode === columnValue);
						return <div>{role ? role.displayName : 'N/A'}</div>;
					}
				};
			}

			result.payload.push({
				title: 'Actions',
				key: ACTIONS,
				renderFunction: (columnValue, rowData) => {
					if (rowData.status === STATUS_PREREGISTERED) {
						return (
							<div style={{display: 'flex', justifyContent: 'center'}}>
								<LinkButton label={'DELETE'} onClick={() => this.deletePreRegUser(rowData)}/>
							</div>
						);
					}
					else {
						return (
							<div style={{display: 'flex', justifyContent: 'center'}}>
								<LinkButton label={'PRE-REG'} onClick={() => this.preRegCRMUser(rowData)}/>
							</div>
						);
					}
				}
			});
			this.initColumnData(result.payload, false);
			this.fetchRoles(org);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchUserColumns', error);
		});
	}

	fetchRoles(org) {
		Promise.all([AdminEndpoints.fetchRoles(), AdminEndpoints.fetchRoles(org.orgId)])
		.then((results) => {
			if (this.unmounted) return;
			let standardRoles = results[0].payload.filter((role) => role.roleCode !== SUPERADMIN);
			let customRoles = results[1].payload;
			let defaultRole = standardRoles.find((role) => role.roleCode === CHANNEL_SALES_REP);
			this.setState({allRoles: [...standardRoles, ...customRoles], rolePublicId: defaultRole.rolePublicId, selectedOrg: org, loading: true});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchRoles', error);
		});
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get additionalToolbarButtons() {
		return [
			<PrimaryButton key={'add_pre_reg_user'}
						   rowDataNotRequired={true}
						   label={'ADD PRE-REGISTER USER'}
						   icon={<PersonAdd/>}
						   onClick={() => this.setState({showNewUserDialog: true})}/>
		];
	}

	get preRegDialog() {
		return <Dialog title={'Pre-register New User'}
					   message={this.manualPreRegForm}
					   noLabel={'CANCEL'}
					   noAction={() => {
						   this.resetInputs();
						   this.setState({showNewUserDialog: false});
					   }}
					   yesLabel={'SAVE'}
					   yesAction={this.preRegManualUser}/>;
	}

	get manualPreRegForm() {
		let {isMissingRequiredValue, errorMessage} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: partnerTapWhite}}>
				{Boolean(errorMessage) &&
				 <div style={{padding: 10, display: 'flex', flexDirection: 'column', color: partnerTapAlert}}>
					 {errorMessage}
				 </div>}
				<div style={{padding: 10, width: 320}}>
					<TextInputBox hintText={'First Name'}
								  value={this.state.firstName}
								  errorText={(isMissingRequiredValue && !this.state.firstName) ? 'First Name is required' : null}
								  onChange={this.inputFirstName}
								  maxChars={255}/>
				</div>
				<div style={{padding: 10, width: 320}}>
					<TextInputBox hintText={'Last Name'}
								  value={this.state.lastName}
								  errorText={(isMissingRequiredValue && !this.state.lastName) ? 'Last Name is required' : null}
								  onChange={this.inputLastName}
								  maxChars={255}/>
				</div>
				<div style={{padding: 10, width: 320}}>
					<TextInputBox hintText={'Email Address'}
								  value={this.state.emailAddress}
								  inputMode={'email'}
								  errorText={(isMissingRequiredValue && !StringHelper.validateEmail(this.state.emailAddress)) ? 'Email Address must be valid' : null}
								  onChange={this.inputEmail}
								  maxChars={255}/>
				</div>
				<div style={{padding: 10}}>
					{this.renderRolePopover(this.inputRolePublicId, this.state, 300)}
				</div>
			</div>
		);
	}

	renderRolePopover(onItemSelection, data, width) {
		let {allRoles} = this.state;
		let currentRole = allRoles.find((role) => role.roleCode === data.roleCode || role.rolePublicId === data.rolePublicId);
		let channelRepRole = allRoles.find((role) => role.roleCode === CHANNEL_SALES_REP);
		return <PopoverSearchList label={'Select Role'}
								  list={allRoles}
								  labelRenderer={(role) => role.displayName}
								  preselectedItem={currentRole || channelRepRole}
								  onItemSelected={onItemSelection}
								  searchByObjectKeys={['displayName']}
								  minWidth={width}
								  maxWidth={width}/>;
	}

	preRegManualUser() {
		let {selectedOrg, firstName, lastName, emailAddress, rolePublicId} = this.state;
		let isMissingRequiredValue = !StringHelper.validateEmail(emailAddress) || !firstName || !lastName || !rolePublicId;
		if (isMissingRequiredValue) {
			this.setState({isMissingRequiredValue: true});
			return;
		}
		this.setState({showLoadingScrim: true, isMissingRequiredValue: false});
		AdminEndpoints.saveManualPreRegUser(selectedOrg.orgId, firstName, lastName, emailAddress, rolePublicId)
		.then((result) => {
			if (this.unmounted) return;
			this.resetInputs();
			this.setState({showNewUserDialog: false, showLoadingScrim: false, loading: true});
		})
		.catch((error) => {
			console.error('Error saving pre-reg user', this.state, error);
			this.setState({errorMessage: ErrorMessageHelper.renderErrorMessage(error.message), showLoadingScrim: false});
		});
	}

	preRegCRMUser(user) {
		if (!user.rolePublicId) {
			let defaultRole = this.state.allRoles.find((role) => role.roleCode === CHANNEL_SALES_REP);
			user.rolePublicId = defaultRole.rolePublicId;
		}
		AdminEndpoints.saveCRMPreRegUser(this.state.selectedOrg.orgId, user)
		.then(() => {
			if (this.unmounted) return;
			this.setState({loading: true});
		})
		.catch((error) => {
			console.error('Error activating pre-reg user', user, error);
			EnvHelper.serverError('Error activating pre-reg user', error);
		});
	}

	deletePreRegUser(user) {
		if (!user.rolePublicId) {
			let defaultRole = this.state.allRoles.find((role) => role.roleCode === CHANNEL_SALES_REP);
			user.rolePublicId = defaultRole.rolePublicId;
		}
		AdminEndpoints.deletePreRegUser(this.state.selectedOrg.orgId, user)
		.then(() => {
			if (this.unmounted) return;
			this.setState({loading: true});
		})
		.catch((error) => {
			console.error('Error deleting pre-reg user', user, error);
			EnvHelper.serverError('Error deleting pre-reg user', error);
		});
	}

	inputFirstName(value) {
		this.setState({firstName: value});
	}

	inputLastName(value) {
		this.setState({lastName: value});
	}

	inputEmail(value) {
		this.setState({emailAddress: value});
	}

	inputRolePublicId(value) {
		this.setState({rolePublicId: value.rolePublicId});
	}

	resetInputs() {
		this.setState({
			firstName: '',
			lastName: '',
			emailAddress: '',
			roleCode: CHANNEL_SALES_REP,
			isMissingRequiredValue: false,
			errorMessage: null
		});
	}

	get filterSelectorArgs() {
		return {orgId: this.state.selectedOrg.orgId, isPreReg: true};
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {selectedOrg} = this.state;
		let params = this.processParameters();
		AdminEndpoints.fetchUserProfiles(selectedOrg.orgId, params.search, params.filters, params.page, params.pageSize, params.sort, true)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						This org does not have any CRM users available for pre-registration.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	render() {
		return (
			<div>
				{this.state.showLoadingScrim &&
				 <ScrimMessage message={'Saving pre-reg user...'}/>}
				<div style={{display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: partnerTapWhite}}>
					<OrgSelector onOrgSelection={this.handleOrgSelection}/>
				</div>
				{this.state.showNewUserDialog && this.preRegDialog}
				{Boolean(this.state.selectedOrg) && super.render()}
			</div>
		);
	}
}

export default connect()(AdminPreRegUsersPage);
