import FeatureHelper, {FEATURE_COSELL_EDIT_ACTION_LISTS, FEATURE_COSELL_EDIT_ALL_ACTION_LISTS} from '../../../helpers/FeatureHelper';

// co-sell workflows
export const WORKFLOW_COSELL_PLAY = 'COSELL_PLAY';
export const WORKFLOW_SALES_PLAY = 'SALES_PLAY';
export const WORKFLOW_THRU_CHANNEL_PLAY = 'THRU_CHANNEL_PLAY';
export const WORKFLOW_SHARE_ACTION_LIST = 'SHARE_ACTION_LIST';
export const WORKFLOW_CRM_TRACKING = 'CRM_TRACKING';
export const WORKFLOW_P2P = 'P2P';
export const WORKFLOW_LEAD = 'LEAD';

// co-sell workflow sequences
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO = 'INTRO';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN = 'PARTNER_OPT_IN_THEN_INTRO';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN = 'OWNER_OPT_IN_THEN_INTRO';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN = 'PARTNER_OPT_IN_THEN_OWNER_OPT_IN_THEN_INTRO';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN = 'OWNER_OPT_IN_THEN_PARTNER_OPT_IN_THEN_INTRO';

// p2p workflow sequences
export const WORKFLOW_SEQUENCE_P2P_INTRO = 'P2P_INTRO';
export const WORKFLOW_SEQUENCE_P2P_OPT_IN_THEN_INTRO = 'P2P_OPT_IN_THEN_INTRO';
export const WORKFLOW_SEQUENCE_P2P_DOUBLE_OPT_IN_THEN_INTRO = 'P2P_DOUBLE_OPT_IN_THEN_INTRO';

// distribute action list records internal workflow sequences
export const WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_INTERNAL = 'ACTION_LIST_INTERNAL';

// distribute action list records workflow sequences
export const WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL = 'ACTION_LIST_EXTERNAL';

// share action list workflow sequences
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_CONNECTED_PARTNER = 'SHARE_WITH_CONNECTED_PARTNER';
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_FELLOW_EMPLOYEE = 'SHARE_WITH_FELLOW_EMPLOYEE';
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_EMAIL_ADDRESS = 'SHARE_WITH_EMAIL_ADDRESS';

// co-sell email landing page steps
export const LANDING_STEP_OPT_IN_OWNER = 'OPT_IN_OWNER';
export const LANDING_STEP_OPT_IN_PARTNER = 'OPT_IN_PARTNER';
export const LANDING_STEP_OWNER_AND_PARTNER_INTRO_EMAIL = 'OWNER_AND_PARTNER_INTRO_EMAIL';
export const LANDING_STEP_P2P_INTRO = 'P2P_INTRO';
export const LANDING_STEP_P2P_OPTIN = 'P2P_OPT_IN';
export const LANDING_STEP_ACTION_LIST_INTERNAL_EMAIL = 'ACTION_LIST_INTERNAL_EMAIL';
export const LANDING_STEP_ACTION_LIST_EXTERNAL_EMAIL = 'ACTION_LIST_EXTERNAL_EMAIL';
export const LANDING_STEP_CRM_TRACKING = 'CRM_TRACKING';

// co-sell dashboard and report types
export const DASHBOARD_TYPE_WORKFLOW= 'WORKFLOW_DASHBOARD';
export const REPORT_WORKFLOW_ACTIVITY = 'WORKFLOW_ACTIVITY';
export const REPORT_ATTRIBUTION = 'ATTRIBUTION';

export const RECIPIENT_OWNER = 'OWNER';
export const RECIPIENT_PARTNER = 'PARTNER';
export const AUTHOR = 'AUTHOR';

export const ACTION_LIST_REPORT_TYPE = 'VIEW_ACTION_LIST_ACTIVE';
export const ACTION_LIST_ARCHIVED_REPORT_TYPE = 'VIEW_ACTION_LIST_ARCHIVED';

export function getRecipientDisplayName (recipientType, workflowName) {
	switch (workflowName) {
		case WORKFLOW_P2P:
			return recipientDisplayNameP2P[recipientType];
		default:
			return recipientDisplayName[recipientType];
	}
}

export const recipientDisplayNameP2P = {
	[RECIPIENT_OWNER]: 'Partner 1 Recipient',
	[RECIPIENT_PARTNER]: 'Partner 2 Recipient',
	[AUTHOR]: 'Author'
};

export const recipientDisplayName = {
	[RECIPIENT_OWNER]: 'Sales Rep Recipient',
	[RECIPIENT_PARTNER]: 'Partner Recipient',
	[AUTHOR]: 'Author'
};

export const recipientPayloadField = {
	[RECIPIENT_OWNER]: 'ownerColumn',
	[RECIPIENT_PARTNER]: 'partnerColumn'
};

export const RECIPIENT_FIELD_EMAIL = 'EMAIL';
export const RECIPIENT_FIELD_NAME = 'NAME';
export const RECIPIENT_FIELD_TITLE = 'TITLE';
export const SELECT_NONE = 'none';

export const recipientFieldDisplayName = {
	[RECIPIENT_FIELD_EMAIL]: 'Email',
	[RECIPIENT_FIELD_NAME]: 'Name',
	[RECIPIENT_FIELD_TITLE]: 'Title'
};

export const recipientFieldPayloadField = {
	[RECIPIENT_FIELD_EMAIL]: 'emailColumn',
	[RECIPIENT_FIELD_NAME]: 'nameColumn',
	[RECIPIENT_FIELD_TITLE]: 'titleColumn'
};

export const workflowOrdinal = {
	[WORKFLOW_COSELL_PLAY]: 1,
	[WORKFLOW_SALES_PLAY]: 2,
	[WORKFLOW_THRU_CHANNEL_PLAY]: 3
};

export const sequenceOrdinal = {
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO]: 1,
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN]: 2,
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN]: 3,
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN]: 4,
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN]: 5,
	[WORKFLOW_SEQUENCE_P2P_INTRO]: 6,
	[WORKFLOW_SEQUENCE_P2P_OPT_IN_THEN_INTRO]: 7,
	[WORKFLOW_SEQUENCE_P2P_DOUBLE_OPT_IN_THEN_INTRO]: 8
};

export const ACTION_LIST_PROCESSING_STATUS_READY_TO_PROCESS = 'Ready to process';
export const ACTION_LIST_PROCESSING_STATUS_PROCESSING = 'Processing';
export const ACTION_LIST_PROCESSING_STATUS_AVAILABLE = 'Available';
export const ACTION_LIST_PROCESSING_STATUS_FAILED = 'Failed';
export const ACTION_LIST_WORKFLOW_STATUS_READY = 'Ready';
export const ACTION_LIST_WORKFLOW_STATUS_COMPLETE = 'Completed';
export const ACTION_LIST_WORKFLOW_STATUS_STOPPED = 'Stopped';

// co-sell workflow status
export const WORKFLOW_STATUS_RUNNING = 'RUNNING';
export const WORKFLOW_STATUS_STOPPED = 'STOPPED';

export const INTRO_EMAIL_CRM_NONE = 'NONE';
export const INTRO_EMAIL_CRM_COSELL_INTRO = 'COSELL_INTRO';

export const nudgeOptions = [
	{label: 'None', value: 0},
	{label: '3 days', value: 3},
	{label: '4 days', value: 4},
	{label: '5 days', value: 5},
	{label: '6 days', value: 6},
	{label: '7 days', value: 7},
	{label: '8 days', value: 8},
	{label: '9 days', value: 9},
	{label: '10 days', value: 10}
];

export const emailConfig = {
	actionListMetadataPublicId: null,
	emailWorkflow: {
		ownerColumn: {emailColumn: null, nameColumn: null, titleColumn: null},
		partnerColumn: {emailColumn: null, nameColumn: null, titleColumn: null},
		manualRecipient1: {name: null, email: null, title: null},
		manualRecipient2: {name: null, email: null, title: null},
		isRecipient1FromActionList: true,
		isRecipient2FromActionList: true,
		customizations: null,
		nudge: null,
		crmTrackingOption: INTRO_EMAIL_CRM_NONE
	}
};

// co-sell action list manual recipient column names
export const ACTION_LIST_COLUMNS = {
	manualRecipient1: {
		email: "manual_recipient1_email",
		name: "manual_recipient1_name",
		title: "manual_recipient1_title"
	},
	manualRecipient2: {
		email: "manual_recipient2_email",
		name: "manual_recipient2_name",
		title: "manual_recipient2_title"
	}
};

export function clearEmailConfig() {
	emailConfig.actionListMetadataPublicId = null;
	emailConfig.emailWorkflow = {
		ownerColumn: {emailColumn: null, nameColumn: null, titleColumn: null},
		partnerColumn: {emailColumn: null, nameColumn: null, titleColumn: null},
		manualRecipient1: {name: null, email: null, title: null},
		manualRecipient2: {name: null, email: null, title: null},
		isRecipient1FromActionList: true,
		isRecipient2FromActionList: true,
		customizations: null,
		nudge: null,
		crmTrackingOption: INTRO_EMAIL_CRM_NONE,
		recipients: []
	};
}

export function isWorkflowAvailable(actionListMetadata) {
	return actionListMetadata.processingStatus === ACTION_LIST_PROCESSING_STATUS_AVAILABLE &&
		   !actionListMetadata.activeWorkflow;
}

export function canUserEditActionLists(actionListMetadata, authState) {
	const canUserEditAllActionLists = FeatureHelper.isFeatureEnabled(FEATURE_COSELL_EDIT_ALL_ACTION_LISTS);
	const canUserEditOwnActionLists = FeatureHelper.isFeatureEnabled(FEATURE_COSELL_EDIT_ACTION_LISTS);
	const isUserActionListAuthor = actionListMetadata?.createdByPerson === authState.person.userName;

	return canUserEditAllActionLists || (canUserEditOwnActionLists && isUserActionListAuthor);
}