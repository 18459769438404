import {Cancel} from '@mui/icons-material';
import {InputAdornment, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import EnvHelper from '../helpers/EnvHelper';
import StringHelper from '../helpers/StringHelper';
import {
	partnerTapBlack,
	partnerTapPrimary,
	partnerTapPrimaryLight,
	partnerTapSecondary,
	partnerTapSecondaryLight,
	partnerTapStroke,
	partnerTapWhite
} from '../styles/partnertap_theme';

class TextInputBox extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			value: this.props.value || '',
			isOpen: false,
			filteredSuggestions: [],
			cursorPosition: 0,
			suggestionStart: 0,
		};
		this.onChange = this.onChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.inputRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({value: this.props.value});
		}
	}

	onChange(event) {
		let {maxChars, onChange, enableIntellisense, mergeFields} = this.props;
		let value = event.target.value;
		if (maxChars) value = value.substring(0, maxChars);

		const cursorPosition = event.target.selectionStart;

		this.setState({value, cursorPosition}, () => {
			onChange(value);

			if (enableIntellisense) {
				const beforeCursor = value.slice(0, cursorPosition);
				const match = beforeCursor.match(/\$\{([^}]*)$/);
				if (match) {
					const query = match[1];
					const suggestionStart = match.index + 2; // +2 to account for '${'
					this.setState({
						isOpen: true,
						filteredSuggestions: mergeFields.filter(field =>
							field.toLowerCase().includes(query.toLowerCase())
						),
						suggestionStart,
					});
				}
				else {
					this.setState({isOpen: false});
				}
			}
		});
	}

	handleSelect(field) {
		const {value, suggestionStart, cursorPosition} = this.state;
		const before = value.slice(0, suggestionStart);
		let after = value.slice(cursorPosition).replace(/^[^}]*/, '');
		if (after.charAt(0) !== '}') {
			after = `}${after}`;
		}
		const newValue = `${before}${field}${after}`;
		const newCursorPosition = suggestionStart + field.length + 1;

		this.setState({value: newValue, isOpen: false}, () => {
			this.props.onChange(newValue);
			this.inputRef.current.focus();
			this.inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
		});
	}

	render() {
		let {
			type,
			icon,
			hintText,
			placeholderText,
			errorText,
			onKeyDown,
			onEnter,
			onFocus,
			onBlur,
			onClear,
			doNotAutoFocus,
			inputMode,
			rows,
			minWidth,
			maxWidth,
			multiLine,
			disabled,
			isSimple,
			fontSize,
			enableIntellisense,
			readOnly
		} = this.props;

		const {value, isOpen, filteredSuggestions} = this.state;

		return (
			<div style={{position: 'relative'}}>
				<TextField
					variant={'outlined'}
					color={'primary'}
					size={isSimple ? 'small' : 'medium'}
					style={{
						backgroundColor: 'rgba(255, 255, 255, 0.5)',
						borderRadius: 4,
						minWidth: minWidth || 180,
						maxWidth: maxWidth || null
					}}
					fullWidth={true}
					rows={rows}
					multiline={Boolean(rows) || multiLine}
					disabled={disabled}
					label={isSimple ? undefined : hintText}
					aria-placeholder={placeholderText || hintText}
					placeholder={placeholderText || (isSimple && hintText)}
					type={type ? type : 'text'}
					value={value}
					error={Boolean(errorText)}
					helperText={errorText}
					onChange={this.onChange}
					onKeyDown={onKeyDown}
					onKeyUp={(event) => {
						if (event.key === 'Enter' && onEnter) {
							onEnter(value);
						}
					}}
					onFocus={onFocus}
					onBlur={onBlur}
					autoFocus={!doNotAutoFocus && EnvHelper.isDesktop && Boolean(value || placeholderText)}
					inputRef={this.inputRef}
					inputProps={{
						inputMode: inputMode || 'text',
						'data-cy': 'text_field_' + (hintText ? StringHelper.formatKey(hintText) : '0')
					}}
					InputProps={{
						readOnly: readOnly,
						style: {fontSize: isSimple && fontSize ? fontSize : null},
						startAdornment: icon &&
										<InputAdornment position={'start'} style={{color: partnerTapStroke}}>
											{icon}
										</InputAdornment>,
						endAdornment: onClear &&
									  (value ?
										  <InputAdornment position={'end'}>
											  <Cancel data-cy={'clear_input'}
													  style={{cursor: 'pointer', color: partnerTapSecondary}}
													  onClick={onClear}/>
										  </InputAdornment>
										  :
										  <div style={{width: 32}}/>)
					}}
				/>
				{enableIntellisense && isOpen && filteredSuggestions.length > 0 && (
					<ul style={{
						position: 'absolute',
						border: '1px solid #ccc',
						padding: 0,
						margin: 0,
						listStyleType: 'none',
						backgroundColor: '#fff',
						maxHeight: '150px',
						overflowY: 'auto',
						zIndex: 100,
						width: '100%',
					}}>
						{filteredSuggestions.map((field, index) => (
							<li
								key={index}
								onClick={() => this.handleSelect(field)}
								onMouseEnter={(e) => {
									e.target.style.backgroundColor = partnerTapPrimary;
									e.target.style.color = partnerTapWhite;
								}}
								onMouseLeave={(e) => {
									e.target.style.backgroundColor = partnerTapSecondaryLight;
									e.target.style.color = partnerTapBlack;
								}}
								style={{
									padding: '8px',
									cursor: 'pointer',
									backgroundColor: partnerTapSecondaryLight,
									borderBottom: '1px solid #ddd',
									transition: 'background-color 0.2s ease',
								}}
							>
								${field}
							</li>
						))}
					</ul>
				)}
			</div>
		);
	}
}

TextInputBox.propTypes = {
	onChange: PropTypes.func.isRequired,
	onEnter: PropTypes.func,
	onClear: PropTypes.func,
	onKeyDown: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	doNotAutoFocus: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputMode: PropTypes.string,
	hintText: PropTypes.string,
	placeholderText: PropTypes.string,
	errorText: PropTypes.string,
	rows: PropTypes.number,
	multiLine: PropTypes.bool,
	maxChars: PropTypes.number,
	minWidth: PropTypes.number,
	maxWidth: PropTypes.number,
	disabled: PropTypes.bool,
	icon: PropTypes.element,
	isSimple: PropTypes.bool,
	fontSize: PropTypes.number,
	enableIntellisense: PropTypes.bool,
	mergeFields: PropTypes.arrayOf(PropTypes.string),
	readOnly: PropTypes.bool,
};

export default TextInputBox;
