import {CheckCircleOutlineRounded, ErrorOutlineRounded, RemoveCircleOutlineRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {BASE_OPPORTUNITIES_MANUAL, UPLOAD_DATA_MANAGER_ENRICH_SHEET, UPLOAD_DATA_MANAGER_SHEET, UPLOAD_PARTNER_SHEET} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import EnvHelper from '../../../../helpers/EnvHelper';
import UploadHelper, {
	OPP_UPLOAD_SHEET_COLUMN_ACCOUNT_ID,
	PERSON_QUEUE_STATUS_INIT,
	PERSON_QUEUE_STATUS_UPLOADED_TO_S3,
	UPLOAD_SHEET_COLUMN_ACCOUNT_ID,
	UPLOAD_SHEET_COLUMN_ACCOUNT_NAME,
	UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE,
	UPLOAD_SHEET_COLUMN_CITY,
	UPLOAD_SHEET_COLUMN_COUNTRY,
	UPLOAD_SHEET_COLUMN_DUNS,
	UPLOAD_SHEET_COLUMN_INDUSTRY,
	UPLOAD_SHEET_COLUMN_NUMBER_OF_EMPLOYEES,
	OPP_UPLOAD_SHEET_COLUMN_OPPORTUNITY_ID,
	OPP_UPLOAD_SHEET_COLUMN_OPPORTUNITY_NAME,
	UPLOAD_SHEET_COLUMN_OWNER_DIVISION,
	UPLOAD_SHEET_COLUMN_OWNER_EMAIL,
	UPLOAD_SHEET_COLUMN_OWNER_NAME,
	UPLOAD_SHEET_COLUMN_OWNER_TITLE,
	UPLOAD_SHEET_COLUMN_PHONE,
	UPLOAD_SHEET_COLUMN_STATE,
	UPLOAD_SHEET_COLUMN_STREET,
	UPLOAD_SHEET_COLUMN_TAX_ID,
	UPLOAD_SHEET_COLUMN_WEBSITE,
	UPLOAD_SHEET_COLUMN_ZIP,
	OPP_UPLOAD_SHEET_COLUMN_STAGE,
	OPP_UPLOAD_SHEET_COLUMN_CREATED_DATE,
	OPP_UPLOAD_SHEET_COLUMN_IS_WON,
	OPP_UPLOAD_SHEET_COLUMN_IS_CLOSED
} from '../../../../helpers/UploadHelper';
import {partnerTapAlert, partnerTapInactive, partnerTapPrimary, partnerTapTernary, partnerTapWhite} from '../../../../styles/partnertap_theme';
import InfoButton from '../../../../ui/buttons/InfoButton';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import PopoverSearchList from '../../../../ui/PopoverSearchList';
import Stepper from '../../../../ui/Stepper';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import CsvHelper from '../../../../helpers/CsvHelper';
import ExpandableMessage from '../../../../ui/messages/ExpandableMessage';
import MessageBoxInfo from '../../../../ui/messages/MessageBoxInfo';
import Radio from '@mui/material/Radio';

export const DEFAULT_NOT_MAPPED_COLUMNS = [
	"OPEN_OPPS",
	"EMPLOYEE_COUNT",
	"CLOSED_OPPS",
	"SIC#",
	"NAICS#",
	"PHONE_NUMBER",
	"TAX_ID",
	"ACCOUNT_OWNER_NAME",
	"ACCOUNT_OWNER_EMAIL",
	"ACCOUNT_OWNER_TITLE",
	"ACCOUNT_OWNER_PHONE",
	"ACCOUNT_OWNER_DIVISION",
	"SIC_CODE",
	"NAICS_CODE",
	"PHONE_NUMBER",
	"TAX_ID",
	"CUSTOM_CRM_OBJECT_JSON",
	"CUSTOM_CRM_FIELDS",
	"ZIP_CODE"
];

const CUSTOMER = 'SET ALL TO CUSTOMER';
const PROSPECT = 'SET ALL TO PROSPECT';
const virtualHeaderDisplayNameToValue = new Map([
	[CUSTOMER, 'CUSTOMER'],
	[PROSPECT, 'PROSPECT']
]);
const virtualColumnValueToDisplayName = new Map([
	['CUSTOMER', CUSTOMER],
	['PROSPECT', PROSPECT]
]);

const tableDataCellStyle = {padding: 10, fontSize: 14, backGroundColor: partnerTapWhite};

const MAPPED_NONE = 'None';
const SAMPLES_DELIMITER = '~~~';

class SheetColumnMapping extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			steps: UploadHelper.getUploadSteps(this.uploadType, this.step),
			formIsValid: false,
			updatingStep: false,
			virtualHeaders: null,
			includeSelectAllChecked: false,
			columnKeyHasError: {},
			uniqueIDColumns: [],
			UIDColumnSelected: UPLOAD_SHEET_COLUMN_ACCOUNT_ID
		};

		this.fetchPersonUpload = this.fetchPersonUpload.bind(this);
		this.onSheetMappingSelected = this.onSheetMappingSelected.bind(this);
		this.onSetCustomColumnIsIncluded = this.onSetCustomColumnIsIncluded.bind(this);
		this.updateCustomFields = this.updateCustomFields.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onContinue = this.onContinue.bind(this);
		this.isColumnOnCurrentStep = this.isColumnOnCurrentStep.bind(this);
		this.fetchOrgMetadata = this.fetchOrgMetadata.bind(this);
	}

	componentDidMount() {
		if (EnvHelper.isSpoofing) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(this.uploadType));
		}
		else {
			this.fetchPersonUpload();
			this.fetchOrgMetadata();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get personUploadId() {
		return this.props.match.params.personUploadId || this.props.personUploadId;
	}

	get uploadType() {
		return this.props.match.params.uploadType;
	}

	get step() {
		return parseInt(this.props.match.params.step);
	}

	get isCustomStep() {
		let {steps} = this.state;
		return this.step === steps.length - 1;
	}

	get isPartnerSheet() {
		return this.uploadType === UPLOAD_PARTNER_SHEET;
	}

	get isDataManagerEnrichSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_ENRICH_SHEET;
	}

	get isManualSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_SHEET;

	}

	get isBaseOpportunitiesManual() {
		return this.uploadType === BASE_OPPORTUNITIES_MANUAL;
	}

	get isEnrichSheetStep1() {
		return (this.isDataManagerEnrichSheet && this.step === 1);
	}

	get isManualSheetStep2() {
		return (this.isManualSheet && this.step === 2);
	}

	fetchPersonUpload() {
		ChannelMappingEndpoints.getPersonUpload(this.personUploadId)
		.then((result) => {
			if (this.unmounted) return;
			let {payload} = result;
			this.processUploadUpdate(payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getPersonUpload', error);
		});
	}

	fetchOrgMetadata() {
		(ChannelMappingEndpoints.getOrgCustomMetadata(this.props.authState.profile.orgId)
		.then(result => {
			this.setState({
				uniqueIDColumns: (result?.payload?.customMetaData?.['types'] || []).length > 0 ? this.getUniqueColumnsAsArray(result.payload.customMetaData['types']) : []
			});
		}));
	}

	getUniqueColumnsAsArray(data) {
		return data.filter(input => input.isUniqueId === true).map(item => ({'label': item.label, 'name': item.name}));
	}

	processUploadUpdate(payload) {
		if (payload.status !== PERSON_QUEUE_STATUS_INIT && payload.status !== PERSON_QUEUE_STATUS_UPLOADED_TO_S3) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(this.uploadType));
		}

		let headerMapping = JSON.parse(payload.headerMapping);
		let virtualHeaders = JSON.parse(payload.virtualHeaders);

		let customMapping = {};
		if (payload.customFields) {
			payload.customFields.split(',').forEach((field) => customMapping[field] = true);
		}

		let headerDescriptions = JSON.parse(payload.headerDescriptions);
		let sampleValues = JSON.parse(payload.sampleValues);

		let formIsValid = Object.keys(headerMapping).every((column) => {
			return !this.isColumnOnCurrentStep(column) || !this.isColumnRequired(column) || headerMapping[column] || virtualHeaders[column];
		});
		if (this.isEnrichSheetStep1) {
			formIsValid = headerMapping[this.state.UIDColumnSelected];
		}
		this.setState({
			loading: false,
			headers: payload.headers,
			headerOrder: payload.headerOrder,
			partnerTapMapping: headerMapping,
			customMapping: customMapping,
			virtualHeaders: virtualHeaders,
			headerDescriptions: headerDescriptions,
			sampleValues: sampleValues,
			unmappedHeaders: this.updateUnmappedHeaders(payload.headers, headerMapping),
			formIsValid: formIsValid,
			steps: UploadHelper.getUploadSteps(this.uploadType, this.step, !formIsValid)
		});
	}

	updateUnmappedHeaders(headers, partnerTapMapping) {
		let unmappedHeaders = [];
		headers.forEach((header) => {
			let found = Object.values(partnerTapMapping).find((sheetColumnKey) => {
				return sheetColumnKey === header;
			});
			if (!found) {
				unmappedHeaders.push(header);
			}
		});
		return unmappedHeaders;
	}

	isColumnRequired(column) {
		if (this.isDataManagerEnrichSheet) {
			return column === this.state.UIDColumnSelected;
		}

		if (this.isBaseOpportunitiesManual) {
			return column === OPP_UPLOAD_SHEET_COLUMN_ACCOUNT_ID ||
				   column === OPP_UPLOAD_SHEET_COLUMN_OPPORTUNITY_NAME ||
				   column === OPP_UPLOAD_SHEET_COLUMN_OPPORTUNITY_ID ||
				   column === OPP_UPLOAD_SHEET_COLUMN_STAGE ||
				   column === OPP_UPLOAD_SHEET_COLUMN_CREATED_DATE ||
				   column === OPP_UPLOAD_SHEET_COLUMN_IS_WON ||
			       column === OPP_UPLOAD_SHEET_COLUMN_IS_CLOSED;
		}

		return column === UPLOAD_SHEET_COLUMN_ACCOUNT_NAME || column === UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE;
	}

	isUIDStep(column) {
		if (this.isBaseOpportunitiesManual) {
			return false;
		}
		return column === UPLOAD_SHEET_COLUMN_ACCOUNT_ID;
	}

	isColumnOnCurrentStep(column) {
		switch (this.step) {
			case 1: {
				if (this.isBaseOpportunitiesManual) {
					return column === OPP_UPLOAD_SHEET_COLUMN_ACCOUNT_ID ||
						   column === OPP_UPLOAD_SHEET_COLUMN_OPPORTUNITY_NAME ||
						   column === OPP_UPLOAD_SHEET_COLUMN_OPPORTUNITY_ID ||
						   column === OPP_UPLOAD_SHEET_COLUMN_STAGE ||
						   column === OPP_UPLOAD_SHEET_COLUMN_CREATED_DATE ||
						   column === OPP_UPLOAD_SHEET_COLUMN_IS_WON ||
						   column === OPP_UPLOAD_SHEET_COLUMN_IS_CLOSED;
				}

				return column === UPLOAD_SHEET_COLUMN_ACCOUNT_NAME ||
					   column === UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE;
			}
			case 2: {
				if (column === UPLOAD_SHEET_COLUMN_WEBSITE ||
					column === UPLOAD_SHEET_COLUMN_PHONE ||
					column === UPLOAD_SHEET_COLUMN_STREET ||
					column === UPLOAD_SHEET_COLUMN_CITY ||
					column === UPLOAD_SHEET_COLUMN_STATE ||
					column === UPLOAD_SHEET_COLUMN_ZIP ||
					column === UPLOAD_SHEET_COLUMN_COUNTRY ||

					column === UPLOAD_SHEET_COLUMN_OWNER_NAME ||
					column === UPLOAD_SHEET_COLUMN_OWNER_EMAIL ||
					column === UPLOAD_SHEET_COLUMN_OWNER_TITLE ||
					column === UPLOAD_SHEET_COLUMN_OWNER_DIVISION ||

					column === UPLOAD_SHEET_COLUMN_DUNS ||
					column === UPLOAD_SHEET_COLUMN_TAX_ID ||
					column === UPLOAD_SHEET_COLUMN_INDUSTRY ||
					column === UPLOAD_SHEET_COLUMN_NUMBER_OF_EMPLOYEES) {
					return true;
				}

				if (this.isDataManagerEnrichSheet) {
					return column === UPLOAD_SHEET_COLUMN_ACCOUNT_NAME ||
						   column === UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE;
				}

				return column === UPLOAD_SHEET_COLUMN_ACCOUNT_ID;
			}
		}
		return false;
	}

	async onSheetMappingSelected(columnKey, columnName) {
		if (this.isEnrichSheetStep1) {
			this.setState({...this.state, formIsValid: true});
		}
		let {partnerTapMapping, virtualHeaders, columnKeyHasError, uniqueIDColumns} = this.state;

		columnKeyHasError[columnKey] = false;

		let alreadyMappedColumnKey = Object.keys(partnerTapMapping).find((mappedColumnKey) => partnerTapMapping[mappedColumnKey] === columnName);
		if (alreadyMappedColumnKey) {
			partnerTapMapping[alreadyMappedColumnKey] = null;
		}
		partnerTapMapping[columnKey] = columnName === MAPPED_NONE ? null : columnName;

		if (columnKey === UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE) {
			if (columnName === CUSTOMER || columnName === PROSPECT) {
				partnerTapMapping[columnKey] = null;
				virtualHeaders = {[columnKey]: virtualHeaderDisplayNameToValue.get(columnName)};
			}
			else {
				virtualHeaders = {[columnKey]: null};
			}
		}

		try {
			const result = await ChannelMappingEndpoints.updatePersonUpload(this.personUploadId, partnerTapMapping, virtualHeaders);
			if (!this.unmounted) {
				this.processUploadUpdate(result.payload);
			}

		}
		catch (error) {
			let errorMessage = JSON.parse(error.message);
			let columnData = errorMessage.error.data.find((errorColumn) => errorColumn.header === columnName && errorColumn.isMissingDataSamples);
			columnKeyHasError[columnKey] = Boolean(columnData);
			this.setState({formIsValid: false, virtualHeaders: virtualHeaders});
			EnvHelper.serverError('Error from updatePersonUpload', error);
		}
	}

	onSetAllCustomColumnIsIncluded(isIncluded) {
		let {unmappedHeaders} = this.state;
		unmappedHeaders = CsvHelper.removeMissingHeaders(unmappedHeaders);
		unmappedHeaders.forEach((unmappedHeader) => this.onSetCustomColumnIsIncluded(unmappedHeader, isIncluded, true));
		this.setState({includeSelectAllChecked: isIncluded});
		this.updateCustomFields();
	}

	onSetCustomColumnIsIncluded(columnKey, isIncluded, doNotUpdate = false) {
		let {customMapping} = this.state;

		if(this.isDuplicatedColumnInCustomStep(columnKey)) {
			return;
		}

		customMapping[columnKey] = isIncluded;

		if (!doNotUpdate) this.updateCustomFields();

	}

	updateCustomFields() {
		let customMappingList = [];
		let {customMapping} = this.state;
		Object.keys(customMapping).forEach((columnKey) => {
			if (customMapping[columnKey]) customMappingList.push(columnKey);
		});

		ChannelMappingEndpoints.updateCustomFields(this.personUploadId, customMappingList.join(','))
		.then((result) => {
			if (this.unmounted) return;
			this.processUploadUpdate(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from updateCustomFields', error);
		});
	}

	async onContinue() {
		this.setState({updatingStep: true});
		if (this.isCustomStep) {
			let customColumns = [];
			let {customMapping} = this.state;
			Object.keys(customMapping).forEach((columnKey) => {
				customColumns.push({name: columnKey, label: columnKey, type: 'string'});
			});

			// Determine the key for the custom metadata asset dynamically
			const customMetadataKey = this.isBaseOpportunitiesManual ? 'opps' : 'accounts';

			ChannelMappingEndpoints.mappingComplete(this.personUploadId, {[customMetadataKey]: customColumns})
			.then((result) => {
				if (this.unmounted) return;
				EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(this.uploadType, this.personUploadId));
			})
			.catch((error) => {
				EnvHelper.serverError('Error from mappingComplete', error);
			});
		}
		else {
			const {partnerTapMapping, virtualHeaders, uniqueIDColumns, UIDColumnSelected} = this.state;

			const promises = [
				ChannelMappingEndpoints.updatePersonUpload(
					this.personUploadId,
					partnerTapMapping,
					virtualHeaders
				)
			];
			try {
				if (this.isEnrichSheetStep1) {
					const uniqueColumn = uniqueIDColumns.find(item => item.label === UIDColumnSelected);
					const columnName = partnerTapMapping[UIDColumnSelected];

					promises.push(
						ChannelMappingEndpoints.updateUniqueIdInPersonUpload(this.personUploadId,
							uniqueColumn ? uniqueColumn.name : UPLOAD_SHEET_COLUMN_ACCOUNT_ID,
							columnName)
					);
				}

				const [updateMapping, updateUniqueId] = await Promise.all(promises);

				if (this.unmounted) return;

				EnvHelper.push(
					Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(
						this.uploadType,
						this.personUploadId,
						this.step + 1
					)
				);

			}
			catch (error) {
				EnvHelper.serverError('Error in updateMapping', error);
			}
		}
	}

	onCancel() {
		if (this.step > 1) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(this.uploadType, this.personUploadId, this.step - 1));
		}
		else {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(this.uploadType));
		}
	}

	getColumnName(key) {
		switch (key) {
			case UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE:
				return 'Account Type';
			case UPLOAD_SHEET_COLUMN_ACCOUNT_NAME:
				return 'Account Name';
			case UPLOAD_SHEET_COLUMN_ACCOUNT_ID:
				return 'Account Id';
			case UPLOAD_SHEET_COLUMN_OWNER_NAME:
				return 'Owner Name';
			case UPLOAD_SHEET_COLUMN_OWNER_EMAIL:
				return 'Owner Email';
			case UPLOAD_SHEET_COLUMN_OWNER_TITLE:
				return 'Owner Title';
			case UPLOAD_SHEET_COLUMN_OWNER_DIVISION:
				return 'Owner Division';
			case UPLOAD_SHEET_COLUMN_NUMBER_OF_EMPLOYEES:
				return 'Employee Count';
		}
		return key.toLowerCase().replaceAll('_', ' ');
	}

	get isFullyMapped() {
		let {unmappedHeaders} = this.state;
		unmappedHeaders = CsvHelper.removeMissingHeaders(unmappedHeaders);
		return unmappedHeaders.length === 0;
	}

	get tableLayout() {
		let {
			partnerTapMapping,
			virtualHeaders,
			customMapping,
			sampleValues,
			headerOrder,
			headerDescriptions,
			headers,
			unmappedHeaders,
			columnKeyHasError,
			uniqueIDColumns
		} = this.state;
		headers = CsvHelper.removeMissingHeaders(headers);
		unmappedHeaders = CsvHelper.removeMissingHeaders(unmappedHeaders);
		const partnerTapMandatoryColumns = headerOrder.filter(x => this.isColumnRequired(x));
		const partnerTapUIDStep = headerOrder.filter(x => this.isUIDStep(x));
		const partnerTapNonMandatoryColumns = headerOrder.filter(x => (!this.isColumnRequired(x) && !this.isUIDStep(x)));
		let currentStepColumns = partnerTapMandatoryColumns;

		if (this.isDataManagerEnrichSheet) {
			let updatedUniqueColumns = [
				{
					label: UPLOAD_SHEET_COLUMN_ACCOUNT_ID, name: UPLOAD_SHEET_COLUMN_ACCOUNT_ID
				}, ...uniqueIDColumns
			];
			currentStepColumns = updatedUniqueColumns.map(item => item.label);

		}

		let headerOptionsForCurrentStep = headers;
		if (this.step === 2) {
			if (this.uploadType === UPLOAD_DATA_MANAGER_SHEET) {
				currentStepColumns = partnerTapUIDStep;
				headerOptionsForCurrentStep = [MAPPED_NONE, ...unmappedHeaders];
			}
			else {
				currentStepColumns = partnerTapNonMandatoryColumns;
				headerOptionsForCurrentStep = [MAPPED_NONE, ...unmappedHeaders];
			}
		}
		else if (this.step === 3) {
			if (this.uploadType === UPLOAD_DATA_MANAGER_SHEET) {
				currentStepColumns = partnerTapNonMandatoryColumns;
				headerOptionsForCurrentStep = [MAPPED_NONE, ...unmappedHeaders];
			}
			else {
				currentStepColumns = unmappedHeaders;
			}
		}
		else if (this.step === 4) {
			currentStepColumns = unmappedHeaders;
		}
		return (
			<table style={{borderCollapse: 'collapse', borderRadius: 13, backgroundColor: partnerTapWhite, textAlign: 'left', margin: '0px 15px 15px 15px'}}>
				<thead style={{position: 'sticky', top: 0, zIndex: 1, background: partnerTapPrimary, color: partnerTapWhite}}>
				{this.getHeadersRow(this.isCustomStep, this.isPartnerSheet, this.isEnrichSheetStep1, this.isManualSheetStep2)}
				</thead>
				<tbody>
				{this.isCustomStep &&
				 this.selectAllRow}
				{currentStepColumns.map((columnKey, index) => {
					const partnerTapColumn = (this.isEnrichSheetStep1 && this.state.UIDColumnSelected !== columnKey) ? '' : partnerTapMapping[columnKey];
					let isCustomColumn = customMapping[columnKey];
					let virtualColumn = virtualHeaders[columnKey];
					const isColumnRequired = this.isColumnRequired(columnKey);
					const useKey = this.isCustomStep ? columnKey : partnerTapColumn;
					const samplesForThisRow = sampleValues[useKey];
					let samplesCell = this.getFormattedSampleCellValue(samplesForThisRow, useKey, virtualColumn, isColumnRequired);
					let statusIcon = this.getStatusIconBasedOnValues(partnerTapColumn, virtualColumn, isCustomColumn, samplesForThisRow, columnKeyHasError[columnKey], isColumnRequired);
					if (columnKey === UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE) {
						headerOptionsForCurrentStep = [CUSTOMER, PROSPECT, ...headerOptionsForCurrentStep];
					}
					const headerDescription = headerDescriptions[columnKey];
					return (<tr style={{borderTop: '1px solid lightgrey'}} key={index}>
						{this.isEnrichSheetStep1 ?
							<td key={'columnName'.concat(' - ', index)} style={tableDataCellStyle}>
								{this.getRadioButtonColumn(columnKey)}
							</td> :
							<td key={'columnName'.concat(' - ', index)} style={tableDataCellStyle}>
							{this.getHeaderInfoColumn(columnKey, headerDescription)}
							</td>}
						{!this.isCustomStep &&
						 <td style={{padding: 10}}>
							 <div key={partnerTapColumn || virtualColumn} style={{display: 'flex', gap: 5, alignItems: 'center'}}>
								 {this.getOptionSelectorAndStatus(headerOptionsForCurrentStep, columnKey, partnerTapColumn, virtualColumnValueToDisplayName.get(virtualColumn))}
								 {statusIcon}
							 </div>
						 </td>}
						{this.isCustomStep &&
						 <td style={{}}>
							 <div style={{display: 'flex', alignItems: 'center'}}>
								 <CheckboxButton checked={Boolean(isCustomColumn) && !this.isDuplicatedColumnInCustomStep(columnKey)}
												 disabled={this.isDuplicatedColumnInCustomStep(columnKey)}
												 onChange={(event) => this.onSetCustomColumnIsIncluded(columnKey, event.target.checked)}/>
								 {this.isDuplicatedColumnInCustomStep(columnKey) && <ErrorOutlineRounded style={{color: partnerTapAlert}}/>}
							 </div>
						 </td>}
						<td key={'dataSamples'.concat(' - ', index)} style={tableDataCellStyle}>
							{this.isDuplicatedColumnInCustomStep(columnKey) ? this.duplicatedColumnInCustomStepMessage : samplesCell}
						</td>
					</tr>);
				})}
				</tbody>
			</table>
		);
	}

	get duplicatedColumnInCustomStepMessage() {
		return <div style={{color: partnerTapAlert}}>
			This column name conflicts with a standard column. To include it in your upload, please go back and map it or change the column name in your CSV.
		</div>;
	}

	isDuplicatedColumnInCustomStep(columnKey) {
		// also account for exactly duped columns
		const removeNumberedDupe = /\s+\(\d+\)$/;
		const column = columnKey.replace(removeNumberedDupe, '').trim().replaceAll(" ", "_");
		const mappedColumns = Object.values(this.state.partnerTapMapping)
		.filter(c => c != null && c.trim() !== '')
		.map(c => c.trim().replaceAll(" ", "_"));
		return [...this.state.headerOrder, ...mappedColumns, ...DEFAULT_NOT_MAPPED_COLUMNS].includes(column) && this.isCustomStep;
	}

	getFormattedSampleCellValue(samples, validColumn, virtualColumn, isColumnRequired) {
		if (virtualColumn) {
			return virtualColumn + ', ' + virtualColumn + ', ' + virtualColumn;
		}
		if (validColumn) {
			if (!samples) return <div style={{color: partnerTapAlert}}>NO SAMPLES AVAILABLE</div>;
			return <div>{samples.split(SAMPLES_DELIMITER).map((sample) => sample.trim()).join(', ')}</div>;
		}
		if (isColumnRequired) {
			return <div style={{color: partnerTapAlert}}>This column is required </div>;
		}
		return <div style={{color: partnerTapInactive}}>THIS COLUMN IS UNASSIGNED </div>;
	}

	getHeaderInfoColumn(columnKey, description) {
		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
				<InfoButton message={description || 'This is a custom column in your sheet.'}/>
				<div style={{fontWeight: 'bold', textTransform: 'capitalize', textAlign: 'left'}}>
					{this.getColumnName(columnKey)}
				</div>
			</div>
		);
	}

	getRadioButtonColumn(columnKey) {
		let {partnerTapMapping} = this.state;
		let isMappedAlready = Object.hasOwn(partnerTapMapping, columnKey);

		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
				<Radio name={this.getColumnName(columnKey)}
					   onChange={() => this.setState({...this.state, UIDColumnSelected: columnKey, formIsValid: isMappedAlready})}
					   checked={this.state.UIDColumnSelected === columnKey}/>
				<div style={{fontWeight: 'bold', textTransform: 'capitalize', textAlign: 'left'}}>
					{this.getColumnName(columnKey)}
				</div>
			</div>
		);
	}

	getOptionSelectorAndStatus(headerOptions, columnKey, partnerTapColumn, virtualColumn) {
		return <PopoverSearchList label={'Select column'}
								  list={headerOptions}
								  preselectedItem={partnerTapColumn || virtualColumn}
								  disabled={this.getDropDownFlagForUID(columnKey)}
								  onItemSelected={async (columnName) => await this.onSheetMappingSelected(columnKey, columnName)}
								  maxWidth={200}
								  minWidth={200}/>;
	}

	getDropDownFlagForUID(columnKey) {
		return this.isEnrichSheetStep1 ? (this.state.UIDColumnSelected !== columnKey) : false;
	}

	getStatusIconBasedOnValues(partnerTapColumn, virtualColumn, isCustomColumn, samplesForThisRow, columnKeyHasError, isColumnRequired) {
		if ((virtualColumn || partnerTapColumn || isCustomColumn) && (samplesForThisRow || virtualColumn) && !columnKeyHasError) {
			return <CheckCircleOutlineRounded style={{color: partnerTapTernary}}/>;
		}
		else if (isColumnRequired) {
			return <ErrorOutlineRounded style={{color: partnerTapAlert}}/>;
		}
		return <RemoveCircleOutlineRounded style={{color: partnerTapInactive}}/>;
	}

	get selectAllRow() {
		return (
			<tr>
				<td/>
				<td>
					<div style={{display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}>
						<CheckboxButton checked={this.state.includeSelectAllChecked}
										onChange={(event) => this.onSetAllCustomColumnIsIncluded(event.target.checked)}/>
						Select all
					</div>
				</td>
				<td/>
			</tr>
		);
	}

	getHeadersRow(isCustomStep, isPartnerSheet, isEnrichSheetStep1, isManualSheetStep2) {
		let ptColumnHeader = (isEnrichSheetStep1) ? 'Your Unique Identifier' : 'PartnerTap Columns';
		let yourColumnHeader = (isManualSheetStep2) ? 'Your Unique Identifier' : 'Your Columns';
		if (isCustomStep) {
			return (
				<tr>
					<th key={'partnerTapColumnHeader'} style={{borderTopLeftRadius: 10, width: '20%', fontSize: 18, padding: 15}}>Your Custom Columns</th>
					<th key={'includeOrNot'} style={{width: '15%', fontSize: 18, padding: 15}}>Include Custom Columns</th>
					<th key={'dataSamplesHeader'} style={{borderTopRightRadius: 10, minWidth: '50%', fontSize: 18, padding: 15}}>Sample Values</th>
				</tr>
			);
		}
		return (
			<tr>
				<th key={'partnerTapColumnHeader'} style={{borderTopLeftRadius: 10, width: '20%', fontSize: 18, padding: 15}}>{ptColumnHeader}</th>
				<th key={'yourColumnHeader'} style={{width: '30%', fontSize: 18, padding: 15}}>{yourColumnHeader}</th>
				<th key={'dataSamplesHeader'} style={{borderTopRightRadius: 10, minWidth: '50%', fontSize: 18, padding: 15}}>Sample Values</th>
			</tr>
		);
	}

	get renderFullyMappedMessage() {
		return (
			<div style={{display: 'flex', marginBottom: 10}}>
				<MessageBoxInfo title={'Congratulations, you can skip this step!'} hideIcon={true}>
					All of the columns in your sheet have been assigned.
				</MessageBoxInfo>
			</div>
		);
	}

	render() {
		let {loading, steps, formIsValid, updatingStep} = this.state;
		if (loading) return <Loading>Loading...</Loading>;
		return (
			<ScrollingContainer divId={'partnertap_field_mapping'}>
				<Stepper steps={steps}/>
				<ExpandableMessage message={UploadHelper.getUploadHelp(this.uploadType, this.step)}/>
				<div style={{overflowY: 'scroll', overflowX: 'hidden'}}>
					<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
						{this.isCustomStep &&
						 this.isFullyMapped ? this.renderFullyMappedMessage : this.tableLayout}
						<div style={{display: 'flex', justifyContent: 'center', padding: 20, gap: 20, backgroundColor: partnerTapWhite}}>
							<SecondaryButton label={this.step === 1 ? 'CANCEL' : 'BACK'} onClick={this.onCancel}/>
							<PrimaryButton disabled={!formIsValid || updatingStep}
										   label={this.isCustomStep ? 'DONE' : 'CONTINUE'}
										   onClick={this.onContinue}/>
						</div>
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

SheetColumnMapping.propTypes = {
	match: PropTypes.object.isRequired,
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(SheetColumnMapping));
