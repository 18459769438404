import React from 'react';

const P2pSummary = ({summaryData}) => {
	return (
		<>
			<div>
				Total action list accounts: {summaryData?.totalActionListAccounts}
			</div>
			<div>
				Total partner 1 recipients: {summaryData?.totalActionListOwners}
			</div>
			<div>
				Total partner 2 recipients: {summaryData?.totalActionListPartners}
			</div>
		</>
	);
};

export default P2pSummary;
