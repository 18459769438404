import React from 'react';
import RecipientForm from '../RecipientForm';
import RecipientTile from '../RecipientTile';
import {AUTHOR, RECIPIENT_OWNER, RECIPIENT_PARTNER} from '../../../CoSellEngineHelperLive';
import AuthHelper from '../../../../../../helpers/AuthHelper';

const manualRecipient1 = 'manual_recipient1';
const manualRecipient2 = 'manual_recipient2';

const ValidatedP2pWorkflow = ({
	workflowName,
	orgList,
	contactGroups,
	recipient1,
	recipient2,
	recipientForm1,
	recipientForm2,
	onPartnerChange,
	onRecipientFormChange,
}) => {

	const getAvailablePartners = (forRecipient2) => {
		if (forRecipient2) {
			return orgList.filter(org => org.partnerOrgPublicId !== recipient1.partnerOrgPublicId && org.partnerOrgPublicId !== recipient1.ownerOrgPublicId);
		}
		return orgList.filter(org => org.partnerOrgPublicId !== recipient2.partnerOrgPublicId && org.partnerOrgPublicId !== recipient2.ownerOrgPublicId);
	};

	return (
		<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
			<RecipientTile workflowName={workflowName} toOrFrom="From" recipientType={AUTHOR}>
				<div className="p-4 space-y-2">
					<div>{AuthHelper.userProfile.emailAddress}</div>
					<div>{`${AuthHelper.userProfile.firstName} ${AuthHelper.userProfile.lastName}`}</div>
					<div>{AuthHelper.userProfile.ptJobTitle}</div>
				</div>
			</RecipientTile>

			<RecipientTile workflowName={workflowName} toOrFrom="To" recipientType={RECIPIENT_OWNER}>
				<RecipientForm
					orgList={getAvailablePartners(false)}
					contactGroups={contactGroups}
					manualRecipientPrefix={manualRecipient1}
					recipient={recipient1}
					formState={recipientForm1}
					onFormStateChange={(changes) => onRecipientFormChange(1, changes)}
					onSelected={(value) => onPartnerChange(1, value)}
				/>
			</RecipientTile>

			<RecipientTile workflowName={workflowName} toOrFrom="To" recipientType={RECIPIENT_PARTNER}>
				<RecipientForm
					orgList={getAvailablePartners(true)}
					contactGroups={contactGroups}
					manualRecipientPrefix={manualRecipient2}
					recipient={recipient2}
					formState={recipientForm2}
					onFormStateChange={(changes) => onRecipientFormChange(2, changes)}
					onSelected={(value) => onPartnerChange(2, value)}
				/>
			</RecipientTile>
		</div>
	);
};

export default ValidatedP2pWorkflow;
