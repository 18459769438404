import PropTypes from 'prop-types';
import React from 'react';
import * as RestAdminCenterEndPoints from '../../../endpoints/RestAdminCenterEndPoints';
import EnvHelper from '../../../helpers/EnvHelper';
import PartnerReassignBase from '../../../ui/lists/PartnerReassignBase';

class AdminPartnerReassignDialog extends PartnerReassignBase {
	handlePartnerOrgCreated(companyPartnerPublicId, orgCode) {
		this.bulkUpdateSheets({
			personQueuePublicIds: this.props.selectedSheets,
			companyPartnerPublicId: companyPartnerPublicId,
			partnerOrgCode: null
		});
	}

	handleExistingPartnerOrg(companyPartnerPublicId, orgCode) {
		this.bulkUpdateSheets({
			personQueuePublicIds: this.props.selectedSheets,
			companyPartnerPublicId: companyPartnerPublicId,
			partnerOrgCode: orgCode
		});
	}

	bulkUpdateSheets(payload) {
		RestAdminCenterEndPoints.reassignPartnerOrgs(payload)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({reassignmentInProgress: false});
			this.props.onClose(true);
		})
		.catch((error) => {
			this.setState({reassignmentInProgress: false, uploadError: 'Error occurred while reassigning the org'});
			EnvHelper.serverError('Error from onUpload', error);
		});
	}

	renderCustomDialogContent() {
		const {selectedSheets} = this.props;
		const selectedSheetsCount = selectedSheets.length;

		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, width: 700}}>
				You have selected {`${selectedSheetsCount} partner ${selectedSheetsCount > 1 ? 'sheets' : 'sheet'} `} to reassign. Please select
				the org you want to assign them to.
			</div>
		);
	}
}

AdminPartnerReassignDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	selectedSheets: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AdminPartnerReassignDialog;