import PropTypes from 'prop-types';
import React from 'react';
import * as ChannelMappingEndpoints from '../../endpoints/ChannelMappingEndpoints';
import EnvHelper from '../../helpers/EnvHelper';
import PartnerReassignBase from '../lists/PartnerReassignBase';

class SheetReassignDialog extends PartnerReassignBase {
	componentDidMount() {
		this.getPartnerOrgs(this.props.rowData.partnerOrgPublicId);
	}

	handlePartnerOrgCreated(companyPartnerPublicId, orgCode) {
		this.updateCompanyPartnerOnPersonQueueRecord(
			companyPartnerPublicId,
			orgCode,
			this.props.rowData.personQueuePublicId
		);
	}

	handleExistingPartnerOrg(companyPartnerPublicId, orgCode) {
		this.updateCompanyPartnerOnPersonQueueRecord(
			companyPartnerPublicId,
			orgCode,
			this.props.rowData.personQueuePublicId
		);
	}

	updateCompanyPartnerOnPersonQueueRecord = (companyPartnerPublicId, orgCode, personQueuePublicId) => {
		ChannelMappingEndpoints.updatePartnerOrg(companyPartnerPublicId, orgCode, personQueuePublicId)
		.then((result) => {
			this.setState({reassignmentInProgress: false});
			this.props.onClose(true);
		})
		.catch((error) => {
			this.setState({reassignmentInProgress: false, uploadError: 'Error occurred while reassigning the org'});
			EnvHelper.serverError('Error from onUpload', error);
		});
	};

	renderCustomDialogContent() {
		const {rowData} = this.props;
		return (
			<>
				<div style={{display: 'flex', padding: 10}}>
					<div style={{width: '35%'}}>File name</div>
					<div style={{width: '65%'}}>{rowData.batchName}</div>
				</div>
				<div style={{display: 'flex', padding: 10}}>
					<div style={{width: '35%'}}>Current Org Assignment</div>
					<div style={{width: '65%'}}>{rowData.partnerOrgName}</div>
				</div>
			</>
		);
	}
}

SheetReassignDialog.propTypes = {
	rowData: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired
};

export default SheetReassignDialog;