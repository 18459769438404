import {Menu, MenuOpen} from '@mui/icons-material';
import {Badge, Drawer} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {matchPath, withRouter} from 'react-router-dom';
import EnvHelper from '../../helpers/EnvHelper';
import NavHelper from '../../helpers/NavHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import StringHelper from '../../helpers/StringHelper';
import {
	partnerTapAppBackground,
	partnerTapDefaultText,
	partnerTapDropShadow,
	partnerTapPrimary,
	partnerTapSecondary,
	partnerTapSecondaryLight,
	partnerTapSecondaryMedium,
	partnerTapWhite
} from '../../styles/partnertap_theme';
import ScrollingContainer from '../lists/ScrollingContainer';
import Loading from '../Loading';
import PartnerTapIcon from '../PartnerTapIcon';
import ToolTipOverlay from '../ToolTipOverlay';

class SideNav extends Component {

	constructor(props, context) {
		super(props, context);

		let showLabels = EnvHelper.isDesktop ? PersistenceHelper.getValue(this.labelsStorageKey) !== 'false' : true;
		this.state = {showLabels: showLabels};

		this.onClick = this.onClick.bind(this);
		this.navigateToSelected = this.navigateToSelected.bind(this);
		this.expandActiveKey = this.expandActiveKey.bind(this);
		this.collapseActiveKey = this.collapseActiveKey.bind(this);
		this.onToggleNavOpen = this.onToggleNavOpen.bind(this);
		this.onToggleLabels = this.onToggleLabels.bind(this);
	}

	componentDidMount() {
		this.determineSelectedNavButton();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	determineSelectedNavButton() {
		let {navButtons} = this.props.navButtonData;
		let lastSelected = NavHelper.selectedNavButton;

		let lastSelectedParent = lastSelected ? (lastSelected.parent ? lastSelected.parent : lastSelected) : null;
		let selectedNavButton = null;
		let selectedNavButtonParent = null;
		navButtons.some((navButton) => {
			if (navButton.children) {
				navButton.children.some((navButtonChild) => {
					if (this.isRouteMatchChild(navButtonChild)) {
						selectedNavButton = navButtonChild;
						selectedNavButtonParent = navButton;
					}
					return selectedNavButton;
				});
			}
			if (!selectedNavButton && this.isRouteMatchExact(navButton)) {
				selectedNavButton = navButton;
				selectedNavButtonParent = navButton;
			}
			return selectedNavButton;
		});

		if (!selectedNavButton) {
			selectedNavButton = lastSelectedParent ? lastSelectedParent : navButtons[0];
			if (selectedNavButton.children && selectedNavButton.children.length) {
				selectedNavButton = selectedNavButton.children[0];
			}
		}

		NavHelper.selectedNavButton = selectedNavButton;

		if (!this.state.selectedNavButton || (this.state.selectedNavButton.id !== selectedNavButton.id)) {
			this.setState({selectedNavButton: selectedNavButton, lastSelectedParent: lastSelectedParent});
		}
	}

	onToggleNavOpen() {
		this.props.onToggleMobileNavOpen(!this.props.isMobileNavOpen);
	}

	onToggleLabels() {
		let showLabels = !this.state.showLabels;
		this.setState({showLabels: showLabels});
		console.log(this.labelsStorageKey);
		PersistenceHelper.setValue(this.labelsStorageKey, String(showLabels));
	}

	get labelsStorageKey() {
		return this.props.navButtonData.storageKey + '_labels';
	}

	get iconSize() {
		return 50;
	}

	get navStyle() {
		return {display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer'};
	}

	get navStyleSelected() {
		return Object.assign(
			{background: 'linear-gradient(to right, ' + partnerTapPrimary + ' 0px, ' + partnerTapPrimary + ' 5px, ' + partnerTapWhite + ' 5px, ' + partnerTapWhite + ' 100%)'},
			this.navStyle);
	}

	get navStyleGroup() {
		return Object.assign(
			{background: 'linear-gradient(to right, ' + partnerTapSecondaryMedium + ' 0px, ' + partnerTapSecondaryMedium + ' 5px, ' + partnerTapSecondaryLight + ' 5px, ' + partnerTapSecondaryLight + ' 100%)'},
			this.navStyle);
	}

	get labelStyle() {
		return {fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingRight: 10, color: partnerTapSecondary};
	}

	get labelStyleSelected() {
		return Object.assign({...this.labelStyle}, {fontWeight: 'bold', color: partnerTapDefaultText});
	}

	expandActiveKey() {
		if (this.navButtonExpanded) {
			let expandedElement = document.getElementById(this.navButtonExpanded.id);
			if (expandedElement) {
				expandedElement.style.height = (this.navButtonExpanded.children.length * this.iconSize) + 'px';
				setTimeout(() => expandedElement.scrollIntoView({behavior: 'smooth', block: 'end'}), 200);
			}
		}
	}

	collapseActiveKey() {
		if (this.navButtonExpanded) {
			let expandedElement = document.getElementById(this.navButtonExpanded.id);
			if (expandedElement) expandedElement.style.height = '0px';
			this.navButtonExpanded = null;
		}
	}

	isRouteMatchChild(childNavButton) {
		if (this.isRouteMatchExact(childNavButton)) return true;
		let subRouteMatches = false;
		if (childNavButton.activeKeys) {
			childNavButton.activeKeys.some((key) => {
				let matches = matchPath(EnvHelper.path, {path: key, exact: true, strict: true});
				if (matches || key === EnvHelper.path) subRouteMatches = true;
				return subRouteMatches;
			});
		}
		return subRouteMatches;
	}

	isRouteMatchExact(navButtonData) {
		return navButtonData.destination === EnvHelper.path || navButtonData.forceSelectDynamicNav;
	}

	makeNavButton(navButtonData, navButtonParentData) {
		let isParent = Boolean(navButtonData.children);
		let isParentSelected = navButtonData.id === this.state.selectedNavButton.id;
		let isChild = Boolean(navButtonParentData);
		let isChildSelected = navButtonData.id === this.state.selectedNavButton.parentId;
		let wasLastSelectionSibling = isParent && this.state.lastSelectedParent && navButtonData.id === this.state.lastSelectedParent.id;
		let isSelected = navButtonData.id === this.state.selectedNavButton.id;
		let isGroup = isParentSelected || isChildSelected || isChild;
		let styleNavButton = isSelected ? this.navStyleSelected : (isGroup ? this.navStyleGroup : this.navStyle);
		let blockClick = isChildSelected && EnvHelper.isDesktop && !navButtonData.isAlwaysClickable;
		let navButton =
			<ToolTipOverlay key={navButtonData.id} title={this.state.showLabels ? '' : navButtonData.label} placement={'right'}>
				<div key={navButtonData.id}
					 style={styleNavButton}
					 onClick={blockClick ? null : () => this.onClick(navButtonData)}
					 data-cy={StringHelper.formatKey(navButtonData.label) + '_side_nav'}>
					<div style={{
						color: partnerTapSecondary,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: this.iconSize,
						height: this.iconSize,
						paddingLeft: isChild && this.state.showLabels ? 30 : (isChild ? 5 : 0)
					}}>
						<Badge badgeContent={navButtonData.alertCount} color={'primary'}>
							{navButtonData.glyph &&
							 <PartnerTapIcon glyph={navButtonData.glyph}
											 style={isSelected ? PartnerTapIcon.sideNavSelectedIconStyle : PartnerTapIcon.sideNavIconStyle}/>}
							{navButtonData.MaterialIcon &&
							 <navButtonData.MaterialIcon style={{color: isSelected ? partnerTapPrimary : partnerTapSecondary}}/>}
						</Badge>
					</div>
					{this.state.showLabels &&
					 <div style={isSelected ? this.labelStyleSelected : this.labelStyle}>
						 {navButtonData.label}
					 </div>}
				</div>
			</ToolTipOverlay>;

		if (isParent && (isParentSelected || isChildSelected)) {
			this.navButtonExpanded = navButtonData;
			if (!wasLastSelectionSibling) setTimeout(this.expandActiveKey, 100);
			return (
				<div key={navButtonData.id}>
					{navButton}
					<div id={navButtonData.id}
						 style={{
							 height: wasLastSelectionSibling ? navButtonData.children.length * this.iconSize : 0,
							 overflow: 'hidden',
							 transition: 'height 0.2s ease-out'
						 }}>
						{navButtonData.children.map((navButtonChildData) => this.makeNavButton(navButtonChildData, navButtonData))}
					</div>
				</div>
			);
		}
		else {
			return navButton;
		}
	}

	onClick(navButtonData) {
		let delay = 200;
		if (navButtonData.stateChangeFunction) {
			navButtonData.stateChangeFunction(this);
		}
		else if (navButtonData.isParent && (!this.navButtonExpanded || this.navButtonExpanded.id !== navButtonData.id)) {
			this.collapseActiveKey();
			setTimeout(() => {
				if (this.unmounted) return;
				this.navigateToSelected(navButtonData);
			}, delay);
			delay += 200;
		}
		else {
			this.navigateToSelected(navButtonData);
		}

		if (!EnvHelper.isDesktop && !navButtonData.children) {
			setTimeout(this.onToggleNavOpen, delay);
		}
	}

	navigateToSelected(navButtonData) {
		if (!navButtonData.destination || navButtonData.destination === EnvHelper.path) return;

		if (!this.props.navButtonData.lastSelectedChildren) {
			this.props.navButtonData.lastSelectedChildren = {};
		}

		if (navButtonData.isParent && !navButtonData.isAlwaysClickable) {
			let lastSelectedChild = this.props.navButtonData.lastSelectedChildren[navButtonData.id];
			if (lastSelectedChild) navButtonData = lastSelectedChild;
		}
		else if (navButtonData.isChild) {
			this.props.navButtonData.lastSelectedChildren[navButtonData.parent.id] = navButtonData;
		}

		let hasMobileNavOptions = !EnvHelper.isDesktop && navButtonData.isParent && navButtonData.children;
		setTimeout(() => {
			if (this.unmounted) return;
			EnvHelper.push(navButtonData.destination);
		}, hasMobileNavOptions ? 500 : 0);
	}

	render() {
		if (!this.state.selectedNavButton) return <Loading small={true}>Loading Navigation</Loading>;

		let {navButtons} = this.props.navButtonData;
		let navButtonsTop = navButtons.filter((nav) => !nav.position || nav.position === 'top');
		let navButtonsBottom = navButtons.filter((nav) => nav.position === 'bottom');

		let nav = (
			<ScrollingContainer divId={'side_nav'}>
				<div style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					overflow: 'scroll',
					backgroundColor: partnerTapAppBackground,
					boxShadow: partnerTapDropShadow,
					marginRight: 1,
					minWidth: this.state.showLabels ? 240 : 50,
					maxWidth: 240
				}}>
					<div>
						{EnvHelper.isDesktop &&
						 <ToolTipOverlay title={this.state.showLabels ? '' : 'Show Labels'} placement={'right'}>
							 <div style={this.navStyle} onClick={this.onToggleLabels}>
								 <div style={{
									 color: partnerTapSecondary,
									 display: 'flex',
									 alignItems: 'center',
									 justifyContent: 'center',
									 width: this.iconSize,
									 height: this.iconSize
								 }}>
									 {this.state.showLabels ? <MenuOpen/> : <Menu/>}
								 </div>
								 {this.state.showLabels &&
								  <div style={this.labelStyle}>
									  Hide Labels
								  </div>}
							 </div>
						 </ToolTipOverlay>}
						{navButtonsTop.map((navButtonData) => this.makeNavButton(navButtonData))}
					</div>
					<div>
						{navButtonsBottom.map((navButtonData) => this.makeNavButton(navButtonData))}
					</div>
				</div>
			</ScrollingContainer>
		);

		if (EnvHelper.isDesktop) {
			return nav;
		}
		else {
			return (
				<div style={{position: 'fixed', top: 0, left: 4}}>
					<Menu fontSize={'large'}
						  style={{paddingTop: 7, paddingRight: 7, cursor: 'pointer', color: partnerTapWhite}}
						  onClick={this.onToggleNavOpen}/>
					<Drawer open={this.props.isMobileNavOpen} elevation={16} onClose={this.onToggleNavOpen}>
						{nav}
					</Drawer>
				</div>
			);
		}
	}
}

SideNav.propTypes = {
	navButtonData: PropTypes.shape({navButtons: PropTypes.array, storageKey: PropTypes.string}).isRequired,
	isMobileNavOpen: PropTypes.bool.isRequired,
	onToggleMobileNavOpen: PropTypes.func.isRequired
};

export default withRouter(connect()(SideNav));
