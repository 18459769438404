import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import * as TrackingEndpoints from '../../../../endpoints/TrackingEndpoints';
import {BASE_OPPORTUNITIES_MANUAL, UPLOAD_FULL_SERVICE_CSV, UPLOAD_PARTNER_SHEET, UPLOAD_PII_SHARING_OPT_IN} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import EnvHelper from '../../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_UPLOAD_PARTNER_SHEETS} from '../../../../helpers/FeatureHelper';
import UploadHelper from '../../../../helpers/UploadHelper';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import MessageBoxInfo from '../../../../ui/messages/MessageBoxInfo';
import Stepper from '../../../../ui/Stepper';
import SheetUploader from './SheetUploader';
import ExpandableMessage from '../../../../ui/messages/ExpandableMessage';
import MessageBoxAlert from '../../../../ui/messages/MessageBoxAlert';

class SheetUploadPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: this.isPartnerSheet,
			selectedErrors: null,
			steps: UploadHelper.getUploadSteps(this.uploadType, 0),
			sheetCount: 0
		};

		this.onUploadSuccess = this.onUploadSuccess.bind(this);
	}

	componentDidMount() {
		if (this.isPartnerSheet) {
			this.getPartnerSheetCount();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get uploadType() {
		return this.props.match.params.uploadType;
	}

	get isPartnerSheet() {
		return this.uploadType === UPLOAD_PARTNER_SHEET;
	}

	get isBaseOpportunitiesManual() {
		return this.uploadType === BASE_OPPORTUNITIES_MANUAL;
	}

	get isPiiOptIn() {
		return this.uploadType === UPLOAD_PII_SHARING_OPT_IN;
	}

	get isFullServiceCsv() {
		return this.uploadType === UPLOAD_FULL_SERVICE_CSV;
	}

	get hasStepper() {
		return !this.isPiiOptIn && !this.isFullServiceCsv;
	}

	getPartnerSheetCount() {
		ChannelMappingEndpoints.getUploadSheetCount()
		.then((result) => {
			if (this.unmounted) return;
			let sheetCount = result.payload || 0;
			this.setState({loading: false, sheetCount: sheetCount});
		});
	}

	onUploadSuccess(numAccounts, results) {
		if (this.hasStepper) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.PATH(this.uploadType, results.personUploadId, 1));
		}
		else {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.PATH(this.uploadType, results.personUploadId));
		}
	}

	render() {
		let {loading, steps, sheetCount, upsellComplete} = this.state;
		const {
			authState: {
				person: {orgHasBaseAccountData},
			},
		} = this.props;
		const isOrgDataUnavailable = (this.isBaseOpportunitiesManual || this.isPartnerSheet) && !orgHasBaseAccountData;

		if (loading) return <Loading>Loading...</Loading>;
		if (this.isPartnerSheet && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_UPLOAD_PARTNER_SHEETS) && sheetCount >= 5) {
			return (
				<ScrollingContainer divId={'spreadsheet_upload'}>
					<MessageBoxInfo title={'You\'ve reached the free version upload limit!'} hideIcon={true}>
						<div>
							PartnerTap makes it easy to instantly see your account overlap when partners send you their account lists.
						</div>
						<div>
							With the free version of PartnerTap you can upload up to five partner account sheets.
							You can still connect directly with your partners, but you will need to upgrade to upload more sheets.
						</div>
						<div>
							NOTE: You can continue to upload your <i>own</i> account sheets in Data Manager and map with the partner sheets you've uploaded.
						</div>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, fontSize: 16}}>
							{upsellComplete ?
								<div>
									Thank you! We will be in touch soon.
								</div>
								:
								<Fragment>
									<div>
										Contact us if you’d like to upgrade.
									</div>
									<PrimaryButton label={'Contact Sales'}
												   onClick={() => {
													   TrackingEndpoints.trackLead(FEATURE_CHANNEL_UPLOAD_PARTNER_SHEETS)
													   .then(() => {
														   if (this.unmounted) return;
														   this.setState({upsellComplete: true});
													   });
												   }}/>
								</Fragment>}
						</div>
					</MessageBoxInfo>
				</ScrollingContainer>
			);
		}
		return (
			<ScrollingContainer divId={'spreadsheet_upload'}>
				{this.hasStepper && <Stepper steps={steps}/>}
				<div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
					<ExpandableMessage message={UploadHelper.getUploadHelp(this.uploadType, 0)}/>
					{isOrgDataUnavailable && (
						<MessageBoxAlert>
							<div>
								{this.isBaseOpportunitiesManual ? (
									<>
										Before you can upload opportunity data, there must be base account data in your org. If you need help bringing in
										account data,
										please reach out to <a href={'mailto:support@partnertap.com'}>support@partnertap.com</a>.
									</>
								) : this.isPartnerSheet ? (
									<>
										Before you can upload a partner sheet, there must be base account data in your org. Please reach out to your PartnerTap
										administrator.
									</>
								) : null}
							</div>
						</MessageBoxAlert>
					)}
					<SheetUploader onUploadSuccess={this.onUploadSuccess}/>
				</div>
			</ScrollingContainer>
		);
	}
}

SheetUploadPage.propTypes = {
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(SheetUploadPage));
