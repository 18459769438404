import {Cancel, Check, Edit} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import DateHelper from '../../../../helpers/DateHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import {partnerTapAlert, partnerTapDefaultText, partnerTapWhite} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import ScrimMessage from '../../../../ui/messages/ScrimMessage';
import TextInputBox from '../../../../ui/TextInputBox';
import ReportHelper from '../../../../helpers/ReportHelper';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {canUserEditActionLists} from '../CoSellEngineHelperLive';

class CoSellActionListMetadataLive extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {editingActionListMetadata: null, savingActionListMetadata: false};

		this.saveChanges = this.saveChanges.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get isActionListUsed() {
		let {activeWorkflow} = this.props;
		return Boolean(activeWorkflow);
	}

	get canUserEditActionLists() {
		const { actionListMetadata, authState } = this.props;
		return canUserEditActionLists(actionListMetadata, authState);
	}

	saveChanges() {
		let {actionListMetadata} = this.props;
		let {editingActionListMetadata} = this.state;
		if (!actionListMetadata || !editingActionListMetadata) {
			return;
		}

		let actionListMetadataUpdates = {};
		if (editingActionListMetadata.name.length > 3) {
			actionListMetadataUpdates.name = editingActionListMetadata.name;
		}
		else {
			actionListMetadataUpdates.name = actionListMetadata.name;
		}
		actionListMetadataUpdates.description = editingActionListMetadata.description;
		actionListMetadataUpdates.isActive = editingActionListMetadata.isActive;
		actionListMetadataUpdates.expirationDate = editingActionListMetadata.expirationOnDate;
		actionListMetadataUpdates.isLocked = editingActionListMetadata.isLocked;

		this.setState({savingActionListMetadata: true, editingActionListMetadata: null});
		window.dispatchEvent(new Event('resize')); // adjust for height change
		CoSellEngineEndpoints.updateCoSellActionListMetadata(editingActionListMetadata.cosellActionListMetadataPublicId, actionListMetadataUpdates)
		.then((result) => {
			if (this.unmounted) return;
			Object.keys(actionListMetadataUpdates).forEach((key) => actionListMetadata[key] = actionListMetadataUpdates[key]);
			this.setState({savingActionListMetadata: false});
		})
		.catch((error) => {
			this.setState({savingActionListMetadata: false});
			EnvHelper.serverError('Error from saveChanges', error);
		});
	}

	render() {
		let {actionListMetadata, activeWorkflow} = this.props;
		if (!actionListMetadata) return;
		let {editingActionListMetadata, savingActionListMetadata} = this.state;
		let isExpired = actionListMetadata.expirationOnDate < (new Date().getTime() / 1000);
		let labelCellWidth1 = 100;
		let labelCellWidth2 = 150;
		let spacerCellWidth = 120;
		return (
			<div style={{display: 'flex', justifyContent: 'space-between', padding: 10, borderRadius: 5, backgroundColor: partnerTapWhite}}>
				{savingActionListMetadata &&
				 <ScrimMessage message={'Saving Changes...'}/>}
				<table cellSpacing={10}>
					<tbody>
					<tr>
						<td style={{fontWeight: 'bold', width: labelCellWidth1}}>Action List:</td>
						<td>
							{editingActionListMetadata ?
								<TextInputBox value={editingActionListMetadata.name}
											  onChange={(value) => {
												  editingActionListMetadata.name = value;
												  this.forceUpdate();
											  }}
											  doNotAutoFocus={true}
											  isSimple={true}
											  minWidth={400}
											  maxChars={255}/>
								:
								actionListMetadata.name}
						</td>
						<td style={{width: spacerCellWidth}}/>
						<td style={{fontWeight: 'bold', width: labelCellWidth2}}>Created By:</td>
						<td>
							{actionListMetadata.createdByPerson}
						</td>
					</tr>
					<tr>
						<td style={{fontWeight: 'bold', width: labelCellWidth1}}>Description:</td>
						<td>
							{editingActionListMetadata ?
								<TextInputBox value={editingActionListMetadata.description}
											  onChange={(value) => {
												  editingActionListMetadata.description = value;
												  this.forceUpdate();
											  }}
											  doNotAutoFocus={true}
											  isSimple={true}
											  minWidth={400}
											  rows={3}
											  maxChars={255}/>
								:
								(actionListMetadata.description || '-')}
						</td>
						<td style={{width: spacerCellWidth}}/>
						<td style={{fontWeight: 'bold', width: labelCellWidth2}}>Created On:</td>
						<td>
							{DateHelper.epochToDate(actionListMetadata.createdOnDate)}
						</td>
					</tr>
					<tr>
						<td style={{fontWeight: 'bold', width: labelCellWidth1}}>Partner:</td>
						<td>
							{actionListMetadata.companyPartner}
						</td>
						<td style={{width: spacerCellWidth}}/>
						<td style={{fontWeight: 'bold', width: labelCellWidth2}}>Expires On:</td>
						<td style={{color: isExpired ? partnerTapAlert : partnerTapDefaultText, fontWeight: isExpired ? 'bold' : 'normal'}}>
							{editingActionListMetadata ?
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker value={moment.unix(new Date(actionListMetadata.expirationOnDate)).toDate()}
												minDate={moment().add(30, 'days').toDate()}
												maxDate={moment().add(1, 'years').toDate()}
												format={'MM/dd/yyyy'}
												onChange={(value, context) => {
													if (value && !context.validationError) {
														editingActionListMetadata.expirationOnDate = value.valueOf() / 1000;
													}
												}}/>
								</LocalizationProvider>
								:
								DateHelper.epochToDate(actionListMetadata.expirationOnDate)}
						</td>
					</tr>
					<tr>
						<td style={{fontWeight: 'bold', width: labelCellWidth1}}>Record Type:</td>
						<td>
							{ReportHelper.getAssetTypeDisplayName(actionListMetadata.assetType)}
						</td>
						<td style={{width: spacerCellWidth}}/>
						<td style={{fontWeight: 'bold', width: labelCellWidth2}}>Workflow Started By:</td>
						<td>
							{activeWorkflow?.workflowStartedBy || '-'}
						</td>
					</tr>
					<tr>
						<td/>
						<td/>
						<td style={{width: spacerCellWidth}}/>
						<td style={{fontWeight: 'bold', width: labelCellWidth2}}>Workflow Started On:</td>
						<td>
							{activeWorkflow ? DateHelper.epochToDate(activeWorkflow.dateExecuted) : '-'}
						</td>
					</tr>
					</tbody>
				</table>
				<div>
					{editingActionListMetadata ?
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							<PrimaryButton label={'SAVE'} icon={<Check/>} fullWidth={true} onClick={this.saveChanges}/>
							<SecondaryButton label={'CANCEL'}
											 icon={<Cancel/>}
											 fullWidth={true}
											 onClick={() => {
												 this.setState({editingActionListMetadata: null});
												 window.dispatchEvent(new Event('resize')); // adjust for height change
											 }}/>
						</div>
						:
						<SecondaryButton label={'EDIT'}
										 icon={<Edit/>}
										 disabled={this.isActionListUsed || !this.canUserEditActionLists}
										 onClick={() => {
											 this.setState({editingActionListMetadata: _.clone(actionListMetadata)});
											 window.dispatchEvent(new Event('resize')); // adjust for height change
										 }}/>}
				</div>
			</div>
		);
	}
}

CoSellActionListMetadataLive.propTypes = {
	actionListMetadata: PropTypes.object, activeWorkflow: PropTypes.object, authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CoSellActionListMetadataLive));