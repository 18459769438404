import {Forum} from '@mui/icons-material';
import React from 'react';
import * as PartnersEndpoints from '../endpoints/PartnersEndpoints';
import {
	ACCEPT_PARTNER_REQUEST,
	ALREADY_PARTNERED,
	INVITE_ADD,
	INVITE_RECOMMENDATION,
	INVITE_SENT,
	INVITE_STATUS_ACCEPTED,
	INVITE_STATUS_DENIED,
	INVITE_STATUS_INITIATED,
	INVITE_STATUS_INITIATED_DENIED,
	INVITE_STATUS_RECOMMENDED,
	INVITE_STATUS_REQUESTED,
	INVITE_STATUS_RETRACTED,
	INVITE_STATUS_SENT,
	INVITE_STATUS_UNPARTNERED,
	PARTNER_SHEETS,
	REQUIRES_PARTNER_INVITE,
	REQUIRES_PARTNER_REQUEST
} from '../globals/Enums';
import {Routes} from '../globals/Routes';
import {LOGIN_INVITER_PROFILE_KEY} from '../globals/StorageKeys';
import PersistenceHelper from '../helpers/PersistenceHelper';
import {partnerTapInactive, partnerTapSecondary} from '../styles/partnertap_theme';
import CancelButton from '../ui/buttons/CancelButton';
import InviteButton from '../ui/buttons/InviteButton';
import LinkButton from '../ui/buttons/LinkButton';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import {MEMBER_INVITE_NOTE} from '../ui/EmailInvite';
import Pic from '../ui/Pic';
import EcosystemShareHelper, {SHARE_SUMMARY} from './EcosystemShareHelper';
import EnvHelper from './EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_SHARE_OPPS, PRODUCT_CHANNEL_ECOSYSTEM} from './FeatureHelper';
import AuthHelper from './AuthHelper';

export default class PartnerStatusHelper {

	static processInviterProfile() {
		let inviterProfile = PersistenceHelper.getValue(LOGIN_INVITER_PROFILE_KEY);
		if (inviterProfile) {
			let profile = JSON.parse(inviterProfile);
			PartnersEndpoints.createPartner(profile.inviterPersonId, profile.productCode, profile.connectWithPartner)
			.then(() => {
				PersistenceHelper.removeValue(LOGIN_INVITER_PROFILE_KEY);
			})
			.catch((error) => {
				console.error('Error fro processInviterProfile: Unable to create partnership', error);
			});
		}
	}

	static renderStatusMessage(itemData) {
		return (
			<div>
				{this.getStatusMessage(itemData.partnerStatus, itemData.isNew)}
			</div>
		);
	}

	static getPartnerStatus(partnerStatus) {
		partnerStatus = partnerStatus ? partnerStatus.toUpperCase() : 'Unknown';
		if (partnerStatus === INVITE_STATUS_INITIATED) {
			partnerStatus = 'Sent (Pending)';
		}
		else if (partnerStatus === INVITE_STATUS_REQUESTED) {
			partnerStatus = 'Received (Pending)';
		}
		else if (partnerStatus === INVITE_STATUS_RETRACTED) {
			partnerStatus = 'Canceled';
		}
		else if (partnerStatus === INVITE_STATUS_ACCEPTED) {
			partnerStatus = 'Accepted';
		}
		else if (partnerStatus === INVITE_STATUS_RECOMMENDED) {
			partnerStatus = 'Recommended';
		}
		else if (partnerStatus === INVITE_STATUS_DENIED || partnerStatus === INVITE_STATUS_INITIATED_DENIED) {
			partnerStatus = 'Denied';
		}
		else if (partnerStatus === INVITE_STATUS_UNPARTNERED) {
			partnerStatus = 'Removed';
		}
		return partnerStatus;
	}

	static getStatusMessage(partnerStatus, isNew) {
		let message;
		switch (partnerStatus) {
			case INVITE_ADD:
				message = 'Invite Your Partners';
				break;
			case INVITE_RECOMMENDATION:
				message = 'View Potential Partners';
				break;
			case INVITE_STATUS_ACCEPTED:
				message = isNew ? 'New Partner!' : 'Active Partner';
				break;
			case INVITE_STATUS_INITIATED:
				message = 'Invitation Sent';
				break;
			case INVITE_STATUS_REQUESTED:
				message = 'Invitation to Connect!';
				break;
			case INVITE_STATUS_SENT:
				message = 'Email Sent';
				break;
			case PARTNER_SHEETS:
				message = 'View and Upload Sheets of Partner Accounts';
				break;
			default:
				message = 'Partnership Available!';
		}
		return message;
	}

	static renderAction(itemData, useLinkButton) {
		let inviterProfile = AuthHelper.userProfile;
		itemData.inviterProfile = inviterProfile;
		const ButtonForAction = useLinkButton ? LinkButton : PrimaryButton;
		const ButtonForCancel = useLinkButton ? LinkButton : CancelButton;
		const isSameOrg = inviterProfile.orgId === itemData.partnerOrganizationId;
		switch (itemData.partnerStatus) {
			case INVITE_ADD:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<ButtonForAction label={'INVITE'}
										 disabled={isSameOrg}
										 onClick={(e) => {
											 e.stopPropagation(); // block potential dupe event from PartnerConnectionTileRenderer
											 EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH());
										 }}/>
					</div>
				);
			case INVITE_RECOMMENDATION:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<ButtonForAction label={'VIEW'}
										 onClick={(e) => {
											 e.stopPropagation(); // block potential dupe event from PartnerConnectionTileRenderer
											 EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.RECOMMENDATIONS.ROUTE);
										 }}/>
					</div>
				);
			case INVITE_STATUS_ACCEPTED:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<ButtonForAction label={'VIEW'} onClick={() => PartnerStatusHelper.navigateToPartnerDetails(itemData)}/>
					</div>
				);
			case INVITE_STATUS_INITIATED:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<ButtonForCancel label={'RETRACT'}
										 onClick={() => itemData.updatePartner(itemData, INVITE_STATUS_RETRACTED)}
										 confirmationMessage={'Are you sure you want to retract this invite?'}
										 disabled={EnvHelper.isSpoofing}/>
					</div>
				);
			case PARTNER_SHEETS:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<ButtonForAction label={'VIEW'}
										 onClick={(e) => {
											 e.stopPropagation(); // block potential dupe event from PartnerConnectionTileRenderer
											 EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.UPLOAD_HISTORY.ROUTE);
										 }}/>
					</div>
				);
			default: {
				return (
					<InviteButton inviteData={itemData}
								  dialogTitle={'Invite ' + itemData.partnerName + ' to Connect'}
								  disabled={isSameOrg}
								  dialogContent={this.renderPartnerForInvite(itemData, inviterProfile)}
								  userNote={PartnerStatusHelper.renderNoteForInvite(itemData.firstName, inviterProfile)}
								  userNoteRows={12}
								  useLinkButton={useLinkButton}/>
				);
			}
		}
	}

	static renderActionByStatus(itemData) {
		if (itemData.partnerId) {
			switch (itemData.status) {
				case INVITE_STATUS_ACCEPTED:
					return (
						<div>
							<PrimaryButton label={'COLLABORATE'}
										   icon={<Forum/>}
										   backgroundColor={partnerTapSecondary}
										   onClick={() => {
											   EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(itemData.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.INTEL.PAGE_NAME));
										   }}/>
						</div>
					);
				default:
					return <div/>;
			}
		}
		return <div/>;
	}

	static renderChannelPartnershipAction(itemData, handlePartnership) {
		const inviterProfile = AuthHelper.userProfile;
		if (itemData.partnerStatus === INVITE_STATUS_RETRACTED) {
			return <InviteButton inviteData={itemData}
								 dialogTitle={'Invite ' + itemData.partnerName + ' to Connect'}
								 dialogContent={this.renderPartnerForInvite(itemData, inviterProfile)}
								 userNote={PartnerStatusHelper.renderNoteForInvite(itemData.partnerFirstName, inviterProfile)}
								 userNoteRows={12}/>;
		}
		switch (itemData.partnershipAction) {
			case ALREADY_PARTNERED:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<LinkButton label={'COLLABORATE'}
									onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(itemData.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.INTEL.PAGE_NAME))}/>
					</div>
				);
			case REQUIRES_PARTNER_REQUEST:
				return <InviteButton key={Math.random()} // random key fixes incomprehensible rendering bug when sort order is changed
									 inviteData={itemData}
									 dialogTitle={'Invite ' + itemData.name + ' to Connect'}
									 dialogContent={this.renderPartnerForInvite(itemData, inviterProfile)}
									 userNote={PartnerStatusHelper.renderNoteForInvite(itemData.partnerFirstName, inviterProfile)}
									 userNoteRows={12}
									 useLinkButton={true}/>;
			case ACCEPT_PARTNER_REQUEST:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<LinkButton label={'ACCEPT'}
									onClick={() => this.updatePartner(itemData.partnerId, handlePartnership)}
									disabled={EnvHelper.isSpoofing}/>
					</div>
				);
			case REQUIRES_PARTNER_INVITE:
				return (
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<LinkButton label={'INVITE'}
									onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH(itemData.email))}
									disabled={EnvHelper.isSpoofing}/>
					</div>
				);
			case INVITE_SENT:
				return <div style={{display: 'flex', justifyContent: 'center'}}>Invite Sent</div>;
			default:
				return <div style={{display: 'flex', justifyContent: 'center'}}>N/A</div>;
		}
	}

	static updatePartner(partnerId, handlePartnership) {
		return PartnersEndpoints.updatePartner(partnerId, INVITE_STATUS_ACCEPTED, false, PRODUCT_CHANNEL_ECOSYSTEM)
		.then((result) => {
			handlePartnership(true, 'Accepted partnership request');
		});
	}

	static renderPartnerForInvite(itemData, inviterProfile) {
		return (
			<div>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div style={{paddingRight: 4}}>
							<Pic personId={itemData.personId}/>
						</div>
						<div style={{paddingRight: 16}}>
							<Pic orgId={itemData.partnerOrganizationId}/>
						</div>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', fontWeight: 'bold'}}>
							<div style={{fontSize: 14, paddingBottom: 8}}>
								{itemData.partnerName || itemData.name}
								<span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
								{itemData.partnerOrganization || itemData.companyName}
							</div>
							<div style={{display: 'flex', alignItems: 'center', color: partnerTapInactive, fontSize: 12}}>
								{itemData.ptJobTitle}
							</div>
						</div>
					</div>
					<hr style={{width: '100%', margin: 10}}/>
				</div>
				{EcosystemShareHelper.renderAccountShareMenu(inviterProfile.availableAccountShareOptions, inviterProfile.defaultAccountShareOption)}
				{EcosystemShareHelper.renderOpportunityShareMenu(inviterProfile.availableOppShareOptions, inviterProfile.defaultOppShareOption)}
			</div>
		);
	}

	static renderNoteForInvite(inviteeName, inviterProfile) {
		let userNote;
		if (inviterProfile) {
			let signatureKeys = ['ptJobTitle', 'organization', 'emailAddress', 'cellPhoneNumber'];
			let userSignature = inviterProfile.firstName + ' ' + inviterProfile.lastName + '\n';
			signatureKeys.forEach((key) => {
				if (inviterProfile[key]) userSignature += inviterProfile[key] + '\n';
			});
			userNote = 'Hi ' + inviteeName + ',\n\n' + MEMBER_INVITE_NOTE + '\n\n' + 'Regards,\n\n' + userSignature;
		}
		return userNote;
	}

	static navigateToPartnerDetails(partnerData) {
		let {partnerId, shareSettings} = partnerData;
		let isNotSharingAccounts = shareSettings && shareSettings.accountShareSettings.ownerAssetShareOption === SHARE_SUMMARY;
		let isNotSharingOpps = shareSettings && shareSettings.oppShareSettings.ownerAssetShareOption === SHARE_SUMMARY && FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS);
		let routePage = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SUMMARY.PAGE_NAME;
		// if current user is not sharing with partner, always navigate to share tab
		if (isNotSharingAccounts || isNotSharingOpps) {
			routePage = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARE.PAGE_NAME;
		}
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(partnerId, routePage));
	}
}
