import TableHelper from '../../../../../helpers/TableHelper';
import React from 'react';

const WorkFlowSummary = ({summaryData, displayName, summaryComponent: SummaryComponent}) => {
	return (
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
			<div style={TableHelper.boxStyle}>
				<div style={{fontSize: 20}}>
					{displayName}
				</div>
				{summaryData ?
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<SummaryComponent summaryData={summaryData}/>

						<div style={{marginTop: 20}}>
							<div style={{fontSize: 18}}>
								Are you ready to start this workflow?
							</div>
							<div>
								Starting this workflow will lock your action list. You will no longer be able to make any changes to this list or use
								this list for any other workflows.
							</div>
						</div>
					</div>
					:
					<div>Sorry, summary data is unavailable</div>}
			</div>
		</div>
	);
};

export default WorkFlowSummary;
