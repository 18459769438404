import React from 'react';
import PartnerTapIcon from '../PartnerTapIcon';
import PrimaryButton from './PrimaryButton';
import * as AuthActions from '../../redux/AuthActions';
import EnvHelper from '../../helpers/EnvHelper';
import {Routes} from '../../globals/Routes';
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';

function SignInButton({onClick, disabled, fullWidth}) {

	const dispatch = useDispatch();

	function routeToLogin() {
		dispatch(AuthActions.logout());
		dispatch(AuthActions.clearServerError());
		EnvHelper.push(Routes.HOME.ROUTE);
	}

	return <PrimaryButton label={'SIGN IN'}
						  icon={PartnerTapIcon.logoSmall}
						  onClick={onClick || routeToLogin}
						  disabled={disabled || false}
						  fullWidth={fullWidth || false}/>;
}

SignInButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool
};

export default SignInButton;