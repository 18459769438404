import {History} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {
	BASE_OPPORTUNITIES_MANUAL,
	UPLOAD_DATA_MANAGER_ENRICH_SHEET,
	UPLOAD_DATA_MANAGER_SHEET,
	UPLOAD_PARTNER_SHEET,
	UPLOAD_PII_SHARING_OPT_IN
} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import DateHelper from '../../../../helpers/DateHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import UploadHelper, {PERSON_QUEUE_STATUS_PROCESSED} from '../../../../helpers/UploadHelper';
import LinkButton from '../../../../ui/buttons/LinkButton';
import SheetAuditLabel from '../../../../ui/buttons/SheetAuditLabel';
import PagingBase from '../../../../ui/lists/PagingBase';
import SheetRecordActions from '../../../../ui/buttons/SheetRecordActions';

class SheetUploadHistoryPage extends PagingBase {

	getSheetViewRoute(personQueueId, partnerOrgName) {
		switch (this.uploadType) {
			case UPLOAD_PARTNER_SHEET: {
				let baseRoute = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS;
				return baseRoute.PATH(personQueueId, baseRoute.DASHBOARD.PAGE_NAME, null, partnerOrgName);
			}
			case UPLOAD_DATA_MANAGER_SHEET:
			case UPLOAD_DATA_MANAGER_ENRICH_SHEET:
				return Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.PATH(personQueueId);
			case BASE_OPPORTUNITIES_MANUAL:
				return Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_OPPORTUNITIES.PATH(personQueueId);
		}
		return null;
	}

	get uploadType() {
		return this.props.match.params.uploadType;
	}

	get isPartnerSheet() {
		return this.uploadType === UPLOAD_PARTNER_SHEET;
	}

	get isPiiOptIn() {
		return this.uploadType === UPLOAD_PII_SHARING_OPT_IN;
	}

	get storageKeyBase() {
		return 'upload_history';
	}

	get title() {
		return UploadHelper.getSheetTitle(this.uploadType);
	}

	get icon() {
		return History;
	}

	get columnData() {
		let columns = [
			{
				title: 'Actions',
				key: 'actionB',
				sortDisabled: true,
				renderFunction: (columnValue, rowData) => <SheetRecordActions isPartnerSheet={this.isPartnerSheet}
																			  refreshTable={() => this.setState({loading: true})}
																			  rowData={rowData}/>
			},
			{
				title: 'Sheet Name',
				key: 'batchName',
				renderFunction: this.isPiiOptIn ? null : (columnValue, rowData) => {
					return <LinkButton label={columnValue}
									   disabled={rowData.status !== PERSON_QUEUE_STATUS_PROCESSED}
									   onClick={() => EnvHelper.push(this.getSheetViewRoute(rowData.id, rowData.partnerOrgName))}/>;
				}
			},
			{title: 'Description', key: 'fileDescription'},
			{title: '# Records', key: 'totalRows', type: 'number'},
			{title: 'Status', key: 'status', renderFunction: (status) => UploadHelper.getUploadStatus(status)},
			{title: 'Created', key: 'createdDate', type: 'date', renderFunction: (createdDate) => DateHelper.epochToDate(createdDate)},
			{title: 'Uploaded By', key: 'channelMapperName'},

		];

		if (this.isPartnerSheet) {
			columns.splice(2, 0, {title: 'Assigned Partner Org', key: 'partnerOrgName'});
			columns.push({
				title: 'Audit Status',
				key: 'auditStatus',
				renderFunction: (columnValue, rowData) =>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
						{this.isPartnerSheet && <SheetAuditLabel rowData={rowData}/>}
					</div>
			});
		}
		return columns;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getUploadHistory(this.uploadType, params.page, params.pageSize, params.search, params.sort)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{display: 'flex', alignItems: 'center', padding: 20}}>
						{UploadHelper.getSheetTitle(this.uploadType)} will be listed here
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}
}

SheetUploadHistoryPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(SheetUploadHistoryPage);
