export const questionsData = {
	"sales-exec": {
		id: "sales-exec",
		title: "Sales Executives and Sales Reps",
		questions: [
			{
				id: "sales-q1",
				text: "Which partners can help generate new pipeline quickly?",
				flow: "product-selection" // Reference to which flow to use
			},
			{
				id: "sales-q2",
				text: "How can I help my top partners?",
				flow: "partner-selection" // Different flow for this question
			},
			{
				id: "sales-q3",
				text: "Which partners can help close the most business this quarter?",
				flow: "product-selection"
			}
		]
	},
	"marketing": {
		id: "marketing",
		title: "Marketing and Partner Marketing",
		questions: [
			{
				id: "mkt-q1",
				text: "Which partners can help generate new pipeline?",
				flow: "product-selection"
			},
			{
				id: "mkt-q2",
				text: "Which accounts should we focus on?",
				flow: "account-selection"
			},
			{
				id: "mkt-q3",
				text: "Which partners should I invite to our SKO?",
				flow: "partner-selection"
			}
		]
	}
};