import {Beenhere, Group, JoinInner, ListAlt, LocalHospital, LocalOffer, MonetizationOn, Savings, Spa, Whatshot} from '@mui/icons-material';
import {ACCOUNTS_ASSET, OPPORTUNITIES_ASSET} from '../globals/Enums';
import {
	CHANNEL_REPORT_ACTIVE_PARTNER_PIPELINE,
	CHANNEL_REPORT_ACTIVE_PIPELINE,
	CHANNEL_REPORT_ALL,
	CHANNEL_REPORT_AUDITED,
	CHANNEL_REPORT_CONNECTIONS,
	CHANNEL_REPORT_CUSTOMER_CUSTOMER,
	CHANNEL_REPORT_CUSTOMER_PROSPECT,
	CHANNEL_REPORT_GREENFIELD,
	CHANNEL_REPORT_MUTUAL_CUSTOMER,
	CHANNEL_REPORT_OPEN,
	CHANNEL_REPORT_PARTNER_CUSTOMER,
	CHANNEL_REPORT_PARTNER_OPEN,
	CHANNEL_REPORT_PROSPECT_CUSTOMER,
	CHANNEL_REPORT_PROSPECT_PROSPECT,
	CHANNEL_REPORT_SPREADSHEETS
} from './ChannelHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_MATCHING_BY_LOCATION_CONNECTED,
	FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS,
	FEATURE_CHANNEL_VIEW_GREENFIELD_ACCOUNTS,
	FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES,
	FEATURE_REQUIRE_DELETE_ALL_PARTNER_SHEETS
} from './FeatureHelper';
import StringHelper from './StringHelper';

// report asset type
export const ASSET_TYPE_ACCOUNTS = ACCOUNTS_ASSET;
export const ASSET_TYPE_OPPS = OPPORTUNITIES_ASSET;
export const ASSET_TYPE_LEADS = 'LEADS_ASSET';
export const ASSET_TYPE_MAPPED_ACCOUNTS = 'MAPPED_ACCOUNTS_ASSET';
export const ASSET_TYPE_COMPANY_PARTNER = 'COMPANY_PARTNER_ASSET';

// report matching type (values match backend)
export const MATCHING_TYPE_UNMATCHED = 'UNMATCHED';
export const MATCHING_TYPE_MUTUAL = 'MUTUAL';
export const MATCHING_TYPE_GREENFIELD = 'GREENFIELD';
// report matching type for accounts
export const MATCHING_TYPE_ALL_ACCOUNTS = 'ALL_ACCOUNTS';
export const MATCHING_TYPE_ALL_MY_ACCOUNTS = 'ALL_MY_ACCOUNTS';
export const MATCHING_TYPE_ALL_PARTNER_ACCOUNTS = 'ALL_PARTNER_ACCOUNTS';
export const MATCHING_TYPE_MAPPED_ACCOUNTS = 'MAPPED_ACCOUNTS';
export const MATCHING_TYPE_RECENTLY_VIEWED_ACCOUNTS = 'RECENTLY_VIEWED_ACCOUNTS';
// report matching type for opps
export const MATCHING_TYPE_ALL_OPPS = 'ALL_OPPS';
export const MATCHING_TYPE_ALL_MY_SHARED_OPPS = 'ALL_MY_SHARED_OPPS';
export const MATCHING_TYPE_ALL_OPPS_SHARED_TO_ME = 'ALL_OPPS_SHARED_TO_ME';
export const MATCHING_TYPE_MY_MUTUAL_OPPS = 'MY_MUTUAL_OPPS';
export const MATCHING_TYPE_NEW_OPPS_CREATED_AFTER_ACCOUNTS_MATCHED = 'NEW_OPPS_CREATED_AFTER_ACCOUNTS_MATCHED';
export const MATCHING_TYPE_PARTNER_MUTUAL_OPPS = 'PARTNER_MUTUAL_OPPS';
export const MATCHING_TYPE_MY_UNLINKED_OPPS = 'MY_UNLINKED_OPPS';

export const MATCHING_TYPE_ALL_PARTNER_ORGS = 'ALL_PARTNER_ORGS';
export const MATCHING_TYPE_ALL_PARTNER_CONNECTIONS = 'ALL_PARTNER_CONNECTIONS';
export const MATCHING_TYPE_ALL_PARTNER_SHEETS = 'ALL_PARTNER_SHEETS';

// channel report types
//  Base Records
export const CHANNEL_REPORT_TYPE_BASE_ACCOUNTS = 'BASE_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES = 'BASE_OPPORTUNITIES';

// Global
export const CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS = 'GLOBAL_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_GLOBAL_OPPORTUNITIES = 'GLOBAL_OPPORTUNITIES';
export const CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS = 'GLOBAL_MAPPED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_GLOBAL_UNMATCHED_ACCOUNTS = 'GLOBAL_UNMATCHED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_GLOBAL_SOURCED_PIPELINE = 'GLOBAL_SOURCED_PIPELINE';
export const CHANNEL_REPORT_TYPE_GLOBAL_PIPELINE = 'GLOBAL_PIPELINE';
export const CHANNEL_REPORT_TYPE_GLOBAL_PARTNERS = 'GLOBAL_PARTNERS';
export const CHANNEL_REPORT_TYPE_GLOBAL_USERS = 'GLOBAL_USERS';
export const CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS_RECENTLY_VIEWED = 'GLOBAL_MAPPED_ACCOUNTS_RECENTLY_VIEWED';

// Partner Org
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS = 'PARTNER_ORG_MATCHED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES = 'PARTNER_ORG_MATCHED_OPPORTUNITIES';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_GREENFIELD_OPPORTUNITIES = 'PARTNER_ORG_GREENFIELD_OPPORTUNITIES';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNER_ORGS = 'PARTNER_ORG_CONNECTED_PARTNER_ORGS';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS = 'PARTNER_ORG_CONNECTED_PARTNERS';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS_ACTIVE = 'PARTNER_ORG_CONNECTED_PARTNERS_ACTIVE';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_PARTNER_SHEETS = 'PARTNER_ORG_PARTNER_SHEETS';
export const CHANNEL_REPORT_TYPE_PARTNER_ORG_PARTNER_SHEET_UPLOADS = 'PARTNER_ORG_PARTNER_SHEET_UPLOADS';

// Partner Person
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS = 'PARTNER_PERSON_MATCHED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_ACCOUNTS = 'PARTNER_PERSON_MY_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS = 'PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS = 'PARTNER_PERSON_GREENFIELD_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE = 'PARTNER_PERSON_MY_PIPELINE';

export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED = 'PARTNER_PERSON_OPPS_MATCHED';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_SHARED_OPPS = 'PARTNER_PERSON_MY_SHARED_OPPS';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_OPPORTUNITIES = 'PARTNER_PERSON_MY_UNMATCHED_OPPORTUNITIES';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_COMBINED_OPPORTUNITIES = 'PARTNER_PERSON_COMBINED_OPPORTUNITIES';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_OPPORTUNITIES = 'PARTNER_PERSON_GREENFIELD_OPPORTUNITIES';
export const CHANNEL_REPORT_TYPE_PARTNER_PERSON_PARTNER_OPPS_SHARED = 'PARTNER_PERSON_PARTNER_OPPS_SHARED';

// Partner Sheet
export const CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_ACCOUNTS = 'PARTNER_SHEET_MATCHED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_SHEET_UNMATCHED_ACCOUNTS = 'PARTNER_SHEET_UNMATCHED_ACCOUNTS';
export const CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_OPPORTUNITIES = 'PARTNER_SHEET_MATCHED_OPPORTUNITIES';


export default class ReportHelper {

	static getAssetTypeDisplayName(assetType) {
		if (assetType === ACCOUNTS_ASSET) return 'Accounts';
		if (assetType === OPPORTUNITIES_ASSET) return 'Opportunities';
	}

	static getAssetTypeFromDisplayName(displayName) {
		if (displayName === 'Accounts' || displayName === ACCOUNTS_ASSET) return ACCOUNTS_ASSET;
		if (displayName === 'Opportunities' || displayName === 'Opps' || displayName === OPPORTUNITIES_ASSET) return OPPORTUNITIES_ASSET;
	}

	static get accountReportTypes() {
		return [
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_PROSPECT), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_CUSTOMER), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_PROSPECT_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_PROSPECT), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_CUSTOMER_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS, true)
		];
	}

	static get partnerAccountReportTypes() {
		return [
			new ReportType('All matched accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS, true),
			...ReportHelper.accountReportTypes,

			new ReportType('All my accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_ACCOUNTS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_ACCOUNTS, false),
			new ReportType('All my customers', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_ACCOUNTS, false),
			new ReportType('All my prospects', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_ACCOUNTS, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_ACCOUNTS, false),

			new ReportType('All partner accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_PARTNER_ACCOUNTS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS, true),
			new ReportType('All partner customers', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_PARTNER_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS, true),
			new ReportType('All partner prospects', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_PARTNER_ACCOUNTS, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS, true),

			new ReportType('My unmatched accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_UNMATCHED, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS, true),
			new ReportType('My unmatched customers', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_UNMATCHED, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS, true),
			new ReportType('My unmatched prospects', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_UNMATCHED, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS, true),

			new ReportType('Greenfield accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_GREENFIELD, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS, true),
			new ReportType('Greenfield customers', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_GREENFIELD, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS, true),
			new ReportType('Greenfield prospects', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_GREENFIELD, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS, true),

			new ReportType('My pipeline on matched accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_MY_MUTUAL_OPPS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE, true),
			new ReportType('My open pipeline on matched accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_MY_MUTUAL_OPPS, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE, true),
			new ReportType('New opps created after accounts matched', ASSET_TYPE_OPPS, MATCHING_TYPE_NEW_OPPS_CREATED_AFTER_ACCOUNTS_MATCHED, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE, true)
		];
	}

	static get mappedAccountsReportTypes() {
		return [
			new ReportType('All my accounts', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_ALL_MY_ACCOUNTS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS, false),
			new ReportType('All matched accounts', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_MAPPED_ACCOUNTS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS, true),
			new ReportType('All mutual customer accounts', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_MAPPED_ACCOUNTS, CHANNEL_REPORT_MUTUAL_CUSTOMER, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS, true),
			new ReportType('Accounts with active pipeline', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_ALL_MY_ACCOUNTS, CHANNEL_REPORT_ACTIVE_PIPELINE, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS, true),
			new ReportType('Accounts with active partner pipeline', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_MAPPED_ACCOUNTS, CHANNEL_REPORT_ACTIVE_PARTNER_PIPELINE, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS, true),
			new ReportType('All partner customer accounts', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_MAPPED_ACCOUNTS, CHANNEL_REPORT_PARTNER_CUSTOMER, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS, true),
			new ReportType('Recently viewed accounts', ASSET_TYPE_MAPPED_ACCOUNTS, MATCHING_TYPE_RECENTLY_VIEWED_ACCOUNTS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_GLOBAL_MAPPED_ACCOUNTS_RECENTLY_VIEWED, true)
		];
	}

	static get partnerOpportunityReportTypes() {
		return [
			new ReportType('All partner opps shared with me', ASSET_TYPE_OPPS, MATCHING_TYPE_ALL_OPPS_SHARED_TO_ME, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_PARTNER_OPPS_SHARED, true),
			new ReportType('All partner open opps shared with me', ASSET_TYPE_OPPS, MATCHING_TYPE_ALL_OPPS_SHARED_TO_ME, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_PARTNER_OPPS_SHARED, true),

			new ReportType('All unmatched opps', ASSET_TYPE_OPPS, MATCHING_TYPE_UNMATCHED, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_OPPORTUNITIES, true),
			new ReportType('All unmatched open opps', ASSET_TYPE_OPPS, MATCHING_TYPE_UNMATCHED, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_OPPORTUNITIES, true),

			new ReportType('All greenfield opps', ASSET_TYPE_OPPS, MATCHING_TYPE_GREENFIELD, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_OPPORTUNITIES,true),
			new ReportType('All greenfield open opps', ASSET_TYPE_OPPS, MATCHING_TYPE_GREENFIELD, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_OPPORTUNITIES,true),

			new ReportType('All partner opps on matched accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED, true),
			new ReportType('All partner open opps on matched accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED, true),
			new ReportType('All partner opps on mutual customer accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED, true),
			new ReportType('All partner opps on mutual prospect accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED, true),

			new ReportType('All opps I shared', ASSET_TYPE_OPPS, MATCHING_TYPE_ALL_MY_SHARED_OPPS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_SHARED_OPPS, true),
			new ReportType('All open opps I shared', ASSET_TYPE_OPPS, MATCHING_TYPE_ALL_MY_SHARED_OPPS, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_SHARED_OPPS, true)
		];
	}

	static get sheetAccountReportTypes() {
		return [
			new ReportType('Matched accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_ALL, true),
			...ReportHelper.accountReportTypes,
			new ReportType('Audited accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_AUDITED, true)
		];
	}

	static get companyPartnerReportTypes() {
		return [
			new ReportType('All Partner Organizations', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_PARTNER_ORGS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNER_ORGS, true),
			new ReportType('All Partner Connections', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_PARTNER_CONNECTIONS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS_ACTIVE, true),
			new ReportType('All Partner Sheets', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_ALL_PARTNER_SHEETS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_ORG_PARTNER_SHEETS, true)
		];
	}

	static get companyPartnerAccountReportTypes() {
		return [
			new ReportType('All matched accounts', ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_PROSPECT), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_CUSTOMER), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_PROSPECT_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS, true),
			new ReportType(ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_PROSPECT), ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, CHANNEL_REPORT_CUSTOMER_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS, true)
		];
	}

	static get companyPartnerOpportunityReportTypes() {
		return [
			new ReportType('All partner opps on matched accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_ALL, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES, true),
			new ReportType('All partner open opps on matched accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_OPEN, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES, true),
			new ReportType('All partner opps on mutual customer accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_CUSTOMER_CUSTOMER, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES, true),
			new ReportType('All partner opps on mutual prospect accounts', ASSET_TYPE_OPPS, MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_PROSPECT_PROSPECT, CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES, true)
		];
	}

	static getReportTypeByTitle(reportTypes, reportTitle) {
		return reportTypes.find((reportType) => reportType.reportTitle === reportTitle);
	}

	static getReportTitle(assetType, matchingType, filterType) {
		let reportType = ReportHelper.getReportTypes(assetType)
		.find((reportType) => reportType.matchingType === matchingType && reportType.filterType === filterType);
		if (reportType) return reportType.reportTitle;
		return 'Invalid getReportTitle args: ' + assetType + ' ' + matchingType + ' ' + filterType;
	}

	static getReportTypes(assetType) {
		if (assetType === ASSET_TYPE_ACCOUNTS) {
			return ReportHelper.partnerAccountReportTypes;
		}
		else if (assetType === ASSET_TYPE_OPPS) {
			return ReportHelper.partnerOpportunityReportTypes;
		}
		else {
			return ['Invalid getReportTypes arg: ' + assetType];
		}
	}

	static getReportFilterTitle(assetType, filterType) {
		switch (filterType) {
			case CHANNEL_REPORT_CUSTOMER_CUSTOMER:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Mutual customers';
				if (assetType === ASSET_TYPE_OPPS) return 'Partner opps on customers';
				break;
			case CHANNEL_REPORT_CUSTOMER_PROSPECT:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Help my partner';
				if (assetType === ASSET_TYPE_OPPS) return 'Help my partner opps';
				break;
			case CHANNEL_REPORT_PROSPECT_PROSPECT:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Mutual prospects';
				if (assetType === ASSET_TYPE_OPPS) return 'Partner opps on prospects';
				break;
			case CHANNEL_REPORT_PROSPECT_CUSTOMER:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Hot prospects';
				if (assetType === ASSET_TYPE_OPPS) return 'Hot prospect opps';
				break;
			case CHANNEL_REPORT_ALL:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Accounts';
				if (assetType === ASSET_TYPE_OPPS) return 'Opps';
				break;
			case CHANNEL_REPORT_OPEN:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Open pipeline';
				if (assetType === ASSET_TYPE_OPPS) return 'Open opps';
				break;
			case CHANNEL_REPORT_GREENFIELD:
				if (assetType === ASSET_TYPE_ACCOUNTS) return 'Greenfield accounts';
				if (assetType === ASSET_TYPE_OPPS) return 'Greenfield opps';
				break;
			case CHANNEL_REPORT_CONNECTIONS:
				return 'Partner connections';
			case CHANNEL_REPORT_SPREADSHEETS:
				return 'Sheets';
		}
		return 'Invalid getReportFilterTitle args: ' + assetType + ' ' + filterType;
	}

	static getReportFilterIcon(assetType, filterType) {
		switch (filterType) {
			case CHANNEL_REPORT_CUSTOMER_CUSTOMER:
				if (assetType === ASSET_TYPE_ACCOUNTS) return MonetizationOn;
				if (assetType === ASSET_TYPE_OPPS) return Savings;
				break;
			case CHANNEL_REPORT_PROSPECT_CUSTOMER:
				return Whatshot;
			case CHANNEL_REPORT_CUSTOMER_PROSPECT:
				return LocalHospital;
			case CHANNEL_REPORT_PROSPECT_PROSPECT:
				return LocalOffer;
			case CHANNEL_REPORT_AUDITED:
				return Beenhere;
			case CHANNEL_REPORT_GREENFIELD:
				return Spa;
			case CHANNEL_REPORT_CONNECTIONS:
				return Group;
			case CHANNEL_REPORT_SPREADSHEETS:
				return ListAlt;
		}
		return JoinInner;
	}
}

class ReportType {

	constructor(reportTitle, assetType, matchingType, filterType, channelReportType, reportDownloadable) {
		this.reportTitle = reportTitle;
		this.assetType = assetType;
		this.matchingType = matchingType;
		this.filterType = filterType;
		this.channelReportType = channelReportType;
		this.reportDownloadable = reportDownloadable;
	}

	get isDownloadable() {
		return this.reportDownloadable;
	}

	get isAccountsGreenfield() {
		return this.matchingType === MATCHING_TYPE_GREENFIELD || this.matchingType === MATCHING_TYPE_ALL_PARTNER_ACCOUNTS;
	}

	get isAccountsUnmatched() {
		return this.matchingType === MATCHING_TYPE_UNMATCHED;
	}

	get isAccountsAll() {
		return this.matchingType === MATCHING_TYPE_ALL_ACCOUNTS;
	}

	get isOppsGreenfield() {
		return this.assetType === ASSET_TYPE_OPPS && (this.matchingType === MATCHING_TYPE_GREENFIELD || this.matchingType === MATCHING_TYPE_ALL_OPPS_SHARED_TO_ME);
	}

	get isOppsUnmatched() {
		return this.assetType === ASSET_TYPE_OPPS && (this.matchingType === MATCHING_TYPE_UNMATCHED || this.matchingType === MATCHING_TYPE_ALL_MY_SHARED_OPPS);
	}

	get isOppsCombined() {
		return this.matchingType === MATCHING_TYPE_MUTUAL || this.matchingType === MATCHING_TYPE_ALL_OPPS;
	}

	get isMyAccounts() {
		return this.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_ACCOUNTS;
	}

	get isMyOpps() {
		return this.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE;
	}

	get isMySharedOpps() {
		return this.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_SHARED_OPPS;
	}

	get isPartnerAccounts() {
		return this.matchingType === MATCHING_TYPE_ALL_PARTNER_ACCOUNTS;
	}

	get isRecentlyViewed() {
		return this.matchingType === MATCHING_TYPE_RECENTLY_VIEWED_ACCOUNTS;
	}

	get isAudited() {
		return this.filterType === CHANNEL_REPORT_AUDITED;
	}

	get hasActionColumn() {
		return Boolean(this.matchingType === MATCHING_TYPE_ALL_PARTNER_ORGS || (this.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_ORG_PARTNER_SHEETS && FeatureHelper.isFeatureEnabled(FEATURE_REQUIRE_DELETE_ALL_PARTNER_SHEETS)));
	}

	get inherentReportFilters() {
		if (this.assetType === ASSET_TYPE_ACCOUNTS) {
			if (this.isAccountsGreenfield || this.isPartnerAccounts) {
				switch (this.filterType) {
					case CHANNEL_REPORT_CUSTOMER_CUSTOMER:
						return {partnerIsCustomer: true};
					case CHANNEL_REPORT_PROSPECT_PROSPECT:
						return {partnerIsCustomer: false};
				}
				return null;
			}
			if (this.isAccountsUnmatched || this.isAccountsAll) {
				switch (this.filterType) {
					case CHANNEL_REPORT_CUSTOMER_CUSTOMER:
						return {isCustomer: true};
					case CHANNEL_REPORT_PROSPECT_PROSPECT:
						return {isCustomer: false};
				}
				return null;
			}
			if (this.isAudited) {
				return {isAudited: true};
			}
			switch (this.filterType) {
				case CHANNEL_REPORT_CUSTOMER_CUSTOMER:
					return {isCustomer: true, partnerIsCustomer: true};
				case CHANNEL_REPORT_PROSPECT_CUSTOMER:
					return {isCustomer: false, partnerIsCustomer: true};
				case CHANNEL_REPORT_CUSTOMER_PROSPECT:
					return {isCustomer: true, partnerIsCustomer: false};
				case CHANNEL_REPORT_PROSPECT_PROSPECT:
					return {isCustomer: false, partnerIsCustomer: false};
			}
		}
		else if (this.assetType === ASSET_TYPE_OPPS) {
			switch (this.filterType) {
				case CHANNEL_REPORT_OPEN:
					return {isClosed: false};
				case CHANNEL_REPORT_PARTNER_OPEN:
					return {partnerIsClosed: false};
				case CHANNEL_REPORT_CUSTOMER_CUSTOMER:
					return {isCustomer: true, partnerIsCustomer: true};
				case CHANNEL_REPORT_PROSPECT_PROSPECT:
					return {isCustomer: false, partnerIsCustomer: false};
			}
		}
		else if (this.assetType === ASSET_TYPE_MAPPED_ACCOUNTS) {
			switch (this.filterType) {
				case CHANNEL_REPORT_ALL:
					return {};
				case CHANNEL_REPORT_MUTUAL_CUSTOMER:
					return {mutualCustomerCount: '1->-'};
				case CHANNEL_REPORT_ACTIVE_PIPELINE:
					return {openPipelineAmountForInherentFilter: '1->-'};
				case CHANNEL_REPORT_ACTIVE_PARTNER_PIPELINE:
					return {activePartnerPipelineCount: '1->-'};
				case CHANNEL_REPORT_PARTNER_CUSTOMER:
					return {partnerCustomerCount: '1->-'};
			}
		}
		return null;
	}

	get downloadFileName() {
		return StringHelper.formatKey(this.reportTitle);
	}

	upsellIfDisabledReportFeature(mapByLocation) {
		if (mapByLocation && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_MATCHING_BY_LOCATION_CONNECTED)) {
			return FEATURE_CHANNEL_MATCHING_BY_LOCATION_CONNECTED;
		}
		if (this.assetType === ASSET_TYPE_ACCOUNTS) {
			if ((this.matchingType === MATCHING_TYPE_GREENFIELD ||
				 this.matchingType === MATCHING_TYPE_ALL_PARTNER_ACCOUNTS) &&
				!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_GREENFIELD_ACCOUNTS)) {
				return FEATURE_CHANNEL_VIEW_GREENFIELD_ACCOUNTS;
			}
		}
		else if (this.assetType === ASSET_TYPE_OPPS) {
			if ((this.matchingType === MATCHING_TYPE_GREENFIELD ||
				 this.matchingType === MATCHING_TYPE_ALL_OPPS_SHARED_TO_ME ||
				 this.matchingType === MATCHING_TYPE_ALL_OPPS) &&
				!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES)) {
				return FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES;
			}
			if ((this.matchingType !== MATCHING_TYPE_MY_MUTUAL_OPPS &&
				this.matchingType !== MATCHING_TYPE_NEW_OPPS_CREATED_AFTER_ACCOUNTS_MATCHED &&
				this.matchingType !== MATCHING_TYPE_ALL_MY_SHARED_OPPS) &&
				!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS)) {
				return FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS;
			}
		}
		return null;
	}
}
